import { Alert, Button, Col, message, Modal, Row, Tooltip } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  BookingError,
  BookingStartResponse,
  RevenueBookingClient,
  RevenueBookingInput,
  RevenueType,
} from "common/services/microservices/finance-client";
import { authenticatedClient } from "core/components/AuthProvider";
import React, { useState } from "react";
import { Months } from "./MonthlyOriginalRevenueBookingTool";
import { WorkClient, WorkStateType } from "common/services/microservices/basic-client";
import { waitFor } from "common/utilities/waitFor";

interface RevenueBookerProps {
  input: RevenueBookingInput;
  bookingType: RevenueType;
}

interface AlertGuts {
  type: "success" | "warning";
  message: string;
}

export function MonthlyRevenueBooker(props: RevenueBookerProps) {
  const { bookingType } = props;
  const { year, month, accounting_date, posting_date } = props.input;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNoOrigModalOpen, setIsNoOrigModalOpen] = useState(false);
  const [alertGuts, setAlertGuts] = useState<AlertGuts | undefined>();

  const revenueName = bookingType === RevenueType.Original ? "Original" : "True-up";

  async function checkRevenueExists(type: RevenueType) {
    try {
      const client = await authenticatedClient(MicroservicesEndpoint.finance, RevenueBookingClient);
      return client.checkRevenueExists(month, year, type);
    } catch (ex) {
      message.error("Unable to check if revenue exists.");
    }
    return false;
  }

  async function bookRevenue() {
    setAlertGuts(undefined);
    try {
      message.info(`Starting booking revenue. This could take a while.`);

      const client = await authenticatedClient(MicroservicesEndpoint.finance, RevenueBookingClient);

      const basicClient = await authenticatedClient(MicroservicesEndpoint.basic, WorkClient);

      let guid: string;
      if (bookingType === RevenueType.Original) {
        guid = (await client.startBookingOriginalRevenueTask(new RevenueBookingInput(props.input))).guid;
      } else {
        guid = (await client.startBookingDownstreamRevenueTask(new RevenueBookingInput(props.input))).guid;
      }

      await waitFor(async () => {
        let state = await basicClient.getWorkState(guid);

        if (state.state === WorkStateType.Failed) throw "Job failed.";

        return state.state === WorkStateType.Success;
      });

      const resultUrl = await basicClient.getWorkResultDownloadUrl(guid, null, null);
      let result = await fetch(resultUrl.presignedUrl);
      let resultData: BookingStartResponse = await result.json();

      console.log(resultData);

      if (resultData.error === BookingError.AcquireLockFailed) {
        message.warning("There is already a booking task in progress. Please wait a few minutes and try again.");
        setAlertGuts({
          type: "warning",
          message: `There is already a booking task running. Try again in a few minutes.`,
        });
      } else {
        message.success(`Successfully booked revenue for ${month}/${year}!`);
        setAlertGuts({
          type: "success",
          message: `${revenueName} revenue for ${month}/${year} booked successfully.`,
        });
      }
    } catch (ex) {
      message.error("Unable to book revenue. Please contact engineering.");
    }
  }

  return (
    <>
      <Tooltip title="A check will be preformed for existing values first.">
        <Button
          className={`btn btn-success`}
          id={`book-button-${revenueName}`}
          onClick={async () => {
            setLoading(true);
            try {
              if (await checkRevenueExists(bookingType)) {
                setIsModalOpen(true);
              } else if (bookingType === RevenueType.TrueUp) {
                if (!(await checkRevenueExists(RevenueType.Original))) {
                  setIsNoOrigModalOpen(true);
                } else {
                  await bookRevenue();
                }
              } else {
                await bookRevenue();
              }
            } catch (ex) {
            } finally {
              setLoading(false);
            }
          }}
          loading={isLoading}
          style={{
            height: "60px",
            fontSize: "11px",
            whiteSpace: "break-spaces",
          }}
        >
          Book {revenueName} Revenue for {Months[month - 1]} {year}
        </Button>
      </Tooltip>
      <Row>
        <Col span={16} offset={4}>
          {alertGuts && <Alert showIcon type={alertGuts.type} className="mt-3" message={alertGuts.message} />}
        </Col>
      </Row>
      <Modal
        title="Revenue Already Exists"
        width={"1000px"}
        visible={isModalOpen}
        cancelText="Nah"
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          // bookRevenue(); Booking not implemented yet if revenue already exists.
          setIsModalOpen(false);
        }}
      >
        {revenueName} revenue is already booked for {month}/{year}. Please contact James to delete for that month first.
      </Modal>
      <Modal
        title="Original Revenue Does Not Exist"
        width={"1000px"}
        visible={isNoOrigModalOpen}
        cancelText="Nah"
        onCancel={() => {
          setIsNoOrigModalOpen(false);
        }}
        onOk={() => {
          // bookRevenue(); Booking not implemented yet if revenue already exists.
          setIsNoOrigModalOpen(false);
        }}
      >
        You want to book {revenueName} revenue for {month}/{year}, but there is no original revenue for that month.
        You'll need to book that first.
      </Modal>
    </>
  );
}
