import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Col, DatePicker, Dropdown, Form, Input, InputNumber, Row, Select, Switch, Menu } from "antd";
import CarrierSelect from "common/components/CarrierSelect";
import { EnsProductCarrierTid, EnsProductTid } from "common/services/ApiServerClient";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ProductSelect from "../../../../common/components/ProductSelect";
import StatusSelect from "../StatusSelect";
import { DownOutlined } from "@ant-design/icons";
import AgentSelect from "../AgentSelect";
import FinanceTagSelect from "./FinanceTagsSelect";
import { authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { SaleFinanceTagClient } from "common/services/microservices/finance-client";
import { EnsUserInfo } from "common/services/microservices/ensusers-client";
import { SaleStatus, PolicyNumberRecord, EnsSaleStatusTid } from "common/services/microservices/sales-client";

export interface SalesTabObject {
  ens_product_carrier_tid: number | undefined;
  ens_product_tid: number | undefined;
  premium: number | undefined;
  premium_multiplier: number | undefined;
  issued_ens_sale_status_tid: number | undefined;
  current_ens_sale_status_tid: number | undefined;
  sale_datetime: Date | undefined;
  effective_date: Date | undefined;
  approval_datetime: Date | undefined;
  termination_date: Date | undefined;
  notes: string | undefined;
  rewrite_fl: boolean;
  primary_policy_number: string | undefined;
  agent_ens_user_tid: number | undefined;
  sale_finance_tags: number[] | undefined;
}

interface SalesTabProps {
  selectedData: (data: SalesTabObject) => void;
  selectedRecord?: SaleStatus;
  insertModal?: boolean;
}

interface MultiplierOptions {
  multiplier_value: number;
  mutliplier_text: string;
}

export const multiplierDropwdown: MultiplierOptions[] = [
  { multiplier_value: 1, mutliplier_text: "Annually" },
  { multiplier_value: 2, mutliplier_text: "Semiannually" },
  { multiplier_value: 4, mutliplier_text: "Quarterly" },
  { multiplier_value: 12, mutliplier_text: "Monthly" },
];

const dateFormat = "MM/DD/YYYY";

export default function SalesTab(props: SalesTabProps) {
  const { selectedData, selectedRecord, insertModal } = props;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [carrierSelected, setCarrierSelected] = useState<EnsProductCarrierTid | undefined>(
    !insertModal ? selectedRecord?.ens_product_carrier_tid : undefined
  );
  const [productSelected, setProductSelected] = useState<EnsProductTid | undefined>(
    !insertModal ? selectedRecord?.ens_product_tid : undefined
  );
  const [primaryPolicy, setPrimaryPolicy] = useState<string | undefined>(
    !insertModal ? selectedRecord?.primary_policy_number : undefined
  );
  const [premium, setPremium] = useState<number | undefined>(!insertModal ? selectedRecord?.premium : undefined);
  const [premiumMultiplier, setPremiumMultiplier] = useState<number | undefined>(
    !insertModal ? selectedRecord?.premium_multiplier : 12
  );
  const [issueStatus, setIssueStatus] = useState<number | undefined>(
    !insertModal ? selectedRecord?.issued_ens_sale_status_tid : EnsSaleStatusTid.Pending
  );
  const [currentStatus, setCurrentStatus] = useState<number | undefined>(
    !insertModal ? selectedRecord?.current_ens_sale_status_tid : EnsSaleStatusTid.Pending
  );
  const [saleDate, setSaleDate] = useState<moment.Moment | undefined>(
    !insertModal ? moment(selectedRecord?.sale_datetime) : undefined
  );
  const [effectiveDate, setEffectiveDate] = useState<moment.Moment | undefined>(
    !insertModal ? moment(selectedRecord?.effective_date) : undefined
  );
  const [approvalDate, setApprovalDate] = useState<moment.Moment | undefined>(
    !insertModal && selectedRecord?.approval_datetime ? moment(selectedRecord.approval_datetime) : undefined
  );
  const [terminationDate, setTerminationDate] = useState<moment.Moment | undefined>(
    !insertModal && selectedRecord?.termination_date ? moment(selectedRecord?.termination_date) : undefined
  );
  const [notes, setNotes] = useState<string | undefined>(!insertModal ? selectedRecord?.recent_note : undefined);
  const [financeTags, setFinanceTags] = useState<number[] | undefined>(!insertModal ? [] : undefined);
  const [rewrite, setRewrite] = useState<boolean>(false);
  const [agentSelected, setAgentSelected] = useState<number | undefined>(
    !insertModal ? selectedRecord?.agent_ens_user_tid : undefined
  );

  useEffect(() => {
    if (!selectedRecord) return;

    form.setFieldsValue({
      carrier: selectedRecord.ens_product_carrier_tid,
      product: selectedRecord.ens_product_tid,
      primaryPolicy: selectedRecord.primary_policy_number,
      premium: selectedRecord.premium,
      premiumMultiplier: selectedRecord.premium_multiplier,
      issueStatus: selectedRecord.issued_ens_sale_status_tid,
      currentStatus: selectedRecord.current_ens_sale_status_tid,
      saleDate: moment(selectedRecord.sale_datetime),
      effectiveDate: moment(selectedRecord.effective_date),
      approvalDate: selectedRecord.approval_datetime ? moment(selectedRecord.approval_datetime) : undefined,
      terminationDate: selectedRecord.termination_date ? moment(selectedRecord.termination_date) : undefined,
      notes: selectedRecord.recent_note,
      rewrite: selectedRecord.rewrite_fl,
      agent: agentSelected,
    });

    authenticatedFetch(MicroservicesEndpoint.finance, SaleFinanceTagClient, (c) =>
      c.getSaleFinanceTagsForSale(selectedRecord.ens_sale_tid)
    ).then((tags) => {
      const tagTids = tags.map((t) => t.tid);
      setFinanceTags(tagTids);
      form.setFieldsValue({
        financeTags: tagTids,
      });
    });

    // eslint-disable-next-line
  }, [selectedRecord]);

  useEffect(() => {
    selectedData({
      ens_product_carrier_tid: carrierSelected,
      ens_product_tid: productSelected,
      premium: premium,
      premium_multiplier: premiumMultiplier,
      issued_ens_sale_status_tid: issueStatus,
      current_ens_sale_status_tid: currentStatus,
      sale_datetime: saleDate?.toDate(),
      effective_date: effectiveDate?.toDate(),
      approval_datetime: approvalDate?.toDate(),
      termination_date: terminationDate?.toDate(),
      notes: notes,
      rewrite_fl: rewrite,
      primary_policy_number: primaryPolicy,
      agent_ens_user_tid: agentSelected,
      sale_finance_tags: financeTags,
    });
    // eslint-disable-next-line
  }, [
    carrierSelected,
    productSelected,
    primaryPolicy,
    premium,
    premiumMultiplier,
    issueStatus,
    currentStatus,
    saleDate,
    effectiveDate,
    approvalDate,
    terminationDate,
    notes,
    rewrite,
    agentSelected,
    financeTags,
  ]);

  const layout = {
    labelCol: { span: 8 },
  };

  const menu = (
    <Menu>
      {selectedRecord?.policy_number_history?.map((policy: PolicyNumberRecord, index: number) => (
        <Menu.Item key={index}>{`${policy.policy_number} - ${policy.effective_date?.toLocaleDateString(
          "en-US"
        )}`}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Form {...layout} name="basic" initialValues={{ remember: true }} form={form}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Carrier" name="carrier" rules={[{ required: true }]}>
            <CarrierSelect
              onCarrierSelected={(carrierId: number) => setCarrierSelected(carrierId)}
              data={selectedRecord}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Product" name="product" rules={[{ required: true }]}>
            <ProductSelect
              onProductSelected={(productId: number) => setProductSelected(productId)}
              data={selectedRecord}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Policy number" name="primaryPolicy">
            <Input
              style={{ width: "100%" }}
              placeholder="Set policy number"
              onChange={(e: any) => setPrimaryPolicy(e.target.value)}
              value={selectedRecord ? selectedRecord.primary_policy_number : undefined}
            ></Input>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Alt Policy:" name="alternatePolicy">
            {selectedRecord?.policy_number_history?.length !== 0 && !insertModal ? (
              <Dropdown overlay={menu}>
                <a href="# " className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                  Multiple policies <DownOutlined />
                </a>
              </Dropdown>
            ) : (
              <Input
                style={{ width: "100%" }}
                disabled={true}
                placeholder={!insertModal ? "No alternate policies" : undefined}
              ></Input>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Premium" name="premium" rules={[{ type: "number", message: "Numbers only!" }]}>
            <InputNumber
              style={{ width: "100%" }}
              precision={2}
              formatter={(value) => `$ ${value?.toLocaleString("en-US", { maximumFractionDigits: 2 })}`}
              placeholder="Set premium"
              onChange={(e: any) => setPremium(e)}
              value={selectedRecord ? selectedRecord.premium : undefined}
            ></InputNumber>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Multiplier" name="premiumMultiplier">
            <Select
              allowClear
              placeholder="Set premium multiplier"
              onChange={(value, option) => {
                setPremiumMultiplier(value as number);
              }}
              defaultValue={premiumMultiplier ?? multiplierDropwdown[3].mutliplier_text} //defaults to Monthly
            >
              {multiplierDropwdown.map((item) => {
                return (
                  <Option key={item.multiplier_value} value={item.multiplier_value}>
                    {item.mutliplier_text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Issue status" name="issueStatus" rules={[{ required: true }]}>
            <StatusSelect
              onStatusSelected={(statusId: number) => setIssueStatus(statusId)}
              issueStatus={true}
              data={selectedRecord}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Current status" name="currentStatus" rules={[{ required: true }]}>
            <StatusSelect onStatusSelected={(statusId: number) => setCurrentStatus(statusId)} data={selectedRecord} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Sale date" name="saleDate" rules={[{ required: true, type: "date" }]}>
            <DatePicker
              showTime={{ format: "hh:mm a", use12Hours: true }}
              style={{ width: "100%" }}
              value={saleDate}
              format={dateFormat.concat(" hh:mm a")}
              placeholder="Select sale date"
              onChange={(e: any) => {
                setSaleDate(e);
              }}
            ></DatePicker>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Effective date" name="effectiveDate" rules={[{ required: true, type: "date" }]}>
            <DatePicker
              style={{ width: "100%" }}
              value={effectiveDate}
              format={dateFormat}
              placeholder="Select effective date"
              onChange={(e: any) => setEffectiveDate(e)}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Approval date" name="approvalDate">
            <DatePicker
              showTime={{ format: "hh:mm a", use12Hours: true }}
              style={{ width: "100%" }}
              defaultValue={approvalDate}
              format={dateFormat.concat(" hh:mm a")}
              placeholder="Select approval date"
              onChange={(e: any) => setApprovalDate(e)}
            ></DatePicker>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Termination" name="terminationDate">
            <DatePicker
              style={{ width: "100%" }}
              defaultValue={terminationDate}
              format={dateFormat}
              placeholder="Select termination date"
              onChange={(e: any) => {
                setTerminationDate(e);
              }}
            ></DatePicker>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Form.Item label="Agent" name="agent" rules={[{ required: true }]}>
            <AgentSelect
              onAgentSelected={(agentId: number | undefined) => {
                setAgentSelected(agentId);
              }}
              data={selectedRecord}
              style={{ width: "100%" }}
              additionalAgents={[
                {
                  ensUserTid: 8,
                  userName: "DTC@insuretysolutions.com",
                  userFirstName: "DTC",
                  userLastName: "DTC",
                  userEmail: "DTC@insuretysolutions.com",
                  active: false,
                } as EnsUserInfo,
                {
                  ensUserTid: 11,
                  userName: "Coverance@insuretysolutions.com",
                  userFirstName: "Coverance",
                  userLastName: "Coverance",
                  userEmail: "Coverance@insuretysolutions.com",
                  active: false,
                } as EnsUserInfo,
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Rewrite?" name="rewrite">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={selectedRecord ? selectedRecord.rewrite_fl : undefined}
              onChange={(checked) => {
                setRewrite(checked);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Finance Tags" name="financeTags">
            <FinanceTagSelect onChange={(value) => setFinanceTags(value)} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
