import React, { useEffect, useState } from "react";
import { Button, Form, message, Modal, Row, Typography } from "antd";
import { authenticatedClient, authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  InsertSaleNoteRequest,
  SaleNoteHistoryClient,
  SaleNotesObject,
} from "common/services/microservices/sales-client";
import TextArea from "antd/lib/input/TextArea";
import SaleNotesTable from "./SaleNotesTable";
import moment from "moment";

interface SaleNotesModalProps {
  showModal: boolean;
  hideModal: () => void;
  ensSaleTid: number | undefined;
  loggedInUserTid: number;
  refreshTableAfterAddingNote: () => Promise<void>;
}

export default function SaleNotesModal(props: SaleNotesModalProps) {
  const { hideModal, ensSaleTid, loggedInUserTid, showModal, refreshTableAfterAddingNote } = props;
  const [saleNotes, setSaleNotes] = useState<SaleNotesObject[]>([]);
  const [showDeleteNoteModal, setShowDeleteNoteModal] = useState<boolean>(false);
  const [editableSaleNote, setEditableSaleNote] = useState<SaleNotesObject | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [refreshSalesGrid, setRefreshSalesGrid] = useState<boolean>(false);
  const [form] = Form.useForm();
  const dateFormat = "MM/DD/yyyy hh:mm a";

  async function getSaleNotes() {
    const saleNotes = await authenticatedFetch(MicroservicesEndpoint.sales, SaleNoteHistoryClient, (client) =>
      client.getAllSaleNotes(ensSaleTid!)
    );
    setSaleNotes(saleNotes);
  }

  useEffect(() => {
    if (ensSaleTid) getSaleNotes();
  }, [ensSaleTid]);

  const addNewSaleNote = async () => {
    try {
      setIsSubmitting(true);
      const client = await authenticatedClient(MicroservicesEndpoint.sales, SaleNoteHistoryClient);
      const request = new InsertSaleNoteRequest({
        ens_sale_tid: ensSaleTid!,
        ens_user_tid: loggedInUserTid,
        note_content: form.getFieldValue("noteContents"),
      });
      await client.insertSaleNote(request);
      await getSaleNotes();
      setRefreshSalesGrid(true);
      message.success("Sale note successfully created!");
      form.resetFields();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        visible={showModal}
        okButtonProps={{ style: { display: "none" } }}
        onCancel={async () => {
          if(refreshSalesGrid) await refreshTableAfterAddingNote();
          hideModal()
        }}
        width={600}
      >
        <Row style={{ margin: "1rem 0" }}>
          <h5>
            <Typography>Sale Note History for {ensSaleTid} </Typography>
          </h5>
        </Row>
        <Form form={form} onFinish={addNewSaleNote}>
          <Row style={{ marginBottom: "1rem" }}>
            <Form.Item style={{ width: "100%", flexDirection: "column" }} label="Contents" name="noteContents">
              <TextArea
                placeholder={"Note contents"}
                onChange={(e: any) => form.setFieldsValue({ noteContents: e.target.value })}
                rows={4}
                cols={100}
                autoSize={false}
                style={{ width: "100%", overflowY: "auto" }}
              ></TextArea>
            </Form.Item>
          </Row>
          <Row style={{ float: "right" }}>
            <Button disabled={isSubmitting} loading={isSubmitting} htmlType="submit" type="primary">
              Create Note
            </Button>
          </Row>
        </Form>
        <SaleNotesTable saleNotes={saleNotes} />
      </Modal>
    </>
  );
}
