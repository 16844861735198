import React from "react";
import { Col, Divider, Input, Row, Tabs } from "antd";
import { useState } from "react";
import CronTaskEventByGuidDisplay from "operations/commission-upload/components/CronTaskEventDisplay";
import Table, { ColumnsType } from "antd/lib/table";
import { RunCashMatchingButton } from "operations/common/CashMatchingButton";

interface MatchTaskResult {
  PolicyNumber: string;
  EffectiveDate: Date;
  Result: Result;
}

interface Result {
  EnsSaleTid: number;
  MatchState: number;
  MatchErrors?: MatchError[];
  MatcherName: string;
}

interface MatchError {
  PossibleEnsSaleTids?: number[];
  ErrorMessage: string;
}

interface CashMatchTaskResult {
  Success: boolean;
  UpdatedResults: MatchTaskResult[];
  ErrorResults: MatchTaskResult[];
}

function TableWithSearch(props: { results: MatchTaskResult[]; columns: ColumnsType<MatchTaskResult> }) {
  const { results: dataRows, columns } = props;

  const [searchText, setSearchText] = useState<string>();

  const data = searchText ? dataRows.filter((c) => c.PolicyNumber.toLowerCase().includes(searchText)) : dataRows;

  return (
    <>
      <Row>
        <Col>
          <Input.Search
            placeholder="Enter policy number..."
            enterButton="Search"
            size="large"
            onSearch={(x) => setSearchText(x)}
          />
        </Col>
      </Row>
      <Table<MatchTaskResult> columns={columns} dataSource={data} />
    </>
  );
}

function CashMatchingSuccessTable(props: { results: MatchTaskResult[] }) {
  const columns: ColumnsType<MatchTaskResult> = [
    {
      title: "Policy Number",
      render: (text: string, record, index: number) => record.PolicyNumber,
    },
    {
      title: "Effective Date",
      render: (text: string, record, index: number) => record.EffectiveDate,
    },
    {
      title: "Matched Sale",
      render: (text: string, record, index: number) => record.Result.EnsSaleTid,
    },
  ];

  return <TableWithSearch columns={columns} results={props.results} />;
}

function CashMatchingErrorTable(props: { results: MatchTaskResult[] }) {
  const columns: ColumnsType<MatchTaskResult> = [
    {
      title: "Policy Number",
      render: (text: string, record, index: number) => record.PolicyNumber,
    },
    {
      title: "Effective Date",
      render: (text: string, record, index: number) => record.EffectiveDate,
    },
    {
      title: "Error",
      render: (text: string, record, index: number) => record.Result.MatchErrors?.[0].ErrorMessage,
    },
  ];

  return <TableWithSearch columns={columns} results={props.results} />;
}

export function CashMatchingSubApp() {
  const [taskGuid, setTaskGuid] = useState<string>();
  const [taskResult, setTaskResults] = useState<CashMatchTaskResult>();

  return (
    <>
      <h3>Cash Matching</h3>
      <RunCashMatchingButton onGuidStarted={setTaskGuid} />
      {taskGuid && (
        <>
          {taskResult && (
            <div className="p-4">
              <Divider />
              <h4>Cash Matching Results</h4>
              <Tabs className="p-4">
                <Tabs.TabPane tab="New Cash Matched" key={"good"}>
                  <CashMatchingSuccessTable results={taskResult.UpdatedResults} />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Matching Errors" key={"bad"}>
                  <CashMatchingErrorTable results={taskResult.ErrorResults} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          )}
          {!taskResult && (
            <Row justify="center">
              <CronTaskEventByGuidDisplay<CashMatchTaskResult>
                guid={taskGuid}
                onTaskFinished={(results) => setTaskResults(results)}
              />
            </Row>
          )}
        </>
      )}
    </>
  );
}
