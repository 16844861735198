import React from "react";
import { Tabs } from "antd";
import { MonthlyOriginalRevenueBookingTool } from "./RevenueBookingTool/MonthlyOriginalRevenueBookingTool";
import { TrueUpRevenueBookingTool } from "./RevenueBookingTool/TrueUpRevenueBookingTool";
import { TrueUpReportGeneratorTool } from "./RevenueBookingTool/TrueUpReportGenerator";
import { FullReportGeneratorLiveTool } from "./RevenueBookingTool/FullReportGeneratorLive";
import { FullReportGeneratorCashReceived } from "./RevenueBookingTool/FullReportGeneratorCashReceived";

export function RevenueBookingTool() {
  return (
    <Tabs style={{ marginLeft: "20px", marginRight: "20px" }}>
      <Tabs.TabPane tab="Original Revenue" key="item-1">
        <MonthlyOriginalRevenueBookingTool />
      </Tabs.TabPane>
      <Tabs.TabPane tab="True-up Revenue" key="item-2">
        <TrueUpRevenueBookingTool />
      </Tabs.TabPane>
      <Tabs.TabPane tab="True-up Pivot Report" key="item-3">
        <TrueUpReportGeneratorTool />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Live Forecast Report" key="item-4">
        <FullReportGeneratorLiveTool />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Actual Cash Received Report" key="item-5">
        <FullReportGeneratorCashReceived />
      </Tabs.TabPane>
    </Tabs>
  );
}
