import ApplicationPageProps from "common/models/ApplicationPageProps";
import React from "react";
import { Tabs } from "antd";
import { EnsUserPermissionTypeTid } from "common/services/ApiServerClient";
import F9AudioApp from "../data/components/f9audio/F9AudioApp";
import Paragraph from "antd/lib/typography/Paragraph";
import DictionaryApp from "./components/dictionary/DictionaryApp";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import F9ComplianceApp from "./components/f9compliance/F9ComplianceApp";

const { TabPane } = Tabs;

export default function DataApp(props: ApplicationPageProps) {
  const { user } = props;
  return (
    <Paragraph className="container border bg-white p-4 shadow-sm border mt-4">
      <Tabs defaultActiveKey="2">
        {/* {user.hasPermission(EnsUserPermissionType.DataAccess) && (
          <TabPane tab="Dictionary" key="1">
            <DictionaryApp user={user} />
          </TabPane>
        )} */}
        {user.hasPermission(EnsUserPermissionType.CallAnalyticsAccess) && (
          <TabPane tab="Call Search" key="2">
            <F9ComplianceApp />
          </TabPane>
        )}
      </Tabs>
    </Paragraph>
  );
}
