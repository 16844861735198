import CarrierDropDown from "common/components/CarrierDropDown";
import StatusAlert from "common/components/StatusAlert";
import { authenticatedFetch } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import StateRtsColorGenerator from "licensing/models/StateRtsColorization";
import "licensing/scss/LicenseMap.scss";
import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { MapModal } from "./MapModal";
import ProductCategoryDropdown from "./ProductCategoryDropdown";
import RtsYearDropDown from "./RtsYearDropdown";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import {
  EnsProductCarrierTid,
  EnsProductCategory,
  EnsUserRtsTypeTid,
  ReadyToSellClient,
  RtsStatus,
} from "common/services/microservices/licenses-client";
import { EnsUser } from "common/services/ApiServerClient";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
let ReactUsaMap = require("react-usa-map").default;

const RtsReportCarriers = [
  EnsProductCarrierTid.Aetna,
  EnsProductCarrierTid.Anthem,
  EnsProductCarrierTid.Humana,
  EnsProductCarrierTid.UnitedHealthcare,
  EnsProductCarrierTid.HarvardPilgrim,
  EnsProductCarrierTid.Cdphp,
];

interface RtsTabProps {
  user: UserClass;
  agent: EnsUser;
}

export default function RtsMap(props: RtsTabProps): JSX.Element {
  const { agent, user } = props;
  const [rtsStatuses, setRtsStatuses] = useState<RtsStatus[]>([]);
  const [carrierIdValue, setCarrierIdValue] = useState<EnsProductCarrierTid>();
  const [productCategoryIdValue, setProductCategoryIdValue] = useState<EnsProductCategory>();
  const [selectedState, setSelectedState] = useState<string>();
  const [selectedYear, setSelectedYear] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const mapHandler = (event: any) => {
    setSelectedState(event.target.dataset.name);
  };

  const getRtsString = (o: RtsStatus) => EnsUserRtsTypeTid[o.type];

  const RtsModal = (rts: RtsStatus | undefined) => {
    if (!rts) {
      return "No RTS data.";
    }

    return (
      <>
        <Row className="justify-content-md-center">
          <Col md={10} sm={10} className="border mx-2 shadow-sm">
            <Row className="justify-content-md-center">
              <h4 className="pt-2">Ready to Sell Status</h4>
            </Row>
            <Row className="justify-content-md-center">
              <h6> {getRtsString(rts)}</h6>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  const getRts = (userId: number, carrierId: EnsProductCarrierTid, productId: EnsProductCategory, year: number) => {
    authenticatedFetch(MicroservicesEndpoint.licenses, ReadyToSellClient, (c) => {
      setIsLoading(true);
      setDisplayError(false);
      return c.getAgentRtsStatuses(userId, carrierId, productId, year);
    })
      .then((res) => {
        setIsLoading(false);
        setRtsStatuses(res);
      })
      .catch((e) => {
        alert("Something went wrong");
        setIsLoading(false);
        setDisplayError(true);
      });
  };

  useEffect(() => {
    if (!carrierIdValue || !productCategoryIdValue) return;

    if (hasYearOption(carrierIdValue) && !selectedYear) return;

    getRts(agent.ens_user_tid, carrierIdValue, productCategoryIdValue, selectedYear ?? new Date().getFullYear());
  }, [agent, carrierIdValue, selectedYear, productCategoryIdValue]);

  const hasYearOption = (carrierTid: EnsProductCarrierTid | undefined) => {
    return RtsReportCarriers.some((r) => r.valueOf() === carrierTid);
  };

  return (
    <>
      {displayError && <StatusAlert shouldDisplay={true} success={false} closable={true} />}
      <Row className="my-2">
        <Col md={8}>
          Product
          <ProductCategoryDropdown
            onProductSelected={(productCategoryId: number) => setProductCategoryIdValue(productCategoryId)}
            whiteListAppointments
            displayError={(error: boolean) => setDisplayError(error)}
          />
        </Col>
        <Col md={8}>
          Carrier
          <CarrierDropDown
            onCarrierSelected={(carrierId: number) => setCarrierIdValue(carrierId)}
            displayError={(error: boolean) => setDisplayError(error)}
          />
        </Col>
        {hasYearOption(carrierIdValue) && (
          <Col md={8}>
            Year
            <RtsYearDropDown onYearSelected={(year: number) => setSelectedYear(year)} />
          </Col>
        )}
      </Row>
      <Row>
        {displayError && <StatusAlert shouldDisplay={true} success={false} />}
        {isLoading ? (
          <>
            <div style={{ opacity: 0.3, width: "100%" }}>
              <ReactUsaMap customize={StateRtsColorGenerator([])} onClick={mapHandler} />
            </div>
            <Spin
              style={{
                margin: "0",
                marginTop: "290px",
                position: "absolute",
                left: "50%",
              }}
            />
          </>
        ) : (
          <ReactUsaMap customize={StateRtsColorGenerator(rtsStatuses)} onClick={mapHandler} />
        )}
      </Row>
      <Col>
        <svg width="18" height="18">
          <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#4C8E4C", stroke: "black" }} />
        </svg>{" "}
        Ready to Sell
      </Col>
      <Col className="align-middle">
        <svg width="18" height="18">
          <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#CFCFC4", stroke: "black" }} />
        </svg>{" "}
        Inactive
      </Col>

      {user.hasPermission(EnsUserPermissionType.LicensingAccess) ||
        (user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
          <MapModal displayLicense={true} closeModal={() => setSelectedState(undefined)} selectedState={selectedState}>
            {RtsModal(rtsStatuses.find((rts) => rts.state === selectedState))}
          </MapModal>
        ))}
    </>
  );
}
