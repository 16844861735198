import React, { useEffect, useState } from "react";
import Select, { ValueType } from "react-select";
import {
  ProductCarrierClient,
  EnsProductCategoryResponse,
  EnsProductCategoryTid,
} from "common/services/ApiServerClient";
import { authenticatedFetch } from "core/components/AuthProvider";
import ApiServerService from "common/services/ApiServerService";

interface ProductDropdownProps {
  onProductSelected: (productCategoryId: number) => void;
  whiteListAppointments?: boolean;
  displayError?: (error: boolean) => void;
  style?: React.CSSProperties;
}

const whiteList = [
  EnsProductCategoryTid.LifeInsurance,
  EnsProductCategoryTid.MedicareAdvantage,
  EnsProductCategoryTid.MedSupp,
  EnsProductCategoryTid.MedicarePDP,
  EnsProductCategoryTid.VisionDentalHearing,
  EnsProductCategoryTid.Other,
];

type OptionType = { value: number; label: string };

export default function ProductCategoryDropdown(props: ProductDropdownProps): JSX.Element {
  const { whiteListAppointments, onProductSelected, displayError, style } = props;
  const [loading, setLoading] = useState(false);
  const [productCategoryList, setProductCategoryList] = useState<EnsProductCategoryResponse[]>([]);

  const options = [...(productCategoryList ?? [])]
    .sort((a, b) => (a.product_category_name > b.product_category_name ? 1 : -1))
    .map((item) => {
      return { value: item.ens_product_category_tid, label: item.product_category_name } as OptionType;
    });

  const appointmentOptions = [...(productCategoryList ?? [])]
    .filter((product) => whiteList.some((x) => x === product.ens_product_category_tid))
    .sort((a, b) => (a.product_category_name > b.product_category_name ? 1 : -1))
    .map((item) => {
      return { value: item.ens_product_category_tid, label: item.product_category_name } as OptionType;
    });

  useEffect(() => {
    const getProductCategory = () => {
      authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
        setLoading(true);
        return c.getActiveProductCategory();
      })
        .then((res: EnsProductCategoryResponse[]) => {
          setProductCategoryList(res);
        })
        .catch(() => {
          displayError!(true);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getProductCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Select
        style={style}
        loading={loading}
        options={whiteListAppointments ? appointmentOptions : options}
        placeholder={"Select product"}
        onChange={(selectedOption: ValueType<OptionType>) => {
          const value = (selectedOption as OptionType).value;
          onProductSelected(value);
        }}
      />
    </>
  );
}
