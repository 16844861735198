import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { EnsSaleStatusTid, SaleStatus } from "common/services/microservices/sales-client";

const { Option } = Select;

interface StatusSelectProps {
  clear?: number;
  issueStatus?: boolean;
  placeholder?: string;
  formDisplay?: boolean;
  data?: SaleStatus;
  onStatusSelected: (statusId: number) => void;
  style?: React.CSSProperties;
}

export default function StatusSelect(props: StatusSelectProps) {
  const { clear, issueStatus, data, onStatusSelected, style, placeholder, formDisplay } = props;
  const [selectedStatus, setSelectedStatus] = useState<number | undefined>(
    issueStatus ? data?.issued_ens_sale_status_tid : data?.current_ens_sale_status_tid
  );

  useEffect(() => {
    setSelectedStatus(undefined);
  }, [clear]);

  useEffect(() => {
    if (!data) return;

    if (issueStatus) setSelectedStatus(data.issued_ens_sale_status_tid);
    else setSelectedStatus(data.current_ens_sale_status_tid);
  }, [data, issueStatus]);

  const salesStatus: Record<EnsSaleStatusTid, string> = {
    [EnsSaleStatusTid.Cancelled]: "Canceled",
    [EnsSaleStatusTid.Lapsed]: "Lapsed",
    [EnsSaleStatusTid.Deleted]: "Deleted",
    [EnsSaleStatusTid.Duplicate]: "Duplicate",
    [EnsSaleStatusTid.Incomplete]: "Incomplete",
    [EnsSaleStatusTid.Issued]: "Issued",
    [EnsSaleStatusTid.NTO]: "NTO",
    [EnsSaleStatusTid.Pending]: "Pending",
    [EnsSaleStatusTid.PendingCms]: "Pending CMS",
    [EnsSaleStatusTid.Rejected]: "Rejected",
    [EnsSaleStatusTid.RapidDisenrollment]: "Rapid Disenrollment",
    [EnsSaleStatusTid.Terminated]: "Terminated",
    [EnsSaleStatusTid.Error]: "Error",
  };

  return (
    <Select
      allowClear
      showSearch
      style={style}
      placeholder={placeholder}
      optionFilterProp="children"
      value={selectedStatus}
      onChange={(value, option) => {
        onStatusSelected(value);
        setSelectedStatus(value);
      }}
      filterOption={(input: string, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      defaultValue={!formDisplay ? selectedStatus ?? EnsSaleStatusTid.Pending : selectedStatus} //defaults to pending in add/update modals
    >
      {Object.entries(salesStatus)
        .sort(([_1, name1], [_2, name2]) => name1.localeCompare(name2))
        .map(([tid, name]) => (
          <Option key={tid} value={parseInt(tid)}>
            {name}
          </Option>
        ))}
    </Select>
  );
}
