import { Col, Divider, Row, Select, Tabs } from "antd";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import UserClass from "core/models/UserClass";
import React from "react";
import { useState } from "react";
import RequestsHistory from "./RequestsHistory";
import RequestStateLicenses from "./RequestStateLicenses";
import ReviewRequests from "./ReviewRequests";

const { Option } = Select;
const { TabPane } = Tabs;

interface LicensingRequestsProps {
  user: UserClass;
}

export default function LicensingRequests(props: LicensingRequestsProps) {
  const { user } = props;
  const [tool, setTool] = useState<string>();

  return (
    <>
      <Tabs type="card" defaultActiveKey="New Requests">
        <TabPane tab="New Requests" key="New Requests">
          <Row>
            <Col span={24}>
              <Select placeholder="Select Request Type" style={{ width: 200 }} onChange={(v) => setTool(v?.toString())}>
                <Option value="RequestStateLicenses">Request State Licenses</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 15 }}>
            <Col span={24}>
              <Tool tool={tool} user={user} />
            </Col>
          </Row>
        </TabPane>
        {user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
          <TabPane key="Review Requests" tab="Review Requests">
            <ReviewRequests />
          </TabPane>
        )}
        <TabPane key="Requests History" tab="Requests History">
          <RequestsHistory />
        </TabPane>
      </Tabs>
    </>
  );
}

interface ToolProps {
  user: UserClass;
  tool: string | undefined;
}

const Tool = (props: ToolProps) => {
  const { tool, user } = props;

  if (!tool) return null;

  switch (tool) {
    case "RequestStateLicenses":
      return <RequestStateLicenses user={user} />;
    default:
      return null;
  }
};
