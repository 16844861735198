import { Button, message, notification, Radio, RadioChangeEvent, Space } from "antd";
import { authenticatedClient, authenticatedFetch } from "core/components/AuthProvider";
import React, { useEffect, useRef, useState } from "react";
import AddEntryModal from "./EntryModal/AddEntryModal";
import SalesStatusForms from "./SalesStatusForms";
import UpdateModal from "./EntryModal/UpdateModal";
import StatusAlert from "common/components/StatusAlert";
import { PlusCircleOutlined } from "@ant-design/icons";
import { MarketingTableColumns } from "./MarketingTableProps";
import { SalesTableColumns } from "./SalesTableProps";
import { DemographicTableColumns } from "./DemographicTableProps";
import { AgentTableColumns } from "./AgentTableProps";
import SalesStatusTable from "./SalesStatusTable";
import { ColumnsType } from "antd/lib/table";
import { CashReceiptTableColumns } from "./CashReceiptTableProps";
import {
  ForecastTabSaleInfo,
  GetSaleStatusToolForecastTabRequest,
  GetSaleStatusToolForecastTabResponse,
  SaleFinanceTagClient,
  SaleStatusToolForecastTabClient,
} from "common/services/microservices/finance-client";
import UserClass from "core/models/UserClass";
import SaleNotesModal from "./SaleNotesModal/SaleNotesModal";
import { ForecastTableColumns } from "./ForecastTableProps";
import {
  SalesStatusSearchRequest,
  SalesStatusClient,
  SaleStatus,
  ISaleUpdateRequest,
  SaleUpdateRequest,
  ApiException,
  SaleInsertClient,
  SaleInsertRequest,
} from "common/services/microservices/sales-client";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";

// Combines data needed to update most of the sale via API Server (ISaleUpdateRequest)
// and other properties via microservices
export interface FullSaleUpdateRequest extends ISaleUpdateRequest {
  sale_finance_tags?: number[];
}

interface SalesStatusToolProps {
  user: UserClass;
}

export default function SalesStatusTool(props: SalesStatusToolProps) {
  const [tableData, setTableData] = useState<SaleStatus[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [fcTableLoading, setFcTableLoading] = useState(false);
  //selectedRecord is the stored record when a user Clicks a row in the table
  const [selectedRecord, setSelectedRecord] = useState<SaleStatus>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
  const [notesSaleTid, setNotesSaleTid] = useState<number | undefined>(undefined);
  const [calledApi, setCalledApi] = useState<boolean>(false);
  const [tableViewValue, setTableViewValue] = useState("Sales");
  const [tableColumns, setTableColumns] = useState<
    ColumnsType<SaleStatus> | ColumnsType<GetSaleStatusToolForecastTabResponse>
  >();
  const tableRef = useRef<HTMLInputElement>(null);
  const loggedInUserTid = props.user.EnsUserTid;

  const showSalesNoteModal = (ensSaleTid: number) => {
    setNotesSaleTid(ensSaleTid);
    setShowNotesModal(true);
  };

  const [forecastTableData, setForecastTableData] = useState<GetSaleStatusToolForecastTabResponse[]>();
  const [fcCurrentPage, setFCCurrentPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const refreshFcData = async (currentPage?: number | undefined) => {
    if (currentPage == 1) setFCCurrentPage(currentPage);
    try {
      const sales = [
        ...new Set(
          tableData && tableData.length > 0
            ? tableData.map(
                (di: SaleStatus) =>
                  new ForecastTabSaleInfo({
                    ensSaleTid: di.ens_sale_tid,
                    saleDateTime: di.sale_datetime,
                    effectiveDate: di.effective_date,
                    approvalDatetime: di.approval_datetime,
                    terminationDate: di.termination_date,
                  })
              )
            : []
        ),
      ];
      const client = await authenticatedClient(MicroservicesEndpoint.finance, SaleStatusToolForecastTabClient);
      const forecastData = await client.getSaleStatusToolForecastTab(
        new GetSaleStatusToolForecastTabRequest({
          ensSales: sales,
          salesPerPage: 50,
          currentPage: currentPage ?? fcCurrentPage,
        })
      );
      setForecastTableData(forecastData);
    } finally {
      setFcTableLoading(false);
    }
  };

  const handleAdd = (row: SaleInsertRequest) => {
    let ensSaleTidToReturn: number | undefined;
    setTableLoading(true);
    return authenticatedClient(MicroservicesEndpoint.sales, SaleInsertClient)
      .then((c) => c.insertSale(row))
      .then((newEnsSale) => {
        ensSaleTidToReturn = newEnsSale.ensSaleTid;
        return authenticatedFetch(MicroservicesEndpoint.sales, SalesStatusClient, async (c) => {
          let request = new SalesStatusSearchRequest({
            ensSaleTid: ensSaleTidToReturn,
            getSaleNotes: true,
          });
          return c.searchSales(request);
        }).then((response) => {
          setTableLoading(false);
          let row = response.find((r) => r.ens_sale_tid === newEnsSale.ensSaleTid);

          if (!row) {
            setDisplayError(true);
            return;
          }
          setTableData([row, ...tableData]);
          return ensSaleTidToReturn;
        });
      })
      .catch(() => {
        setTableLoading(false);
        setDisplayError(true);
      });
  };

  const refreshTableAfterAddingNote = async () => {
    const salesClient = await authenticatedClient(MicroservicesEndpoint.sales, SalesStatusClient);

    let request = new SalesStatusSearchRequest({
      ensSaleTid: notesSaleTid,
      getSaleNotes: true,
    });

    var getSales = await salesClient.searchSales(request);
    let updatedSale = getSales.find((r) => r.ens_sale_tid === notesSaleTid);
    let filteredData = tableData.filter((r) => r.ens_sale_tid !== updatedSale?.ens_sale_tid);
    setTableData([updatedSale!, ...filteredData]);
  };

  const handleUpdate = async (row: FullSaleUpdateRequest) => {
    const salesClient = await authenticatedClient(MicroservicesEndpoint.sales, SalesStatusClient);
    const financeMsClient = await authenticatedClient(MicroservicesEndpoint.finance, SaleFinanceTagClient);

    setTableLoading(true);

    try {
      var newEnsSale = await salesClient.updateSale(
        new SaleUpdateRequest({ ens_sale_tid: selectedRecord!.ens_sale_tid, ...row })
      );

      await financeMsClient.updateSaleFinanceTagsForSale(
        newEnsSale.ensSaleTid!,
        props.user.UserEmail,
        row.sale_finance_tags ?? []
      );

      let request = new SalesStatusSearchRequest({
        ensSaleTid: newEnsSale.ensSaleTid,
        getSaleNotes: true,
      });

      var getSales = await salesClient.searchSales(request);

      setTableLoading(false);
      let updateSale = getSales.find((r) => r.ens_sale_tid === newEnsSale.ensSaleTid);

      if (!updateSale) {
        setDisplayError(true);
        return;
      }
      let filteredData = tableData.filter((r) => r.ens_sale_tid !== updateSale?.ens_sale_tid);
      setTableData([updateSale, ...filteredData]);
    } catch (e) {
      setTableLoading(false);
      setDisplayError(true);
      notification.error({
        message: "Failed to update sale.",
        description: JSON.parse((e as ApiException).response).detail,
      });
    }
  };

  const saleTableNotesFunc = (saleTid: number) => {
    setNotesSaleTid(saleTid);
    setShowNotesModal(true);
  };
  useEffect(() => {
    if (tableData) tableRef!.current!.scrollIntoView();
  }, [tableData]);

  useEffect(() => {
    switch (tableViewValue) {
      case "Agent":
        setTableColumns(AgentTableColumns);
        break;
      case "Demographics":
        setTableColumns(DemographicTableColumns);
        break;
      case "Marketing":
        setTableColumns(MarketingTableColumns);
        break;
      case "Cash Receipt":
        setTableColumns(CashReceiptTableColumns);
        break;
      case "Forecast":
        setTableColumns(ForecastTableColumns);
        break;
      default:
        setTableColumns(SalesTableColumns(saleTableNotesFunc));
    }
  }, [tableViewValue]);

  //Refresh forecast data when sales data is retrieved.
  useEffect(() => {
    if (calledApi) setFcTableLoading(true);
  }, [tableData, calledApi, fcCurrentPage]);

  useEffect(() => {
    if (calledApi) refreshFcData(1);
  }, [tableData, calledApi]);

  useEffect(() => {
    refreshFcData(fcCurrentPage);
  }, [fcCurrentPage]);

  //Resets error display upon selecting edit
  useEffect(() => {
    setDisplayError(false);
  }, [selectedRecord]);

  const tableViewOptions = [
    { label: "Sales", value: "Sales" },
    { label: "Agent", value: "Agent" },
    { label: "Demographics", value: "Demographics" },
    { label: "Marketing", value: "Marketing" },
    { label: "Cash Receipt", value: "Cash Receipt" },
    { label: "Forecast", value: "Forecast" },
  ];

  const tableViewOnchange = (e: RadioChangeEvent) => setTableViewValue(e.target.value);

  return (
    <>
      <SalesStatusForms
        filterFetchData={(data: SaleStatus[]) => {
          setTableData(data);
          setCalledApi(true);
        }}
        setTableLoad={(clicked) => setTableLoading(clicked)}
        onInsertApiFail={(response) => setDisplayError(response)}
      />
      {showModal && (
        <AddEntryModal
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          callInsertApi={handleAdd}
          loggedInUserTid={loggedInUserTid}
        />
      )}
      <SaleNotesModal
        showModal={showNotesModal}
        hideModal={() => {
          setShowNotesModal(false);
          setNotesSaleTid(undefined);
        }}
        ensSaleTid={notesSaleTid}
        loggedInUserTid={loggedInUserTid}
        refreshTableAfterAddingNote={refreshTableAfterAddingNote}
      />
      {selectedRecord && (
        <UpdateModal
          hideModal={() => setSelectedRecord(undefined)}
          selectedRecord={selectedRecord}
          callUpdateApi={handleUpdate}
        />
      )}
      {displayError && <StatusAlert shouldDisplay={true} success={false} closable={true} />}

      <div ref={tableRef} style={{ padding: 10 }}>
        <Space>
          <span>View:</span>
          <Radio.Group
            options={tableViewOptions}
            onChange={tableViewOnchange}
            value={tableViewValue}
            optionType="button"
            buttonStyle="solid"
          />
        </Space>
        {props.user.hasPermission(EnsUserPermissionType.EnterSale) && (
          <Button
            type="primary"
            icon={<PlusCircleOutlined style={{ verticalAlign: "inherit" }} />}
            onClick={() => {
              setShowModal(true);
              setDisplayError(false);
            }}
            style={{ float: "right" }}
          >
            Add New Entry
          </Button>
        )}
        <SalesStatusTable
          data={tableData}
          fcData={forecastTableData}
          columns={tableColumns}
          showSalesNoteModal={showSalesNoteModal}
          tableViewValue={tableViewValue}
          isForecastTable={tableViewValue === "Forecast"}
          selectedRow={(record) => setSelectedRecord(record)}
          calledApi={calledApi}
          tableLoading={tableLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          fcCurrentPage={fcCurrentPage}
          setFCCurrentPage={setFCCurrentPage}
          fcTableLoading={fcTableLoading}
          user={props.user}
        />
      </div>
    </>
  );
}
