import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { SaleStatus } from "common/services/microservices/sales-client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PhoneNumberInput from "../PhoneNumberInput";

export interface DemographicsTabObject {
  insured_first_name: string | undefined;
  insured_last_name: string | undefined;
  age_at_tos: number | undefined;
  insured_address_line1: string | undefined;
  insured_city: string | undefined;
  insured_state: string | undefined;
  insured_zip: string | undefined;
  insured_gender: string | undefined;
  insured_dob: Date | undefined;
  insured_email: string | undefined;
  insured_phone: string | undefined;
  medicare_id: string | undefined;
}

interface GenderOptions {
  gender_value: string;
  gender_title: string;
}

interface DemographicsTabProps {
  selectedData: (data: DemographicsTabObject) => void;
  selectedRecord?: SaleStatus;
  insertModal?: boolean;
  calculatedAge?: number;
}

const dateFormat = "MM/DD/YYYY";

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const genderDropdown: GenderOptions[] = [
  { gender_value: "M", gender_title: "Male" },
  { gender_value: "F", gender_title: "Female" },
];

export default function DemographicsTab(props: DemographicsTabProps) {
  const { selectedData, selectedRecord, insertModal, calculatedAge } = props;
  const { Option } = Select;
  const [form] = Form.useForm();
  const [insuredFirstName, setInsuredFirstName] = useState<string | undefined>(
    !insertModal ? selectedRecord?.insured_first_name : undefined
  );
  const [insuredLastName, setInsuredLastName] = useState<string | undefined>(
    !insertModal ? selectedRecord?.insured_last_name : undefined
  );
  const [ageAtTos, setAgeAtTos] = useState<number | undefined>(!insertModal ? selectedRecord?.age_at_tos : undefined);
  const [address, setAddress] = useState<string | undefined>(
    !insertModal ? selectedRecord?.insured_address_line1 : undefined
  );
  const [city, setCity] = useState<string | undefined>(!insertModal ? selectedRecord?.insured_city : undefined);
  const [state, setState] = useState<string | undefined>(!insertModal ? selectedRecord?.insured_state : undefined);
  const [zip, setZip] = useState<string | undefined>(!insertModal ? selectedRecord?.insured_zip : undefined);
  const [gender, setGender] = useState<string | undefined>();
  const [insuredDob, setInsuredDob] = useState<moment.Moment | undefined>(
    !insertModal && selectedRecord?.insured_dob ? moment(selectedRecord?.insured_dob) : undefined
  );
  const [email, setEmail] = useState<string | undefined>(!insertModal ? selectedRecord?.insured_email : undefined);
  const [phone, setPhone] = useState<string | undefined>(!insertModal ? selectedRecord?.insured_phone : undefined);
  const [medicareId, setMedicareId] = useState<string | undefined>(
    !insertModal ? selectedRecord?.medicare_id : undefined
  );

  const zipValidation = (value: string) => {
    value = value.replace(/\D/g, "");
    form.setFieldsValue({
      zip: value,
    });
    setZip(value);
    return;
  };

  const phoneValidation = (value: string) => {
    value = value.replace(/\D/g, "");
    value = value.slice(1);
    form.setFieldsValue({
      phone: value,
    });
    setPhone(value);
    return;
  };

  useEffect(() => {
    form.setFieldsValue({
      age: calculatedAge,
    });
    setAgeAtTos(calculatedAge);
  }, [calculatedAge, form]);

  useEffect(() => {
    if (!selectedRecord) return;
    form.setFieldsValue({
      firstName: selectedRecord.insured_first_name,
      lastName: selectedRecord.insured_last_name,
      age: selectedRecord.age_at_tos,
      streetAddress: selectedRecord.insured_address_line1,
      city: selectedRecord.insured_city,
      state: selectedRecord.insured_state,
      zip: selectedRecord.insured_zip,
      gender: selectedRecord.insured_gender,
      dateOfBirth: selectedRecord?.insured_dob ? moment(selectedRecord.insured_dob) : undefined,
      email: selectedRecord.insured_email,
      phone: selectedRecord.insured_phone,
      medicareId: selectedRecord.medicare_id,
    });
    // eslint-disable-next-line
  }, [selectedRecord]);

  useEffect(() => {
    selectedData({
      insured_first_name: insuredFirstName,
      insured_last_name: insuredLastName,
      age_at_tos: ageAtTos,
      insured_address_line1: address,
      insured_city: city,
      insured_state: state,
      insured_zip: zip,
      insured_gender: gender,
      insured_dob: insuredDob?.toDate(),
      insured_email: email,
      insured_phone: phone,
      medicare_id: medicareId,
    });
    // eslint-disable-next-line
  }, [
    insuredFirstName,
    insuredLastName,
    ageAtTos,
    address,
    city,
    state,
    zip,
    gender,
    insuredDob,
    email,
    phone,
    medicareId,
  ]);

  const layout = {
    labelCol: { span: 8 },
  };

  return (
    <Form {...layout} name="basic" initialValues={{ remember: true }} form={form}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="First name" name="firstName">
            <Input
              style={{ width: "100%" }}
              placeholder="Insured first name"
              onChange={(e: any) => setInsuredFirstName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Last name" name="lastName">
            <Input
              style={{ width: "100%" }}
              placeholder="Insured last name"
              onChange={(e: any) => setInsuredLastName(e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Age at TOS" name="age">
            <Input
              style={{ width: "100%" }}
              placeholder="Age at TOS"
              value={ageAtTos}
              maxLength={3}
              disabled={true}
            ></Input>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Address" name="streetAddress">
            <Input
              style={{ width: "100%" }}
              placeholder="Address"
              onChange={(e: any) => setAddress(e.target.value)}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="City" name="city">
            <Input
              style={{ width: "100%" }}
              placeholder="Insured city"
              onChange={(e: any) => setCity(e.target.value)}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="State" name="state">
            <Select
              allowClear
              showSearch
              placeholder={"Insured state"}
              onChange={(value, option) => {
                setState(value as string);
              }}
            >
              {states.map((item) => {
                return (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="ZIP" name="zip">
            <Input
              style={{ width: "100%" }}
              placeholder="Insured ZIP"
              onChange={(e: any) => zipValidation(e.target.value)}
              maxLength={5}
              value={zip}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Gender" name="gender">
            <Select
              showSearch
              placeholder={"Insured gender"}
              onChange={(value, option) => {
                setGender(value as string);
              }}
            >
              {genderDropdown.map((item) => {
                return (
                  <Option key={item.gender_value} value={item.gender_value}>
                    {item.gender_title}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="DOB" name="dateOfBirth" rules={[{ required: true, type: "date" }]}>
            <DatePicker
              style={{ width: "100%" }}
              value={insuredDob}
              format={dateFormat}
              placeholder="Insured DOB"
              onChange={(e: any) => setInsuredDob(e)}
            ></DatePicker>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="Email" name="email">
            <Input
              style={{ width: "100%" }}
              placeholder="Insured Email"
              onChange={(e: any) => setEmail(e.target.value)}
              value={email}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Phone" name="phone">
            <PhoneNumberInput onChange={phoneValidation} value={phone} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Medicare ID" name="medicareId">
            <Input
              style={{ width: "100%" }}
              onChange={(e: any) => setMedicareId(e.target.value)}
              value={medicareId}
            ></Input>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
