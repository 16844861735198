import { Alert, Button, Col, Descriptions, Divider, Drawer, Empty, message, Row, Select, Space, Spin, Table, Tabs, Typography } from "antd";
import Title from "antd/lib/typography/Title";
import { EnsUser } from "common/services/ApiServerClient";
import { AgentStateLicensesRequest, AgentStateLicensesRequestClient, EnsLicensingRequestStatusTypeTid, EnsLicensingRequestTypeTid, LicenseRequest, RequestsClient } from "common/services/microservices/licenses-client";
import UserClass from "core/models/UserClass";
import { ReloadOutlined } from '@ant-design/icons';
import React, { useEffect } from "react"
import { useState } from "react";
import RequestStateLicenses from "./RequestStateLicenses";
import ReviewRequests from "./ReviewRequests";
import { ColumnsType } from "antd/lib/table";
import { authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";

const { Option } = Select;
const { TabPane } = Tabs;
const { Text } = Typography;

interface RequestsHistoryProps {
  //user: UserClass;
}

export default function RequestsHistory(props: RequestsHistoryProps) {
  const [tool, setTool] = useState<string>();
  const [requests, setRequests] = useState<LicenseRequest[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<LicenseRequest>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadRequests();
  }, []);

  const loadRequests = () => {
    setRequests([]);
    setSelectedRequest(undefined);

    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, RequestsClient, async c => {
      let res = await c.getLatestRequests(100);
      setRequests(res);
    })
      .catch(e => message.error("Something went wrong."))
      .finally(() => setLoading(false));
  }

  const columns: ColumnsType<LicenseRequest> = [
    {
      title: (text: string, record: LicenseRequest, index: number) =>
        <Button icon={<ReloadOutlined />} onClick={() => loadRequests()} />,
      render: (value: any, record: LicenseRequest, index: number) => <Button onClick={() => setSelectedRequest(record)}>View</Button>
    },
    {
      title: "Requestor",
      dataIndex: ["requestor", "userEmail"],
      filters: [...new Set((requests || []).map((i) => i.requestor.userEmail))].map((userEmail) => {
        return {
          text: userEmail,
          value: userEmail,
        };
      }),
      onFilter: (value: any, record: LicenseRequest) => record.requestor.userEmail === value
    },
    {
      title: "Agent",
      dataIndex: ["target", "userEmail"],
      filters: [...new Set((requests || []).map((i) => i.target.userEmail))].map((userEmail) => {
        return {
          text: userEmail,
          value: userEmail,
        };
      }),
      onFilter: (value: any, record: LicenseRequest) => record.target.userEmail === value
    },
    {
      title: "Request Type",
      render: (value: any, record: LicenseRequest, index: number) => EnsLicensingRequestTypeTid[record.ensLicensingRequestType],
      filters: [...new Set((requests || []).map((i) => i.ensLicensingRequestType))].map((reqType) => {
        return {
          text: EnsLicensingRequestTypeTid[reqType],
          value: reqType,
        };
      }),
      onFilter: (value: any, record: LicenseRequest) => record.ensLicensingRequestType === value
    },
    {
      title: "Review Date",
      render: (value: any, record: LicenseRequest, index: number) => record.approvalDateTime?.toLocaleDateString("en-US")
    },
    {
      title: "Status",
      render: (value: any, record: LicenseRequest, index: number) => EnsLicensingRequestStatusTypeTid[record.ensLicensingRequestStatusType],
      filters: [...new Set((requests || []).map((i) => i.ensLicensingRequestStatusType))].map((reqType) => {
        return {
          text: EnsLicensingRequestStatusTypeTid[reqType],
          value: reqType,
        };
      }),
      onFilter: (value: any, record: LicenseRequest) => record.ensLicensingRequestStatusType === value
    }
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={requests}
        columns={columns}
        rowKey={(record) => record.ensLicenseRequestTid}
        pagination={{ pageSize: 5 }}
      />
      <Drawer
        title="Viewing Request"
        placement="right"
        width="75%"
        visible={selectedRequest !== undefined}
        onClose={() => loadRequests()}
      >
        <ViewRequest request={selectedRequest} />
        <div style={{ textAlign: "right", width: "calc(100% - 24px)", bottom: 10, position: "absolute", padding: 5 }}>
          <Text type="secondary" >Request: {selectedRequest?.ensLicenseRequestTid}</Text>
        </div>
      </Drawer>
    </>
  )
}


interface ViewRequestProps {
  request: LicenseRequest | undefined;
}

const ViewRequest = (props: ViewRequestProps) => {
  const { request } = props;

  switch (request?.ensLicensingRequestType) {
    case EnsLicensingRequestTypeTid.AgentStateLicenseRequest:
      return <ReviewStateLicensesRequest requestTid={request.ensLicenseRequestTid} />
    default:
      return <>Not found</>
  }
}

const ReviewStateLicensesRequest = (props: { requestTid: number | undefined }) => {
  const { requestTid } = props;
  const [selectedRequest, setSelectedRequest] = useState<AgentStateLicensesRequest>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadRequest(requestTid);
  }, [requestTid]);

  const loadRequest = (requestTid: number | undefined) => {
    if (!requestTid)
      return;

    setSelectedRequest(undefined);

    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, AgentStateLicensesRequestClient, async c => {
      let res = await c.getAgentStateLicensingRequest(requestTid);
      setSelectedRequest(res);
    })
      .catch(e => message.error("Something went wrong."))
      .finally(() => setLoading(false));
  }

  if (!selectedRequest && !loading)
    return <Empty />

  return <>
    <Spin spinning={loading}>
      <Row>
        <Col span={24}>
          {selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.Approved ?
            <Alert message="Approved" type="success" />
            : selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.PartiallyApproved ?
              <Alert message="Partially Approved" type="warning" />
              : selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.Denied ?
                <Alert message="Denied" type="error" />
                : selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.AwaitingApproval &&
                <Alert message="Awaiting Approval" type="info" />
          }
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col>
          <Descriptions
            bordered
            title="Request Info"
            layout="vertical"
            size="small"
          >
            <Descriptions.Item label="Requestor">{selectedRequest?.requestor.userEmail}</Descriptions.Item>
            <Descriptions.Item label="Agent">{selectedRequest?.target.userEmail}</Descriptions.Item>
            <Descriptions.Item label="Request Type">{EnsLicensingRequestTypeTid[selectedRequest?.ensLicensingRequestType || EnsLicensingRequestTypeTid.AgentStateLicenseRequest]}</Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
      <Divider />
      <Row style={{ marginTop: 15 }}>
        {selectedRequest?.ensLicensingRequestStatusType == EnsLicensingRequestStatusTypeTid.AwaitingApproval ? <>
          <Col span={24}>
            <Title level={5}>Requested States</Title>
            <ul>
              {selectedRequest?.states.map(s => <li key={s.state}>{s.state}</li>)}
            </ul>
          </Col>
        </> :
          <>
            <Col span={11}>
              <Title level={5}><Text type="danger">Denied States</Text></Title>
              <ul>
                {selectedRequest?.states.filter(s => !s.approved).map(s => <li key={s.state}>{s.state}</li>)}
              </ul>
            </Col>
            <Col span={2}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>
            <Col span={11}>
              <Title level={5}><Text type="success">Approved States</Text></Title>
              <ul>
                {selectedRequest?.states.filter(s => s.approved).map(s => <li key={s.state}>{s.state}</li>)}
              </ul>
            </Col>
          </>}
      </Row>
    </Spin>
  </>
}