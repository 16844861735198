import React, { useEffect, useState } from "react";
import {
  AddEnsProductCarrierChildRequest,
  EnsProductCarrierChildResponse,
  EnsProductCarrierTid,
  ProductCarrierClient,
  UpdateEnsProductCarrierChildRequest,
} from "common/services/ApiServerClient";
import UserClass from "core/models/UserClass";
import CarrierSelect from "../../../common/components/CarrierSelect";
import { authenticatedFetch } from "core/components/AuthProvider";
import ApiServerService from "common/services/ApiServerService";
import { Button, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ChildCarrierPreappStatesEditor from "./ChildCarrierPreappStatesEditor";
import NewCarrierChildEditor from "./NewCarrierChildEditor";
import ChildCarrierProductsEditor from "./ChildCarrierProductsEditor";
import { SortOrder } from "antd/lib/table/interface";
import ChildCarrierInfoEditor from "./ChildCarrierInfoEditor";
import Paragraph from "antd/lib/typography/Paragraph";

interface CarriersAppProps {
  user: UserClass;
}

export default function CarriersApp(props: CarriersAppProps) {
  const [parentCarrierTid, setParentCarrierTid] = useState<EnsProductCarrierTid>(EnsProductCarrierTid.None);
  const [carrierChildren, setCarrierChildren] = useState<EnsProductCarrierChildResponse[]>([]);
  const [carrierChild, setCarrierChild] = useState<EnsProductCarrierChildResponse>();

  const [showNewCarrierChildModal, setShowNewCarrierChildModal] = useState(false);
  const [showPreappStateModal, setShowPreappStateModal] = useState(false);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);

  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    if (!parentCarrierTid) return;

    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      setTableLoading(true);
      return c.getActiveCarrierChildren(parentCarrierTid as EnsProductCarrierTid);
    }).then((d) => {
      setTableLoading(false);
      setCarrierChildren(d);
    });
  }, [parentCarrierTid]);

  const handleAdd = (addReq: AddEnsProductCarrierChildRequest) => {
    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      setTableLoading(true);
      return c.addCarrierChild(addReq);
    }).then((newChildRes) => {
      setTableLoading(false);
      setCarrierChildren([...carrierChildren, newChildRes]);
    });
  };

  const handleUpdate = (updateReq: UpdateEnsProductCarrierChildRequest) => {
    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      setTableLoading(true);
      return c.updateCarrierChild(updateReq);
    }).then((updatedChildRes) => {
      setTableLoading(false);
      // remove old object, replace with new one coming from server
      setCarrierChildren([
        updatedChildRes,
        ...carrierChildren.filter((c) => c.ens_product_carrier_child_tid !== updateReq.ens_product_carrier_child_tid),
      ]);
    });
  };

  const handleDelete = (childTid: number) => {
    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      setTableLoading(true);
      return c.deleteCarrierChild(childTid);
    }).then((removedChildRes) => {
      setTableLoading(false);
      setCarrierChildren(carrierChildren.filter((c) => c.ens_product_carrier_child_tid !== childTid));
    });
  };

  const openPreappsEditor = (selectedChild: EnsProductCarrierChildResponse) => {
    setCarrierChild(selectedChild);
    setShowPreappStateModal(true);
  };

  const openProductsEditor = (selectedChild: EnsProductCarrierChildResponse) => {
    setCarrierChild(selectedChild);
    setShowProductsModal(true);
  };

  const openInfoEditor = (selectedChild: EnsProductCarrierChildResponse) => {
    setCarrierChild(selectedChild);
    setShowInfoModal(true);
  };

  const columns: ColumnsType<EnsProductCarrierChildResponse> = [
    {
      title: "Company name",
      dataIndex: "company_name",
      align: "left",
      defaultSortOrder: null as SortOrder,
      sorter: (a: EnsProductCarrierChildResponse, b: EnsProductCarrierChildResponse) =>
        a.company_name.localeCompare(b.company_name),
      render: (value: any, record: EnsProductCarrierChildResponse) => (
        <Paragraph copyable>{record.company_name}</Paragraph>
      ),
    },
    {
      title: "Cocode",
      dataIndex: "cocode",
      align: "left",
      defaultSortOrder: null as SortOrder,
      sorter: (a: EnsProductCarrierChildResponse, b: EnsProductCarrierChildResponse) =>
        a.cocode.localeCompare(b.cocode),
      render: (value: any, record: EnsProductCarrierChildResponse) => <Paragraph copyable>{record.cocode}</Paragraph>,
    },
    {
      title: "RTS Report",
      width: 100,
      dataIndex: "rts_report_fl",
      render: (text, record) => (record.rts_report_fl ? "Yes" : "No"),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => openPreappsEditor(record)}>
            Preapp States
          </Button>
          <Button type="primary" onClick={() => openProductsEditor(record)}>
            Products
          </Button>
          <Button type="primary" onClick={() => openInfoEditor(record)}>
            Info
          </Button>
          <Button onClick={() => handleDelete(record.ens_product_carrier_child_tid)}>X</Button>
        </Space>
      ),
    },
  ];

  const getRecordKey = (record: EnsProductCarrierChildResponse) => {
    return record.ens_product_carrier_child_tid;
  };

  return (
    <>
      <CarrierSelect onCarrierSelected={(carrierId: number) => setParentCarrierTid(carrierId)} />
      {parentCarrierTid !== EnsProductCarrierTid.None && (
        <>
          <Button onClick={() => setShowNewCarrierChildModal(true)}>Add child</Button>
          <NewCarrierChildEditor
            ensProductCarrierTid={parentCarrierTid}
            visible={showNewCarrierChildModal}
            setVisible={setShowNewCarrierChildModal}
            addChildCarrierHandler={handleAdd}
          />
        </>
      )}
      {carrierChild && (
        <>
          <ChildCarrierPreappStatesEditor
            childCarrier={carrierChild}
            visible={showPreappStateModal}
            setVisible={setShowPreappStateModal}
            updateChildCarrierHandler={handleUpdate}
          />
          <ChildCarrierProductsEditor
            childCarrier={carrierChild}
            visible={showProductsModal}
            setVisible={setShowProductsModal}
            updateChildCarrierHandler={handleUpdate}
          />
          <ChildCarrierInfoEditor
            childCarrier={carrierChild}
            visible={showInfoModal}
            setVisible={setShowInfoModal}
            updateChildCarrierHandler={handleUpdate}
          />
        </>
      )}
      <Table
        loading={tableLoading}
        rowKey={getRecordKey}
        dataSource={carrierChildren}
        columns={columns}
        scroll={{ y: 800 }}
        pagination={{
          total: carrierChildren.length,
          pageSize: carrierChildren.length,
          hideOnSinglePage: true,
        }}
      />
    </>
  );
}
