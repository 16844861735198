import React, { useState } from "react";
import { Modal, Button, Input, Checkbox } from 'antd';
import { AddEnsProductCarrierChildRequest, EnsProductCarrierTid } from "common/services/ApiServerService";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface NewCarrierChildEditorProps {
  ensProductCarrierTid: EnsProductCarrierTid
  visible: boolean
  setVisible: (visible: boolean) => void
  addChildCarrierHandler: (newChild: AddEnsProductCarrierChildRequest) => void
}

export default function NewCarrierChildEditor(props: NewCarrierChildEditorProps) {
  const { ensProductCarrierTid, visible, setVisible, addChildCarrierHandler } = props;
  const [companyName, setCompanyName] = useState("");
  const [cocode, setCocode] = useState("");
  const [rtsReport, setRtsReport] = useState(false);

  const close = () => {
    setVisible(false);
  };

  const companyChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyName(event.target.value ?? "");
  }

  const cocodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCocode(event.target.value ?? "");
  }

  const rtsChanged = (event: CheckboxChangeEvent) => {
    setRtsReport(event.target.checked);
  }

  const submitNewChild = () => {
    if (!companyName || !cocode) {
      return;
    }

    if (ensProductCarrierTid === EnsProductCarrierTid.None)
      return;

    addChildCarrierHandler({
      ens_product_carrier_tid: ensProductCarrierTid,
      cocode: cocode,
      company_name: companyName,
      rts_report_fl: rtsReport
    } as AddEnsProductCarrierChildRequest);

    close();
  };

  return (<Modal
    title="Add new child"
    visible={visible}
    onOk={close}
    onCancel={close}
    footer={[]}
  >
    {(ensProductCarrierTid === EnsProductCarrierTid.None) ?
      "You must select a Product Carrier first" :
      (<>
        <Input placeholder="Company name" onChange={companyChanged} />
        <Input placeholder="Cocode" onChange={cocodeChanged} />
        <Checkbox onChange={rtsChanged}>RTS Report</Checkbox>
        <Button onClick={submitNewChild}>Add</Button>
      </>)}
  </Modal>
  );
}