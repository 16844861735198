import React, { useEffect, useState } from 'react';
import { Button, Space, message } from 'antd';
import RoleSelect from './RoleSelect';
import { authenticatedFetch } from 'core/components/AuthProvider';
import { MicroservicesEndpoint } from 'common/services/ApiServerService';
import { EnsUserRoleType, RolesClient, UserRole } from 'common/services/microservices/ensusers-client';

type UserRoleManagerProps = {
  ensUserTid: number | undefined,
  onRoleUpdated?: (newRoleTid: EnsUserRoleType) => void
};

export default function UserRoleManager(props: UserRoleManagerProps) {
  const { ensUserTid, onRoleUpdated } = props;
  const [selectedRole, setSelectedRole] = useState<UserRole>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectedRole(undefined);

    if (!ensUserTid)
      return;

    setLoading(true);

    authenticatedFetch(MicroservicesEndpoint.ensUsers, RolesClient, async c => {
      let userRolesReq = c.getRoles(ensUserTid);
      setSelectedRole((await userRolesReq)[0])
    }).catch(() => message.error("Something went wrong. Contact Engineering."))
      .finally(() => setLoading(false));

  }, [ensUserTid]);

  const updateRole = () => {
    if (!ensUserTid || !selectedRole)
      return;

    setLoading(true);

    authenticatedFetch(MicroservicesEndpoint.ensUsers, RolesClient, async c => {
      let currentRole = await c.setRole(ensUserTid, selectedRole?.ensUserRoleType);
      setSelectedRole(currentRole);
      if (onRoleUpdated)
        onRoleUpdated(currentRole.ensUserRoleType);
    }).catch(() => message.error("Something went wrong. Contact Engineering."))
      .finally(() => setLoading(false));
  };

  return (<>
    <Space>
      <RoleSelect selectedValue={selectedRole?.ensUserRoleType} onSelect={(v) => setSelectedRole(v)} />
      <Button loading={loading} type="primary" onClick={updateRole}>Update</Button>
    </Space>
  </>);
}