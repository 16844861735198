import React from "react";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import { Col, Row, Upload, Button, message, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { MicroserviceUploadRequest as MicroserviceUploadRequestHandler } from "operations/common/s3PresignedUploadRequest";
import { useState } from "react";
import { IPreSignedUrlResponse } from "common/services/microservices/basic-client";
import {
  CronTaskStateType,
  ITaskRunResponse,
  TaskRunRequest,
  TasksClient,
} from "common/services/microservices/cron-boss-client";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import CronTaskEventByGuidDisplay from "operations/commission-upload/components/CronTaskEventDisplay";

export function FutureValueRulesUploadTool(props: ApplicationPageProps) {
  const [presignedUrl, setPresignedUrl] = useState<IPreSignedUrlResponse>();
  const [isTaskRunning, setIsTaskRunning] = useState<boolean>(false);
  const [processingTaskInfo, setProcessingTaskInfo] = useState<ITaskRunResponse>();

  const goodBoyFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const uploadProps: UploadProps = {
    accept: goodBoyFileTypes.join(", "),
    multiple: false,
    maxCount: 1,
    customRequest: (options) => {
      MicroserviceUploadRequestHandler("CommissionRuleUploads", options).then((url) => setPresignedUrl(url));
    },
    onChange(info) {},
  };

  function startUploadCronTask() {
    setIsTaskRunning(true);
    authenticatedClient(MicroservicesEndpoint.cronboss, TasksClient)
      .then((client) =>
        client.startTask(
          new TaskRunRequest({
            taskModule: "future-value",
            taskName: "UpdateFutureValueRulesTask",
            specs: {
              sheet_download_guid: presignedUrl!.objectGuid,
              sheet_download_prefix: presignedUrl!.objectPrefix,
              calling_user: props.user.UserEmail,
            },
          })
        )
      )
      .then((response) => {
        setProcessingTaskInfo(response);
        message.success(`Started task ${response.guid}`);
      })
      .catch(() => {
        message.error(`Failed to start upload task.`);
        setIsTaskRunning(false);
      });
  }

  return (
    <Row justify="center">
      <Col md={18} className="pb-4">
        <h3>Upload Commission Rule Excel Sheet</h3>
        <p>Changes will take effect on the next forcast run</p>
        <Upload {...uploadProps}>
          <Button block icon={<UploadOutlined />} style={{ width: "100%" }}>
            Browse
          </Button>
        </Upload>
        {presignedUrl && (
          <Button disabled={isTaskRunning} className="my-4" type="primary" block onClick={startUploadCronTask}>
            Start Rule Upload
          </Button>
        )}
        {processingTaskInfo && (
          <CronTaskEventByGuidDisplay
            guid={processingTaskInfo.guid}
            onTaskStateChanged={(state) => {
              if (state === CronTaskStateType.Success || state === CronTaskStateType.Failure) {
                setIsTaskRunning(false);
              }
            }}
          />
        )}
      </Col>
    </Row>
  );
}
