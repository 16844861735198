import { Button, Col, message, Row, Spin, Upload, UploadProps } from "antd";
import { authenticatedClient } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import Paragraph from "antd/lib/typography/Paragraph";
import { UploadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import {
  DeltaBookingClient,
  DeltaStartReportRequest,
  IDeltaStartReportRequest,
} from "common/services/microservices/finance-client";
import { ObjectStorageClient } from "common/services/microservices/basic-client";
import { DeltaReportStartResponse } from "common/services/microservices/finance-client";

interface DeltaBookingUserInput {
  lapse_sheet_guid: undefined | string;
  lapse_sheet_prefix: undefined | string;
  //this is just the sheet name, not user name, this has a confusing name in the finance-client.ts
  lapse_sheet_user_name: undefined | string;
}

export function DeltaBookingTool() {
  // State to store the selected file
  const [fileName, setFileName] = useState<string | null>(null);
  // Set state for user data
  //You would get the sheet guid and sheet prefix when you upload the prefix to s3 to get the guid.
  const [userInput, setUserInput] = useState<DeltaBookingUserInput>({
    lapse_sheet_guid: undefined,
    lapse_sheet_prefix: undefined,
    lapse_sheet_user_name: undefined,
  });

  //Boolean value indicating your a submitting a request to the delta booking tool with all your information
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  //String value that will hold the resulting guid, no need to check the results just display on the ui and allow the user to copy it.
  const [deltaBookingToolGuid, setDeltaBookingGuid] = useState<string | undefined>("");
  //Error message
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");

  const uploadProps: UploadProps = {
    multiple: false,
    maxCount: 1,
    onChange(info) {
      setFileName(info.file.name);
      if (info.file.status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest: async (options: any) => {
      // console.log(options.file);
      try {
        const basicClient = await authenticatedClient(MicroservicesEndpoint.basic, ObjectStorageClient);

        //upload the file into s3 pass it pathPrefix, and fileName. In return will retrieve a guid.]
        const objectStorageResponse = await basicClient.getUploadPreSignedUrl(
          `delta-booking/"${options.file ? options.file.name : ""}"`
        );
        const copyOfUserInput = Object.assign({}, userInput);
        setUserInput({
          ...copyOfUserInput,
          lapse_sheet_guid: objectStorageResponse.objectGuid,
          lapse_sheet_prefix: objectStorageResponse.objectPrefix,
          lapse_sheet_user_name: options.file.name,
        });

        //Pass the path prefix, file name and guid from above and saleIds to the delta booking controller.
        let uploadRes = await fetch(objectStorageResponse.presignedUrl, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          method: "PUT",
          body: options.file,
        });
        if (!uploadRes.ok) {
          options.onError?.(undefined!, uploadRes.statusText);
          return;
        } else if (uploadRes.ok) {
          setIsSubmitting(false);
          // console.log(userInput);
          message.success(`${fileName} uploaded successfully, can now Calculate`);
        }
      } catch (error) {
        options.onError?.(undefined!, "Failed");
      }
    },
  };

  async function postDeltaBookingInput() {
    setIsSubmitting(true);
    try {
      const deltaBookingClient = await authenticatedClient(MicroservicesEndpoint.finance, DeltaBookingClient);
      const response = await deltaBookingClient.startTask(new DeltaStartReportRequest(userInput));

      if (response.guid !== null && response.error_message === null) {
        message.success(`Successfully Calculated`);
        setDeltaBookingGuid(response.guid);
        setErrorMessage("");
      } else if (response.guid === null && response.error_message !== null) {
        message.error({
          content: `${response.error_message}`,
          duration: 5,
        });
        setDeltaBookingGuid("");
        setErrorMessage(response.error_message);
      }

      setIsSubmitting(false);
    } catch (error) {
      message.error(`${error}`);
      setIsSubmitting(false);
    }
  }

  const rowStyle = {
    margin: "10px",
  };

  return (
    <Paragraph className="container my-3">
      <Row justify="center" style={rowStyle}>
        <h3>Upload Lapse Sheet</h3>
      </Row>
      <Row justify="center" style={rowStyle}>
        <Upload
          {...uploadProps}
          beforeUpload={() => {
            setIsSubmitting(true); // Start the spinner when the upload begins
          }}
          showUploadList={false}
          accept=".xls,.xlsx"
          maxCount={1} // Allow only one file to be uploaded
        >
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
      </Row>
      <Row justify="center" style={rowStyle}>
        <b>File Uploaded:{"\u00A0"}</b>
        {fileName !== null ? fileName : ""}
      </Row>
      <Row justify="center" style={rowStyle}>
        <Spin spinning={isSubmitting} />
      </Row>
      <Row justify="center" style={rowStyle}>
        <Button
          type="primary"
          onClick={postDeltaBookingInput}
          style={rowStyle}
          disabled={isSubmitting || userInput.lapse_sheet_user_name === undefined}
        >
          Calculate
        </Button>
      </Row>
      <Row justify="center">
        {deltaBookingToolGuid !== "" ? (
          <>
            <b>Task guid:{"\u00A0"}</b>
            <Paragraph copyable>{deltaBookingToolGuid}</Paragraph>
          </>
        ) : (
          <Paragraph>{/* <b style={{ color: 'red' }}>{errorMessage}</b> */}</Paragraph>
        )}
      </Row>
    </Paragraph>
  );
}
