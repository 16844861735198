import React, { useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import AgentTab, { AgentTabObject } from "./AgentTab";
import SalesTab, { SalesTabObject } from "./SalesTab";
import DemographicsTab, { DemographicsTabObject } from "./DemographicsTab";
import MarketingTab, { MarketingTabObject } from "./MarketingTab";
import { SaleUpdateRequest } from "common/services/ApiServerClient";
import CashReceiptTab from "./CashReceiptTab";
import { FullSaleUpdateRequest } from "../SalesStatusTool";
import { SaleStatus } from "common/services/microservices/sales-client";

interface UpdateEntryModalProps {
  selectedRecord: SaleStatus;
  hideModal: () => void; //sets row to undefined incase user wants to click the same row again
  callUpdateApi: (row: FullSaleUpdateRequest) => void;
}

export default function UpdateEntryModal(props: UpdateEntryModalProps) {
  const { hideModal, selectedRecord, callUpdateApi } = props;
  const { TabPane } = Tabs;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [agentObject, setAgentObject] = useState<AgentTabObject>();
  const [salesObject, setSalesObject] = useState<SalesTabObject>();
  const [demographicsObject, setDemographicsObject] = useState<DemographicsTabObject>();
  const [marketingObject, setMarketingObject] = useState<MarketingTabObject>();
  const [calculatedAge, setCalculatedAge] = useState<number>();
  const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>(true);

  useEffect(() => {
    if (selectedRecord) setShowModal(true);
  }, [selectedRecord]);

  const updateRow = (
    agent: AgentTabObject | undefined,
    sales: SalesTabObject | undefined,
    demographics: DemographicsTabObject | undefined,
    marketing: MarketingTabObject | undefined
  ) => {
    let row: FullSaleUpdateRequest = { ...agent, ...sales, ...demographics, ...marketing };
    callUpdateApi(row);
  };

  const displayAge = (dateOfBirth: Date, effectiveDate: Date) => {
    if (!dateOfBirth || !effectiveDate) return;
    let age = 0;
    age = effectiveDate.getFullYear() - dateOfBirth.getFullYear();
    if (dateOfBirth.getMonth() > effectiveDate.getMonth()) {
      age--;
    }
    setCalculatedAge(age);
  };

  const validateData = (
    agent: AgentTabObject | undefined,
    sales: SalesTabObject | undefined,
    demographics: DemographicsTabObject | undefined
  ) => {
    let row = { ...agent, ...sales, ...demographics } as SaleUpdateRequest;

    setDisableUpdateButton(
      !row.agent_ens_user_tid ||
        !row.ens_product_carrier_tid ||
        !row.ens_product_tid ||
        !row.current_ens_sale_status_tid ||
        !row.issued_ens_sale_status_tid ||
        !row.sale_datetime ||
        !row.effective_date ||
        !row.insured_dob
    );
  };

  useEffect(() => {
    if (salesObject?.effective_date && demographicsObject?.insured_dob) {
      displayAge(demographicsObject.insured_dob, salesObject.effective_date);
      return;
    }
    if (salesObject?.effective_date) {
      displayAge(selectedRecord.insured_dob!, salesObject.effective_date);
      return;
    }
    if (demographicsObject?.insured_dob) {
      displayAge(demographicsObject.insured_dob, selectedRecord.effective_date);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demographicsObject, salesObject]);

  useEffect(() => {
    validateData(agentObject, salesObject, demographicsObject);
  }, [demographicsObject, salesObject, agentObject]);

  return (
    <>
      <Modal
        visible={showModal}
        onOk={() => {
          updateRow(agentObject, salesObject, demographicsObject, marketingObject);
          hideModal();
        }}
        okText={"Update"}
        onCancel={() => {
          hideModal();
        }}
        width={1000}
        okButtonProps={{ disabled: disableUpdateButton }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sales" key="1" forceRender>
            <SalesTab
              selectedData={(data) => {
                setSalesObject(data);
              }}
              selectedRecord={selectedRecord}
              insertModal={false}
            />
          </TabPane>
          <TabPane tab="Demographics" key="2" forceRender>
            <DemographicsTab
              selectedData={(data) => {
                setDemographicsObject(data);
              }}
              selectedRecord={selectedRecord}
              calculatedAge={calculatedAge}
              insertModal={false}
            />
          </TabPane>
          <TabPane tab="Agents" key="3" forceRender>
            <AgentTab
              selectedData={(data) => {
                setAgentObject(data);
              }}
              selectedRecord={selectedRecord}
              insertModal={false}
            />
          </TabPane>
          {/* <TabPane tab="Marketing" key="4" forceRender>
            <MarketingTab
              selectedRecord={selectedRecord}
              onMarketingCampaignSelected={(data) => setMarketingObject(data)}
            />
          </TabPane> */}
          <TabPane tab="Cash Receipts" key="5" forceRender>
            <CashReceiptTab selectedRecord={selectedRecord} />
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
}
