import React, { useState } from "react";
import { Table, Col, Input, Row, Button } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import { ColumnsType } from "antd/lib/table";
import { F9AudioSearchClient, IF9AudioRowInfo } from "common/services/microservices/five9-client";
import moment from "moment";
import { useWorkWaiter } from "common/components/useWorkWaiter";
import { WorkStateType } from "common/services/microservices/basic-client";

export default function F9ComplianceApp() {
  const [data, setData] = useState<IF9AudioRowInfo[]>([]);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [lastSearchedPhone, setLastSearchedPhone] = useState("");
  const [lastWorkPhone, setLastWorkPhone] = useState("");
  const { startWork, isLoading: isWorkLoading, taskState, downloadResult } = useWorkWaiter();

  async function search() {
    try {
      setLoading(true);
      const client = await authenticatedClient(MicroservicesEndpoint.five9, F9AudioSearchClient);
      const callData = await client.callSearch(phone);
      setData(callData);
      setLastSearchedPhone(phone);
    } finally {
      setLoading(false);
    }
  }

  async function startDownloadWork() {
    setLastWorkPhone(phone);
    const client = await authenticatedClient(MicroservicesEndpoint.five9, F9AudioSearchClient);
    return client.startCallZipWork(phone);
  }

  const columns: ColumnsType<IF9AudioRowInfo> = [
    {
      title: "Agent",
      width: "100px",
      render: (_: any, record: IF9AudioRowInfo) => record.agent,
    },
    {
      title: "Date",
      render: (_: any, record: IF9AudioRowInfo) => moment(record.callDate).format("MM/DD/YYYY"),
    },
    {
      title: "Time",
      render: (_: any, record: IF9AudioRowInfo) => record.callTime,
    },
    {
      title: "Length",
      render: (_: any, record: IF9AudioRowInfo) => record.callLength,
    },
    {
      title: "Audio Record",
      render: (_: any, record: IF9AudioRowInfo) => record.audioWavFilename,
    },
  ];

  return (
    <Col>
      <Row style={{ paddingBottom: "20px" }}>
        <Col span={6}>
          <Input
            required
            size="large"
            autoFocus
            type="tel"
            placeholder="Enter Phone..."
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Col>
        <Col>
          <Button size="large" onClick={search} disabled={isWorkLoading}>
            Search
          </Button>
        </Col>
        {data.length > 0 && (
          <Col>
            <Button
              size="large"
              className="ml-3"
              loading={isWorkLoading}
              onClick={() => {
                startWork(startDownloadWork, `${phone}-audio.zip`, "Generating ZIP file. This may take a few minutes.");
              }}
            >
              Create ZIP
            </Button>
            {taskState === WorkStateType.Success && lastSearchedPhone === lastWorkPhone && (
              <Button size="large" loading={isWorkLoading} className="ml-3 btn-success" onClick={downloadResult}>
                Download ZIP
              </Button>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          rowKey={(record) => record.f9AudioTid}
          style={{ width: "100%" }}
          pagination={{
            total: data.length,
            pageSize: data.length,
            hideOnSinglePage: true,
          }}
        />
      </Row>
    </Col>
  );
}
