import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { IapAccessClient, IapAccessEntry, IapAccessTarget } from "common/services/microservices/logging-client";
import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { authenticatedClient, authenticatedFetch } from "./AuthProvider";
import WithTracking from "./WithTracking";
import { UserBasicInfo } from "common/services/microservices/ensusers-client";

interface AuthenticatedRouteProps extends React.ComponentProps<typeof Route> {
  accessTarget: IapAccessTarget;
  authenticated: boolean;
  pass: JSX.Element;
  fail?: JSX.Element;
}

// A wrapper for <Route> that redirects to the login
// screen if the user is not yet authenticated.
export default function AuthenticatedRoute(props: AuthenticatedRouteProps) {
  const { accessTarget, authenticated, pass, fail, ...others } = { ...props };
  const failComponent = fail || <Redirect to="/" />;

  // useEffect(() => {
  //   const c = await authenticatedClient(MicroservicesEndpoint.ensUsers, UserBasicInfo);
  //   authenticatedFetch(MicroservicesEndpoint.logging, IapAccessClient, (c) =>
  //     c.recordIapAccess(new IapAccessEntry({ accessTarget: accessTarget }))
  //   );
  // }, [accessTarget]);

  return (
    <Route
      {...others}
      render={({ location }) => (
        <WithTracking location={location}>
          <>{authenticated ? pass : failComponent}</>
        </WithTracking>
      )}
    />
  );
}
