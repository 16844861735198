import { Row, Col, Progress } from "antd";
import { IWorkEvent, WorkEventType, WorkStateType } from "common/services/microservices/basic-client";
import moment from "moment";
import React from "react";

export function ProgressDisplayer(props: { progress: number; taskEvents: IWorkEvent[]; state?: WorkStateType }) {
  const { progress, taskEvents, state } = props;

  return (
    <Row align="middle" justify="center">
      <Col span={12}>
        <Progress
          className="mb-2"
          percent={progress * 100}
          status={state === WorkStateType.Failed ? "exception" : state === WorkStateType.Success ? "success" : "active"}
        />
        {taskEvents
          .filter((e) => e.eventType === WorkEventType.UserLog)
          .map((e, i) => (
            <div key={i} style={{ display: "flex", justifyContent: "left" }}>
              {moment(e.eventTime).format("LTS")} - {e.message}
            </div>
          ))}
      </Col>
    </Row>
  );
}
