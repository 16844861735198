import React, { useState, useEffect } from "react";
import LicensingSearch from "licensing/components/LicensingSearch";
import { Tabs } from "antd";
import LicenseReports from "licensing/components/LicenseReportGenerator";
import Loader from "common/components/ApiLoader";
import AgentTabRender from "./AgentTabRender";
import { FindUserRequestType, EnsUser } from "common/services/ApiServerService";
import UserClass from "core/models/UserClass";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import LicensingRequests from "./licensing-requests/LicensingRequests";
import RtsReportUpload2 from "./RtsReportUpload2";
import OnDemandReports from "./OnDemandReports";

const { TabPane } = Tabs;

export interface LicenseAuthorizedDisplayProps {
  user: UserClass;
  loadingResults: boolean;
  searchAgent: (idType: FindUserRequestType, id: string, inactive?: boolean) => void;
  selectedUser: EnsUser | null;
  showError: boolean;
}

export default function LicenseAuthorizedDisplay(props: LicenseAuthorizedDisplayProps) {
  const { user, loadingResults, searchAgent, selectedUser, showError } = props;
  const [activeTabKey, setActiveTabKey] = useState<"search" | "reports" | "agent">();

  const showsearchAndReportTab = user.hasPermission(EnsUserPermissionType.LicensingEditAccess);

  const showAgentTab = selectedUser?.ens_user_tid != null;

  useEffect(() => {
    if (selectedUser) {
      setActiveTabKey("agent");
    }
  }, [selectedUser]);

  return (
    <Tabs type="card" id="LicensingContainer" activeKey={activeTabKey} onTabClick={(k: any) => setActiveTabKey(k)}>
      {showsearchAndReportTab && (
        <TabPane key="search" tab="Search">
          <LicensingSearch searchAgent={searchAgent} displayError={showError} />
          {loadingResults && <Loader />}
        </TabPane>
      )}
      {/* {user.hasPermission(EnsUserPermissionType.LicensingEditAll) && (
        <TabPane key="rts-upload" tab="RTS Upload">
          <RtsReportUpload />
        </TabPane>
      )} */}
      {user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
        <TabPane key="rts-upload2" tab="RTS Upload">
          <RtsReportUpload2 user={user} />
        </TabPane>
      )}
      {user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
        <TabPane key="Requests" tab="Requests">
          <LicensingRequests user={props.user} />
        </TabPane>
      )}
      {showsearchAndReportTab && (
        <TabPane tab="Reports" key="reports">
          <LicenseReports selectedAgent={selectedUser} searchAgent={searchAgent} />
        </TabPane>
      )}
      {showAgentTab && (
        <TabPane tab={`${selectedUser?.user_first_name} ${selectedUser?.user_last_name}`} key="agent">
          <AgentTabRender agent={selectedUser!} user={user} />
        </TabPane>
      )}
      {user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
        <TabPane key="OnDemand" tab="On Demand Reports">
          <OnDemandReports user={props.user} />
        </TabPane>
      )}
    </Tabs>
  );
}
