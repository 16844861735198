import { Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Cleave from "cleave.js/react";
import StatusAlert from "common/components/StatusAlert";
import { SubmitButton } from "common/components/SubmitButton";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useState } from "react";
import "../scss/InputField.scss";
import {
  SalePolicyCode,
  SalePolicyHistoryClient,
  SalePolicyUpdateRequest,
  SalePolicyUpdateResponse,
} from "common/services/microservices/sales-client";

export default function PolicyMappingTool() {
  const [policyNumber, setPolicyNumber] = useState<string>("");
  const [effectiveDate, setEffectiveDate] = useState<Date>();
  const [salesId, setSalesId] = useState<number>();
  const [renderStatus, setRenderStatus] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [updateResponse, setUpdateResponse] = useState<SalePolicyUpdateResponse | undefined | null>(undefined);

  const stateValid =
    salesId !== undefined &&
    policyNumber !== undefined &&
    policyNumber !== "" &&
    effectiveDate !== undefined &&
    !isNaN(effectiveDate?.getDate());

  function updatePolicyNumber(saleId: number, policyId: string, effectiveDate: Date) {
    setLoading(true);
    setUpdateResponse(undefined);
    authenticatedFetch(MicroservicesEndpoint.sales, SalePolicyHistoryClient, (c) =>
      c.updateSalePolicyNumber(
        new SalePolicyUpdateRequest({ ensSaleTid: saleId, policyNumber: policyId, effectiveDate: effectiveDate })
      )
    )
      .then((s) => setUpdateResponse(s))
      .catch(() => setUpdateResponse(null))
      .finally(() => setLoading(false));
  }

  return (
    <Paragraph className="my-3">
      <Row>
        <Col xs={{ span: 8, offset: 8 }}>
          <Cleave
            id="sales-id"
            className="cleaveInput my-3"
            options={{
              numericOnly: true,
            }}
            onChange={(ev: any) => {
              let salesId = parseInt(ev.target.value);
              setSalesId(!isNaN(salesId) ? salesId : undefined);
              setRenderStatus(false);
            }}
            placeholder="Sales ID #"
            required
          />
          <Cleave
            id="policy-number"
            className="cleaveInput"
            options={{}}
            onChange={(ev: any) => {
              setPolicyNumber(ev.target.value);
              setRenderStatus(false);
            }}
            placeholder="Policy #"
            required
          />
          <Cleave
            id="effective-date"
            className="cleaveInput my-3"
            options={{ date: true, datePattern: ["m", "d", "y"] }}
            onChange={(ev) => {
              // Length for "MM/DD/YY"
              if (ev.target.value.length === 8) {
                const date = new Date(ev.target.value);
                setEffectiveDate(date);
                setRenderStatus(false);
              } else {
                setEffectiveDate(undefined);
              }
            }}
            placeholder="Effective Date (MM/DD/YY)"
            required
          />

          <SubmitButton
            className="my-3"
            text={"Update Sales Policy"}
            loading={loading}
            onClick={() => {
              updatePolicyNumber(salesId!, policyNumber!, effectiveDate!);
              setRenderStatus(true);
            }}
            disabled={!stateValid}
          />

          {renderStatus && (
            <StatusAlert
              shouldDisplay={updateResponse !== undefined ?? false}
              success={updateResponse?.code === SalePolicyCode.SUCCESS ?? false}
              errorText={updateResponse?.message}
            ></StatusAlert>
          )}
        </Col>
      </Row>
    </Paragraph>
  );
}
