import React, { useEffect, useState } from "react";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";
import { DropdownClient, IDropbownProductCarrier } from "common/services/microservices/basic-client";
import { Select } from "antd";

export interface CarrierDropDownProps {
  additionalOptions?: IDropbownProductCarrier[];
  disabled?: boolean;
  onCarrierSelected: (carrierId: number) => void;
  displayError?: (error: boolean) => void;
}

export default function CarrierDropDown(props: CarrierDropDownProps): JSX.Element {
  const { additionalOptions, onCarrierSelected, displayError, disabled } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<IDropbownProductCarrier[]>([]);

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.basic, DropdownClient, (c) => {
      setLoading(true);
      return c.getActiveProductCarriers();
    })
      .then(setCarrierList)
      .catch(() => displayError?.(true))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select<number>
      style={{ width: "100%" }}
      loading={loading}
      disabled={disabled}
      options={[...(carrierList ?? []), ...(additionalOptions ?? [])]
        .sort((a, b) => (a.product_carrier_name > b.product_carrier_name ? 1 : -1))
        .map((item) => ({ value: item.ens_product_carrier_tid, label: item.product_carrier_name }))}
      placeholder={"Select carrier"}
      onChange={(selectedOption) => onCarrierSelected(selectedOption)}
    />
  );
}
