import { Col, Descriptions, Divider, Row } from "antd";
import Title from "antd/lib/typography/Title";
import Loader from "common/components/ApiLoader";
import NiprDateStatus, { NiprDateType } from "common/components/NiprDateStatus";
import StatusAlert from "common/components/StatusAlert";
import ApiServerService, { EnsUser, License, UserClient } from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import StateColorDisplay from "licensing/models/StateLicenseColorization";
import "licensing/scss/LicenseMap.scss";
import React, { useEffect, useState } from "react";
import { MapModal } from "./MapModal";
let ReactUsaMap = require("react-usa-map").default;

interface LicenseMapProps {
  user: UserClass;
  agent: EnsUser;
}

export default function LicenseMap(props: LicenseMapProps): JSX.Element {
  const { agent, user } = props;
  const [selectedState, setSelectedState] = useState<string>();
  const [licenseList, setLicenseList] = useState<License[]>([]);
  const [loading, setLoading] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const mapHandler = (event: any) => {
    setSelectedState(event.target.dataset.name);
  };
  const getLicenses = (ensUserTid: number) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoading(true);
      return c.getUserLicenses(ensUserTid);
    })
      .then((res: License[]) => {
        setLicenseList(res);
      })
      .catch(() => {
        setDisplayError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLicenses(agent.ens_user_tid);
  }, [agent]);

  return (
    <div className="MapContainer my-5">
      {loading && <Loader />}
      {displayError && <StatusAlert shouldDisplay={true} success={false} />}
      {!loading && (
        <>
          <ReactUsaMap customize={StateColorDisplay(user, licenseList)} onClick={mapHandler} />

          <MapModal displayLicense={true} closeModal={() => setSelectedState(undefined)} selectedState={selectedState}>
            {LicenseModal(licenseList.filter((lic) => lic.state === selectedState && lic.license_class_code !== 6613)) /* 6613 is the class code for john rich's sublicensee license in NY */}
          </MapModal>

          <Row style={{ margin: "2em 0 2em 0" }}>
            <Col offset={12} span={12}>
              {<h5>* FL Health only agents can only sell Health in TX </h5>}
            </Col>
          </Row>
          <Row>
            <Col>
              <NiprDateStatus type={NiprDateType.License} />
            </Col>
          </Row>
        </>
      )}
      <Col>
        <svg width="18" height="18">
          <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#4C8E4C", stroke: "black" }} />
        </svg>
        {" Fully Licensed"}
      </Col>
      <Col className="align-middle">
        <svg width="18" height="18">
          <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#FDE396", stroke: "black" }} />
        </svg>
        {" Partially Licensed"}
      </Col>
      <Col className="align-middle">
        <svg width="18" height="18">
          <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#CFCFC4", stroke: "black" }} />
        </svg>
        {" Unlicensed"}
      </Col>
    </div>
  );
}

const LicenseModal = (licenses: License[]) => {
  if (!licenses.length) return "No license data.";
  return (
    <>
      {licenses.map(license => <div
        key={license.ens_user_license_tid}
        style={{
          border: "2px solid #E8E8E8",
          padding: 5,
          marginBottom: 10
        }}
      >
        <Row>
          <Col span={24}>
            <Descriptions
              size="small"
              layout="vertical"
              bordered
              labelStyle={{ fontWeight: "bold" }}
            >
              <Descriptions.Item label="License #">{license.license_number}</Descriptions.Item>
              <Descriptions.Item label="Issued Date">{license.issued_date?.toLocaleDateString("en-US")}</Descriptions.Item>
              <Descriptions.Item label="Expiration Date">{license.expiration_date?.toLocaleDateString("en-US")}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Title level={5}>LOA w/Issue Date</Title>
            <ul>
              {license.license_loa?.map((loa) => (
                <li key={loa.nipr_report_pdb_producer_license_loa_tid}><b>{loa.loa_name}</b>: {loa.loa_issue_date?.toLocaleDateString("en-US")}</li>
              ))}
            </ul>
          </Col>
        </Row>
      </div>)}
    </>
  );
};
