import React from "react";
import { Select, DatePicker, Col } from "antd";
import moment from "moment";

export const Months = [
  "Janurary",
  "Februray",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface YearAndMonthSelectorProps {
  onSetMonth: (m: number) => void;
  onSetYear: (y: number) => void;
  onSetPostingDate?: (d: moment.Moment) => void;
  onSetAccountingDate?: (d: moment.Moment) => void;
}

export function YearAndMonthSelector(props: YearAndMonthSelectorProps) {
  const { onSetMonth, onSetYear, onSetAccountingDate, onSetPostingDate } = props;
  return (
    <>
      <Select<number>
        placeholder={"Month..."}
        style={{ width: "45%" }}
        options={Months.map((item, i) => ({ label: item, value: i + 1 }))} // i + 1 is important to keep the month 1-based
        onChange={(value) => onSetMonth(value)}
      />
      <Select<number>
        placeholder={"Year..."}
        style={{ width: "45%", marginBottom: "20px" }}
        options={[
          { value: 2016 },
          { value: 2017 },
          { value: 2018 },
          { value: 2019 },
          { value: 2020 },
          { value: 2021 },
          { value: 2022 },
          { value: 2023 },
          { value: 2024 },
          { value: 2025 },
        ]}
        onChange={(value) => onSetYear(value)}
      />
      {onSetPostingDate && (
        <Col>
          <h5>Select Posting Date</h5>
          <DatePicker
            style={{ width: "45%", marginBottom: "20px" }}
            format="MM/DD/YYYY (ddd)"
            placeholder="Posting Date"
            onChange={(e) => {
              if (e) {
                onSetPostingDate?.(e);
              }
            }}
          />
        </Col>
      )}
      {onSetAccountingDate && (
        <>
          <h5>Select Accounting Date</h5>
          <DatePicker
            format="MM/DD/YYYY (ddd)"
            style={{ width: "45%", marginBottom: "20px" }}
            placeholder="Accounting Date"
            onChange={(e) => {
              if (e) {
                onSetAccountingDate?.(e);
              }
            }}
          />
        </>
      )}
    </>
  );
}
