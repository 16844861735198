import * as History from "history";
import React from "react";
import ReactGA from "react-ga";
import packagejson from "../../../package.json";

const trackingId = packagejson["config"][process.env.NODE_ENV]["GA_TRACKING_ID"];
ReactGA.initialize(trackingId);

interface WithTrackingProps {
  children: React.ReactChild;
  location: History.Location<History.LocationState>;
}

const WithTracking = (props: WithTrackingProps) => {
  const { location, children } = { ...props };
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  return <>{children}</>;
};

export default WithTracking;
