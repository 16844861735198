import React, { useContext, useEffect, useState } from "react";
import { Select, Row, Col, Checkbox, Button } from "antd";
import {
  EnsProductCarrierTid,
  ISheetColumns,
  SheetColumns,
  UploadCarrierPaymentsTaskSpecs,
} from "common/services/microservices/finance-client";
import { ReportUploadCreatorSharedProps } from "./UploadSheetForm";
import { UserContext } from "core/context/userContext";

const { Option } = Select;

interface CommissionUploadTaskCreatorProps extends ReportUploadCreatorSharedProps {}

export function CommissionUploadTaskCreator(props: CommissionUploadTaskCreatorProps) {
  const { sheetColumns, sheetS3Location, product, carrier, sheetSelectedName, sheetOriginalFilename, startTask } =
    props;

  const { user } = useContext(UserContext);

  const [isTaskStarting, setIsTaskStarting] = useState(false);
  const [useInsuredName, setUseInsuredName] = useState(false);
  const [useLedgerCategory, setUseLedgetCategory] = useState(false);
  const [useTermDate, setUseTermDate] = useState(false);

  const [selectedColumnInfo, setSelectedColumnInfo] = useState<ISheetColumns>({
    policy_number_header_name: "",
    policy_effective_date_header_name: "",
    payment_date_header_name: "",
    payment_effective_date_header_name: "",
    payment_amount_header_name: "",
    insured_name_header_name: "",
    ledger_category_header_name: "",
    termination_date: "",
  });

  function startUploadPaymentTask() {
    setIsTaskStarting(true);
    startTask({
      specs: new UploadCarrierPaymentsTaskSpecs({
        calling_user: user.UserEmail,
        sheet_download_guid: sheetS3Location.objectGuid,
        sheet_download_prefix: sheetS3Location.objectPrefix,
        sheet_table_name: sheetSelectedName,
        ens_product_carrier_tid: carrier,
        report_carrier_product_category: product,
        update_underwriting_status: false,
        sheet_table_columns: new SheetColumns(selectedColumnInfo),
      }),
      taskName: "UploadCarrierPaymentsTask",
    }).finally(() => setIsTaskStarting(false));
  }

  const filterOutSelectedColumns = () => {
    const selectedColumnsValues = Object.values(selectedColumnInfo);
    return sheetColumns?.filter((col) => !selectedColumnsValues.includes(col)) ?? [];
  };

  function updateColumn(columnName: keyof ISheetColumns, value: string) {
    setSelectedColumnInfo((info) => ({ ...info, [columnName]: value }));
  }

  const options = (
    <>
      {filterOutSelectedColumns().map((col: string, idx: number) => (
        <Option key={idx} value={col}>
          {col}
        </Option>
      ))}
    </>
  );

  useEffect(() => {
    if (carriersThatRequireName.includes(carrier)) {
      setUseInsuredName(true);
    }
  }, []);

  const requiredKeys: (keyof ISheetColumns)[] = [
    "policy_number_header_name",
    "policy_effective_date_header_name",
    "payment_date_header_name",
    "payment_effective_date_header_name",
    "payment_amount_header_name",
  ];

  // These carriers must submit a name field as they rely on first/last name cash matching
  // on the backend to apply policy numbers to sales.
  const carriersThatRequireName = [EnsProductCarrierTid.Ameritas, EnsProductCarrierTid.UnitedHealthcare];

  const submitButtonDisabled =
    Object.keys(selectedColumnInfo)
      .filter((k) => requiredKeys.includes(k as keyof ISheetColumns))
      .some((k) => selectedColumnInfo[k as keyof ISheetColumns]?.length === 0) ||
    (carriersThatRequireName.includes(carrier) && selectedColumnInfo["insured_name_header_name"]?.length === 0);

  return (
    <>
      <Row gutter={[16, 16]} className="my-3 spreadsheet-column-select-container">
        <Col offset={6} span={4}>
          Columns
        </Col>
        <Col span={9} className="spreadsheet-column-select-subcontainer">
          <Row className="spreadsheet-column-select-row">
            <Row>
              <p>Payment Date:</p>
              <Select showSearch onChange={(value: string) => updateColumn("payment_date_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <p>Policy Number:</p>
              <Select showSearch onChange={(value: string) => updateColumn("policy_number_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <p>Payment Effective Date:</p>
              <Select
                showSearch
                onChange={(value: string) => updateColumn("payment_effective_date_header_name", value)}
              >
                {options}
              </Select>
            </Row>
            <Row>
              <p>Policy Effective Date:</p>
              <Select showSearch onChange={(value: string) => updateColumn("policy_effective_date_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <p>Payment Amount:</p>
              <Select showSearch onChange={(value: string) => updateColumn("payment_amount_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <Row>
                <Checkbox
                  defaultChecked={carriersThatRequireName.includes(carrier) ? true : useInsuredName}
                  onChange={(e) => setUseInsuredName(e.target.checked)}
                />
                <p>Insured Name:</p>
              </Row>
              <Select
                showSearch
                onChange={(value: string) => updateColumn("insured_name_header_name", value)}
                disabled={!useInsuredName}
              >
                {options}
              </Select>
            </Row>
            <Row>
              <Row>
                <Checkbox onChange={(e) => setUseLedgetCategory(e.target.checked)} />
                <p>Ledger Category:</p>
              </Row>
              <Select
                showSearch
                onChange={(value: string) => updateColumn("ledger_category_header_name", value)}
                disabled={!useLedgerCategory}
              >
                {options}
              </Select>
            </Row>
            <Row>
              <Row>
                <Checkbox onChange={(e) => setUseTermDate(e.target.checked)} />
                <p>Termination Date:</p>
              </Row>
              <Select
                showSearch
                onChange={(value: string) => updateColumn("termination_date", value)}
                disabled={!useTermDate}
              >
                {options}
              </Select>
            </Row>
          </Row>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center" }} className="my-3">
        <Button
          disabled={submitButtonDisabled}
          loading={isTaskStarting}
          type="primary"
          onClick={() => startUploadPaymentTask()}
        >
          Upload sheet {sheetSelectedName ? `"${sheetSelectedName}"` : "?"} of {sheetOriginalFilename ?? "?"}
        </Button>
      </Row>
    </>
  );
}
