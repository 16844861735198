import React, { useEffect, useState } from "react";
import { Select, SelectProps } from "antd";
import { authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { ISaleFinanceTagType, SaleFinanceTagClient } from "common/services/microservices/finance-client";

export default function FinanceTagSelect(props: SelectProps<number[]>) {
  const [loading, setLoading] = useState<boolean>(false);
  const [selections, setSelections] = useState<ISaleFinanceTagType[]>([]);

  useEffect(() => {
    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.finance, SaleFinanceTagClient, (c) => c.getAllSaleFinanceTags())
      .then((res) => {
        setSelections(res);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select loading={loading} allowClear placeholder="Finance tags..." mode="multiple" {...props}>
      {selections.map((item) => (
        <Select.Option key={item.tid} value={item.tid}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  );
}
