import { Alert, AlertProps, Button, Col, Row, Select, Table, Typography } from "antd";
import Dragger, { DraggerProps } from "antd/lib/upload/Dragger";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import React, { useEffect, useState } from "react";
import { InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { CronTaskState, CronTaskStateType, SortOrder, TasksClient } from "common/services/microservices/cron-boss-client";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import { ReadyToSellJobClient, ReadyToSellJobStatus, ReadyToSellReportType } from "common/services/microservices/licenses-client";

const { Paragraph } = Typography;
const { Option } = Select;

interface RtsReportUploadProps {
  user: UserClass;
}

export default function RtsReportUpload(props: RtsReportUploadProps) {
  const { user } = props;
  const [uploadFailed, setUploadFailed] = useState(false);
  const [latestUploads, setLatestUploads] = useState<ReadyToSellJobStatus[]>([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [reportType, setReportType] = useState<ReadyToSellReportType>();

  useEffect(() => {
    getTasks();

    const refresh = setInterval(() => {
      getTasks();
    }, 3000);

    return () => clearInterval(refresh);
  }, []);

  const uploadProps: DraggerProps = {
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    onChange: (info: UploadChangeParam) => {
      const { status } = info.file;

      setUploadFailed(false);

      if (status === 'done')
        getTasks();
      else if (status === 'error')
        setUploadFailed(true);
    },
    customRequest: (options: UploadRequestOption) => {
      authenticatedClient(MicroservicesEndpoint.licenses, ReadyToSellJobClient)
        .then(async c => {
          let url = await c.getUploadUrl();
          let uploadRes = await fetch(url.presignedUrl, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            method: "PUT",
            body: options.file,
          });

          if (!uploadRes.ok) {
            options.onError?.(undefined!, uploadRes.statusText);
            return;
          }

          await c.processReport(url.objectGuid, reportType);

          options.onSuccess?.(uploadRes.statusText, undefined!);
        }).catch(() => {
          options.onError?.(undefined!, "Failed");
        });
    }
  };

  const getTasks = () => {
    setTasksLoading(true);
    authenticatedClient(MicroservicesEndpoint.licenses, ReadyToSellJobClient)
      .then(async c => setLatestUploads(await c.getLatest())
      ).finally(() => setTasksLoading(false));
  };

  const jobsColumns: ColumnsType<ReadyToSellJobStatus> = [
    {
      title: () => <Button icon={<ReloadOutlined />} onClick={() => getTasks()} />,
      width: 250,
      dataIndex: ["guid"]
    },
    {
      title: "TaskName",
      dataIndex: ["taskName"]
    },
    {
      title: "Date",
      align: "right",
      render: (value: any, record: ReadyToSellJobStatus) => record.lastEventDatetime.toLocaleString()
    },
    {
      title: "Status",
      render: (value: any, record: ReadyToSellJobStatus) => CronTaskStateType[record.state]
    }
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Select
            placeholder="Select Report Type"
            style={{ width: "20%" }}
            onChange={(v: number) => setReportType(v)}
          >
            {Object.keys(ReadyToSellReportType)
              .filter((k) => !isNaN(parseInt(k)))
              .map((k: string) => (
                <Option key={parseInt(k)} value={parseInt(k)}>
                  {ReadyToSellReportType[parseInt(k)]}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div>
            <Dragger disabled={reportType == undefined} {...uploadProps} >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag RTS Report file to this area to upload</p>
              <p className="ant-upload-hint">
                Processing can take up to 3 minutes.
              </p>
            </Dragger>
            {uploadFailed && <Alert message="RTS Report upload failed. Please contact engineering." type="error" />}
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <Title level={4}>RTS Report History</Title>
          <Table
            columns={jobsColumns}
            rowKey={(record) => record.guid}
            dataSource={latestUploads}
            loading={tasksLoading}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
