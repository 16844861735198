import * as React from "react";
import { Alert } from "antd";
interface StatusAlertProps {
  shouldDisplay: boolean;
  success: boolean;
  successText?: string;
  errorText?: string;
  warning?: boolean;
  warningText?: string;
  showIcon?: boolean;
  closable?: boolean;
}

export default function StatusAlert(props: StatusAlertProps): JSX.Element {
  const { shouldDisplay, success, successText, errorText, warning, warningText, showIcon, closable } = props;
  const defaultTextError = "Oh no! Something went wrong. Contact the engineering team about this.";
  const defaultTextSuccess = "Success";
  const defaultTextWarning = "Warning";
  if (shouldDisplay) {
    return (
      <Alert
        message={success 
                  ? successText || defaultTextSuccess 
                  : warning 
                    ? warningText || defaultTextWarning 
                    : errorText || defaultTextError}
        type={success ? "success" : warning ? "warning" : "error"}
        showIcon={showIcon ? showIcon : true}
        closable={closable ? closable : false}
      ></Alert>
    );
  }
  return <></>;
}
