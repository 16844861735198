//Error page when a route is a bad gateway

import React from "react";
import "core/scss/NotFound.scss";

export default function NotFound() {
  return (
    <div className="NotFound">
      <h3>Sorry, page not found!</h3>
    </div>
  );
} 
