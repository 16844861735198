import React from "react";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { SaleStatus } from "common/services/microservices/sales-client";

export const AgentTableColumns: ColumnsType<SaleStatus> = [
  {
    title: () => <span>Sale ID</span>,
    defaultSortOrder: "descend" as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.ens_sale_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) =>
      a.ens_sale_tid < b.ens_sale_tid ? 1 : -1,
  },
  {
    title: () => <span>Agent</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `${record.agent_first_name} ${record.agent_last_name ?? ""}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.agent_last_name ?? "").localeCompare(b.agent_last_name ?? "");
    },
  },
  {
    title: () => <span>Agent ID</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.agent_ens_user_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      if (!a.agent_ens_user_tid || !b.agent_ens_user_tid) return 0;
      return a.agent_ens_user_tid < b.agent_ens_user_tid ? 1 : -1;
    },
  },
  {
    title: () => <span>Referring Agent</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `${record.referring_agent_first_name ?? ""} ${record.referring_agent_last_name ?? ""}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.agent_last_name ?? "").localeCompare(b.agent_last_name ?? "");
    },
  },
  {
    title: () => <span>Referring Agent ID</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.referral_ens_user_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      if (!a.referral_ens_user_tid || !b.referral_ens_user_tid) return 0;
      return a.referral_ens_user_tid < b.referral_ens_user_tid ? 1 : -1;
    },
  },
  {
    title: () => <span>Referral Agent Answer</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.referral_agent_answer;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.referral_agent_answer ?? "").localeCompare(b.referral_agent_answer ?? "");
    },
  },
  {
    title: () => <span>Commission Basis</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.commission_basis;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.commission_basis ?? "").localeCompare(b.commission_basis ?? "");
    },
  },
  {
    title: () => <span>Underwriting Comments</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.underwriting_comments;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.underwriting_comments ?? "").localeCompare(b.underwriting_comments ?? "");
    },
  }
];