import { Button, message, Modal, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Table, { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  CalculateFutureValueClient,
  ForecastRecalculateRequest,
  IRevenueBookingForecastInfo,
  RevenueBookingClient,
} from "common/services/microservices/finance-client";
import { authenticatedClient } from "core/components/AuthProvider";
import { ReloadOutlined, DownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import CronTaskEventByGuidDisplay from "operations/commission-upload/components/CronTaskEventDisplay";
import { CronTaskStateType } from "common/services/microservices/cron-boss-client";

export function Forecast2ReRunner() {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [saleTids, setSaleTids] = useState<number[]>([]);
  const [results, setResults] = useState<IRevenueBookingForecastInfo[]>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recalculateGuid, setRelculateGuid] = useState<string>();

  const OUTPUT_TABLE_NAME = window.location.href.includes("insuretysolutions.com")
    ? "ens_commission_forecast_777"
    : "ens_commission_forecast_test";

  const SearchResultsColumns: ColumnsType<IRevenueBookingForecastInfo> = [
    {
      title: "Sale Tid",
      dataIndex: ["sale_tid"],
      render: (_: any, record) => record?.sale_tid,
      sorter: (a, b) => ((a.sale_tid ?? 0) < (b.sale_tid ?? 0) ? 1 : -1),
      onFilter: (value, record) => record.sale_tid.toString().startsWith(value.toString()),
    },
    {
      title: "Forecast 1",
      dataIndex: ["f1_sum"],
      render: (_: any, record) => `$${record?.f1_sum || "0.00"}`,
      sorter: (a, b) => ((a.f1_sum ?? 0) < (b.f1_sum ?? 0) ? 1 : -1),
    },
    {
      title: "Forecast 2",
      defaultSortOrder: "descend" as SortOrder,
      dataIndex: ["f2_sum"],
      render: (_: any, record) => `$${record?.f2_sum || "0.00"}`,
      sorter: (a, b) => ((a.f2_sum ?? 0) < (b.f2_sum ?? 0) ? 1 : -1),
    },
  ];

  async function fetchForecast2s() {
    setLoading(true);
    try {
      const client = await authenticatedClient(MicroservicesEndpoint.finance, RevenueBookingClient);
      const forecasts = await client.getForecast1And2InfoBySid(saleTids);
      setResults(forecasts);
    } catch (ex) {
      message.error("Unable to fetch forecasts");
      setResults([]);
    } finally {
      setLoading(false);
    }
  }

  function recalculateForecasts() {
    setLoading(true);

    authenticatedClient(MicroservicesEndpoint.finance, CalculateFutureValueClient)
      .then((client) => {
        return client.runRecalculateFutureValueTask777(
          new ForecastRecalculateRequest({
            outputTableName: OUTPUT_TABLE_NAME,
            ensSaleTids: saleTids,
          })
        );
      })
      .then((results) => {
        setRelculateGuid(results.guid);
        setIsModalOpen(true);
        message.success(`Started recalculation of ${saleTids.length} sales. This may take a few minutes.`);
      })
      .catch(() => {
        setRelculateGuid(undefined);
        message.error(`Failed to start recalculate task.`);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="row justify-content-center mb-3">
      <TextArea
        className="rbt-sale-tids"
        onChange={(event) => {
          const actualSaleTids = event.target.value
            .split("\n")
            .map((b) => parseInt(b))
            .filter((b) => !isNaN(b));
          setSaleTids(actualSaleTids);
        }}
        placeholder={`Enter Sale IDs to re-run, one per line.`}
        style={{ resize: "none", height: "60px", marginBottom: "20px", width: "60%", minHeight: "200px" }}
      />
      <Tooltip title={`Pull the current HAL values for these ${saleTids.length} sales without re-running anything.`}>
        <Button
          className={`btn ${saleTids.length !== 0 ? "btn-info  ml-3" : ""} `}
          loading={isLoading}
          onClick={fetchForecast2s}
          disabled={saleTids.length === 0}
          style={{
            height: "60px",
            fontSize: "11px",
            whiteSpace: "break-spaces",
          }}
        >
          <DownloadOutlined />
          Load {saleTids.length} sales
        </Button>
      </Tooltip>
      <Tooltip
        title={`Write new forecasts for these ${saleTids.length} sales into ${OUTPUT_TABLE_NAME}, then display the new values.`}
      >
        <Button
          className={`btn ${saleTids.length !== 0 ? "btn-info ml-3" : ""} `}
          loading={isLoading}
          onClick={recalculateForecasts}
          disabled={saleTids.length === 0}
          style={{
            height: "60px",
            fontSize: "11px",
            whiteSpace: "break-spaces",
          }}
        >
          <ReloadOutlined />
          Rerun {saleTids.length} sales
        </Button>
      </Tooltip>
      <Table
        loading={isLoading}
        style={{ width: "90%" }}
        id="rbt-search-table"
        columns={SearchResultsColumns}
        rowKey={"ensSaleTid"}
        dataSource={results}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: 30,
        }}
      />
      {recalculateGuid && (
        <Modal
          title="Forecast Recalculation"
          width={"1000px"}
          visible={isModalOpen}
          cancelButtonProps={{ disabled: true }}
          onOk={() => {
            setRelculateGuid(undefined);
            setIsModalOpen(false);
          }}
        >
          <CronTaskEventByGuidDisplay
            guid={recalculateGuid}
            onTaskStateChanged={(state) => {
              if (state === CronTaskStateType.Success) {
                fetchForecast2s();
              } else if (state === CronTaskStateType.Failure) {
                message.error(`Failed to recalculate forecasts.`);
              }
            }}
          />
        </Modal>
      )}
    </div>
  );
}
