import React, { useState } from "react";
import { Collapse } from "antd";
import { ReveneBookingReportGenerator } from "./MonthlyReportGenerator";
import { Forecast2ReRunner } from "./Forecast2ReRun";
import { MonthlyRevenueBooker } from "./MonthlyRevenueBooker";
import { YearAndMonthSelector } from "./YearAndMonthSelector";
import { ReportForecastTid, RevenueBookingInput, RevenueType } from "common/services/microservices/finance-client";

export const Months = [
  "Janurary",
  "Februray",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function MonthlyOriginalRevenueBookingTool() {
  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();

  return (
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <h3>Original Revenue Booking Tool</h3>
      <div className="col-14" style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
        <h5>Select month and year to book</h5>
        <YearAndMonthSelector onSetMonth={setMonth} onSetYear={setYear} />
        {month && year && (
          <Collapse style={{ marginBottom: "20px", maxWidth: "100%", textAlign: "center" }}>
            <Collapse.Panel header={"Generate Report"} key="1">
              <ReveneBookingReportGenerator month={month} year={year} forecast_type_tid={ReportForecastTid.Original} />
            </Collapse.Panel>
            <Collapse.Panel header={"Re-run Forecasts"} key="2">
              <Forecast2ReRunner />
            </Collapse.Panel>
            <Collapse.Panel header={"Book Revenue"} key="3">
              <MonthlyRevenueBooker
                input={
                  new RevenueBookingInput({
                    year: year,
                    month: month,
                    accounting_date: undefined,
                    posting_date: undefined,
                  })
                }
                bookingType={RevenueType.Original}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
    </div>
  );
}
