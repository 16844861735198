import { message, Modal } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  CommissionsBulkDeleteResult,
  CommissionsUploadManagerClient,
  CommissionUploadManagerSearchResult,
} from "common/services/microservices/finance-client";
import { authenticatedClient } from "core/components/AuthProvider";
import React, { useEffect, useState } from "react";
import { ModalBody } from "react-bootstrap";
import { WarningOutlined } from "@ant-design/icons/lib/icons/";

interface DeleteCommissionRecordModalProps {
  records: CommissionUploadManagerSearchResult[];
  carrierId: number;
  productCategoryId: number | undefined;
  paymentYear: number | undefined;
  paymentMonth: number | undefined;
  paymentDate: Date | undefined;
  visible: boolean;
  bulkDeleteMode?: BulkDeleteMode;
  closeModal: () => void;
  refreshGrid: (payments: CommissionUploadManagerSearchResult[]) => void;
}

export type BulkDeleteMode = "all" | "by_tid";

export default function DeleteCommissionRecordModal(props: DeleteCommissionRecordModalProps) {
  const {
    records,
    visible,
    carrierId,
    bulkDeleteMode: deleteMode,
    productCategoryId,
    paymentYear,
    paymentMonth,
    paymentDate,
    closeModal,
    refreshGrid,
  } = props;

  const [deleteButtonLoading, setDeleteButtonLoading] = useState<boolean>(false);
  const [bulkDeleteGuid, setBulkDeleteGuid] = useState<string | undefined>();
  const [bulkDeleteResults, setBulkDeleteResults] = useState<CommissionsBulkDeleteResult | undefined>();
  const count: number = getCountOfRecordsToDelete();

  useEffect(() => {
    if (!bulkDeleteGuid || bulkDeleteResults?.isDone) return;

    const interval = setInterval(() => {
      authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient).then(async (client) => {
        client
          .getBulkDeleteByMonthTaskResult(bulkDeleteGuid)
          .then((response) => {
            if (response.isDone && response.successful) {
              setDeleteButtonLoading(false);
              refreshGrid(records);
              closeModal();
              message.success(`Successfully deleted selected carrier payments`);
            } else if (response.isDone && !response.successful) {
              message.warning(`Error completing the bulk delete. Please check if deletion occured in database`);
            }
            setBulkDeleteResults(response);
          })
          .catch((e: unknown) => {
            if (e instanceof Error) {
              message.error(e.message);
            } else {
              message.warning(`Deletion appeared to not complete. Please check if deletion occured in database`);
            }
            setDeleteButtonLoading(false);
          });
      });
    }, 2000);
    return () => clearInterval(interval);
  }, [bulkDeleteGuid]);

  function bulkDelete() {
    if (records) {
      authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
        .then((client) => {
          setDeleteButtonLoading(true);
          if (deleteMode === "all") {
            if (paymentDate) {
              return client.bulkDeleteCarrierPaymentsByDate(carrierId, productCategoryId, paymentDate);
            } else if (paymentYear && paymentMonth) {
              return client.bulkDeleteCarrierPaymentsByMonth(carrierId, productCategoryId, paymentYear, paymentMonth);
            } else {
              // Fallback and simply delete all records by their TID as requested
              const tids = records.map((r) => r.ens_sale_carrier_payment_tid);
              return client.bulkDeleteCarrierPaymentsByTid(tids);
            }
          } else if (deleteMode === "by_tid") {
            const tids = records.map((r) => r.ens_sale_carrier_payment_tid);
            return client.bulkDeleteCarrierPaymentsByTid(tids);
          } else {
            throw new Error("Unknown delete mode. Please contact engineering.");
          }
        })
        .then((response) => {
          setBulkDeleteGuid(response.guid);
          return response;
        })
        .catch((e: unknown) => {
          if (e instanceof Error) {
            message.error(e.message);
          } else {
            message.warning(`Deletion appeared to not complete. Please check if deletion occured in database`);
          }
          setDeleteButtonLoading(false);
        });
    }
  }

  function deleteRecord() {
    if (records) {
      authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
        .then((client) => {
          setDeleteButtonLoading(true);
          return client.deleteSingleCarrierPayment(records[0].ens_sale_carrier_payment_tid!);
        })
        .then((response) => {
          setDeleteButtonLoading(false);
          closeModal();
          refreshGrid(records);
          message.success(`Successfully deleted record`);
          return response;
        })
        .catch(() => {
          message.warning(`Deletion took a long time. Please check if deletion occured`);
          setDeleteButtonLoading(false);
        });
    }
  }

  function getCountOfRecordsToDelete(): number {
    if (typeof records === typeof CommissionUploadManagerSearchResult) {
      return 1;
    } else if (Array.isArray(records)) {
      return records.length;
    }
    return 0;
  }

  return (
    <Modal
      title={"Bulk Delete"}
      visible={visible}
      onOk={() => {
        if (count === 1) {
          return deleteRecord();
        } else if (count > 1) return bulkDelete();
      }}
      onCancel={closeModal}
      width={"40%"}
      confirmLoading={deleteButtonLoading}
    >
      <ModalBody style={{ textAlign: "center" }}>
        <WarningOutlined style={{ backgroundColor: "yellow", color: "black", fontSize: "80px" }} />
        <br></br>
        {getCountOfRecordsToDelete()} records will be deleted.
        <br></br>
        Are you sure?
      </ModalBody>
    </Modal>
  );
}
