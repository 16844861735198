import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface StateSelectProps {
  onStateSelect: (state: string) => void;
  loading?: boolean;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  clear?: number;
  size?: SizeType;
  style?: React.CSSProperties;
  disableTerritories: boolean;
}

const { Option } = Select;

const territories = [
  { value: "AS", text: "American Samoa" },
  { value: "FM", text: "Micronesia" },
  { value: "GU", text: "Guam" },
  { value: "MH", text: "Marshall Islands" },
  { value: "MP", text: "Northern Mariana Islands" },
  { value: "PR", text: "Puerto Rico" },
  { value: "PW", text: "Palau" },
  { value: "VI", text: "Virgin Islands" },
];

const states = [
  { value: "AK", text: "Alaska" },
  { value: "AL", text: "Alabama" },
  { value: "AR", text: "Arkansas" },
  { value: "AZ", text: "Arizona" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DC", text: "District of Columbia" },
  { value: "DE", text: "Delaware" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "HI", text: "Hawaii" },
  { value: "IA", text: "Iowa" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "MA", text: "Massachusetts" },
  { value: "MD", text: "Maryland" },
  { value: "ME", text: "Maine" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MO", text: "Missouri" },
  { value: "MS", text: "Mississippi" },
  { value: "MT", text: "Montana" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "NE", text: "Nebraska" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NV", text: "Nevada" },
  { value: "NY", text: "New York" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VA", text: "Virginia" },
  { value: "VT", text: "Vermont" },
  { value: "WA", text: "Washington" },
  { value: "WI", text: "Wisconsin" },
  { value: "WV", text: "West Virginia" },
  { value: "WY", text: "Wyoming" },
];

export default function StateSelect(props: StateSelectProps) {
  const { onStateSelect, loading, clear, placeholder, defaultValue, size, style, disableTerritories } = props;
  const [selectedState, setSelectedState] = useState<string | undefined>();

  const stateList = (disableTerritories: boolean) => {
    if (disableTerritories) return states;
    else {
      return states.concat(territories).sort((a, b) => a.value.localeCompare(b.value));
    }
  };

  useEffect(() => {
    setSelectedState(undefined);
  }, [clear]);

  return (
    <Select
      allowClear
      size={size}
      showSearch
      disabled={loading}
      loading={loading}
      defaultValue={defaultValue}
      value={selectedState}
      placeholder={placeholder}
      style={style}
      optionFilterProp="children"
      onChange={(value, option) => {
        onStateSelect(value as string);
        setSelectedState(value);
      }}
      filterOption={(input: string, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {stateList(disableTerritories).map((item, index) => {
        return (
          <Option key={index} value={item.value}>
            {item.text}
          </Option>
        );
      })}
    </Select>
  );
}
