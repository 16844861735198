import { Col, Row, Empty, message } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  EnsUserPermissionType,
  PermissionsClient,
  RolesClient,
  UserPermission,
  UserRole,
} from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useEffect, useState } from "react";
import RoleSelect from "./RoleSelect";
import { PermissionsTransferWidget } from "../permissions/PermissionsTransferWidget";

export default function RolePermissionsPage() {
  const [selectedRole, setSelectedRole] = useState<UserRole>();
  const [allPerms, setAllPerms] = useState<UserPermission[]>([]);
  const [activeRolePerms, setActiveRolePerms] = useState<UserPermission[]>([]);

  useEffect(() => {
    setAllPerms([]);

    authenticatedFetch(MicroservicesEndpoint.ensUsers, PermissionsClient, async (c) => {
      let permissionsListReq = c.getPermissionsList();
      setAllPerms(await permissionsListReq);
    }).catch(() => message.error("Something went wrong. Contact Engineering."));
  }, []);

  useEffect(() => {
    if (!selectedRole) return;

    authenticatedFetch(MicroservicesEndpoint.ensUsers, RolesClient, async (c) => {
      let rolePermissionsReq = c.getRolePermissions(selectedRole.ensUserRoleType);
      var permissions = await rolePermissionsReq;
      setActiveRolePerms(permissions);
    }).catch(() => message.error("Something went wrong. Contact Engineering."));
  }, [selectedRole]);

  const updatePermissions = (newList: EnsUserPermissionType[]) => {
    if (!selectedRole) return;

    return authenticatedFetch(MicroservicesEndpoint.ensUsers, RolesClient, async (c) => {
      let newPermissions = await c.setRolePermissions(selectedRole.ensUserRoleType, newList);
      setActiveRolePerms(newPermissions);
    });
  };

  return (
    <Row>
      <Col span={24}>
        <RoleSelect selectedValue={selectedRole?.ensUserRoleType} onSelect={(v) => setSelectedRole(v)} />
      </Col>
      <Col span={24} style={{ marginTop: 15 }}>
        {!selectedRole ? (
          <Empty />
        ) : (
          <PermissionsTransferWidget
            availablePermissions={allPerms}
            currentActivePermissions={activeRolePerms}
            onPermissionsUpdated={updatePermissions}
          />
        )}
      </Col>
    </Row>
  );
}
