import React, { useState, useEffect } from "react";
import {
  ApiServerService,
  Certification,
  UserClient,
  UpdateUserCertificationCompleteFlRequest,
  EnsUser,
  EnsUserPermissionTypeTid,
  MicroservicesEndpoint,
} from "common/services/ApiServerService";
import Checkmark from "licensing/images/checkmark.svg";
import Xmark from "licensing/images/x-mark.svg";
import UserClass from "core/models/UserClass";
import { authenticatedFetch } from "core/components/AuthProvider";
import { Button, Col, Input, InputNumber, Modal, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import { CertificationClient } from "common/services/microservices/licenses-client";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";

interface CertificationDisplayProps {
  user: UserClass;
  agent: EnsUser;
}

export default function CertificationEditor(props: CertificationDisplayProps) {
  const { user, agent } = props;
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [sortedCertifications, setSortedCertifications] = useState<Certification[]>([]);
  const [generateModal, setGenerateModal] = useState(false);

  useEffect(() => {
    loadCerts(agent);
  }, [agent]);

  //Default sorts the certifications by year and name
  useEffect(() => {
    var sortByYear = certifications.sort((a, b) =>
      a.expiration_date!.getFullYear() > b.expiration_date!.getFullYear() ? -1 : 1
    );
    var sortByYearAndName = sortByYear.sort((c, d) =>
      c.expiration_date?.getFullYear() === d.expiration_date?.getFullYear()
        ? c.user_certification_name.localeCompare(d.user_certification_name)
        : 0
    );
    setSortedCertifications(sortByYearAndName);
  }, [certifications]);

  const loadCerts = (agent: EnsUser) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setTableLoading(true);
      return c.getUserCertifications(agent.ens_user_tid);
    }).then((certs) => {
      setTableLoading(false);
      setCertifications(certs);
    });
  };

  const toggleCertification = (cert: Certification) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setTableLoading(true);
      return c.updateUserCertificationCompleteFl(cert.ens_user_certification_tid, {
        complete_fl: !cert.complete_fl,
        calling_user: props.user.UserEmail,
      } as UpdateUserCertificationCompleteFlRequest);
    }).then(() => {
      setTableLoading(false);
      let updateCert = certifications.find((c) => c.ens_user_certification_tid === cert.ens_user_certification_tid);

      if (!updateCert) return;

      // simply flip the cert flag
      updateCert.complete_fl = !updateCert.complete_fl;

      setCertifications([...certifications]);
    });
  };

  const getRecordKey = (cert: Certification) => {
    return cert.ens_user_certification_tid;
  };

  const columns: ColumnsType<Certification> = [
    {
      title: "Active",
      width: 80,
      render: (value: any, c: Certification) => (
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (user.hasPermission(EnsUserPermissionType.LicensingEditAccess)) toggleCertification(c);
          }}
        >
          <img src={c.complete_fl ? Checkmark : Xmark} alt="" />
        </span>
      ),
    },
    {
      title: "Certificate Name",
      dataIndex: "user_certification_name",
      align: "left",
      defaultSortOrder: null as SortOrder,
      sorter: (a: Certification, b: Certification) =>
        a.user_certification_name.localeCompare(b.user_certification_name),
    },
    {
      title: "Expiration Year",
      align: "left",
      defaultSortOrder: null as SortOrder,
      render: (value: any, c: Certification) => c.expiration_date?.getFullYear(),
      sorter: (a: Certification, b: Certification) =>
        new Date(a.expiration_date!) < new Date(b.expiration_date!) ? 1 : -1,

      filters: [...new Set((certifications || []).map((a) => a.expiration_date?.getFullYear() ?? -1))]
        .sort()
        .map((year) => {
          return {
            text: year,
            value: year,
          };
        }),
      onFilter: (value: any, record: Certification) => record.expiration_date?.getFullYear() === value,
    },
  ];

  return (
    <>
      {generateModal && (
        <CertificateGenerator
          ensUserTid={agent.ens_user_tid}
          onClose={(refresh) => {
            if (refresh) loadCerts(agent);

            setGenerateModal(false);
          }}
        />
      )}
      <Row>
        <Col span={24}>
          <div style={{ textAlign: "right" }}>
            <Button onClick={() => setGenerateModal(true)}>New Certificates</Button>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <Table
            size="small"
            loading={tableLoading}
            rowKey={getRecordKey}
            dataSource={sortedCertifications}
            columns={columns}
            scroll={{ y: 620 }}
            pagination={{
              total: certifications.length,
              pageSize: certifications.length,
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </>
  );
}

type CertificateGeneratorProps = {
  ensUserTid: number;
  onClose: (refresh: boolean) => void;
};

function CertificateGenerator(props: CertificateGeneratorProps) {
  const { ensUserTid, onClose } = props;
  const [certYear, setCertYear] = useState(2022);
  const [loading, setLoading] = useState(false);

  const generateCerts = (year: number) => {
    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, CertificationClient, (c) => {
      return c.generateCerts(ensUserTid, year);
    })
      .then(() => onClose(true))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Modal
        title="Generate Certifcates"
        width={250}
        visible={true}
        onOk={() => generateCerts(certYear)}
        onCancel={() => onClose(false)}
        okText="Generate"
        confirmLoading={loading}
      >
        <Space>
          Year: <InputNumber min={2020} max={3000} defaultValue={2022} onChange={(v) => setCertYear(v)} />
        </Space>
      </Modal>
    </>
  );
}
