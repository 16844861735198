import React from "react";
import { Link } from "react-router-dom";

interface AuthorizedLinkProps extends React.ComponentProps<typeof Link> {
  authorized: boolean;
  unauthorizedMessage?: string;
}

export default function AuthorizedLink(props: React.PropsWithChildren<AuthorizedLinkProps>) {
  const { to, authorized, unauthorizedMessage, ...others } = props;
  const linkProps = authorized ? { to: to } : { to: "" };

  return (
    <>
      <Link {...linkProps} {...others}>
        {props.children}
      </Link>
    </>
  );
}
