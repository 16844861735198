import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Empty } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";
import {
  EnsUserPermissionType,
  PermissionsClient,
  UserPermission,
} from "common/services/microservices/ensusers-client";
import { PermissionsTransferWidget } from "../permissions/PermissionsTransferWidget";

const { Title } = Typography;

type UserRoleManagerProps = {
  ensUserTid: number | undefined;
};

export default function UserRoleManager(props: UserRoleManagerProps) {
  const { ensUserTid } = props;
  const [allPerms, setAllPerms] = useState<UserPermission[]>([]);
  const [currentUserPerms, setCurrentUserPerms] = useState<UserPermission[]>([]);

  useEffect(() => {
    if (!ensUserTid) return;
    getCurrentPermissions(ensUserTid);
  }, [ensUserTid]);

  const getCurrentPermissions = (ensUserTid: number) => {
    authenticatedFetch(MicroservicesEndpoint.ensUsers, PermissionsClient, async (c) => {
      let permissionsListReq = await c.getPermissionsList();
      let userPermissionsReq = await c.getPermissions(ensUserTid);
      setAllPerms(permissionsListReq);
      setCurrentUserPerms(userPermissionsReq);
    });
  };

  const updatePermissions = (newList: EnsUserPermissionType[]) => {
    if (!ensUserTid) return;
    return authenticatedFetch(MicroservicesEndpoint.ensUsers, PermissionsClient, async (c) => {
      let newUserPermissions = await c.setPermissions(ensUserTid, newList);
      setCurrentUserPerms(newUserPermissions);
    });
  };

  return (
    <Row>
      <Col flex="300px">
        <Title level={4}>Role Inherited Permissions</Title>
        {!currentUserPerms.some((p) => p.isRolePermission) ? (
          <Empty />
        ) : (
          <ul>
            {currentUserPerms
              .sort((a, b) => a.ensUserPermissionName.localeCompare(b.ensUserPermissionName))
              .filter((p) => p.isRolePermission)
              .map((p) => (
                <li key={p.ensUserPermissionType}>{p.ensUserPermissionName}</li>
              ))}
          </ul>
        )}
      </Col>
      <Col flex="auto">
        <Title level={4}>Explicit Permissions</Title>
        <PermissionsTransferWidget
          availablePermissions={allPerms}
          currentActivePermissions={currentUserPerms.filter((r) => !r.isRolePermission)}
          onPermissionsUpdated={updatePermissions}
        />
      </Col>
    </Row>
  );
}
