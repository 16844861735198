import { Button, Col, Modal, Row, message } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { WorkClient, WorkStateType } from "common/services/microservices/basic-client";
import {
  IUnmatchedCashRecord,
  UnmatchedCashClient,
  UnmatchedCashRecord,
} from "common/services/microservices/finance-client";
import { waitFor } from "common/utilities/waitFor";
import { authenticatedClient } from "core/components/AuthProvider";
import CronTaskEventByGuidDisplay from "operations/commission-upload/components/CronTaskEventDisplay";
import { CashMatchingErrorTable } from "operations/common/CashMatchErrorTable";
import { RunCashMatchingButton } from "operations/common/CashMatchingButton";
import React, { useEffect, useState } from "react";

interface CronTaskProgressDisplayModalProps {
  guid: string;
  visible: boolean;
  onClose: () => void;
}

function CronTaskProgressDisplayModal(props: CronTaskProgressDisplayModalProps) {
  const { guid, visible, onClose } = props;

  return (
    <Modal
      width="30%"
      title={`Cash Matching Progress...`}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      footer={[]}
    >
      <Row className="mb-4" align="middle">
        <CronTaskEventByGuidDisplay guid={guid} />
      </Row>
      <Row>
        <Button onClick={onClose}>Close</Button>
      </Row>
    </Modal>
  );
}

export function UnmatchedCashTool() {
  const [loading, setLoading] = useState(false);
  const [unmatchedCash, setUnmatchedCash] = useState<IUnmatchedCashRecord[]>();
  const [taskGuid, setTaskGuid] = useState<string>();

  async function fetchUnmatchedCash() {
    setLoading(true);
    try {
      const client = await authenticatedClient(MicroservicesEndpoint.finance, UnmatchedCashClient);
      const { guid } = await client.startGetUnmatchedCashWork();

      const workClient = await authenticatedClient(MicroservicesEndpoint.basic, WorkClient);

      await waitFor(async () => {
        const state = await workClient.getWorkState(guid);
        if (state.state === WorkStateType.Failed) throw "Getting report failed";
        return state.state === WorkStateType.Success;
      }, 500);

      // Get the result of the job
      let resultUrl = await workClient.getWorkResultDownloadUrl(guid, null, null);

      // Download the result
      let result = await fetch(resultUrl.presignedUrl);

      // Parse the result
      let resultData: IUnmatchedCashRecord[] = await result.json();

      setUnmatchedCash(resultData.map((raw) => UnmatchedCashRecord.fromJS(raw)));
    } catch (ex) {
      message.error("Unable to get unmatched cash. Please contact engineering.");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchUnmatchedCash();
  }, []);

  return (
    <>
      <Col className="mx-4 mb-4">
        <Button className="mb-2 mr-4" onClick={fetchUnmatchedCash}>
          Refresh Table
        </Button>
        <RunCashMatchingButton onGuidStarted={setTaskGuid} />
        <CashMatchingErrorTable loading={loading} errors={unmatchedCash ?? []} onRefresh={fetchUnmatchedCash} />
      </Col>
      {taskGuid && (
        <CronTaskProgressDisplayModal guid={taskGuid} visible={!!taskGuid} onClose={() => setTaskGuid(undefined)} />
      )}
    </>
  );
}
