import ApplicationPageProps from "core/models/ApplicationPageProps";
import React, { useState } from "react";
import { Tabs } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { FutureValueApp } from "./future-value/components/FutureValueApp";
import FormLeadsSearchTool from "./lead-information/tools/FormLeadsSearchTool";
import FormLeadsTool from "./lead-information/tools/FormLeadsTool";
import { UserContext } from "core/context/userContext";

const { TabPane } = Tabs;

export default function OperationsApp(props: ApplicationPageProps): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>("leads");
  return (
    <UserContext.Provider value={{ user: props.user }}>
      <Paragraph className="mb-5 border p-0 bg-white shadow-sm border mt-4">
        <Tabs
          type="card"
          id="operations-tools-tabs"
          defaultActiveKey={activeTab}
          onChange={(activeKey: any) => setActiveTab(activeKey)}
          style={{ overflow: "visible" }}
        >
          {/* <TabPane key="leads" className="pt-2" tab="Leads">
            <FormLeadsTool isVisible={activeTab === "leads"} user={props.user} />
          </TabPane> */}
          <TabPane key="fsv-rule-visibility" className="pt-2" tab="Future Value Calculation">
            <FutureValueApp user={props.user} />
          </TabPane>
          {/* <TabPane key="form-leads-search" className="pt-2" tab="Search Leads">
            <FormLeadsSearchTool user={props.user} />
          </TabPane> */}
        </Tabs>
      </Paragraph>
    </UserContext.Provider>
  );
}
