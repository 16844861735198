import React, { useEffect, useState } from "react";
import { Row, Col, Select } from "antd";
import { authenticatedFetch } from "core/components/AuthProvider";
import ApiServerService, {
  MktgCampaign,
  SalesClient,
} from "common/services/ApiServerService";
import StatusAlert from "common/components/StatusAlert";
import { SaleStatus } from "common/services/microservices/sales-client";

export interface MarketingTabObject {
  ens_marketing_campaign_tid: number | undefined;
}

interface MarketingTabProps {
  onMarketingCampaignSelected: (data: MarketingTabObject) => void;
  selectedRecord?: SaleStatus;
}

export default function MarketingTab(props: MarketingTabProps) {
  const { Option } = Select;
  const { onMarketingCampaignSelected, selectedRecord } = props;
  const [marketingCampaignData, setMarketingCampaignData] = useState<MktgCampaign[]>([]);
  const [selectedMarketingCampaign, setSelectedMarketingCampaign] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  useEffect(() => {
    authenticatedFetch(ApiServerService.endpoint, SalesClient, (c) => {
      setLoading(true);
      return c.getMarketingCampaignOptions();
    })
      .then((response: MktgCampaign[]) => {
        setMarketingCampaignData(response);
      })
      .catch(() => {
        setDisplayError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!selectedRecord) return;

    setSelectedMarketingCampaign(selectedRecord.ens_marketing_campaign_tid);
  }, [selectedRecord]);

  return (
    <Row>
      {displayError && <StatusAlert shouldDisplay={true} success={false}></StatusAlert>}

      <Col span={14}>
        <Select
          showSearch
          loading={loading}
          style={{ width: "100%" }}
          placeholder={"Select marketing campaign"}
          value={selectedMarketingCampaign}
          onChange={(value, option) => {
            onMarketingCampaignSelected({ ens_marketing_campaign_tid: value });
            setSelectedMarketingCampaign(value);
          }}
        >
          {marketingCampaignData
            .sort((a, b) => (a.external_campaign_name! > b.external_campaign_name! ? 1 : -1))
            .map((item) => {
              return (
                <Option key={item.ens_marketing_campaign_tid} value={item.ens_marketing_campaign_tid!}>
                  {item.external_campaign_name}
                </Option>
              );
            })}
        </Select>
      </Col>
    </Row>
  );
}
