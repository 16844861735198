import { Button, Col, Form, Input, Modal, Row } from "antd";
import CarrierDropDown from "common/components/CarrierDropDown";
import StatusAlert from "common/components/StatusAlert";
import { AddBrokerIdRequest, EnsUser } from "common/services/ApiServerService";
import { EnsProductCarrierTid } from "common/services/microservices/basic-client";
import UserClass from "core/models/UserClass";
import ProductCategoryDropdown from "licensing/components/ProductCategoryDropdown";
import React, { useState } from "react";

interface BrokerIdDisplayProps {
  user: UserClass;
  agent: EnsUser;
  setShowModal: (showModal: boolean) => void;
  addBrokerId: (ensUserTid: number, brokerIdReq: AddBrokerIdRequest) => void;
  showModal: boolean;
}

export default function BrokerIdAddModal(props: BrokerIdDisplayProps) {
  const { agent, addBrokerId, setShowModal, showModal } = props;
  const [carrierIdValue, setCarrierIdValue] = useState<number>();
  const [productCategoryIdValue, setProductCategoryIdValue] = useState<number>();
  const [brokerIdValue, setBrokerIdValue] = useState<string>();
  const [displayError, setDisplayError] = useState<boolean>(false);

  const newBrokerId = () => {
    addBrokerId(agent.ens_user_tid, {
      ens_product_carrier_tid: carrierIdValue,
      ens_product_category_tid: productCategoryIdValue,
      broker_id: brokerIdValue,
    } as AddBrokerIdRequest);
    setShowModal(false);
  };

  return (
    <Modal
      title="Add a Broker ID"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={[
        <Button
          type="primary"
          disabled={!carrierIdValue || !productCategoryIdValue || !brokerIdValue}
          onClick={newBrokerId}
        >
          Save
        </Button>,
      ]}
    >
      {displayError && <StatusAlert shouldDisplay={true} success={false} closable={true} />}
      <Row>
        <Col span={24} className="my-1">
          <CarrierDropDown
            additionalOptions={[
              {
                ens_product_carrier_tid: EnsProductCarrierTid.HarvardPilgrim,
                product_carrier_name: "Harvard Pilgrim",
              },
            ]}
            onCarrierSelected={(carrierId: number) => setCarrierIdValue(carrierId)}
            displayError={(error: boolean) => setDisplayError(error)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="my-1">
          <ProductCategoryDropdown
            onProductSelected={(productCategoryId: number) => setProductCategoryIdValue(productCategoryId)}
            displayError={(error: boolean) => setDisplayError(error)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} className="my-1">
          <Form.Item className="m-0">
            <Input
              id="BrokerId"
              required
              placeholder="Broker ID #"
              onChange={(ev: any) => setBrokerIdValue(ev.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Modal>
  );
}
