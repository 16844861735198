import React, { useState } from "react";
import { Button, Col, Row, Select } from "antd";
import CarrierSelect from "common/components/CarrierSelect";
import {
  EnsProductCategory,
  EnsProductCarrierTid,
  IWorksheetInformationResponse,
} from "common/services/microservices/finance-client";
import { SpreadsheetSheetSelect } from "./SpreadsheetSheetSelect";
import { UploadStatus } from "./CommissionUpload";
import { IPreSignedUrlResponse } from "common/services/microservices/basic-client";
import "./UploadSheetForm.scss";
import { CronTaskStateType } from "common/services/microservices/cron-boss-client";
import { CommissionUploadTaskCreator } from "./CommissionUploadTaskCreator";
import { BookOfBusinessUploadTaskCreator } from "./BookOfBusinessUploadTaskCreator";

enum ReportType {
  commission_statement = 0,
  book_of_business = 1,
}

interface ReportTypeOption {
  enumId: ReportType;
  name: string;
}

const ReportTypes: ReportTypeOption[] = [
  {
    enumId: ReportType.commission_statement,
    name: "Commission Statement",
  },
  {
    enumId: ReportType.book_of_business,
    name: "Book Of Business",
  },
];

const validProducts = [
  { value: EnsProductCategory.MedSupp, text: "MS" },
  { value: EnsProductCategory.MedicareAdvantage, text: "MA" },
];

interface UploadSheetFormProps {
  presignedUrl: IPreSignedUrlResponse;
  uploadStatus: UploadStatus;
  onSubmit: (value: any, taskName: string) => Promise<void>;
  currentTaskState: CronTaskStateType | undefined;
}

export interface ReportUploadCreatorSharedProps {
  sheetS3Location: IPreSignedUrlResponse;
  sheetColumns: string[];
  sheetSelectedName: string;
  sheetOriginalFilename: string;
  carrier: EnsProductCarrierTid;
  product: EnsProductCategory | undefined;
  startTask: (specs: { taskName: string; specs: Record<string, any> }) => Promise<void>;
}

export function UploadSheetForm(props: UploadSheetFormProps) {
  const [reportType, setReportType] = useState<ReportTypeOption>(ReportTypes[0]);
  const [carrier, setCarrier] = useState<EnsProductCarrierTid>();
  const [product, setProduct] = useState<EnsProductCategory | undefined>();
  const [selectedSheetName, setSelectedSheetName] = useState<string>();
  const [spreadsheetInfo, setSpreadsheetInfo] = useState<IWorksheetInformationResponse | undefined>(undefined);

  return (
    <>
      <Row gutter={[16, 24]} className="my-3">
        <Col offset={6} span={4}>
          Report Type
        </Col>
        <Col span={8}>
          <Select
            value={reportType.enumId}
            placeholder={"Select"}
            onChange={(value) => {
              const reportTask = ReportTypes.find((rpt) => rpt.enumId === value);
              setReportType(reportTask || ReportTypes[0]);
            }}
            style={{ width: "100%" }}
          >
            {ReportTypes.map((item, index) => (
              <Select.Option key={index} value={item.enumId}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 24]} className="my-3">
        <Col offset={6} span={4}>
          Carrier
        </Col>
        <Col span={8}>
          <CarrierSelect
            style={{ width: "100%" }}
            onCarrierSelected={(carrier: EnsProductCarrierTid) => setCarrier(carrier)}
          ></CarrierSelect>
        </Col>
      </Row>
      {carrier === EnsProductCarrierTid.Aetna && (
        <Row gutter={[16, 24]} className="my-3">
          <Col offset={6} span={4}>
            Product
          </Col>
          <Col span={8}>
            <Select
              style={{ width: "100%" }}
              allowClear
              showSearch
              onChange={(value: EnsProductCategory) => setProduct(value)}
            >
              {validProducts.map((product, i) => (
                <Select.Option key={i} value={product.value}>
                  {product.text}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 24]} className="my-3">
        <Col offset={6} span={4}>
          Sheet
        </Col>
        <Col span={8}>
          <SpreadsheetSheetSelect
            setSpreadsheetInfo={setSpreadsheetInfo}
            spreadsheetInfo={spreadsheetInfo}
            sheetPresignedUrl={props.presignedUrl}
            onSheetSelected={setSelectedSheetName}
          />
        </Col>
      </Row>
      {spreadsheetInfo && carrier && spreadsheetInfo.worksheets && selectedSheetName && (
        <>
          {reportType.enumId === ReportType.commission_statement && (
            <CommissionUploadTaskCreator
              sheetColumns={spreadsheetInfo?.worksheets!.find((wb) => wb.name === selectedSheetName)?.columns!}
              sheetSelectedName={selectedSheetName}
              sheetS3Location={props.presignedUrl}
              sheetOriginalFilename={props.uploadStatus.name!}
              product={product}
              carrier={carrier}
              startTask={(a) => props.onSubmit(a.specs, a.taskName)}
            />
          )}
          {reportType.enumId === ReportType.book_of_business && (
            <>
              <BookOfBusinessUploadTaskCreator
                sheetColumns={spreadsheetInfo?.worksheets!.find((wb) => wb.name === selectedSheetName)?.columns!}
                sheetSelectedName={selectedSheetName}
                sheetS3Location={props.presignedUrl}
                sheetOriginalFilename={props.uploadStatus.name!}
                product={product}
                carrier={carrier}
                startTask={(a) => props.onSubmit(a.specs, a.taskName)}
              />
            </>
          )}
        </>
      )}
    </>
  );
}
