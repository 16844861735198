import React from "react";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { SaleStatus } from "common/services/microservices/sales-client";

export const DemographicTableColumns: ColumnsType<SaleStatus> = [
  {
    title: () => <span>Sale ID</span>,
    defaultSortOrder: "descend" as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.ens_sale_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) =>
      a.ens_sale_tid < b.ens_sale_tid ? 1 : -1,
  },
  {
    title: () => <span>Product</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.product_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.product_name ?? "").localeCompare(b.product_name ?? "");
    },
  },
  {
    title: () => <span>First Name</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_first_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_first_name ?? "").localeCompare(b.insured_first_name ?? "");
    },
  },
  {
    title: () => <span>Last Name</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_last_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_last_name ?? "").localeCompare(b.insured_last_name ?? "");
    },
  },
  {
    title: () => <span>Phone</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_phone;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_phone ?? "").localeCompare(b.insured_phone ?? "");
    },
  },
  {
    title: () => <span>Email</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_email;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_email ?? "").localeCompare(b.insured_email ?? "");
    },
  },
  {
    title: () => <span>Age At TOS</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.age_at_tos;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => (a.age_at_tos < b.age_at_tos ? 1 : -1),
  },
  {
    title: () => <span>Address Line1</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_address_line1;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_address_line1 ?? "").localeCompare(b.insured_address_line1 ?? "");
    },
  },
  {
    title: () => <span>City</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_city;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_city ?? "").localeCompare(b.insured_city ?? "");
    },
  },
  {
    title: () => <span>State</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_state;
    },

    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_state ?? "").localeCompare(b.insured_state ?? "");
    },
  },
  {
    title: () => <span>Zip</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_zip;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_zip ?? "").localeCompare(b.insured_zip ?? "");
    },
  },
  {
    title: () => <span>Gender</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_gender;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_gender ?? "").localeCompare(b.insured_gender ?? "");
    },
  },
  {
    title: () => <span>DOB</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_dob?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.insured_dob ?? 0) < new Date(b.insured_dob ?? 0) ? 1 : -1;
    },
  },
  {
    title: () => <span>Medicare ID</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.medicare_id;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.medicare_id ?? "").localeCompare(b.medicare_id ?? "");
    },
  },
];