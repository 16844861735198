import { Button } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { WorkStateType } from "common/services/microservices/basic-client";
import {
  IYearMonthBookingReportRequest,
  RevenueBookingClient,
  YearMonthBookingReportRequest,
} from "common/services/microservices/finance-client";
import { authenticatedClient } from "core/components/AuthProvider";
import { Months } from "./MonthlyOriginalRevenueBookingTool";
import moment from "moment";
import React from "react";
import { useWorkWaiter } from "common/components/useWorkWaiter";

export function ReveneBookingReportGenerator(props: IYearMonthBookingReportRequest) {
  const { forecast_type_tid, month, year } = props;
  const { isLoading, taskState, downloadResult, startWork } = useWorkWaiter();

  async function startReport() {
    const bookingClient = await authenticatedClient(MicroservicesEndpoint.finance, RevenueBookingClient);
    return await bookingClient.startGenerateReportWork(new YearMonthBookingReportRequest(props));
  }

  return (
    <>
      <Button
        loading={isLoading}
        onClick={() =>
          startWork(
            startReport,
            `RevenueReport-${forecast_type_tid === 9 ? "Original" : "TrueUp"}-${moment().format("YYYYMMDD")}.zip`,
            "Started generating report. This may take some time."
          )
        }
      >
        Generate New Revenue Report ({Months[month - 1]} {year})
      </Button>
      {taskState === WorkStateType.Success && (
        <Button loading={isLoading} className="ml-3 btn-success" onClick={downloadResult}>
          Download Report
        </Button>
      )}
    </>
  );
}
