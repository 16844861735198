import React, { useEffect, useState } from "react";
import { Modal, Button, Input, Space, Table } from "antd";
import ApiServerService, {
  EnsProductCarrierChildInfoTypeResponse,
  EnsProductCarrierChildResponse,
  ProductCarrierChildInfo,
  ProductCarrierClient,
  UpdateEnsProductCarrierChildRequest,
} from "common/services/ApiServerService";

import { authenticatedFetch } from "core/components/AuthProvider";
import ChildCompanyInfoTypeSelect from "./ChildCompanyInfoTypeSelect";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

interface ChildCarrierInfoEditorProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  childCarrier: EnsProductCarrierChildResponse;
  updateChildCarrierHandler: (updateReq: UpdateEnsProductCarrierChildRequest) => void;
}

export default function ChildCarrierInfoEditor(props: ChildCarrierInfoEditorProps) {
  const { visible, setVisible, childCarrier, updateChildCarrierHandler } = props;
  const [childCompanyInfo, setChildCompanyInfo] = useState<ProductCarrierChildInfo[]>([]);

  useEffect(() => {
    if (!visible) return;

    setChildCompanyInfo([]);

    if (childCarrier) setChildCompanyInfo(childCarrier.company_info);
  }, [childCarrier, visible]);

  const close = () => {
    setVisible(false);
  };

  const submitUpdateChild = () => {
    updateChildCarrierHandler({
      ens_product_carrier_child_tid: childCarrier.ens_product_carrier_child_tid,
      company_info: childCompanyInfo,
    } as UpdateEnsProductCarrierChildRequest);

    close();
  };

  const onRemoveChildInfo = (index: number) => {
    let newList = [...childCompanyInfo];
    newList.splice(index, 1);
    setChildCompanyInfo(newList);
  };

  const addNewChildCompanyInfo = (newEntry: ProductCarrierChildInfo) => {
    // check if similiar record already exists
    if (
      childCompanyInfo.some(
        (i) =>
          `${i.ens_product_carrier_child_info_type_tid}///${i.key}///${i.value}` ===
          `${newEntry.ens_product_carrier_child_info_type_tid}///${newEntry.key}///${newEntry.value}`
      )
    )
      return;

    setChildCompanyInfo([newEntry, ...childCompanyInfo]);
  };

  return (
    <Modal title="Company Info Editor" visible={visible} onOk={close} onCancel={close} footer={[]}>
      <AddNewChildCompanyInfoComponent onAddNewChildCompanyInfo={addNewChildCompanyInfo} />
      <ChildCompanyInfoTable childInfo={childCompanyInfo} onRemoveChildInfo={onRemoveChildInfo} />
      <Button onClick={submitUpdateChild}>Update</Button>
    </Modal>
  );
}

type ChildCompanyInfoTableProps = {
  childInfo: ProductCarrierChildInfo[];
  onRemoveChildInfo: (index: number) => void;
};

const ChildCompanyInfoTable = (props: ChildCompanyInfoTableProps) => {
  const { childInfo, onRemoveChildInfo } = props;
  const [infoTypes, setInfoTypes] = useState<EnsProductCarrierChildInfoTypeResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      return c.getCarrierChildrenInfoTypes();
    }).then((res) => {
      setInfoTypes(res);
      setLoading(false);
    });
  }, []);

  const columns: ColumnsType<ProductCarrierChildInfo> = [
    {
      dataIndex: "ens_product_carrier_child_info_type_tid",
      title: "Type",
      render: (value: any, record: ProductCarrierChildInfo) =>
        infoTypes.find(
          (i) => i.ens_product_carrier_child_info_type_tid === record.ens_product_carrier_child_info_type_tid
        )?.name ?? "N/A",
    },
    {
      dataIndex: "key",
      title: "Key",
    },
    {
      dataIndex: "value",
      title: "Value",
      render: (value: any, record: ProductCarrierChildInfo) => <Paragraph copyable>{record.value}</Paragraph>,
    },
    {
      render: (value: any, record: ProductCarrierChildInfo, index: number) => (
        <Button onClick={() => onRemoveChildInfo(index)}>X</Button>
      ),
    },
  ];

  const getRecordKey = (record: ProductCarrierChildInfo) => {
    return `${record.ens_product_carrier_child_info_type_tid}///${record.key}///${record.value}`;
  };

  return (
    <Table
      scroll={{ y: 600 }}
      loading={loading}
      dataSource={childInfo}
      columns={columns}
      rowKey={getRecordKey}
      pagination={{
        total: childInfo.length,
        pageSize: childInfo.length,
        hideOnSinglePage: true,
      }}
    />
  );
};

type AddNewChildCompanyInfoComponentProps = {
  onAddNewChildCompanyInfo: (newEntry: ProductCarrierChildInfo) => void;
};

const AddNewChildCompanyInfoComponent = (props: AddNewChildCompanyInfoComponentProps) => {
  const { onAddNewChildCompanyInfo } = props;
  const [selectedInfoType, setSelectedInfoType] = useState<EnsProductCarrierChildInfoTypeResponse>();
  const [key, setKey] = useState<string>();
  const [value, setValue] = useState<string>();

  const onInfoTypeSelected = (infoType: EnsProductCarrierChildInfoTypeResponse) => {
    setSelectedInfoType(infoType);
  };

  const submitNewChildInfo = () => {
    if (!selectedInfoType || !key || !value) return;

    onAddNewChildCompanyInfo({
      ens_product_carrier_child_info_type_tid: selectedInfoType.ens_product_carrier_child_info_type_tid,
      key: key,
      value: value,
    } as ProductCarrierChildInfo);
  };

  return (
    <>
      <Space>
        <ChildCompanyInfoTypeSelect onChildCompanyInfoTypeSelected={onInfoTypeSelected} />
        <Input
          placeholder="key"
          onChange={(ev) => {
            setKey(ev.target.value.trim() ?? undefined);
          }}
        />
        <Input
          placeholder="value"
          onChange={(ev) => {
            setValue(ev.target.value.trim() ?? undefined);
          }}
        />
        <Button onClick={submitNewChildInfo} disabled={!selectedInfoType || !key || !value}>
          Add
        </Button>
      </Space>
    </>
  );
};
