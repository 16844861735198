import React, { useContext, useState } from "react";
import { Select, Row, Col, Button, DatePicker, Checkbox } from "antd";
import { ReportUploadCreatorSharedProps } from "./UploadSheetForm";
import {
  EnsProductCarrierTid,
  EnsProductCategory,
  GetBookOfBusinessChangesSheetColumns,
  GetBookOfBusinessChangesTaskSpecs,
} from "common/services/microservices/finance-client";
import { UserContext } from "core/context/userContext";
import TerminationDateChangesModal from "./TerminationDateChangesModal/TerminationDateChangesModal";
import moment from "moment";

interface IBookOfBusinessColumns {
  current_status_header_name: string;
  policy_number_header_name: string;
  policy_effective_date_header_name: string;
  approval_date: string;
  medicare_id_header_name: string;
  termination_date_header_name: string;
  underwriting_status: string;
  issue_state_header_name: string;
}

interface UploadBookOfBusinessTaskSpecs {
  sheet_download_guid: string;
  sheet_download_prefix?: string;
  sheet_table_name: string;
  calling_user: string;
  ens_product_carrier_tid: EnsProductCarrierTid;
  report_carrier_product_category?: EnsProductCategory;
  sheet_table_columns: IBookOfBusinessColumns;
}

interface CommissionUploadTaskCreatorProps extends ReportUploadCreatorSharedProps {}

export function BookOfBusinessUploadTaskCreator(props: CommissionUploadTaskCreatorProps) {
  const { sheetColumns, sheetS3Location, product, carrier, sheetSelectedName, sheetOriginalFilename, startTask } =
    props;

  const { user } = useContext(UserContext);

  const [isTaskStarting, setIsTaskStarting] = useState(false);
  const [termChangesModalVisible, setTermChangesModalVisible] = useState<boolean | undefined>();
  const [useMedicareId, setUseMedicareId] = useState<boolean>(false);
  const [useUwStatus, setUseUwStatus] = useState<boolean>(false);
  const [useTermDate, setUseTermDate] = useState<boolean>(false);

  const [selectedColumnInfo, setSelectedColumnInfo] = useState<IBookOfBusinessColumns>({
    current_status_header_name: "",
    policy_number_header_name: "",
    policy_effective_date_header_name: "",
    approval_date: new Date().toDateString(),
    medicare_id_header_name: "",
    termination_date_header_name: "",
    underwriting_status: "",
    issue_state_header_name: "",
  });

  function startBookOfBusinessTask() {
    setIsTaskStarting(true);
    startTask({
      specs: {
        calling_user: user.UserEmail,
        sheet_download_guid: sheetS3Location.objectGuid,
        sheet_download_prefix: sheetS3Location.objectPrefix,
        sheet_table_name: sheetSelectedName,
        ens_product_carrier_tid: carrier,
        report_carrier_product_category: product,
        update_underwriting_status: false,
        sheet_table_columns: selectedColumnInfo,
      } as UploadBookOfBusinessTaskSpecs,
      taskName: "UploadBookOfBusinessTask",
    }).finally(() => setIsTaskStarting(false));
  }

  const filterOutSelectedColumns = () => {
    const selectedColumnsValues = Object.values(selectedColumnInfo);
    return sheetColumns?.filter((col) => !selectedColumnsValues.includes(col)) ?? [];
  };

  function updateColumn(columnName: keyof IBookOfBusinessColumns, value: string) {
    setSelectedColumnInfo((info) => ({ ...info, [columnName]: value }));
  }

  const options = (
    <>
      {filterOutSelectedColumns().map((col: string, idx: number) => (
        <Select.Option key={idx} value={col}>
          {col}
        </Select.Option>
      ))}
    </>
  );

  const requiredKeys: (keyof IBookOfBusinessColumns)[] = [
    "current_status_header_name",
    "policy_number_header_name",
    "policy_effective_date_header_name",
    "approval_date",
    "issue_state_header_name",
  ];

  const submitButtonDisabled = Object.keys(selectedColumnInfo)
    .filter((k) => requiredKeys.includes(k as keyof IBookOfBusinessColumns))
    .some((k) => selectedColumnInfo[k as keyof IBookOfBusinessColumns]?.length === 0);

  return (
    <>
      <Row gutter={[16, 16]} className="my-3 spreadsheet-column-select-container">
        <Col offset={6} span={4}>
          Columns
        </Col>
        <Col span={9} className="spreadsheet-column-select-subcontainer">
          <Row className="spreadsheet-column-select-row">
            <Row>
              <p>Policy Number:</p>
              <Select showSearch onChange={(value: string) => updateColumn("policy_number_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <p>Current Status:</p>
              <Select
                showSearch
                onChange={(value: string) => {
                  updateColumn("current_status_header_name", value);
                }}
              >
                {options}
              </Select>
            </Row>
            <Row>
              <p>Policy Effective Date:</p>
              <Select showSearch onChange={(value: string) => updateColumn("policy_effective_date_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <Row>
                <Checkbox defaultChecked={false} onChange={(e) => setUseTermDate(e.target.checked)} />
                <p>Termination Date:</p>
              </Row>
              <Select
                showSearch
                onChange={(value: string) => updateColumn("termination_date_header_name", value)}
                disabled={!useTermDate}
              >
                {options}
              </Select>
            </Row>
            <Row>
              <p>Approval Date:</p>
              <DatePicker
                defaultValue={moment(new Date())}
                onChange={(_, dateString: string) => updateColumn("approval_date", dateString)}
              />
            </Row>
            <Row>
              <p>Issue State:</p>
              <Select showSearch onChange={(value: string) => updateColumn("issue_state_header_name", value)}>
                {options}
              </Select>
            </Row>
            <Row>
              <Row>
                <Checkbox defaultChecked={false} onChange={(e) => setUseMedicareId(e.target.checked)} />
                <p>Medicare Id:</p>
              </Row>
              <Select
                showSearch
                onChange={(value: string) => {
                  updateColumn("medicare_id_header_name", value);
                }}
                disabled={!useMedicareId}
              >
                {options}
              </Select>
            </Row>
            <Row>
              <Row>
                <Checkbox defaultChecked={false} onChange={(e) => setUseUwStatus(e.target.checked)} />
                <p>Underwriting Status:</p>
              </Row>
              <Select
                showSearch
                onChange={(value: string) => {
                  updateColumn("underwriting_status", value);
                }}
                disabled={!useUwStatus}
              >
                {options}
              </Select>
            </Row>
          </Row>
          <hr />
        </Col>
      </Row>
      <Row gutter={[16, 24]} className="my-3">
        <Col offset={6} span={4}></Col>
        <Col span={6}>
          <Button disabled={termChangesModalVisible} onClick={() => setTermChangesModalVisible(true)} type="dashed">
            View Proposed Changes
          </Button>
        </Col>
      </Row>
      {termChangesModalVisible && !submitButtonDisabled && (
        <TerminationDateChangesModal
          visible={termChangesModalVisible}
          setVisible={setTermChangesModalVisible}
          specs={
            new GetBookOfBusinessChangesTaskSpecs({
              sheet_download_guid: sheetS3Location.objectGuid,
              sheet_download_prefix: sheetS3Location.objectPrefix,
              sheet_table_name: sheetSelectedName,
              ens_product_carrier_tid: carrier!,
              report_carrier_product_category: product,
              sheet_columns: new GetBookOfBusinessChangesSheetColumns({
                current_status_header_name: selectedColumnInfo.current_status_header_name,
                policy_number_header_name: selectedColumnInfo.policy_number_header_name,
                policy_effective_date_header_name: selectedColumnInfo.policy_effective_date_header_name,
                termination_date_header_name: selectedColumnInfo.termination_date_header_name,
                approval_date: new Date(selectedColumnInfo.approval_date),
                medicare_id_header_name: selectedColumnInfo.medicare_id_header_name,
                underwriting_status_header_name: selectedColumnInfo.underwriting_status,
                issue_state_header_name: selectedColumnInfo.issue_state_header_name,
              }),
            })
          }
        />
      )}
      <Row style={{ justifyContent: "center" }} className="my-3">
        <Button
          disabled={submitButtonDisabled}
          loading={isTaskStarting}
          type="primary"
          onClick={() => startBookOfBusinessTask()}
        >
          Upload sheet {sheetSelectedName ? `"${sheetSelectedName}"` : "?"} of {sheetOriginalFilename ?? "?"}
        </Button>
      </Row>
    </>
  );
}
