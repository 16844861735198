import { Button, DatePicker, Input, Spin } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { SalePolicyHistoryResponse, SalePolicyHistoryClient, SalePolicyHistoryUpdateRequest } from "common/services/microservices/sales-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useState } from "react";
import moment from 'moment';

interface PolicyHistory {
  ensSaleCarrierPolicyHistoryTid: number | undefined;
  policyNumber: string | undefined;
  effectiveDate: Date | undefined;
  updatedDate: Date | undefined;
  isEditing: boolean;
}

export default function PolicyHistoryTool() {
  const [saleId, setSaleId] = useState<number>();
  const [tableData, setTableData] = useState<PolicyHistory[]>([]);
  const [editingRecord, setEditingRecord] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const handleFetchData = async () => {
    try {
      setLoading(true);
      
      if (!saleId || isNaN(saleId)) {
        setLoading(false);
        return;
      }
      
      await authenticatedFetch(
        MicroservicesEndpoint.sales,
        SalePolicyHistoryClient,
        async (s) => {
          const res = await s.getPolicyHistory(saleId);
          console.log(res);
          const mappedData = res.map((item: SalePolicyHistoryResponse) => ({
            ensSaleCarrierPolicyHistoryTid: item.ensSaleCarrierPolicyHistoryTid,
            policyNumber: item.policyNumber,
            effectiveDate: item.effectiveDate,
            updatedDate: item.updatedDate,
            isEditing: false,
          }));
  
          setTableData(mappedData);
          setLoading(false);
        }
      );
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleEdit = (record: PolicyHistory) => {
    const updatedTableData = tableData.map((item) => {
      if (item.ensSaleCarrierPolicyHistoryTid === record.ensSaleCarrierPolicyHistoryTid) {
        return {
          ...item,
          isEditing: true,
        };
      } else {
        return {
          ...item,
          isEditing: false,
        };
      }
    });
    setTableData(updatedTableData);
  };

  const handleSave = async (record: PolicyHistory) => {
    try {
      setLoading(true); // Set loading state to true
      const updatedRecord = new SalePolicyHistoryUpdateRequest();
      updatedRecord.policyNumber = record.policyNumber;
      updatedRecord.effectiveDate = record.effectiveDate;

      await authenticatedFetch(
        MicroservicesEndpoint.sales,
        SalePolicyHistoryClient,
        async (s) => {
          await s.updatePolicyHistory(record.ensSaleCarrierPolicyHistoryTid, updatedRecord);
          await handleFetchData(); // Fetch the updated data after saving
          setLoading(false); // Set loading state to false
        }
      );
    } catch (error) {
      console.error(error);
      setLoading(false); // Set loading state to false
    }
  };

  const handleCancelEdit = (record?: PolicyHistory) => {
    if (record) {
      const updatedTableData = tableData.map((item) => {
        if (item.ensSaleCarrierPolicyHistoryTid === record.ensSaleCarrierPolicyHistoryTid) {
          return {
            ...item,
            isEditing: false,
          };
        }
        return item;
      });
      setTableData(updatedTableData);
    }
  };

  const handleDelete = async (record: PolicyHistory) => {
    try {
      const confirmed = window.confirm("Are you sure you want to delete this record?");

      if (!confirmed) {
        return;
      }

      await authenticatedFetch(
        MicroservicesEndpoint.sales,
        SalePolicyHistoryClient,
        async (s) => {
          await s.deletePolicyHistory(record.ensSaleCarrierPolicyHistoryTid);
          const updatedTableData = tableData.filter(
            (item) => item.ensSaleCarrierPolicyHistoryTid !== record.ensSaleCarrierPolicyHistoryTid
          );
          setTableData(updatedTableData);
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditChange = (record: PolicyHistory, field: keyof PolicyHistory, value: any) => {
  let updatedValue = value;
  
  if (field === 'policyNumber' && value.length > 255) {
    updatedValue = value.slice(0, 255); // Truncate the value to 255 characters
  }

  const updatedTableData = tableData.map((item) => {
    if (item.ensSaleCarrierPolicyHistoryTid === record.ensSaleCarrierPolicyHistoryTid) {
      return {
        ...item,
        [field]: updatedValue,
      };
    }
    return item;
  });
  setTableData(updatedTableData);
};

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <Input
          className="centered-input"
          style={{ width: 200, margin: "0 auto", textAlign: "center" }}
          value={saleId}
          onChange={(e) => setSaleId(parseInt(e.target.value))}
          placeholder="Enter Sale ID"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: 10, marginBottom: 10 }}>
        <Button type="primary" onClick={handleFetchData} disabled={loading}>
          {loading ? <Spin /> : "Fetch Data"}
        </Button>
      </div>
      <Table
        dataSource={tableData}
        style={{ fontSize: 100, marginLeft: 10, marginRight: 10 }}
        columns={[
          {
            title: 'Policy Number',
            dataIndex: 'policyNumber',
            key: 'policyNumber',
            render: (text, record) => (
              <div style={{ width: 300, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'pre-wrap' }}>
                {record.isEditing ? (
                  <Input
                    style={{ width: '100%' }}
                    value={text}
                    onChange={(e) => handleEditChange(record, 'policyNumber', e.target.value)}
                  />
                ) : (
                  text
                )}
              </div>
            ),
          },
          {
            title: 'Effective Date',
            dataIndex: 'effectiveDate',
            key: 'effectiveDate',
            render: (date, record) => (
              record.isEditing ? (
                <DatePicker
                  style={{ width: 200, margin: "0 auto" }}
                  value={moment(date)}
                  onChange={(value) => handleEditChange(record, 'effectiveDate', value)}
                />
              ) : (
                moment(date).format('MM/DD/YYYY')
              )
            ),
          },
          {
            title: 'Last Updated Date',
            dataIndex: 'updatedDate',
            key: 'updatedDate',
            render: (date) => <span>{moment(date).format('MM/DD/YYYY')}</span>,
          },
          {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_text, record) => (
              <>
                {!record.isEditing ? (
                  <a onClick={() => handleEdit(record)} style={{ color: 'blue', marginRight: 5 }}>Edit</a>
                ) : (
                  <>
                    <a onClick={() => handleSave(record)} style={{ color: 'blue', marginRight: 5 }}>Save</a>
                    <a onClick={() => handleCancelEdit(record)} style={{ color: 'red', marginRight: 5 }}>Cancel</a>
                  </>
                )}
                <a onClick={() => handleDelete(record)} style={{ color: 'red' }}>Delete</a>
              </>
            ),
          },
        ]}
        size="middle"
      />
    </div>
  );
}