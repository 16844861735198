import React from "react";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import { Tabs } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import SalesStatusTool from "./sales-status-tool/components/SalesStatusTool";
import PolicyMappingTool from "./sales-policy-mapping-tool/components/PolicyMappingTool";
import PolicyHistoryTool from "./sale-policy-history/components/PolicyHistoryTool";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import SaleMassUpdateTool from "./sale-mass-update/SaleMassUpdateTool";

const { TabPane } = Tabs;

export function SalesApp(props: ApplicationPageProps): JSX.Element {
  return (
    <Paragraph className="mb-5 border p-0 bg-white shadow-sm border mt-4">
      <Tabs type="card" id="sales-tabs" defaultActiveKey="sales-tabs" style={{ overflow: "visible" }}>
        <TabPane key="sales-status" tab="Sales Status">
          <SalesStatusTool user={props.user} />
        </TabPane>
        {props.user.hasPermission(EnsUserPermissionType.SalesAdminAccess) && (
          <TabPane key="sales-policy-mapping" tab="Policy Mapping">
            <PolicyMappingTool />
          </TabPane>
        )}
        {props.user.hasPermission(EnsUserPermissionType.SalesAdminAccess) && (
          <TabPane key="sale-policy-history" tab="Policy History">
            <PolicyHistoryTool />
          </TabPane>
        )}
        {props.user.hasPermission(EnsUserPermissionType.SalesAdminAccess) && (
          <TabPane key="sale-mass-update-tab" tab="Sale Mass Update">
            <SaleMassUpdateTool user={props.user} />
          </TabPane>
        )}
      </Tabs>
    </Paragraph>
  );
}
