import React, { useEffect, useState } from "react";
import { Transfer, Modal, Button } from "antd";
import ApiServerService, {
  EnsProductCarrierChildResponse,
  EnsProductCategoryResponse,
  ProductCarrierClient,
  UpdateEnsProductCarrierChildRequest,
} from "common/services/ApiServerService";
import { TransferItem } from "antd/lib/transfer";
import { authenticatedFetch } from "core/components/AuthProvider";

interface ChildCarrierProductsEditorProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  childCarrier: EnsProductCarrierChildResponse;
  updateChildCarrierHandler: (updateReq: UpdateEnsProductCarrierChildRequest) => void;
}

export default function ChildCarrierProductsEditor(props: ChildCarrierProductsEditorProps) {
  const { visible, setVisible, childCarrier, updateChildCarrierHandler } = props;
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [products, setProducts] = useState<EnsProductCategoryResponse[]>([]);

  useEffect(() => {
    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      return c.getActiveProductCategory();
    }).then((activeProducts) => {
      setProducts(activeProducts);
    });
  }, []);

  useEffect(() => {
    if (!visible) return;

    setTargetKeys([]);
    setSelectedKeys([]);
  }, [childCarrier, visible, products]);

  useEffect(() => {
    if (!products || !visible) return;

    setTargetKeys(childCarrier.product_categories.map((p) => p.toString()));
  }, [childCarrier, products, visible]);

  const close = () => {
    setVisible(false);
  };

  const handleChange = (newTargetKeys: string[]) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const submitUpdateChild = () => {
    updateChildCarrierHandler({
      ens_product_carrier_child_tid: childCarrier.ens_product_carrier_child_tid,
      product_categories: products
        .filter((p) => targetKeys.includes(p.ens_product_category_tid.toString()))
        .map((p) => p.ens_product_category_tid),
    } as UpdateEnsProductCarrierChildRequest);

    close();
  };

  return (
    <Modal title="Product Category Editor" visible={visible} onOk={close} onCancel={close} footer={[]}>
      {products && (
        <>
          <Transfer
            dataSource={products.map(
              (p) => ({ key: p.ens_product_category_tid.toString(), title: p.product_category_name } as TransferItem)
            )}
            titles={["Inactive", "Active"]}
            targetKeys={targetKeys.sort((a, b) => a.localeCompare(b))}
            selectedKeys={selectedKeys}
            onChange={handleChange}
            onSelectChange={handleSelectChange}
            disabled={false}
            render={(item: TransferItem) => item.title ?? ""}
          />
          <Button onClick={submitUpdateChild}>Update</Button>
        </>
      )}
    </Modal>
  );
}
