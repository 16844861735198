import React, { useEffect, useState } from "react";
import { ColumnsType, SortOrder } from "antd/lib/table/interface";
import { Button, Table } from "antd";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import { CSVLink } from "react-csv";
import { SaleStatus, SaleCarrierPaymentReceipt } from "common/services/microservices/sales-client";

interface CashReceiptTabProps {
  selectedRecord: SaleStatus | undefined;
}

export default function CashReceiptTab(props: CashReceiptTabProps) {
  const { selectedRecord } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [tableData, setTableData] = useState<SaleCarrierPaymentReceipt[]>([]);

  useEffect(() => {
    setTableData(selectedRecord?.cash_receipts || []);
  }, [selectedRecord]);

  const columns: ColumnsType<SaleCarrierPaymentReceipt> = [
    {
      title: () => <span>Payment Date</span>,
      defaultSortOrder: null as SortOrder,
      render: (value: any, record: SaleCarrierPaymentReceipt, index: number) => {
        return record.paymentDate?.toLocaleDateString("en-US");
      },
      sorter: (a: SaleCarrierPaymentReceipt, b: SaleCarrierPaymentReceipt) => {
        return new Date(a.paymentDate ?? 0) < new Date(b.paymentDate ?? 0) ? 1 : -1;
      },
    },
    {
      title: () => <span>Payment Effective Date</span>,
      defaultSortOrder: null as SortOrder,
      render: (value: any, record: SaleCarrierPaymentReceipt, index: number) => {
        return record.paymentEffectiveDate?.toLocaleDateString("en-US");
      },
      sorter: (a: SaleCarrierPaymentReceipt, b: SaleCarrierPaymentReceipt) => {
        return new Date(a.paymentEffectiveDate ?? 0) < new Date(b.paymentEffectiveDate ?? 0) ? 1 : -1;
      },
    },
    {
      title: () => <span>Total Carrier Payment Amount</span>,
      defaultSortOrder: null as SortOrder,
      render: (name: string, record: SaleCarrierPaymentReceipt, index: number) => {
        return `$${record.paymentAmount?.toLocaleString("en-US", { minimumFractionDigits: 2 }) || "0"}`;
      },
      sorter: (a: SaleCarrierPaymentReceipt, b: SaleCarrierPaymentReceipt) => {
        return (a.paymentAmount ?? 0) < (b.paymentAmount ?? 0) ? 1 : -1;
      },
      width: 120,
    },
    {
      title: () => <span>Payment Ledger Category</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (name: string, record: SaleCarrierPaymentReceipt, index: number) => {
        return record.paymentLedgerCategory;
      },
      sorter: (a: SaleCarrierPaymentReceipt, b: SaleCarrierPaymentReceipt) =>
        (a.paymentLedgerCategory ?? 0) < (b.paymentLedgerCategory ?? 0) ? 1 : -1,
    },
    {
      title: () => <span>Debug Policy Number</span>,
      defaultSortOrder: null as SortOrder,
      render: (name: string, record: SaleCarrierPaymentReceipt, index: number) => record.debugPolicyNumber,
      sorter: (a: SaleCarrierPaymentReceipt, b: SaleCarrierPaymentReceipt) => {
        return (a.debugPolicyNumber ?? "").localeCompare(b.debugPolicyNumber ?? "");
      },
    },
    {
      title: () => <span>Policy Effective Date</span>,
      defaultSortOrder: null as SortOrder,
      render: (value: any, record: SaleCarrierPaymentReceipt, index: number) => {
        return record.policyEffectiveDate?.toLocaleDateString("en-US");
      },
      sorter: (a: SaleCarrierPaymentReceipt, b: SaleCarrierPaymentReceipt) => {
        return new Date(a.policyEffectiveDate ?? 0) < new Date(b.policyEffectiveDate ?? 0) ? 1 : -1;
      },
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={{ pageSizeOptions: ["10", "50", "100"], showSizeChanger: true }}
      />
      <Button type="primary" style={{ float: "right" }} icon={<DownloadOutlined />} disabled={tableData.length == 0}>
        <CSVLink
          style={{ color: "white" }}
          data={tableData ?? []}
          filename={"sale-" + selectedRecord?.ens_sale_tid + "-cash-receipt.csv"}
        >
          Download CSV
        </CSVLink>
      </Button>
    </>
  );
}
