import React from "react";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { GetSaleStatusToolForecastTabResponse } from "common/services/microservices/finance-client";

export const ForecastTableColumns: ColumnsType<GetSaleStatusToolForecastTabResponse> = [
  {
    title: () => <span>Sale ID</span>,
    defaultSortOrder: "descend" as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.ens_sale_tid;
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) =>
      a.ens_sale_tid! < b.ens_sale_tid! ? 1 : -1,
  },
  {
    title: () => <span>Nominal(1)</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.nominal_fc;
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return (a.nominal_fc ?? 0) < (b.nominal_fc ?? 0) ? 1 : -1;
    },
  },
  {
    title: () => <span>Revenue(2)</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.revenue_fc;
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return (a.revenue_fc ?? 0) < (b.revenue_fc ?? 0) ? 1 : -1;
    },
  },
  {
    title: () => <span>Special(9)</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => record.special_fc,
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return (a.special_fc ?? 0) < (b.special_fc ?? 0) ? 1 : -1;
    },
  },
  
  {
    title: () => <span>Sale Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.sale_date?.toLocaleDateString("en-US");
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return new Date(a.sale_date ?? 0) < new Date(b.sale_date ?? 0) ? 1 : -1;
    },
  },
  {
    title: () => <span>Effective Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.effective_date?.toLocaleDateString("en-US");
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return new Date(a.effective_date ?? 0) < new Date(b.effective_date ?? 0) ? 1 : -1;
    },
  },
  {
    title: () => <span>Approval Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.approved_date?.toLocaleDateString("en-US");
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return new Date(a.approved_date ?? 0) < new Date(b.approved_date ?? 0) ? 1 : -1;
    },
    width: 80,
  },
  {
    title: () => <span>Termination Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: GetSaleStatusToolForecastTabResponse, index: number) => {
      return record.termination_date?.toLocaleDateString("en-US");
    },
    sorter: (a: GetSaleStatusToolForecastTabResponse, b: GetSaleStatusToolForecastTabResponse) => {
      return new Date(a.termination_date ?? 0) < new Date(b.termination_date ?? 0) ? 1 : -1;
    },
    width: 90,
  },
];