import { DownloadOutlined } from "@ant-design/icons";
import { Button, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder, TableCurrentDataSource } from "antd/lib/table/interface";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import "../scss/LicenseReportGeneratorOutput.scss";
import { LicenseReportRow } from "./LicenseReportGenerator";

const { Text } = Typography;
interface ReportGeneratorDisplayProps {
  licensedAgents: LicenseReportRow[];
}

const willDateOccurInDays = (date: Date, days: number) => {
  const testDateTime = new Date(date).getTime();
  const untilDateTime = Date.now() + days * 24 * 60 * 60 * 1000;
  const nowDateTime = Date.now();
  return testDateTime >= nowDateTime && testDateTime <= untilDateTime;
};

export default function LicenseReportGeneratorDisplay(props: ReportGeneratorDisplayProps) {
  const { licensedAgents } = props;
  const [filteredData, setFilteredData] = useState(licensedAgents);

  const columns: ColumnsType<LicenseReportRow> = [
    {
      title: "State",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: LicenseReportRow, b: LicenseReportRow) => a.state.localeCompare(b.state),
      render: (value: string, record: LicenseReportRow) => (
        <Text type={record.active ? undefined : "danger"}>{record.state}</Text>
      ),
      filters: [...new Set((licensedAgents || []).map((a) => a.state))].sort().map((state) => {
        return {
          text: state,
          value: state,
        };
      }),
      onFilter: (value: any, record: LicenseReportRow) => record.state === value,
    },
    {
      title: "Full Name",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: LicenseReportRow, b: LicenseReportRow) =>
        `${a.last_name}, ${a.first_name}`.localeCompare(`${b.last_name}, ${b.first_name}`),
      render: (value: any, record: LicenseReportRow) => (
        <Text type={record.active ? undefined : "danger"}>
          {record.last_name}, {record.first_name} {record.active ? "" : "(in-active)"}
        </Text>
      ),
      filters: [...new Set((licensedAgents || []).map((a) => `${a.last_name}, ${a.first_name}`))]
        .sort()
        .map((fullname) => {
          return {
            text: fullname,
            value: fullname,
          };
        }),
      onFilter: (value: any, record: LicenseReportRow) => `${record.last_name}, ${record.first_name}` === value,
    },
    {
      title: "NPN",
      dataIndex: "npn",
      render: (value: any, record: LicenseReportRow) => <Text>{record.npn ? record.npn : "No NPN on file"}</Text>,
    },
    {
      title: "License#",
      dataIndex: "license_number",
      render: (value: any, record: LicenseReportRow) => <Text>{record.license_number}</Text>,
    },
    {
      title: "License Type",
      dataIndex: "class",
      render: (value: any, record: LicenseReportRow) => <Text>{record.class}</Text>,
    },
    {
      title: "Issued Date",
      dataIndex: "issued_date",
      render: (value: any, record: LicenseReportRow) => <Text>{record.issued_date}</Text>,
    },
    {
      title: "Expiration Date",
      dataIndex: "expiration_date",
      render: (value: string, record: LicenseReportRow) => <Text>{record.expiration_date}</Text>,
      filters: [
        {
          text: "Expiring within 30 days",
          value: 30,
        },
        {
          text: "Expiring within 60 days",
          value: 60,
        },
        {
          text: "Expiring within 90 days",
          value: 90,
        },
      ],
      filterMultiple: false,
      onFilter: (value: any, record: LicenseReportRow) => {
        const newDate = new Date(record.expiration_date!);
        return willDateOccurInDays(newDate!, value);
      },
    },
    {
      title: "LOA Name",
      dataIndex: "loa_name",
      render: (value: any, agent: LicenseReportRow) => <Text>{agent.loa_name}</Text>,
    },
    {
      title: "LOA Status",
      dataIndex: "loa_status",
      render: (value: any, agent: LicenseReportRow) => <Text>{agent.loa_status}</Text>,
    },
    {
      title: "LOA Issued Date",
      dataIndex: "loa_issue_date",
      render: (value: any, agent: LicenseReportRow) => <Text>{agent.loa_issue_date}</Text>,
    },
    {
      title: "LOA Exp Date",
      dataIndex: "ce_renewal_date",
      width: 70,
      render: (value: any, agent: LicenseReportRow) => <Text>{agent.ce_renewal_date}</Text>,
    },
  ];

  return (
    <>
      <Button type="primary" icon={<DownloadOutlined />}>
        <CSVLink style={{ color: "white" }} data={filteredData} filename="licensed-agents-download.csv">
          &nbsp;&nbsp;Download CSV
        </CSVLink>
      </Button>

      <Table
        style={{ marginTop: "1em" }}
        size="small"
        dataSource={licensedAgents}
        columns={columns}
        onChange={(a, b, c, extra: TableCurrentDataSource<LicenseReportRow>) =>
          setFilteredData(extra.currentDataSource)
        }
        rowKey={(record) => `${record.ens_user_tid}-${record.license_number}-${record.loa_code}-${record.state}`}
        scroll={{ x: "max-content", y: 350 }}
      />
    </>
  );
}
