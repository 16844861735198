import { message, Button } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { TasksClient, TaskRunRequest } from "common/services/microservices/cron-boss-client";
import { authenticatedClient } from "core/components/AuthProvider";
import React, { useState } from "react";

interface RunCashMatchingButtonProps {
  onGuidStarted: (guid: string) => void;
}

export function RunCashMatchingButton(props: RunCashMatchingButtonProps) {
  const [loading, setLoading] = useState(false);

  async function runCashMatchingTask() {
    setLoading(true);
    try {
      const client = await authenticatedClient(MicroservicesEndpoint.cronboss, TasksClient);

      const guid = await client.startTask(
        new TaskRunRequest({
          taskModule: "future-value",
          taskName: "MatchCarrierPaymentSalesTask",
        })
      );

      props.onGuidStarted(guid.guid);

      message.success("Started cash matching task. Please allow a few minutes.");
    } catch (ex) {
      message.error("Failed to start cash matching. Please contact engineering.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Button loading={loading} onClick={runCashMatchingTask}>
      Run Cash Matching
    </Button>
  );
}
