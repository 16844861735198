import React, { useEffect, useState } from "react";
import { ColumnsType, SortOrder } from "antd/lib/table/interface";
import { Table, Alert } from "antd";
import {
  GetBookOfBusinessChangesResult,
  HalMatchState,
  IGetBookOfBusinessChangesResult,
  IGetBookOfBusinessChangesTaskSpecs,
} from "common/services/microservices/finance-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { TaskRunRequest, TasksClient } from "common/services/microservices/cron-boss-client";
import { CronTaskEventDisplay, useCronTaskEventPoll } from "../CronTaskEventDisplay";
export interface TerminationDateChangesTableProps {
  specs: IGetBookOfBusinessChangesTaskSpecs;
}

export default function TerminationDateChangesTable(props: TerminationDateChangesTableProps) {
  const [taskGuid, setTaskGuid] = useState<string>();

  const { events, taskFinished, taskResults } = useCronTaskEventPoll<IGetBookOfBusinessChangesResult[]>(
    taskGuid,
    (taskResultBody) => {
      // Convert each element of the returned array into a IGetTerminationDateChangesResult
      // we do this becasue of the Date objects
      const results = JSON.parse(taskResultBody) as any[];
      return results.map((item) => GetBookOfBusinessChangesResult.fromJS(item));
    }
  );

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.cronboss, TasksClient, (c) =>
      c.startTask(
        new TaskRunRequest({
          taskModule: "future-value",
          taskName: "GetTerminationDateChangesTask",
          specs: props.specs,
        })
      )
    ).then((response) => setTaskGuid(response.guid));
  }, [props.specs]);

  function ClassifyEnsSaleMatchState(state: HalMatchState) {
    if (
      state === HalMatchState.EnsSaleFullMatch_PolicyHistoryFullMatch ||
      state === HalMatchState.EnsSaleFullMatch_PolicyHistoryTidOnly ||
      state === HalMatchState.EnsSaleFullMatch_NoPolicyHistory
    ) {
      return "Full";
    } else if (state === HalMatchState.EnsSaleTidOnly_PolicyHistoryFullMatch) {
      return "ID Only";
    } else {
      return "None";
    }
  }

  function ClassifyHistoryMatchState(state: HalMatchState) {
    if (
      state === HalMatchState.EnsSaleFullMatch_PolicyHistoryFullMatch ||
      state === HalMatchState.EnsSaleTidOnly_PolicyHistoryFullMatch
    ) {
      return "Full";
    } else if (state === HalMatchState.EnsSaleFullMatch_PolicyHistoryTidOnly) {
      return "ID Only";
    } else {
      return "None";
    }
  }

  const columnSpec: ColumnsType<IGetBookOfBusinessChangesResult> = [
    {
      title: () => <span>SaleTid</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.ens_sale_tid,
      className: "table-cell",
    },
    {
      title: () => <span>Policy Number</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.hal_policy_number,
      className: "table-cell",
    },
    {
      title: () => <span>Effective Date</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.effective_date?.toDateString(),
      className: "table-cell",
    },
    {
      title: () => <span>Current Term Date</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.current_termination_date_in_hal?.toDateString(),
      className: "table-cell",
    },
    {
      title: () => <span>Proposed Termination Date</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.proposed_termination_date_from_sheet?.toDateString(),
      className: "table-cell",
    },
    {
      title: () => <span>Match in Sale Table</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => ClassifyEnsSaleMatchState(record.policy_match_state),
      className: "table-cell",
    },
    {
      title: () => <span>Match in History Table</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => ClassifyHistoryMatchState(record.policy_match_state),
      className: "table-cell",
    },
    {
      title: () => <span>Current UW status</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.current_underwriting_status,
      className: "table-cell",
    },
    {
      title: () => <span>Proposed UW status</span>,
      defaultSortOrder: "descend" as SortOrder,
      render: (_1, record, _2) => record.proposed_underwriting_status,
      className: "table-cell",
    },
  ];

  return (
    <>
      {!taskFinished ? (
        <CronTaskEventDisplay events={events} taskFinished={taskFinished} />
      ) : (
        <>
          {taskResults && (
            <Table
              scroll={{ y: 600 }}
              dataSource={taskResults}
              columns={columnSpec}
              rowKey={(record) => record.ens_sale_tid}
              pagination={{
                total: taskResults?.length,
                pageSize: 25,
                hideOnSinglePage: true,
              }}
            />
          )}
          {taskResults === null && (
            <Alert message={"There was an error retreiving the task results. Try again?"} type="error"></Alert>
          )}
        </>
      )}
    </>
  );
}
