import React from "react";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import { Tabs } from "antd";
import CommissionUpload from "operations/commission-upload/components/CommissionUpload";
import CommissionUploadManager from "./CommissionUploadManagerComponents/CommissionUploadManager";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import VariableRateForecast from "./VariableRateForecast";
import SalePledgeReportUploadTool from "./SalePledgeReportUploadTool";
import MonthEndRateEditorApp from "./MonthEndRateEditor/MonthEndRateEditorApp";
import { FutureValueRulesUploadTool } from "./FutureValueMsRulesUploadTool";
import { RevenueBookingTool } from "./RevenueBookingTool";
import { UnmatchedCashTool } from "./UnmatchedCashTool/UnmatchedCashTool";
import { DeltaBookingTool } from "./DeltaBookingTool/DeltaBookingTool";
const { TabPane } = Tabs;

export function FutureValueApp(props: ApplicationPageProps) {
  return (
    <Tabs type="card" id="future-value-tools-tabs">
      <TabPane key="commission-data-upload" className="pt-2" tab="Carrier Report Upload">
        <CommissionUpload user={props.user} />
      </TabPane>
      {props.user.hasPermission(EnsUserPermissionType.OperationsAdminAccess) && (
        <TabPane key="forecast-rule-upload" className="pt-2" tab="Forecast Rule Editor">
          <FutureValueRulesUploadTool user={props.user} />
        </TabPane>
      )}
      <TabPane key="commission-upload-manager" className="pt-2" tab="Commission Upload Manager">
        <CommissionUploadManager user={props.user} />
      </TabPane>
      {props.user.hasPermission(EnsUserPermissionType.OperationsAdminAccess) && (
        <TabPane key="variable-rate-forecast" className="pt-2" tab="Variable Rate Forecast">
          <VariableRateForecast />
        </TabPane>
      )}
      {props.user.hasPermission(EnsUserPermissionType.OperationsAdminAccess) && (
        <TabPane key="sale-pledge-upload" className="pt-2" tab="Sale Pledge">
          <SalePledgeReportUploadTool user={props.user} />
        </TabPane>
      )}
      {props.user.hasPermission(EnsUserPermissionType.OperationsAdminAccess) && (
        <TabPane key="month-end-edit" className="pt-2" tab="Month End Editor">
          <MonthEndRateEditorApp user={props.user} />
        </TabPane>
      )}
      {props.user.hasPermission(EnsUserPermissionType.OperationsAdminAccess) && (
        <TabPane key="revenue-booking-tool" className="pt-2" tab="Revenue Booking Tool">
          <RevenueBookingTool />
        </TabPane>
      )}
      <TabPane key="unmatched-cash" className="pt-2" tab="Unmatched Cash">
        <UnmatchedCashTool />
      </TabPane>
      {props.user.hasPermission(EnsUserPermissionType.OperationsAdminAccess) && (
        <TabPane key="delta-booking" className="pt-2" tab="Delta Booking">
          <DeltaBookingTool />
        </TabPane>
      )}
    </Tabs>
  );
}
