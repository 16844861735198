import { Empty, Form, Table } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { SaleNotesObject } from "common/services/microservices/sales-client";
import moment from "moment";
import React from "react";
interface SaleNotesTableProps {
  saleNotes: SaleNotesObject[];
}

export default function SaleNotesTable(props: SaleNotesTableProps) {
  const { saleNotes } = props;
  const [editableNoteForm] = Form.useForm();
  const dateFormat = "MM/DD/yyyy hh:mm a";

  const SaleNotesTableColumns = [
    {
      title: () => <span>Sale Note ID</span>,
      defaultSortOrder: null as SortOrder,
      render: (name: string, record: SaleNotesObject, index: number) => record.ensSaleNoteTid,
      sorter: (a: SaleNotesObject, b: SaleNotesObject) => (a.ensSaleNoteTid! < b.ensSaleNoteTid! ? 1 : -1),
      width: 100,
    },
    {
      title: () => <span>Note Content</span>,
      dataIndex: 'noteContent',
      defaultSortOrder: null as SortOrder,
      render: (name: string, record: SaleNotesObject, index: number) => record.noteContent,
      sorter: (a: SaleNotesObject, b: SaleNotesObject) => (a.noteContent ?? "").localeCompare(b.noteContent ?? ""),
    },
    {
      title: () => <span>Note Date</span>,
      dataIndex: 'noteDateTime',
      defaultSortOrder: "ascend" as SortOrder,
      render: (name: string, record: SaleNotesObject, index: number) => moment(record.noteDateTime).format(dateFormat),
      sorter: (a: SaleNotesObject, b: SaleNotesObject) =>
        new Date(a.noteDateTime ?? 0) < new Date(b.noteDateTime ?? 0) ? 1 : -1,
        width: 150
    },
    {
      title: () => <span>Author</span>,
      dataIndex: 'userFullName',
      defaultSortOrder: null as SortOrder,
      render: (name: string, record: SaleNotesObject, index: number) => record.userFullName,
      sorter: (a: SaleNotesObject, b: SaleNotesObject) => (a.userFullName! < b.userFullName! ? 1 : -1),
      width: 90,
    },
  ];

  return (
    <Form form={editableNoteForm} component={false}>
      <Table
        locale={{
          emptyText: <Empty description="No notes for this sale" image={Empty.PRESENTED_IMAGE_SIMPLE} />,
        }}
        dataSource={saleNotes}
        loading={false}
        rowKey={(record: SaleNotesObject) => record.ensSaleNoteTid!}
        className="sale-notes"
        columns={SaleNotesTableColumns}
        size="small"
        scroll={{ x: "max-content" }}
        style={{ overflow: "hidden" }}
      ></Table>
    </Form>
  );
}
