import { Alert, Button, Col, message, Row, Select, Space, Table, Typography } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import React, { useEffect, useState } from "react";
import { InboxOutlined, ReloadOutlined } from '@ant-design/icons';
import { CronTaskStateType, TasksClient } from "common/services/microservices/cron-boss-client";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import { ReadyToSellJobStatus, ReadyToSellReportType, ReportsOnDemandClient, ReportsOnDemandJobStatus } from "common/services/microservices/licenses-client";

const { Option } = Select;

interface OnDemandReportsProps {
  user: UserClass;
}

enum ReportType {
  AgentsRequiringCe,
  ExpiringLicenses
}

export default function OnDemandReports(props: OnDemandReportsProps) {
  const { user } = props;
  const [latestRequests, setLatestRequests] = useState<ReportsOnDemandJobStatus[]>([]);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [reportType, setReportType] = useState<ReportType>();

  useEffect(() => {
    getTasks();

    const refresh = setInterval(async () => {
      await getTasks();
    }, 3000);

    return () => clearInterval(refresh);
  }, []);

  const requestReport = async (type: ReportType | undefined) => {
    if (type === undefined)
      return;

    try {
      let client = await authenticatedClient(MicroservicesEndpoint.licenses, ReportsOnDemandClient);
      switch (type) {
        case ReportType.AgentsRequiringCe:
          await client.agentsRequiringCe();
          break;
        case ReportType.ExpiringLicenses:
          await client.expiringLicensesReport();
          break;
      }
      
      await getTasks();
    } catch {
      message.error("Something went wrong requesting the report")
    }
  }

  const getTasks = async () => {
    setTasksLoading(true);
    try {
      let c = await authenticatedClient(MicroservicesEndpoint.licenses, ReportsOnDemandClient);
      setLatestRequests(await c.getLatest());
    } finally {
      setTasksLoading(false)
    }
  };

  const jobsColumns: ColumnsType<ReportsOnDemandJobStatus> = [
    {
      title: () => <Button icon={<ReloadOutlined />} onClick={() => getTasks()} />,
      width: 250,
      dataIndex: ["guid"]
    },
    {
      title: "TaskName",
      dataIndex: ["taskName"]
    },
    {
      title: "Date",
      align: "right",
      render: (value: any, record: ReportsOnDemandJobStatus) => record.lastEventDatetime.toLocaleString()
    },
    {
      title: "Status",
      render: (value: any, record: ReportsOnDemandJobStatus) => CronTaskStateType[record.state]
    },
    {
      title: "",
      render: (value: any, record: ReportsOnDemandJobStatus) => {
        if (record.state.valueOf() != CronTaskStateType.Success.valueOf())
          return <></>

        return <Button onClick={() => downloadResults(record.guid, record.taskName, record.lastEventDatetime)}>Download</Button>
      }
    }
  ];

  const downloadResults = async (guid: string, taskName: string, time: Date) => {
    let client = await authenticatedClient(MicroservicesEndpoint.cronboss, TasksClient);

    let res = await client.getResultsDownloadUrl(guid, `${taskName}-${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}.csv`);
    if (res.presignedUrl)
      window.open(res.presignedUrl, "_blank");
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Space>
            <Select
              placeholder="Select Report Type"
              onChange={(v: number) => setReportType(v)}
            >
              {Object.keys(ReportType)
                .filter((k) => !isNaN(parseInt(k)))
                .map((k: string) => (
                  <Option key={parseInt(k)} value={parseInt(k)}>
                    {ReportType[parseInt(k)]}
                  </Option>
                ))}
            </Select>
            <Button disabled={reportType === undefined || tasksLoading} onClick={() => requestReport(reportType)}>Go</Button>
          </Space>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <Title level={4}>Report History</Title>
          <Table
            columns={jobsColumns}
            rowKey={(record) => record.guid}
            dataSource={latestRequests}
            loading={tasksLoading}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
