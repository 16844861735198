import { Row, Col, Spin, Badge, Tooltip } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  CronTaskEvent,
  TasksClient,
  CronTaskEventType,
  CronTaskStateType,
  ICronTaskEvent,
} from "common/services/microservices/cron-boss-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useEffect, useState } from "react";

export function useCronTaskEventPoll<TTaskResultType>(
  taskGuid?: string,
  responseParser?: (data: string) => TTaskResultType
) {
  const [events, setEvents] = useState<CronTaskEvent[]>([]);
  const [taskState, setTaskState] = useState<CronTaskStateType>();
  const [taskFinished, setTaskFinished] = useState<boolean>(false);
  const [taskResults, setTaskResults] = useState<TTaskResultType | null>();
  const [pollInterval, setPollInterval] = useState<NodeJS.Timeout>();

  // Manage an interverval to poll for events and update the other properties
  useEffect(() => {
    if (!taskGuid) return;

    const interval = setInterval(() => {
      authenticatedFetch(MicroservicesEndpoint.cronboss, TasksClient, (c) => c.getTaskEvents(taskGuid)).then(
        (newEvents) => setEvents(newEvents.filter((e) => e.cronTaskEventType !== CronTaskEventType.Pong))
      );

      authenticatedFetch(MicroservicesEndpoint.cronboss, TasksClient, (c) => c.getTaskState(taskGuid)).then(
        (response) => {
          setTaskState(response.cronTaskStateType);
          setTaskFinished(
            response.cronTaskStateType === CronTaskStateType.Failure ||
              response.cronTaskStateType === CronTaskStateType.Success
          );
        }
      );
    }, 5000);
    setPollInterval(interval);
    return () => {
      if (pollInterval) {
        clearInterval(pollInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskGuid]);

  // Stop polling new events if the task finishes.
  useEffect(() => {
    if (taskFinished && pollInterval) {
      clearInterval(pollInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskFinished]);

  // When the task finishes, grab the results and store them.
  // Assume the data type is JSON by default, and the type of TTaskResultType is correct.
  useEffect(() => {
    if (taskFinished && taskState === CronTaskStateType.Success && taskGuid) {
      authenticatedFetch(MicroservicesEndpoint.cronboss, TasksClient, (c) => {
        return c.getResultsDownloadUrl(taskGuid, null);
      }).then((res) => {
        if (res.presignedUrl) {
          fetch(res.presignedUrl)
            .then((res) => res.text())
            .then((resBody) => {
              if (responseParser) {
                return responseParser(resBody);
              }
              return JSON.parse(resBody);
            })
            .then(setTaskResults)
            .catch(() => setTaskResults(null));
        }
      });
    }
  }, [taskFinished]);

  return {
    events: events,
    taskFinished: taskFinished,
    taskState: taskState,
    taskResults: taskResults,
  };
}

interface CronTaskEventDisplayProps {
  events: ICronTaskEvent[];
  showErrorMesssages?: boolean;
  messageBlacklist?: [CronTaskEventType];
  taskFinished: boolean;
}

export function CronTaskEventDisplay(props: CronTaskEventDisplayProps) {
  const shouldShowEventMessage = (e: ICronTaskEvent) =>
    e.cronTaskEventType === CronTaskEventType.Log ||
    e.cronTaskEventType === CronTaskEventType.UserLog ||
    (e.cronTaskEventType === CronTaskEventType.Error && (props.showErrorMesssages ?? false));

  return (
    <Col className="my-3">
      <div style={{ alignItems: "center" }}>
        {!props.taskFinished && (
          <Row justify="center" className="mb-2">
            <Spin />
          </Row>
        )}
        {props.events
          .filter((message) => !props.messageBlacklist?.includes(message.cronTaskEventType))
          .map((e) => (
            <Row style={{ alignItems: "center" }}>
              <Badge status="default" /> {e.eventDatetime.toLocaleTimeString()}
              &nbsp; &nbsp;
              <b>{"|"}</b>
              &nbsp; &nbsp;
              <Tooltip title={e.eventMessage}>
                {CronTaskEventType[e.cronTaskEventType]}
                {shouldShowEventMessage(e) && ` - ${e.eventMessage}`}
              </Tooltip>
            </Row>
          ))}
      </div>
    </Col>
  );
}

interface CronTaskEventByGuidDisplayProps<TTaskResultType> {
  guid: string;
  showErrorMesssages?: boolean;
  onTaskStateChanged?: (newState: CronTaskStateType | undefined) => void;
  onTaskFinished?: (results: TTaskResultType) => void;
}

export default function CronTaskEventByGuidDisplay<TTaskResultType = unknown>(
  props: CronTaskEventByGuidDisplayProps<TTaskResultType>
) {
  const { events, taskFinished, taskState, taskResults } = useCronTaskEventPoll<TTaskResultType>(props.guid);

  useEffect(() => {
    if (taskResults && props.onTaskFinished) {
      props.onTaskFinished(taskResults);
    }
  }, [taskResults]);

  useEffect(() => {
    props.onTaskStateChanged?.(taskState);
  }, [taskState]);

  return (
    <CronTaskEventDisplay
      taskFinished={taskFinished}
      messageBlacklist={[CronTaskEventType.ResultsUploaded]}
      events={events}
      showErrorMesssages={props.showErrorMesssages}
    />
  );
}
