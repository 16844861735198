import React, { useEffect, useRef, useState } from "react";
import { Empty, Row, Table } from "antd";
import { ColumnsType, SortOrder } from "antd/lib/table/interface";
import { GetSaleStatusToolForecastTabResponse } from "common/services/microservices/finance-client";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import "../scss/SalesStatusTable.scss";
import { SaleStatus } from "common/services/microservices/sales-client";
import UserClass from "core/models/UserClass";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";

interface SalesStatusTableProps {
  data: SaleStatus[] | undefined;
  fcData: GetSaleStatusToolForecastTabResponse[] | undefined;
  columns: ColumnsType<SaleStatus> | ColumnsType<GetSaleStatusToolForecastTabResponse> | undefined;
  calledApi: boolean;
  tableLoading: boolean;
  fcTableLoading: boolean;
  selectedRow: (clicked: SaleStatus) => void;
  isForecastTable: boolean;
  tableViewValue: string;
  showSalesNoteModal: (ensSaleTid: number) => void;
  fcCurrentPage: number;
  setFCCurrentPage: (val: number) => void;
  currentPage: number;
  setCurrentPage: (val: number) => void;
  user: UserClass;
}

export default function SalesStatusTable(props: SalesStatusTableProps): JSX.Element {
  const {
    data,
    fcData,
    columns,
    calledApi,
    tableLoading,
    fcTableLoading,
    selectedRow,
    tableViewValue,
    showSalesNoteModal,
    isForecastTable,
    fcCurrentPage,
    setFCCurrentPage,
    currentPage,
    setCurrentPage,
  } = props;

  const [renderedNonFcColumns, setRenderedNonFcColumns] = useState<ColumnsType<SaleStatus>>();
  const [renderedFcColumns, setRenderedFcColumns] = useState<ColumnsType<GetSaleStatusToolForecastTabResponse>>();

  function recentNotePreview(note?: string | undefined) {
    if (note) {
      if (note.length > 10) {
        return `${note.substring(0, 10)}...`;
      }
      return note;
    }
    return "Add note";
  }

  useEffect(() => {
    if (isForecastTable) {
      setRenderedFcColumns(columns as ColumnsType<GetSaleStatusToolForecastTabResponse>);
    } else {
      var newColumns = [...((columns as ColumnsType<SaleStatus>) ?? [])];
      var isOnSalesTable = tableViewValue === "Sales";
      if (isOnSalesTable) {
        newColumns.push({
          title: () => <span>Recent Note</span>,
          defaultSortOrder: null as SortOrder,
          render: (name: string, record: SaleStatus, index: number) => {
            return (
              <div
                className="underline-on-hover"
                style={{ color: "#1890ff", textAlign: "center" }}
                onClick={() => showSalesNoteModal(record.ens_sale_tid)}
              >
                {recentNotePreview(record.recent_note)}
              </div>
            );
          },
          sorter: (a: SaleStatus, b: SaleStatus) => {
            return (a.recent_note ?? "").localeCompare(b.recent_note ?? "");
          },
          width: 100,
        });
      }

      if (props.user.hasPermission(EnsUserPermissionType.ModifySaleAll)) {
        newColumns.push({
          title: () => (
            <span style={isOnSalesTable ? { textAlign: "center", padding: "0 0.8rem", width: "100%" } : {}}>
              Action
            </span>
          ),
          fixed: "right",
          render: (record: SaleStatus) => (
            <Row style={{ flexDirection: "column", alignItems: "center", padding: 0 }}>
              {isOnSalesTable ? (
                <div className="underline-on-hover" style={{ color: "#1890ff" }} onClick={() => selectedRow(record)}>
                  Edit Sale
                </div>
              ) : (
                <a href="# " style={{ color: "#1890ff" }} onClick={() => selectedRow(record)}>
                  Edit
                </a>
              )}
            </Row>
          ),
          width: isOnSalesTable ? 80 : 60,
        });
      }

      setRenderedNonFcColumns(newColumns);
    }
  }, [columns]);

  const commonTableProps = {
    style: { marginTop: 10 },
    bordered: true,
    size: "small" as SizeType,
    scroll: { x: "max-content", y: 350 },
  };

  if (isForecastTable) {
    return (
      <Table
        rowKey={(r: GetSaleStatusToolForecastTabResponse) => r.ens_sale_tid!}
        columns={renderedFcColumns}
        dataSource={fcData}
        pagination={{
          onChange: (page: number) => {
            setFCCurrentPage(page);
          },
          defaultPageSize: 50,
          total: data?.length,
          pageSize: 50,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          current: fcCurrentPage,
          showSizeChanger: false,
        }}
        locale={
          (!fcData || fcData.length === 0) && calledApi
            ? {
                emptyText: (
                  <Empty description="No results found for search criteria" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ),
              }
            : { emptyText: <Empty description="Awaiting search request" /> }
        }
        loading={fcTableLoading}
        {...commonTableProps}
      />
    );
  } else {
    return (
      <Table
        rowKey={(r: SaleStatus) => r.ens_sale_tid!}
        columns={renderedNonFcColumns}
        dataSource={data}
        pagination={{
          onChange: (page: number) => {
            setCurrentPage(page);
          },
          defaultPageSize: 50,
          total: props.data?.length,
          pageSize: 500,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          current: currentPage,
          showLessItems: true,
        }}
        locale={
          (!data || data.length === 0) && calledApi
            ? {
                emptyText: (
                  <Empty description="No results found for search criteria" image={Empty.PRESENTED_IMAGE_SIMPLE} />
                ),
              }
            : { emptyText: <Empty description="Awaiting search request" /> }
        }
        loading={tableLoading}
        {...commonTableProps}
      ></Table>
    );
  }
}
