import { Checkbox, Spin } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { IDropbownProductCarrier, DropdownClient } from "common/services/microservices/basic-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useState, useEffect } from "react";

interface CarrierCheckboxesProps {
  disabled?: boolean;
  selectedCarriers: number[];
  onCarriersSelected: (carrierIds: number[]) => void;
  displayError?: (error: boolean) => void;
}

export function CarrierCheckboxes(props: CarrierCheckboxesProps) {
  const { selectedCarriers, onCarriersSelected, displayError, disabled } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [carrierList, setCarrierList] = useState<IDropbownProductCarrier[]>([]);

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.basic, DropdownClient, (c) => {
      setLoading(true);
      return c.getActiveProductCarriers();
    })
      .then((c) => setCarrierList(c.sort((a, b) => a.product_carrier_name.localeCompare(b.product_carrier_name))))
      .catch(() => displayError?.(true))
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      {carrierList.map((carrier, i) => (
        <Checkbox
          key={i}
          style={{ margin: 0 }}
          disabled={disabled}
          value={selectedCarriers.includes(carrier.ens_product_carrier_tid)}
          onChange={(f) => {
            const checked = f.target.checked;
            if (checked) {
              onCarriersSelected([...selectedCarriers, carrier.ens_product_carrier_tid]);
            } else {
              onCarriersSelected(selectedCarriers.filter((c) => c != carrier.ens_product_carrier_tid));
            }
          }}
        >
          {carrier.product_carrier_name.includes("NHIC") ? "NHIC" : carrier.product_carrier_name}
        </Checkbox>
      ))}
    </>
  );
}
