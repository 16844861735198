import React from "react";
import { Dropdown, Menu } from "antd";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { EnsSaleStatus } from "common/services/ApiServerClient";
import { multiplierDropwdown } from "./EntryModal/SalesTab";
import { DownOutlined } from "@ant-design/icons";
import { SaleStatus, PolicyNumberRecord } from "common/services/microservices/sales-client";

export const SalesTableColumns = (
  notesFunc: (saleTid: number) => void
): ColumnsType<SaleStatus> => {
  return [
  {
    title: () => <span>Sale ID</span>,
    defaultSortOrder: "descend" as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.ens_sale_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) =>
      a.ens_sale_tid < b.ens_sale_tid ? 1 : -1,
  },
  {
    title: () => <span>Carrier</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => record.product_carrier_name,
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.product_carrier_name ?? "").localeCompare(b.product_carrier_name ?? "");
    },
  },
  {
    title: () => <span>Product</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.product_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.product_name ?? "").localeCompare(b.product_name ?? "");
    },
  },
  {
    title: () => <span>First Name</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_first_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_first_name ?? "").localeCompare(b.insured_first_name ?? "");
    },
  },
  {
    title: () => <span>Last Name</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_last_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_last_name ?? "").localeCompare(b.insured_last_name ?? "");
    },
  },
  {
    title: () => <span>DOB</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_dob?.toLocaleDateString("en-US");
    }
  },
  {
    title: () => <span>Policy Number(s)</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      let combinedPolicies = [...(record.policy_number_history ?? [])];
      if (record.primary_policy_number)
        combinedPolicies.push({
          policy_number: record.primary_policy_number,
          effective_date: record.effective_date,
        } as PolicyNumberRecord);
      const menu = (
        <Menu>
          {combinedPolicies.map((policy: PolicyNumberRecord, index: number) => (
            <Menu.Item key={index}>{`${policy.policy_number} - ${policy.effective_date?.toLocaleDateString(
              "en-US"
            )}`}</Menu.Item>
          ))}
        </Menu>
      );
      switch (combinedPolicies?.length) {
        case 0: {
          return "No policy";
        }
        case 1: {
          return combinedPolicies[0].policy_number;
        }
        default:
          return (
            <Dropdown overlay={menu}>
              <a href="# " className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                Multiple policies <DownOutlined />
              </a>
            </Dropdown>
          );
      }
    },
    sorter: (record: SaleStatus) => {
      let combinedPolicies = [...(record.policy_number_history?.map((a) => a.policy_number) ?? [])];
      if (record.primary_policy_number) combinedPolicies.unshift(record.primary_policy_number);
      switch (combinedPolicies?.length) {
        case 0:
          return -1;
        case 1:
          return 0;
        default:
          return 1;
      }
    },
  },
  {
    title: () => <span>Issued Status</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return EnsSaleStatus[record.issued_ens_sale_status_tid];
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return a.issued_ens_sale_status_tid < b.issued_ens_sale_status_tid ? 1 : -1;
    },
  },
  {
    title: () => <span>Current Status</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return EnsSaleStatus[record.current_ens_sale_status_tid];
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return a.current_ens_sale_status_tid < b.current_ens_sale_status_tid ? 1 : -1;
    },
  },
  {
    title: () => <span>Sale Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.sale_datetime?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.sale_datetime) < new Date(b.sale_datetime) ? 1 : -1;
    },
  },
  {
    title: () => <span>Effective Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.effective_date?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.effective_date) < new Date(b.effective_date) ? 1 : -1;
    },
  },
  {
    title: () => <span>Approval Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.approval_datetime?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.approval_datetime ?? 0) < new Date(b.approval_datetime ?? 0) ? 1 : -1;
    },
    width: 80,
  },
  {
    title: () => <span>Termination Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.termination_date?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.termination_date ?? 0) < new Date(b.termination_date ?? 0) ? 1 : -1;
    },
    width: 90,
  },
  {
    title: () => <span>Agent</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `${record.agent_first_name} ${record.agent_last_name ?? ""}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.agent_last_name ?? "").localeCompare(b.agent_last_name ?? "");
    },
  },
  {
    title: () => <span>Commission Paid Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.commission_paid_date?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.commission_paid_date ?? 0) < new Date(b.commission_paid_date ?? 0) ? 1 : -1;
    },
    width: 90,
  },
  {
    title: () => <span>Premium</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `$${record.premium?.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.premium ?? 0) < (b.premium ?? 0) ? 1 : -1;
    },
    width: 90,
  },
  {
    title: () => <span>Premium Multiplier</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return multiplierDropwdown.find((a) => a.multiplier_value === record.premium_multiplier)?.mutliplier_text;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.premium_multiplier ?? 0) < (b.premium_multiplier ?? 0) ? 1 : -1;
    },
    width: 90,
  },
];
}