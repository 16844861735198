import React, { useEffect, useState } from "react";
import { Select } from "antd";
import {
  MicroservicesEndpoint,
} from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";
import { IProductInfoResponse, ProductCarrierClient, ProductInfoResponse, SaleStatus } from "common/services/microservices/sales-client";

const { Option } = Select;

interface ProductSelectProps {
  additionalOptions?: IProductInfoResponse[];
  data?: SaleStatus;
  formDisplay?: boolean;
  clear?: number;
  onProductSelected: (productId: number) => void;
  style?: React.CSSProperties;
}

export default function ProductSelect(props: ProductSelectProps) {
  const { additionalOptions, clear, data, onProductSelected, style } = props;
  const [productList, setProductList] = useState<ProductInfoResponse[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<number | undefined>(data?.ens_product_tid);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.sales, ProductCarrierClient, (c: ProductCarrierClient) => {
      setLoading(true);
      return c.getProducts(true);
    }).then((res: ProductInfoResponse[]) => {
      setLoading(false);
      setProductList(res);
    });
  }, []);

  useEffect(() => {
    setSelectedProduct(undefined);
  }, [clear]);

  useEffect(() => {
    if (!data) return;

    setSelectedProduct(data.ens_product_tid);
  }, [data]);

  return (
    <Select
      allowClear
      showSearch
      style={style}
      disabled={loading}
      loading={loading}
      value={selectedProduct}
      placeholder="Select a product"
      optionFilterProp="children"
      onChange={(value, option) => {
        onProductSelected(value as number);
        setSelectedProduct(value);
      }}
      filterOption={(input: string, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {[...productList, ...(additionalOptions ?? [])]
        .sort((a, b) => (a.productName > b.productName ? 1 : -1))
        .map((item) => {
          return (
            <Option key={item.ensProductTid} value={item.ensProductTid}>
              {item.productName}
            </Option>
          );
        })}
    </Select>
  );
}
