import { Input } from "antd";
// import type { InputRef } from "antd";
import React, { useEffect, useRef } from "react";
import InputMask from 'inputmask';

interface PhoneNumberInputProps {
  value: string | undefined;
  onChange: (val: string) => void;
}

export default function PhoneNumberInput(props: PhoneNumberInputProps) {
  const { value, onChange } = props;
  const phoneInputMaskRegex = "\\+1 \\([2-9]{1}[0-9]{2}\\) ([2-9]{1}[0-9]{2})-([0-9]{4})";
  const phoneInput = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (phoneInput.current) {
        const pMask = new InputMask({ regex: phoneInputMaskRegex });
        pMask.mask(phoneInput.current);
    }
  }, [phoneInput]);

  return (
    <input
    id='basic_phone'
    className='ant-input'
    type='text'
    style={{ width: "100%" }}
    placeholder="Insured phone"
    onChange={(e: any) => onChange(e.target.value)}
    value={value}
    ref={ref =>phoneInput.current = ref}
    maxLength={50}
    />
  );
}