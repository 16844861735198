import { Col, Divider, Row } from "antd";
import Loader from "common/components/ApiLoader";
import StatusAlert from "common/components/StatusAlert";
import ApiServerService, { FindUserRequestType, GetUserSimpleResponse } from "common/services/ApiServerService";
import { authenticatedConnect } from "core/components/AuthProvider";
import React from "react";
import { PromiseState } from "react-refetch";
import { Link } from "react-router-dom";

interface NpnReportSelectionOuterProps {
  searchAgent: (idType: FindUserRequestType, id: string) => void;
}

interface NpnReportInternalProps extends NpnReportSelectionOuterProps {
  userListFetch?: PromiseState<GetUserSimpleResponse[]>;
}

function NpnReport(props: NpnReportInternalProps) {
  function NpnReportListGenerator(selectorOptions: GetUserSimpleResponse[]): JSX.Element[] {
    const badUsers = selectorOptions.filter((a) => a.npn === null && a.roles.some((r) => r.role_type_id === 1));
    const sortedArray = badUsers.sort((a, b) => (a.user_last_name > b.user_last_name ? 1 : -1));
    const mappedArray = sortedArray.map((element, index) => (
      <Row className="my-1" key={index}>
        <Divider />
        <Col md={12}>{element.user_last_name + ", " + element.user_first_name}</Col>
        <Col md={12}>
          <Link to="#" onClick={() => props.searchAgent(FindUserRequestType.Email, element.user_email.toString())}>
            View Agent
          </Link>
        </Col>
      </Row>
    ));
    return mappedArray;
  }

  return (
    <>
      {props.userListFetch?.rejected && <StatusAlert shouldDisplay={true} success={false} />}

      {props.userListFetch?.settled ? (
        <div className="my-3">
          <div>{props.userListFetch?.fulfilled && NpnReportListGenerator(props.userListFetch?.value!)}</div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default authenticatedConnect<NpnReportSelectionOuterProps, NpnReportInternalProps>((props) => ({
  userListFetch: {
    url: `${ApiServerService.endpoint}/api/portal/user`,
  },
}))(NpnReport);
