import { Button, Col, Row, Select } from "antd";
import Transfer, { TransferItem } from "antd/lib/transfer";
import { EnsUser, TwoLetterState } from "common/services/ApiServerClient";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { UserBasicInfo, UserClient, UserRole } from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import React, { CSSProperties, useEffect, useState } from "react"

const { Option } = Select;

interface EnsUserSelectProps {
  style?: CSSProperties,
  selectedValue?: UserBasicInfo,
  onSelect: (user: UserBasicInfo | undefined) => void;
  agentsOnly?: boolean
}

export default function EnsUserSelect(props: EnsUserSelectProps) {
  const { selectedValue, onSelect, agentsOnly, style } = props;
  const [users, setUsers] = useState<UserBasicInfo[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    setUsers([]);

    setLoadingUsers(true);
    authenticatedFetch(MicroservicesEndpoint.ensUsers, UserClient, async c => {
      let users = await c.getUsers(agentsOnly == true, false);
      setUsers(users.sort((a, b) => a.userEmail.localeCompare(b.userEmail)));
    }).finally(() => setLoadingUsers(false));
  }, []);

  return (
    <Select
      showSearch
      loading={loadingUsers}
      style={{ width: 200, ...style }}
      placeholder="Select a user"
      optionFilterProp="children"
      value={selectedValue?.ensUserTid}
      onChange={v => onSelect(users.find(r => r.ensUserTid == v))}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {users.sort((a, b) => a.userEmail.localeCompare(b.userEmail))
        .map(r => <Option key={r.ensUserTid} value={r.ensUserTid}>{r.userEmail}</Option>)}
    </Select>
  )
}