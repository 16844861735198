import { EnsUser } from "common/services/ApiServerService";
import UserClass from "core/models/UserClass";
import LicenseMap from "licensing/components/LicenseMap";
import LicensingDisplay from "licensing/components/LicensingDisplay";
import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import AgentStateOverview from "./AgentStateOverview";
import AppointmentMap from "./AppointmentsTab";
import BrokerIdTab from "./BrokerIdTab";
import CertificationEditor from "./CertificationEditor";
import RtsMap from "./RtsTab";
import LicensingRequests from "./licensing-requests/LicensingRequests";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";

const { TabPane } = Tabs;

interface AgentTabProps {
  user: UserClass;
  agent: EnsUser;
}

export default function AgentTabRender(props: AgentTabProps) {
  const { user } = props;
  const [lazyLoadList, setlazyLoadList] = useState<string[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<
    "Information" | "BrokerId" | "Certifications" | "Appointments" | "Licensing" | "RTS" | "StateOverview"
  >("Information");

  useEffect(() => {
    const onLazyLoad = (key: string) => {
      let state = lazyLoadList.some((l) => l === key);
      if (state) return;
      setlazyLoadList([...lazyLoadList.filter((l) => l !== key), key]);
    };

    activeTabKey && onLazyLoad(activeTabKey);
  }, [activeTabKey, lazyLoadList]);

  return (
    <Tabs type="card" defaultActiveKey="Information" id="TabContainer" onTabClick={(k: any) => setActiveTabKey(k)}>
      <TabPane key="Information" tab="Information" className="border-bottomless">
        <LicensingDisplay results={props.agent} user={props.user} />
      </TabPane>
      <TabPane tab="Broker ID" key="BrokerId">
        {lazyLoadList.some((l) => l === "BrokerId") && <BrokerIdTab {...props} />}
      </TabPane>
      <TabPane key="Certifications" tab="Certifications">
        {lazyLoadList.some((l) => l === "Certifications") && (
          <CertificationEditor user={props.user} agent={props.agent} />
        )}
      </TabPane>
      <TabPane tab="Appointments" key="Appointments">
        {lazyLoadList.some((l) => l === "Appointments") && <AppointmentMap agent={props.agent} />}
      </TabPane>
      <TabPane key="Licensing" tab="Licensing">
        <LicenseMap user={props.user} agent={props.agent} />
      </TabPane>
      <TabPane key="Rts" tab="Ready-To-Sell">
        <RtsMap user={props.user} agent={props.agent} />
      </TabPane>
      <TabPane key="StateOverview" tab="State Overview">
        {lazyLoadList.some((l) => l === "StateOverview") && (
          <AgentStateOverview user={props.user} agent={props.agent} />
        )}
      </TabPane>
    </Tabs>
  );
}
