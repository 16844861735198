import {
  EnsUserPermissionType,
  UserBasicInfo,
  UserPermission,
  UserRole,
} from "common/services/microservices/ensusers-client";

class UserClass {
  EnsUserTid: number;
  UserEmail: string = "";
  UserName: string = "";
  UserRoles?: UserRole[];
  UserPermissions?: UserPermission[];

  constructor(email: string, name: string, userInfo?: UserBasicInfo) {
    this.EnsUserTid = userInfo?.ensUserTid ?? -1;
    this.UserEmail = email;
    this.UserName = name;
    this.UserPermissions = userInfo?.permissions;
    this.UserRoles = userInfo?.roles;
  }

  hasPermission(permission: EnsUserPermissionType): boolean {
    return this.UserPermissions?.some((p) => p.ensUserPermissionType === permission) || false;
  }
}

export default UserClass;
