import { Col, Row } from "antd";
import Logout from "core/images/icons/logout.svg";
import User from "core/images/icons/user.svg";
import E from "core/images/logos/e.svg";
import React from "react";
import { Link } from "react-router-dom";

interface HeaderProps {
  username: string;
  userrole: string;
  onLogoutClicked: () => void;
}

export default function Header(props: HeaderProps): JSX.Element {
  return (
    <Row style={{ paddingTop: "1em" }}>
      <Col xs={2} lg={1}>
        <Link to="/">
          <img className="iap-header-icon mr-2" alt="Home" src={E} />
        </Link>
      </Col>
      <Col xs={2} md={1} style={{ textAlign: "center" }}>
        <img className="headerUserIcon" alt="Home" src={User} />
      </Col>
      <Col xs={16} md={17} lg={18} className="headerRow">
        {props.username}
        <br />
        {props.userrole}
      </Col>
      <Col xs={4} style={{ textAlign: "right" }}>
        <Link className="logoutText" to="/" onClick={props.onLogoutClicked}>
          <img className="mr-2" alt="Logout" src={Logout} />
          Logout
        </Link>
      </Col>
    </Row>
  );
}
