import AdminApp from "admin/App";
import Paragraph from "antd/lib/typography/Paragraph";
import { IapAccessTarget } from "common/services/microservices/logging-client";
import "core/scss/App.scss";
import DataApp from "data/App";
import LicenseApp from "licensing/App";
import OperationsApp from "operations/App";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { SalesApp } from "sales/App";
import AdminPage from "./application-pages/Admin";
import LicensePage from "./application-pages/License";
import MarketingPage from "./application-pages/MarketingPage";
import Applications from "./Applications";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { authenticatedClient, useAuthProvider } from "./AuthProvider";
import Header from "./Header";
import LoginScreen from "./Login";
import NotFound from "./NotFound";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { UserClient } from "common/services/microservices/ensusers-client";

export default function InternalAppsPortal() {
  const { isAuthenticated, currentUser, onSignIn, onSignOut } = useAuthProvider();

  useEffect(() => {
    // Force MSAL to provide auth
    authenticatedClient(MicroservicesEndpoint.ensUsers, UserClient);
  }, []);

  return (
    <>
      <Paragraph className="container">
        {isAuthenticated && (
          <Header
            username={currentUser!.UserName}
            userrole={(currentUser?.UserRoles || [])[0]?.ensUserRoleName || ""}
            onLogoutClicked={onSignOut}
          />
        )}
        <Switch>
          <AuthenticatedRoute
            path="/"
            exact
            accessTarget={IapAccessTarget.Home}
            authenticated={isAuthenticated}
            pass={<Applications user={currentUser!} />}
            fail={<LoginScreen onLoginButtonClicked={onSignIn} />}
          />
          <AuthenticatedRoute
            path="/applications/admin"
            exact
            accessTarget={IapAccessTarget.Admin}
            authenticated={isAuthenticated}
            pass={<AdminPage user={currentUser!} />}
          />
          <AuthenticatedRoute
            path="/applications/admin/role-update"
            exact
            accessTarget={IapAccessTarget.Admin}
            authenticated={isAuthenticated}
            pass={<AdminApp user={currentUser!} />}
          />
          <AuthenticatedRoute
            path="/applications/marketing"
            exact
            accessTarget={IapAccessTarget.Marketing}
            authenticated={isAuthenticated}
            pass={<MarketingPage user={currentUser!} />}
          />
          <AuthenticatedRoute
            path="/applications/licensing"
            exact
            accessTarget={IapAccessTarget.Licensing}
            authenticated={isAuthenticated}
            pass={<LicensePage />}
          />
          <AuthenticatedRoute
            path="/applications/licensing/agent-info"
            exact
            accessTarget={IapAccessTarget.Licensing}
            authenticated={isAuthenticated}
            pass={<LicenseApp user={currentUser!} />}
          />
          <AuthenticatedRoute
            path="/applications/operations"
            exact
            accessTarget={IapAccessTarget.Operations}
            authenticated={isAuthenticated}
            pass={<OperationsApp user={currentUser!} />}
          />
          <AuthenticatedRoute
            path="/applications/sales"
            exact
            accessTarget={IapAccessTarget.Sales}
            authenticated={isAuthenticated}
            pass={<SalesApp user={currentUser!} />}
          />
          <AuthenticatedRoute
            path="/applications/data"
            exact
            accessTarget={IapAccessTarget.DataDictionary}
            authenticated={isAuthenticated}
            pass={<DataApp user={currentUser!} />}
          />
          <Route component={NotFound} />
        </Switch>
      </Paragraph>
    </>
  );
}
