import { Col, Row, Select, Empty } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { UserClient } from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useEffect, useState } from "react";
import UserPermissionManager from "./UserPermissionManager";
import UserRoleManager from "./UserRoleManager";

const { Option } = Select;

type User = {
  ensUserTid: number,
  email: string
};

export default function UserPermissionsPage() {
  const [selectedUser, setSelectedUser] = useState<User>();
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    setUsers([]);
    setLoadingUsers(true);

    authenticatedFetch(MicroservicesEndpoint.ensUsers, UserClient, async c => {
      let users = await c.getUsers(false, false);
      setUsers(users.sort((a, b) => a.userEmail.localeCompare(b.userEmail)).map(u => {
        return {
          ensUserTid: u.ensUserTid,
          email: u.userEmail
        }
      }));
    }).finally(() => setLoadingUsers(false));
  }, []);

  return (<>
    <Row>
      <Col span={24}>
        <Select
          showSearch
          loading={loadingUsers}
          style={{ width: 200 }}
          placeholder="Select a user"
          optionFilterProp="children"
          onChange={v => setSelectedUser(users.find(u => u.ensUserTid == v))}
          filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {users.map(u => <Option key={u.ensUserTid} value={u.ensUserTid}>{u.email}</Option>)}
        </Select>
      </Col>
    </Row>
    <div style={{ marginTop: 15 }}>
      {!selectedUser ? <Empty description="No user selected" /> : <>
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <UserRoleManager ensUserTid={selectedUser?.ensUserTid} onRoleUpdated={() => {
              // force reload permissions for user
              let user = selectedUser;
              setSelectedUser(undefined);
              setSelectedUser(user);
            }} />
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <UserPermissionManager ensUserTid={selectedUser?.ensUserTid} />
          </Col>
        </Row>
      </>
      }
    </div>
  </>);
}