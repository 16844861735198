import { Col, Form, Input } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Cleave from "cleave.js/react";
import StatusAlert from "common/components/StatusAlert";
import { SubmitButton } from "common/components/SubmitButton";
import {
  ApiServerService,
  EnsUser,
  EnsUserPermissionTypeTid,
  UpdateUserRequest,
  UserClient,
} from "common/services/ApiServerService";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import DateConversion from "core/models/DateConversion";
import UserClass from "core/models/UserClass";
import StateDropDown from "licensing/components/StateList";
import React, { useEffect, useState } from "react";
import "../scss/LicenseDisplay.scss";

interface LicensingDisplayProps {
  results: EnsUser;
  user: UserClass;
}

export default function LicensingDisplay(props: LicensingDisplayProps): JSX.Element {
  const { results, user } = props;
  const [form] = Form.useForm();
  const readonly = !user.hasPermission(EnsUserPermissionType.LicensingEditAccess);
  const [selectedState, setSelectedState] = useState<string>(results.state!);
  const [realSsn, setRealSsn] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const updateLicensing = (ensUserTid: number, request: UpdateUserRequest) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoading(true);
      return c.updateUserV2(ensUserTid, request);
    })
      .then(() => {
        setUpdatedSuccessfully(true);
      })
      .catch(() => {
        setDisplayError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function censorSsn(social: string | undefined) {
    return social?.replace(/^(\D*\d\D*){5}/gm, function (match) {
      return match.replace(/\d/g, "*");
    });
  }

  async function handleSubmit() {
    form.setFieldsValue({ state: selectedState });
    const cleanPhone = form.getFieldValue("phone").replace(/[\D+\s()-]+/g, "");
    form.setFieldsValue({ phone: cleanPhone });

    let censoredValue: string;
    if (readonly) {
      censoredValue = form.getFieldValue("ssn");
      // Un-censor for submit
      form.setFieldsValue({ ssn: realSsn });
    } else {
      const cleanSsn = form.getFieldValue("ssn").replace(/[\D+\s()-]+/g, "");
      form.setFieldsValue({ ssn: cleanSsn });
    }
    updateLicensing(results.ens_user_tid, form.getFieldsValue(true));
    if (readonly) {
      // Re-censor after submit
      form.setFieldsValue({ ssn: censoredValue! });
    }
  }

  useEffect(() => {
    if (!results) return;
    setRealSsn(results.ssn);
    form.setFieldsValue({
      user_email: results.user_email,
      user_first_name: results.user_first_name,
      user_last_name: results.user_last_name,
      ssn: readonly ? censorSsn(results.ssn) : results.ssn ?? null,
      address_line1: results.address_line1,
      address_line2: results.address_line2,
      city: results.city,
      state: results.state,
      zip: results.zip,
      personal_email: results.personal_email,
      phone: results.phone,
      phone_extension: results.phone_extension,
      dob: DateConversion(results.dob),
      hire_date: DateConversion(results.hire_date),
      termination_date: DateConversion(results.termination_date),
      npn: results.npn,
    });
  }, [readonly, form, results]);

  return (
    <Paragraph className="LicensingDisplay my-4">
      <Form {...layout} onFinish={handleSubmit} key={results.ens_user_tid} requiredMark={false} form={form}>
        <Col span={14}>
          <Form.Item label="NPN" name="npn">
            <Input id="npn" required disabled={!results.active_fl} placeholder="Enter NPN" />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="First Name" name="user_first_name">
            <Input id="firstName" disabled={!results.active_fl} placeholder="First" required />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Last Name" name="user_last_name" rules={[{ required: true }]} required>
            <Input id="lastName" disabled={!results.active_fl} placeholder="Last" required />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Work Email" name="user_email">
            <Input id="email" disabled={true} required />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Personal Email" name="personal_email">
            <Input id="personalEmail" disabled={!results.active_fl} placeholder="Personal Email" />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Personal Phone" name="phone">
            <Cleave
              style={{ lineHeight: "22px" }}
              id="phone"
              type="text"
              className="cleaveInput"
              disabled={!results.active_fl}
              options={{
                blocks: [3, 3, 4],
                delimiter: "-",
                numericOnly: true,
              }}
              value={results.phone}
              placeholder="Phone"
              required
            />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Work Extension" name="phone_extension">
            <Input id="phoneExtension" maxLength={10} disabled={!results.active_fl} placeholder="Phone extension" />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="DOB" name="dob">
            <Cleave
              id="dob"
              className="cleaveInput"
              type="text"
              disabled={!results.active_fl}
              placeholder="DOB"
              options={{ date: true, datePattern: ["m", "d", "Y"] }}
              value={DateConversion(results.dob)}
              pattern="(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d"
            />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Address 1" name="address_line1">
            <Input id="addressLineOne" disabled={!results.active_fl} placeholder="Address Line 1" required />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Address 2" name="address_line2" initialValue={results.address_line2}>
            <Input id="addressLineTwo" disabled={!results.active_fl} placeholder="Address Line 2" />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="City" name="city" rules={[{ required: true }]}>
            <Input id="city" disabled={!results.active_fl} placeholder="City" />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="State" name="state">
            {<StateDropDown stateSelected={setSelectedState} displayValue={results.state!} disableSelect={readonly} />}
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Zip" name="zip">
            <Cleave
              id="zip"
              className="cleaveInput"
              disabled={!results.active_fl}
              options={{ numericOnly: true }}
              value={results.zip}
              placeholder="Zip"
              maxLength={5}
              required
            />
          </Form.Item>
        </Col>

        <Col span={14}>
          <Form.Item label="Hire Date" name="hire_date">
            <Cleave
              id="hired"
              className="cleaveInput"
              type="text"
              disabled={!results.active_fl}
              options={{ date: true, datePattern: ["m", "d", "Y"] }}
              value={DateConversion(results.hire_date)}
              placeholder="Hired date"
              pattern="(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d"
            />
          </Form.Item>
        </Col>

        {user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
          <Col span={14}>
            <Form.Item
              label="Termination Date"
              name="termination_date"
              initialValue={DateConversion(results.termination_date)}
            >
              <Cleave
                id="terminated"
                className="cleaveInput"
                type="text"
                options={{ date: true, datePattern: ["m", "d", "Y"] }}
                value={DateConversion(results.termination_date)}
                placeholder="Termination date"
                pattern="(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d"
              />
            </Form.Item>
          </Col>
        )}

        <Col span={14}>
          <Form.Item label="SSN" name="ssn">
            {readonly ? (
              <Cleave
                id="ssn"
                className="cleaveInput"
                type="text"
                disabled={true}
                options={{
                  blocks: [3, 2, 4],
                  delimiter: "-",
                  numericOnly: false,
                }}
                value={censorSsn(results.ssn)}
                placeholder="SSN"
              />
            ) : (
              <Cleave
                id="ssn"
                className="cleaveInput"
                type="text"
                disabled={!results.active_fl}
                options={{
                  blocks: [3, 2, 4],
                  delimiter: "-",
                  numericOnly: true,
                }}
                value={results.ssn}
                placeholder="SSN"
                required
              />
            )}
          </Form.Item>
        </Col>

        {!readonly && (
          <div className="saveButtonContainer">
            <Col md={{ span: 4, offset: 10 }}>
              <SubmitButton className="mt-3" text={"Save"} loading={loading} />
            </Col>

            <Col md={{ span: 8, offset: 8 }} className="text-center my-2">
              <StatusAlert
                shouldDisplay={updatedSuccessfully || displayError}
                success={!displayError}
                closable={true}
              />
            </Col>
          </div>
        )}
      </Form>
    </Paragraph>
  );
}
