import * as React from "react";
import { Button } from "antd";
interface Props extends React.ComponentProps<typeof Button> {
  loading: boolean;
  text?: string;
}

export function SubmitButton(props: Props) {
  const { loading, text, disabled, className, ...htmlProps } = props;
  return (
    <Button
      {...htmlProps}
      disabled={loading || disabled}
      className={`${className}`}
      type="primary"
      htmlType="submit"
      block
    >
      {loading === true ? (
        <span className="spinner-border spinner-border-sm mr-1 pb-1" role="status" aria-hidden="true"></span>
      ) : (
        ""
      )}
      {text || "Submit"}
    </Button>
  );
}
