import { Select } from "antd";
import React from "react"
const stateAbbreviaton = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]

interface StateDropeDownProps {
  stateSelected: (state: string) => void
  displayValue?: string
  disableSelect?: boolean
}

export default function StateDropDown(props: StateDropeDownProps) {
  const { stateSelected, displayValue } = props;
  return (
    <Select
      options={stateAbbreviaton.map(state => {
        return { value: state, label: state }
      })}
      disabled={props.disableSelect}
      placeholder={"Select state"}
      defaultValue={displayValue}
      onChange={(value, option) => {
        stateSelected(value as string)
      }}
    />
  )
}