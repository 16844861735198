import React from "react";
import Paragraph from "antd/lib/typography/Paragraph";
import EnsuremLoader from "common/images/eLoader2.gif"

function Loader() {
  return (
    <Paragraph className="container lora-survey__form">
      <div className="loader flex-column text-center">
        <div className="loader__img">
          <img src={EnsuremLoader} alt="content is loading" />
        </div>
      </div>
    </Paragraph>
  );
}

export default Loader;