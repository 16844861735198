import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { authenticatedFetch } from "core/components/AuthProvider";
import ApiServerService, { MicroservicesEndpoint } from "common/services/ApiServerService";
import { EnsUserInfo, UserClient } from "common/services/microservices/ensusers-client";
import { SaleStatus } from "common/services/microservices/sales-client";


const { Option } = Select;

interface AgentSelectProps {
  clear?: number;
  data?: SaleStatus;
  referralAgentSelect?: boolean;
  onAgentSelected: (agentId: number | undefined) => void;
  additionalAgents?: EnsUserInfo[];
  style?: React.CSSProperties;
}

export default function AgentSelect(props: AgentSelectProps) {
  const { clear, data, referralAgentSelect, onAgentSelected, style, additionalAgents } = props;
  const [agentFetch, setAgentFetch] = useState<EnsUserInfo[]>([]);
  const [selectedAgent, setSelectedAgent] = useState<number | undefined>(
    referralAgentSelect ? data?.referral_ens_user_tid : data?.agent_ens_user_tid
  );
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.ensUsers, UserClient, (c) => {
      setLoading(true);
      return c.getEnsUserInfos(true, true);
    }).then((res: EnsUserInfo[]) => {
      res = res.filter(r => r.userLastName);
      setAgentFetch(res.concat(additionalAgents ?? []));
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedAgent(undefined);
  }, [clear]);

  useEffect(() => {
    if (!data) return;
    if (referralAgentSelect) {
      setSelectedAgent(data.referral_ens_user_tid);
      onAgentSelected(data.referral_ens_user_tid);
    } else {
      setSelectedAgent(data.agent_ens_user_tid);
      onAgentSelected(data.agent_ens_user_tid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Select
      onClear={() => {
        onAgentSelected(undefined);
      }}
      allowClear
      showSearch
      style={style}
      disabled={loading}
      loading={loading}
      placeholder={referralAgentSelect ? "Select referral agent" : "Select an agent"}
      optionFilterProp="children"
      value={selectedAgent}
      onChange={(value, option) => {
        setSelectedAgent(value as number);
        onAgentSelected(value);
      }}
      filterOption={(input: string, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {agentFetch
        .sort((a, b) => ((a.userLastName ?? "") > (b.userLastName ?? "") ? 1 : -1))
        .map((item) => {
          return (
            <Option key={item.ensUserTid} value={item.ensUserTid}>
              {item.ensUserTid === 8 || item.ensUserTid === 11
                ? item.userFirstName
                : `${item.userLastName}, ${item.userFirstName}`}
            </Option>
          );
        })}
    </Select>
  );
}
