// import { DownloadOutlined } from "@ant-design/icons";
import { Button, Row, Select } from "antd";
import Loader from "common/components/ApiLoader";
import StatusAlert from "common/components/StatusAlert";
import { EnsUser, FindUserRequestType, MicroservicesEndpoint } from "common/services/ApiServerService";
import { LicensedAgent, ReportsClient } from "common/services/microservices/licenses-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import LicenseReportGeneratorOutput from "licensing/components/LicenseReportGeneratorOutput";
import React, { useEffect, useState } from "react";
import NpnReportGenerator from "./NpnReportGenerator";

const reportselectOptions = [
  { value: "Agent Licenses", label: "Agent Licenses" },
  { value: "Missing NPN", label: "Missing NPN" },
];

export interface LicenseReportRow {
  ens_user_tid: number;
  first_name: string;
  last_name: string;
  npn: string;
  active: boolean;
  license_number: string;
  class: string; // License Type
  state: string;
  issued_date?: string | undefined;
  expiration_date?: string | undefined;
  loa_name?: string;
  loa_code?: number;
  loa_issue_date?: string | undefined;
  loa_status?: string | undefined;
  ce_renewal_date?: string | undefined;
}

interface LicenseReportSelectionProps {
  selectedAgent: EnsUser | null;
  searchAgent: (idType: FindUserRequestType, id: string, inactive?: boolean) => void;
}

export default function LicenseReport(props: LicenseReportSelectionProps) {
  const [licensedAgents, setLicensedAgents] = useState<LicenseReportRow[]>();
  const [reportDropDownSelection, setReportDropDownSelection] = useState<string>();
  const [displayNpn, setDisplayNpn] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const updateReportOptions = () => {
    if (reportDropDownSelection === "Agent Licenses") {
      setDisplayNpn(false);
    }
    if (reportDropDownSelection === "Missing NPN") {
      setDisplayNpn(true);
    }
  };

  const agentsToRows = (agents: LicensedAgent[]) => {
    let arrayOfRows: LicenseReportRow[] = [];
    agents.forEach((a, i) => {
        a.licenses.forEach((lic) => {
          let rows = lic.license_loas.map((loa) => {
            return {
              ens_user_tid: a.ens_user_tid,
              first_name: a.first_name,
              last_name: a.last_name,
              active: a.active,
              npn: a.npn,
              state: lic.state,
              license_number: lic.license_number,
              expiration_date: lic.expiration_date?.toLocaleDateString("en-US"),
              issued_date: lic.issued_date?.toLocaleDateString("en-US"),
              class: lic.class,
              loa_code: loa.loa_code,
              loa_name: loa.loa_name,
              loa_status: loa.loa_status,
              ce_renewal_date: loa.ce_renewal_date?.toLocaleDateString("en-US"),
              loa_issue_date: loa.loa_issue_date?.toLocaleDateString("en-US"),
            } as LicenseReportRow;
          });
          arrayOfRows.push(...rows);
        });
    });
    return arrayOfRows;
  };

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.licenses, ReportsClient, (c) => {
      setLoading(true);
      setLicensedAgents(undefined);
      return c.getLicensedAgents();
    })
      .then((agents: LicensedAgent[]) => {
        setLicensedAgents(agentsToRows(agents));
      })
      .catch((e) => {
        setError(true);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Row style={{ marginBottom: "1em"}}>
        <Select
          options={reportselectOptions}
          placeholder={"Select report"}
          onChange={(value, option) => {
            setReportDropDownSelection(value as string);
          }}
          style={{ marginTop: "15px" }}
        />
        {reportDropDownSelection && reportDropDownSelection === "Missing NPN" && (
          <Button type="primary" onClick={updateReportOptions} style={{ marginLeft: "1em", marginTop: "1em"}}>Generate</Button>
        )}
        {/* {reportDropDownSelection && reportDropDownSelection === "Agent Licenses" && (
            <Button style={{marginTop: "1em", marginLeft: "1em"}} type="primary" icon={<DownloadOutlined />}>
              Download CSV
            </Button>
          )} */}
      </Row>
  
      {loading && reportDropDownSelection === "Agent Licenses" && <Loader />}
      {error && <StatusAlert shouldDisplay={true} success={false} />}
      {licensedAgents && reportDropDownSelection === "Agent Licenses" && (
        <LicenseReportGeneratorOutput licensedAgents={licensedAgents} />
      )}
      {displayNpn === true && reportDropDownSelection === "Missing NPN" && (
        <NpnReportGenerator searchAgent={props.searchAgent} />
      )}
    
    </>
  );
}
