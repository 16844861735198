import UserClass from "core/models/UserClass";
import React from "react";

interface IUserContext {
  user: UserClass;
}

//
// Provides a Context for the currently-logged-in user
//
export const UserContext = React.createContext<IUserContext>({ user: null as any });
