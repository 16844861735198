import UserClass from "core/models/UserClass";
import { License } from "common/services/ApiServerService";
const stateAbbreviaton = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
const currentDate = new Date();

type Colors = {
  colors: MapColor;
};

type MapColor = {
  partial: string;
  nothing: string;
  full: string;
};

const LicenseColors: Colors = {
  colors: {
    partial: "#FDE396",
    nothing: "#CFCFC4 ",
    full: "#4C8E4C",
  },
};

export default function StateColorDisplay(user: UserClass, licenses: License[]) {
  let obj: { [key: string]: any } = {};

  stateAbbreviaton.forEach((state) => (obj[state] = { fill: LicenseColors.colors.nothing }));

  licenses.forEach((lic) => {
    // ignore sublicensee licenses
    if (lic.license_class_code === 6613)
      return;

    if (!lic.issued_date) {
      setStateColor(obj, lic.state, { fill: LicenseColors.colors.nothing });
      return;
    }

    if (lic.issued_date > currentDate) {
      setStateColor(obj, lic.state, { fill: LicenseColors.colors.nothing });
      return;
    }

    if (lic.expiration_date && lic.expiration_date <= currentDate) {
      setStateColor(obj, lic.state, { fill: LicenseColors.colors.nothing });
      return;
    }

    let hasHealth = lic.license_loa.some((loa) => loa.loa_types.some((name) => name === "health"));
    let hasLife = lic.license_loa.some((loa) => loa.loa_types.some((name) => name === "life"));
    let hasMedSuppNc = lic.license_loa.some((loa) => loa.loa_types.some((name) => name === "medsupp (nc)"));
    let hasfraternalNMLife = lic.license_loa.some((loa) =>
      loa.loa_types.some((name) => name === "fraternal life (nm)")
    );
    let hasfraternalNMHealth = lic.license_loa.some((loa) =>
      loa.loa_types.some((name) => name === "fraternal health (nm)")
    );

    if (lic.state === "NC") {
      if (hasMedSuppNc && hasHealth && hasLife) setStateColor(obj, lic.state, { fill: LicenseColors.colors.full });
      else if (hasMedSuppNc || hasHealth || hasLife) setStateColor(obj, lic.state, { fill: LicenseColors.colors.partial });
      return;
    }

    if (lic.state === "NM") {
      if (hasfraternalNMLife && hasfraternalNMHealth && hasHealth && hasLife)
        setStateColor(obj, lic.state, { fill: LicenseColors.colors.full });
      else if (hasfraternalNMLife || hasfraternalNMHealth || hasHealth || hasLife)
        setStateColor(obj, lic.state, { fill: LicenseColors.colors.partial });
      return;
    }

    // NY work around. life broker class license should cause status to be grey
    if (lic.state === "NY" && lic.license_class_code !== 134) {
      setStateColor(obj, lic.state, { fill: LicenseColors.colors.nothing });
      return;
    }

    setStateColor(obj, lic.state, {
      fill:
        hasHealth && hasLife
          ? LicenseColors.colors.full
          : hasHealth || hasLife
            ? LicenseColors.colors.partial
            : LicenseColors.colors.nothing,
    });
  }); 

  return obj;
}

// sometimes there are mutliple licesnes per state. just assume the highest color value is accurate
function setStateColor(obj: { [key: string]: any }, state: string, fillObj: any) {
  if (fillObj.fill === LicenseColors.colors.full)
    obj[state] = fillObj;

  if (fillObj.fill === LicenseColors.colors.partial)
    if (!obj[state] || obj[state].fill === LicenseColors.colors.nothing)
      obj[state] = fillObj;
}
