import React from "react";
import ApplicationPageProps from "common/models/ApplicationPageProps";
import { Tabs } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import CarriersApp from "./components/childcarrier/CarriersApp";
import TimesheetUpload from "./components/timesheet/TimesheetUpload";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import UserPermissionsPage from "./components/user-management/user-permissions";
import RolePermissionsPage from "./components/user-management/role-permissions";

const { TabPane } = Tabs;

interface AdminAppsProps extends ApplicationPageProps {}

export default function AdminApp(props: AdminAppsProps): JSX.Element {
  const { user } = props;

  return (
    <Paragraph className="container border bg-white p-4 shadow-sm border mt-4">
      <Tabs type="card" id="AdminAppTabs" style={{ overflow: "visible" }}>
        {user.hasPermission(EnsUserPermissionType.AdminEmployeePermissions) && (
          <TabPane tab="User Management" key="User Management">
            <Tabs type="card" id="PermissionsAppTabs" style={{ overflow: "visible" }}>
              <TabPane tab="User Permissions" key="User Permissions">
                <UserPermissionsPage />
              </TabPane>
              <TabPane tab="Role Permissions" key="Role Permissions">
                <RolePermissionsPage />
              </TabPane>
            </Tabs>
          </TabPane>
        )}
        {user.hasPermission(EnsUserPermissionType.AdminAccess) && (
          <TabPane tab="Carriers" key="Carriers">
            <CarriersApp user={user} />
          </TabPane>
        )}
        {user.hasPermission(EnsUserPermissionType.TimesheetUploadAccess) && (
          <TabPane tab="Timesheet Upload" key="Timesheet Upload">
            <TimesheetUpload user={user} />
          </TabPane>
        )}
      </Tabs>
    </Paragraph>
  );
}
