import Paragraph from "antd/lib/typography/Paragraph";
import Loader from "common/components/ApiLoader";
import ApiServerService, {
  EnsUser,
  EnsUserPermissionTypeTid,
  FindUserRequestType,
  UserClient,
} from "common/services/ApiServerService";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import LicenseAuthorizedDisplay from "licensing/components/LicenseAuthorizedDisplay";
import React, { useEffect, useState } from "react";

export default function LicenseApp(props: ApplicationPageProps) {
  const { user } = props;
  const [selectedAgent, setSelectedAgent] = useState<EnsUser | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const searchAgent = (idType: FindUserRequestType, id: string, inactive: boolean | undefined) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoading(true);
      return c.findUser(idType, id, inactive);
    })
      .then((response: EnsUser[]) => {
        if (!response) {
          setDisplayError(true);
          return;
        }
        setDisplayError(false);
        setSelectedAgent(response[0]);
      })
      .catch(() => {
        setLoading(false);
        setDisplayError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const readOnlyAccess = !user.hasPermission(EnsUserPermissionType.LicensingEditAccess);

  useEffect(() => {
    if (readOnlyAccess) {
      searchAgent(FindUserRequestType.Email, user.UserEmail, false);
    }
  }, [readOnlyAccess, user.UserEmail]);

  return (
    <Paragraph className="container border bg-white p-4 shadow-sm border mt-4">
      {!readOnlyAccess || selectedAgent ? (
        <LicenseAuthorizedDisplay
          user={user}
          loadingResults={loading}
          searchAgent={searchAgent}
          selectedUser={selectedAgent!}
          showError={displayError}
        />
      ) : (
        <Loader />
      )}
    </Paragraph>
  );
}
