import React, { useState, useEffect } from "react";
import Select, { ValueType } from "react-select";

interface RtsYearDropdownProps {
  onYearSelected: (year: number) => void;
}

type OptionType = { label: string; value: number };

export default function RtsYearDropdown(props: RtsYearDropdownProps) {
  const [years, setYears] = useState<OptionType[]>();

  // get unique years from all appointments. mendokusai...
  useEffect(() => {
    setYears([
      { label: "2022", value: 2022 },
      { label: "2023", value: 2023 },
    ]);
  }, []);

  return (
    <Select
      options={years}
      placeholder={"Select year"}
      onChange={(selectedOption: ValueType<OptionType>) => {
        const value = (selectedOption as OptionType).value;
        props.onYearSelected(value);
      }}
    />
  );
}
