import React, { useState } from "react";
import { Col, Row, Upload, Button, message, Table, Tabs, Divider } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib/upload";
import { IPreSignedUrlResponse } from "common/services/microservices/basic-client";
import { authenticatedClient } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { TaskRunRequest, TaskRunResponse, TasksClient } from "common/services/microservices/cron-boss-client";
import { UploadSheetForm } from "./UploadSheetForm";
import UserClass from "core/models/UserClass";
import CronTaskEventByGuidDisplay from "./CronTaskEventDisplay";
import { MicroserviceUploadRequest } from "operations/common/s3PresignedUploadRequest";
import { Container } from "react-bootstrap";
import {
  UploadCarrierPaymentSaleStatusUpdates,
  UploadCarrierPaymentTaskResponse,
} from "common/services/microservices/finance-client";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";

export interface UploadStatus {
  name?: string;
  done: boolean;
}

interface CommissionUploadProps {
  user: UserClass;
}

export default function CommissionUpload(props: CommissionUploadProps) {
  const [presignedUrl, setPresignedUrl] = useState<IPreSignedUrlResponse>();
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>({ done: false });
  const [processingTaskInfo, setProcessingTaskInfo] = useState<TaskRunResponse>();
  const [currentTaskState, setCurrentTaskState] = useState<number | undefined>(undefined);

  const [taskResult, setTaskResult] = useState<UploadCarrierPaymentTaskResponse | undefined>();

  const goodBoyFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
  ];

  const uploadProps: UploadProps = {
    accept: goodBoyFileTypes.join(", "),
    multiple: false,
    maxCount: 1,
    customRequest: (options) => {
      MicroserviceUploadRequest("CommissionReports", options).then((url) => setPresignedUrl(url));
    },
    onChange(info) {
      console.log(info);
      setProcessingTaskInfo(undefined);
      const status = info.file.status;
      if (status === "uploading" || status === "removed") {
        const name = status === "uploading" ? info.file.name : undefined;
        setUploadStatus({ name: name, done: false });
        if (status === "removed") {
          setPresignedUrl(undefined);
        }
      } else if (status === "done") {
        setUploadStatus({ name: info.file.name, done: true });
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const statusUpdateColumns: ColumnsType<UploadCarrierPaymentSaleStatusUpdates> = [
    {
      title: "Sale Id",
      render: (_: any, record) => record.ens_sale_tid,
    },
    {
      title: "Policy Number",
      render: (_: any, record) => record.policy_number,
    },
    {
      title: "New Sale Status",
      render: (_: any, record) => record.new_sale_status,
    },
    {
      title: "Term Date",
      render: (_: any, record) => moment.utc(record.term_date).format("MM/DD/YYYY"),
    },
  ];

  function startFutureValueTask(spec: Record<string, any>, taskName: string) {
    return authenticatedClient(MicroservicesEndpoint.cronboss, TasksClient)
      .then((client) =>
        client.startTask(
          new TaskRunRequest({
            taskModule: "future-value",
            taskName: taskName,
            specs: spec,
          })
        )
      )
      .then((response) => {
        setProcessingTaskInfo(response);
        message.success(`Started task ${response.guid}`);
      })
      .catch(() => message.success(`Failed to start upload task.`));
  }

  return (
    <>
      <Container>
        <Row gutter={[16, 24]}>
          <Col offset={6}>
            <h3>Upload a Report</h3>
            <b>Sheet Upload Tips</b>
            <ul>
              <li>Make sure the header column is at the top of the sheet</li>
              <li>Ensure only carrier data rows are present in the sheet</li>
              <li>Ensure data rows are consecutive with no row gaps</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <hr />
      <Row gutter={[16, 24]} className="my-3">
        <Col offset={6} span={4}>
          Select a File
        </Col>
        <Col span={7}>
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
              Browse
            </Button>
          </Upload>
        </Col>
      </Row>
      {uploadStatus.done && presignedUrl?.objectGuid !== undefined && (
        <>
          <UploadSheetForm
            presignedUrl={presignedUrl}
            uploadStatus={uploadStatus}
            currentTaskState={currentTaskState}
            onSubmit={startFutureValueTask}
          />
          {processingTaskInfo && (
            <Row style={{ justifyContent: "center" }} className="my-4">
              <CronTaskEventByGuidDisplay
                onTaskStateChanged={setCurrentTaskState}
                onTaskFinished={(res) => {
                  if (res) setTaskResult(UploadCarrierPaymentTaskResponse.fromJS(res));
                }}
                guid={processingTaskInfo.guid}
                showErrorMesssages
              />
            </Row>
          )}
          {taskResult && (
            <>
              <Divider />
              <Col style={{ marginLeft: "20px", marginRight: "20px", marginBottom: "20px" }}>
                <h3>Task Results</h3>
                <Tabs>
                  <Tabs.TabPane tab="Sale Status Updates" key="item-1">
                    {taskResult.sales_report && taskResult.sales_report.length > 0 ? (
                      <Table
                        style={{ marginTop: "1em" }}
                        size="small"
                        dataSource={taskResult.sales_report}
                        columns={statusUpdateColumns}
                        scroll={{ x: "max-content", y: 600 }}
                        bordered
                        pagination={{
                          total: taskResult.sales_report?.length,
                          pageSize: 40,
                          hideOnSinglePage: true,
                        }}
                      />
                    ) : (
                      "No status updates occured in this upload."
                    )}
                  </Tabs.TabPane>
                  {/* <Tabs.TabPane tab="Cash Matching Issues" key="item-2">
                    {taskResult.matching_errors && taskResult.matching_errors.length > 0 ? (
                      <CashMatchingErrorTable errors={taskResult.matching_errors} />
                    ) : (
                      "There were no cash matching issues this upload."
                    )}
                  </Tabs.TabPane> */}
                </Tabs>
              </Col>
            </>
          )}
        </>
      )}
    </>
  );
}
