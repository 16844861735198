import React from "react";
import "core/scss/Applications.scss";
import Admin from "core/images/logos/admin.svg";
import Operations from "core/images/logos/operations.svg";
import Sales from "core/images/logos/sales.svg";
import Data from "core/images/logos/data.svg";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import E from "core/images/logos/e.svg";
import AuthorizedLink from "common/components/AuthorizedLink";
import Loader from "common/components/ApiLoader";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";

interface ApplicationInfo {
  name: string;
  route: string;
  image: string;
  permissionWhitelist: EnsUserPermissionType[];
  isDashboardApp?: boolean;
}

export default function Applications(props: ApplicationPageProps) {
  const applications: Array<ApplicationInfo> = [
    {
      name: "Admin",
      route: "/applications/admin",
      image: Admin,
      permissionWhitelist: [
        EnsUserPermissionType.AdminAccess,
        EnsUserPermissionType.AdminEmployeePermissions,
        EnsUserPermissionType.TimesheetUploadAccess,
      ],
    },
    {
      name: "Operations",
      route: "/applications/operations",
      image: Operations,
      permissionWhitelist: [EnsUserPermissionType.OperationsAccess],
    },
    {
      name: "Sales",
      route: "/applications/sales",
      image: Sales,
      permissionWhitelist: [EnsUserPermissionType.SalesAccess],
    },
    {
      name: "Data",
      route: "/applications/data",
      image: Data,
      permissionWhitelist: [EnsUserPermissionType.DataAccess],
    },
  ];

  return (
    <section id="apps">
      <div className="container">
        <div
          className="my-4 text-center border bg-white shadow-sm border mt-4 px-5"
          style={{ position: "relative", zIndex: 0 }}
        >
          <div style={{ position: "relative", zIndex: 2 }}>
            <h1 className="mt-3 iap-header">
              <div className="text-center">
                INT
                <img src={E} alt="" />
                RNAL APPS PORTAL
              </div>
            </h1>
            <div className="text-center iap-slogan mb-5">The American Dream is still alive</div>
          </div>

          <svg
            className="svgHeader"
            style={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}
            width="100%"
            height="120"
            viewBox="0.1 0.1 180 40"
            preserveAspectRatio="none"
          >
            <g transform="translate(-18.298844,-77.973964)">
              <path
                className="svgHeader"
                style={{ fill: "#a3ceff" }}
                d="M 31.615583,86.351641 H 192.16499 v 26.901969 c 0,0 -32.03411,-14.237983 -59.62682,-12.72484 -22.34188,1.2252 -54.779359,9.72634 -54.779359,9.72634 0,0 -22.029534,3.62882 -34.471238,-1.88988 -12.441702,-5.51871 -11.67199,-22.013589 -11.67199,-22.013589 z"
              />
              <path
                className="svgHeader"
                style={{ fill: "#fbfbfb" }}
                d="M 18.441597,78.106256 H 198.58126 v 39.288614 c 0,0 -43.10672,-27.825245 -73.47599,-19.687823 -30.369264,8.137423 -46.832208,12.548653 -46.832208,12.548653 0,0 -32.775418,8.05972 -46.735258,0 C 17.577964,102.19598 18.441597,78.106256 18.441597,78.106256 Z"
              />
            </g>
          </svg>

          {props.user.UserPermissions !== undefined ? (
            <div id="appList" className="row justify-content-center ">
              {applications
                .filter((app) => app.permissionWhitelist.some((p) => props.user.hasPermission(p)))
                .map((app) => (
                  <div key={app.name} className="col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 p-3">
                    {!app.isDashboardApp ? (
                      <AuthorizedLink authorized={true} id={"app-" + app.name} to={app.route} className="cool-link">
                        <img className="iconSizeApp" alt={`${app.name}`} src={app.image} />
                        <div className="mt-3 app-name">{app.name}</div>
                      </AuthorizedLink>
                    ) : (
                      <a className="cool-link" id={"app-" + app.name} href={`/portal${app.route}`}>
                        <img className="iconSizeApp" alt={`${app.name}`} src={app.image} />
                        <div className="mt-3 app-name">{app.name}</div>
                      </a>
                    )}
                  </div>
                ))}
            </div>
          ) : (
            <Loader />
          )}

          <div className="my-14 mt-4 app-name">Need access? Contact your manager for assistance.</div>

          <div className="my-4" style={{ height: "50px" }}></div>

          <div style={{ left: 0, bottom: 0, width: "100%" }} className="text-center mt-4">
            <svg
              className="wave-footer"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 24 150 28 "
              preserveAspectRatio="none"
            >
              <defs>
                <path
                  id="suave-wave"
                  d="M-160 44c30 0
                      58-18 88-18s
                      58 18 88 18
                      58-18 88-18
                      58 18 88 18
                      v44h-352z"
                />
              </defs>
              <g className="parallax1">
                <use xlinkHref="#suave-wave" x="50" y="3" fill="#A3CEFF" />
              </g>
              <g className="parallax2">
                <use xlinkHref="#suave-wave" x="50" y="0" fill="#D9FFE8" />
              </g>
              <g className="parallax3">
                <use xlinkHref="#suave-wave" x="50" y="9" fill="#DBE9FF" />
              </g>
              <g className="parallax4">
                <use xlinkHref="#suave-wave" x="50" y="6" fill="#fff" />
              </g>
            </svg>
            <div
              className=" wave-text mb-3"
              style={{ position: "relative", top: 0, zIndex: 100 }}
              text-align="center"
              color="#777"
            >
              Created with <span style={{ color: "red" }}>&#9829;</span> by{" "}
              <img style={{ width: "15px", height: "auto" }} src={E} alt="" />
              ngineers in <span style={{ color: "orange" }}>&#9788;</span> Largo, FL
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
