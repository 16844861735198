import { useState, useEffect } from "react";
import UserClass from "core/models/UserClass";
import ApiServerService, { MicroservicesEndpoint, UserClient } from "common/services/ApiServerService";
import { connect } from "react-refetch";
import packagejson from "../../../package.json";
import { PermissionsClient, RolesClient, UserInfoClient } from "common/services/microservices/ensusers-client";
import {
  AccountInfo,
  AuthenticationResult,
  EventMessage,
  EventType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";

const Ids = {
  // The ID given to Ensurem LLC on Azure AD.
  EnsuremTenantId: "d95d1c36-c612-41d0-93fd-035eb9e03275",

  // The Client ID of the Ensurem Portal registration. We will request a token
  // as this user.
  EsuremPortalClientId: "b327e78c-a953-4e66-9d0b-d7cd1decf788",

  // The Client ID of the Ensurem API Server registration. We will request user access
  // to this client as a user in authorization.
  MicroservicesClientId: "7c2e51c6-be05-4884-b26d-e1b6bca99742",
};

// MSAL NEW
const msalConfig = {
  auth: {
    clientId: Ids.EsuremPortalClientId,
    authority: `https://login.microsoftonline.com/${Ids.EnsuremTenantId}`,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

const acquireAuth = async (instance: IPublicClientApplication) => {
  const loginRequest = {
    scopes: [`api://${Ids.MicroservicesClientId}/access_as_user`],
  };

  return instance.acquireTokenSilent(loginRequest).catch((e) => instance.acquireTokenPopup(loginRequest));
};
// MSAL NEW

type GeneratedClientClass<T> = {
  new (baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }): T;
};

export async function simpleAuthenticatedFetch(url: string, init: RequestInit): Promise<Response> {
  const tokenResponse = await acquireAuth(msalInstance);

  const authorizedInit: RequestInit = {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${tokenResponse.accessToken}`,
    },
  };

  return fetch(url, authorizedInit);
}

//
// Provides a wrapper around a Swagger-Generated TypeScript SDK to add the Bearer JWT
// to the request header.
//
export async function authenticatedClient<T>(endpoint: string, Client: GeneratedClientClass<T>): Promise<T> {
  const tokenResponse = await acquireAuth(msalInstance);
  const authorizedHttp = {
    fetch: (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      const authorizedInit: RequestInit = {
        ...init,
        headers: {
          ...init?.headers,
          Authorization: `Bearer ${tokenResponse.accessToken}`,
        },
      };
      return fetch(url, authorizedInit);
    },
  };
  return new Client(endpoint, authorizedHttp);
}

//
// Provides a promise with a Swagger-generated fetch method, authenticated with the user's JWT token
// This is primarily for use in react-refetch to integrate the client with it.
//
export async function authenticatedFetch<TClientType, TReturnType>(
  endpoint: string,
  client: GeneratedClientClass<TClientType>,
  requestMethod: (client: TClientType) => Promise<TReturnType>
): Promise<TReturnType> {
  const authClient = await authenticatedClient(endpoint, client);
  return requestMethod(authClient);
}

//
// Provides a react-refetch connect function to add the Bearer JWT
// to the request header.
//
export const authenticatedConnect = connect.defaults({
  fetch: async (request: Request) => {
    const tokenResponse = await acquireAuth(msalInstance);
    request.headers.append("Authorization", `Bearer ${tokenResponse.accessToken}`);
    return fetch(request);
  },
});

//
// A hook that provides the application with the authentication state of the user, and handles
// retrieving the user's permissions.
// This should only be used in one spot in the application. Effects of using it in multiple places
// are unknown.
//
export function useAuthProvider() {
  const [activeAccount, setActiveAccount] = useState<AccountInfo | null>(null);
  const [currentUser, setCurrentUser] = useState<UserClass>();

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) setActiveAccount(accounts[0]);

    const evt = msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType != EventType.LOGIN_SUCCESS || !event.payload) return;

      const payload = event.payload as AuthenticationResult;
      const account = payload.account;

      setActiveAccount(account);
    });

    return () => {
      if (evt) msalInstance.removeEventCallback(evt);
    };
  }, []);

  useEffect(() => {
    msalInstance.setActiveAccount(activeAccount);

    if (!activeAccount) {
      setCurrentUser(undefined);
      return;
    }

    // load user permissions
    async function updateCurrentUser(account: AccountInfo) {
      const user = new UserClass(
        (account.idTokenClaims as any)["preferred_username"],
        (account.idTokenClaims as any)["name"]
      );
      setCurrentUser(user);
      const userInfoClient = await authenticatedClient(MicroservicesEndpoint.ensUsers, UserInfoClient);
      const userInfo = await userInfoClient.getBasicInfo(undefined, user.UserEmail);
      setCurrentUser(new UserClass(user.UserEmail, user.UserName, userInfo));
    }

    updateCurrentUser(activeAccount);
  }, [activeAccount]);

  async function onSignIn() {
    return msalInstance.loginRedirect({
      scopes: [`api://${Ids.MicroservicesClientId}/access_as_user`],
    });
  }

  function onSignOut() {
    return msalInstance.logoutRedirect();
  }

  return {
    isAuthenticated: currentUser !== undefined,
    currentUser,
    onSignIn: () => onSignIn(),
    onSignOut: () => onSignOut(),
  };
}
