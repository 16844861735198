import { message } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { WorkStateType, IWorkEvent, WorkClient } from "common/services/microservices/basic-client";
import { waitFor } from "common/utilities/waitFor";
import { authenticatedClient } from "core/components/AuthProvider";
import { useState } from "react";

export function useWorkWaiter() {
  const [taskState, setTaskState] = useState<WorkStateType>();
  const [taskEvents, setTaskEvents] = useState<IWorkEvent[]>([]);
  const [resultDownloadUrl, setResultDownloadUrl] = useState<string>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState(0);

  async function startWork(startWorkFn: () => Promise<string>, filename: string, startMessage?: string) {
    try {
      setLoading(true);
      setTaskState(undefined);
      setResultDownloadUrl(undefined);

      const guid = await startWorkFn();

      if (guid) {
        message.success(startMessage ?? "Started task. This may take some time");
      }

      const workClient = await authenticatedClient(MicroservicesEndpoint.basic, WorkClient);

      await waitFor(async () => {
        const state = await workClient.getWorkState(guid);
        const events = await workClient.getWorkEvents(guid);
        if (state.state === WorkStateType.Failed) throw "Getting report failed";
        setProgress(state.progress ?? 0);
        setTaskState(state.state);
        setTaskEvents(events);
        return state.state === WorkStateType.Success;
      });

      const url = await workClient.getWorkResultDownloadUrl(guid, filename, 60);

      setResultDownloadUrl(url.presignedUrl);

      setTaskState(WorkStateType.Success);
    } catch (ex) {
      setTaskState(WorkStateType.Failed);
      message.error("Unable to complete task. Please contact engineering.");
    } finally {
      setLoading(false);
    }
  }

  function downloadResult() {
    window.open(resultDownloadUrl, "_blank");
  }

  return {
    startWork,
    taskState,
    taskEvents,
    progress,
    isLoading,
    downloadResult,
  };
}
