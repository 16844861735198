import React, { useEffect, useState } from "react";
import { Form, Row, Input, Button, DatePicker, Col } from "antd";
import AgentSelect from "./AgentSelect";
import CarrierSelect from "common/components/CarrierSelect";
import { EnsProductCarrierTid, EnsProductTid } from "common/services/ApiServerClient";
import ProductSelect from "../../../common/components/ProductSelect";
import StatusSelect from "./StatusSelect";
import { authenticatedClient, authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { SearchOutlined, StopOutlined } from "@ant-design/icons";
import StateSelect from "common/components/StateSelect";
import "../scss/SalesStatusForm.scss";
import { EnsUserInfo } from "common/services/microservices/ensusers-client";
import { SalesStatusClient, SalesStatusSearchRequest, SaleStatus } from "common/services/microservices/sales-client";

interface StalesStatusFormsProps {
  filterFetchData: (data: SaleStatus[]) => void;
  setTableLoad: (clicked: boolean) => void;
  onInsertApiFail: (failed: boolean) => void;
}

const dateFormat = "MM/DD/YYYY";

export default function SalesStatusForms(props: StalesStatusFormsProps) {
  const { filterFetchData, setTableLoad, onInsertApiFail } = props;
  const [form] = Form.useForm();
  const [clearSelects, setClearSelects] = useState<number>();
  const [agentSelected, setAgentSelected] = useState<number | null>();
  const [referralAgent, setReferralAgent] = useState<number | null>();
  const [carrierSelected, setCarrierSelected] = useState<EnsProductCarrierTid>();
  const [productSelected, setProductSelected] = useState<EnsProductTid>();
  const [issueStatus, setIssueStatus] = useState<number>();
  const [currentStatus, setCurrentStatus] = useState<number>();
  const [insuredFirstName, setInsuredFirstName] = useState<string>();
  const [insuredLastName, setInsuredLastName] = useState<string>();
  const [insuredPhoneNumber, setInsuredPhoneNumber] = useState<string>();
  const [saleId, setSaleId] = useState<number>();
  const [startDate, setStartDate] = useState<moment.Moment>();
  const [endDate, setEndDate] = useState<moment.Moment>();
  const [repPaidStartDate, setRepPaidStartDate] = useState<moment.Moment>();
  const [repPaidEndDate, setRepPaidEndDate] = useState<moment.Moment>();
  const [approvalStartDate, setApprovalStartDate] = useState<moment.Moment>();
  const [approvalEndDate, setApprovalEndDate] = useState<moment.Moment>();
  const [insuredState, setInsuredState] = useState<string>();
  const [terminationDate, setTerminationDate] = useState<moment.Moment>();
  const [policyNumber, setPolicyNumber] = useState<string>();
  const [medicareId, setMedicareId] = useState<string>();
  const [opportunityId, setOpportunityId] = useState<string>();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);

  useEffect(() => {
    //resets states when clear is selected
    setAgentSelected(undefined);
    setReferralAgent(undefined);
    setCarrierSelected(undefined);
    setProductSelected(undefined);
    setIssueStatus(undefined);
    setCurrentStatus(undefined);
    setInsuredFirstName(undefined);
    setInsuredLastName(undefined);
    setInsuredPhoneNumber(undefined);
    setSaleId(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setRepPaidStartDate(undefined);
    setRepPaidEndDate(undefined);
    setApprovalStartDate(undefined);
    setApprovalEndDate(undefined);
    setInsuredState(undefined);
    setTerminationDate(undefined);
    setPolicyNumber(undefined);
    setMedicareId(undefined);
    setOpportunityId(undefined);
  }, [clearSelects]);

  useEffect(() => {
    //un-renders status alert upon change of any field
    onInsertApiFail(false);
  }, [
    agentSelected,
    referralAgent,
    carrierSelected,
    productSelected,
    issueStatus,
    currentStatus,
    insuredFirstName,
    insuredLastName,
    insuredPhoneNumber,
    saleId,
    startDate,
    endDate,
    repPaidStartDate,
    repPaidEndDate,
    approvalStartDate,
    approvalEndDate,
    insuredState,
    terminationDate,
    policyNumber,
    medicareId,
    opportunityId,
    onInsertApiFail,
  ]);

  const onFilter = async () => {
    setTableLoad(true);
    onInsertApiFail(false);
    try {
      const salesClient = await authenticatedClient(MicroservicesEndpoint.sales, SalesStatusClient);
      let request = new SalesStatusSearchRequest({
        ensSaleTid: saleId ?? undefined,
        agentId: agentSelected ?? undefined,
        carrierId: carrierSelected ?? undefined,
        startDateUnixMilli: startDate == null ? undefined : startDate?.toDate().getTime(),
        endDateUnixMilli: endDate == null ? undefined : endDate?.toDate().getTime(),
        issueSaleStatus: issueStatus ?? undefined,
        currentSaleStatus: currentStatus ?? undefined,
        productId: productSelected ?? undefined,
        referralAgent: referralAgent ?? undefined,
        insuredFirstName: insuredFirstName ?? undefined,
        insuredLastName: insuredLastName ?? undefined,
        insuredPhoneNumber: insuredPhoneNumber ?? undefined,
        repPaidStartDateUnixMilli: repPaidStartDate == null ? undefined : repPaidStartDate?.toDate().getTime(),
        repPaidEndDateUnixMilli: repPaidEndDate == null ? undefined : repPaidEndDate?.toDate().getTime(),
        approvalStartDateUnixMilli: approvalStartDate == null ? undefined : approvalStartDate?.toDate().getTime(),
        approvalEndDateUnixMilli: approvalEndDate == null ? undefined : approvalEndDate?.toDate().getTime(),
        insuredState: insuredState ?? undefined,
        terminationDateUnixMilli: terminationDate == null ? undefined : terminationDate?.toDate().getTime(),
        policyNumber: policyNumber ?? undefined,
        medicareId: medicareId ?? undefined,
        getSaleNotes: true,
        opportunityId: opportunityId ?? undefined,
      });
      const salesSearched = await salesClient.searchSales(request);
      filterFetchData(salesSearched);
    } catch {
      onInsertApiFail(true);
    } finally {
      setTableLoad(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 4 },
  };

  const formSpacing = {
    width: 200,
    margin: 10,
  };

  return (
    <>
      <Form {...layout} form={form} name="salesStatusForms" size="small">
        <Row className="my-3">
          <Col span={8}>
            <Form.Item label="Agent">
              <AgentSelect
                onAgentSelected={(agentId: number | undefined) => setAgentSelected(agentId)}
                clear={clearSelects}
                style={formSpacing}
                additionalAgents={[
                  {
                    ensUserTid: 8,
                    userName: "DTC@insuretysolutions.com",
                    userFirstName: "DTC",
                    userLastName: "DTC",
                    userEmail: "DTC@insuretysolutions.com",
                    active: false,
                  } as EnsUserInfo,
                  {
                    ensUserTid: 11,
                    userName: "Coverance@insuretysolutions.com",
                    userFirstName: "Coverance",
                    userLastName: "Coverance",
                    userEmail: "Coverance@insuretysolutions.com",
                    active: false,
                  } as EnsUserInfo,
                ]}
              />
            </Form.Item>
            <Form.Item label="Carrier">
              <CarrierSelect
                onCarrierSelected={(carrierId: number) => setCarrierSelected(carrierId)}
                clear={clearSelects}
                style={formSpacing}
              />
            </Form.Item>
            <Form.Item label="Product">
              <ProductSelect
                onProductSelected={(productId: number) => setProductSelected(productId)}
                clear={clearSelects}
                style={formSpacing}
              />
            </Form.Item>
            <Form.Item label="State">
              <StateSelect
                onStateSelect={(state: string) => setInsuredState(state)}
                disableTerritories={true}
                clear={clearSelects}
                placeholder={"Select state"}
                size={"small"}
                style={formSpacing}
              />
            </Form.Item>
            <Form.Item label="Sale ID">
              <Input
                style={formSpacing}
                placeholder="Sale ID"
                onChange={(e: any) => setSaleId(e.target.value.replace(/\D/g, ""))}
                value={saleId}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="First name">
              <Input
                style={formSpacing}
                value={insuredFirstName}
                placeholder="Insured first name"
                onChange={(e) => setInsuredFirstName(e.target.value)}
              ></Input>
            </Form.Item>
            <Form.Item label="Last name">
              <Input
                style={formSpacing}
                value={insuredLastName}
                placeholder="Insured last name"
                onChange={(e) => setInsuredLastName(e.target.value)}
              ></Input>
            </Form.Item>
            <Form.Item label="Termination date">
              <DatePicker
                style={formSpacing}
                value={terminationDate}
                format={dateFormat}
                placeholder="Select termination date"
                onChange={(e: any) => setTerminationDate(e)}
              ></DatePicker>
            </Form.Item>
            <Form.Item label="Issue status">
              <StatusSelect
                onStatusSelected={(statusId: number) => setIssueStatus(statusId)}
                issueStatus={true}
                clear={clearSelects}
                style={formSpacing}
                placeholder="Select issue status"
                formDisplay={true}
              />
            </Form.Item>
            <Form.Item label="Current status">
              <StatusSelect
                onStatusSelected={(statusId: number) => setCurrentStatus(statusId)}
                clear={clearSelects}
                placeholder="Select current status"
                style={formSpacing}
                formDisplay={true}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Phone Number">
              <Input
                style={formSpacing}
                value={insuredPhoneNumber}
                placeholder="Isured Phone Number"
                onChange={(e) => setInsuredPhoneNumber(e.target.value)}
              ></Input>
            </Form.Item>
            <Form.Item label="Sale start date">
              <DatePicker
                style={formSpacing}
                value={startDate}
                format={dateFormat}
                placeholder="Select sales start"
                onChange={(e: any) => setStartDate(e)}
              ></DatePicker>
            </Form.Item>
            <Form.Item label="Sale end date">
              <DatePicker
                style={formSpacing}
                value={endDate}
                format={dateFormat}
                placeholder="Select sales end"
                onChange={(e: any) => setEndDate(e)}
              ></DatePicker>
            </Form.Item>
            <Form.Item label="Approval start date">
              <DatePicker
                style={formSpacing}
                value={approvalStartDate}
                format={dateFormat}
                placeholder="Select approval start date"
                onChange={(e: any) => setApprovalStartDate(e)}
              ></DatePicker>
            </Form.Item>
            <Form.Item label="Approval end date">
              <DatePicker
                style={formSpacing}
                value={approvalEndDate}
                format={dateFormat}
                placeholder="Select approval end date"
                onChange={(e: any) => setApprovalEndDate(e)}
              ></DatePicker>
            </Form.Item>
            <Form.Item label="Advanced options?" style={{ marginTop: "10px" }}>
              <Button type="link" onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}>
                {showAdvancedSearch ? "Collapse advanced" : "Show advanced"}
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {showAdvancedSearch && (
          <Row>
            <Col span={8}>
              <Form.Item label="Referral agent">
                <AgentSelect
                  onAgentSelected={(agentId: number | undefined) => setReferralAgent(agentId)}
                  clear={clearSelects}
                  referralAgentSelect={true}
                  style={{ width: 200, margin: 10 }}
                />
              </Form.Item>
              <Form.Item label="Policy Number">
                <Input
                  style={formSpacing}
                  value={policyNumber}
                  placeholder="Policy Number"
                  onChange={(e: any) => setPolicyNumber(e.target.value)}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Rep paid start date">
                <DatePicker
                  style={formSpacing}
                  value={repPaidStartDate}
                  format={dateFormat}
                  placeholder="Select rep paid start date"
                  onChange={(e: any) => setRepPaidStartDate(e)}
                ></DatePicker>
              </Form.Item>
              <Form.Item label="Medicare ID">
                <Input
                  style={formSpacing}
                  value={medicareId}
                  placeholder="Medicare ID"
                  onChange={(e: any) => setMedicareId(e.target.value)}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Rep paid end date">
                <DatePicker
                  style={formSpacing}
                  value={repPaidEndDate}
                  format={dateFormat}
                  placeholder="Select rep paid end date "
                  onChange={(e: any) => setRepPaidEndDate(e)}
                ></DatePicker>
              </Form.Item>
              <Form.Item label="Opportunity ID">
                <Input
                  style={formSpacing}
                  value={opportunityId}
                  placeholder="Opportunity ID"
                  onChange={(e) => setOpportunityId(e.target.value)}
                ></Input>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      <Row style={{ justifyContent: "center", alignItems: "center" }}>
        <Button
          type="primary"
          style={{ width: 150, margin: 10 }}
          onClick={onFilter}
          icon={<SearchOutlined style={{ verticalAlign: "inherit" }} />}
        >
          Search
        </Button>
        <Button
          type="primary"
          style={{ width: 150, margin: 10 }}
          icon={<StopOutlined style={{ verticalAlign: "inherit" }} />}
          onClick={() => {
            form.resetFields();
            setClearSelects(Date.now()); //Allows clearing of dropdowns when the time changes
            onInsertApiFail(false); //Unrenders status alert on button clicks
          }}
        >
          Clear
        </Button>
      </Row>
    </>
  );
}
