import packagejson from "../../../package.json";

// To make this work, because we're using create-react-app, we need to enable
// importing outside of the src directory, as well as including this location in Babel compilation.
// https://github.com/facebook/create-react-app/issues/8143#issuecomment-566523002
export * from "./ApiServerClient";

export const ApiServerService = {
  endpoint: cleanUrlPath(
    process.env.REACT_APP_APISERVER_ENDPOINT ?? packagejson["config"][process.env.NODE_ENV]["APISERVER_ENDPOINT"]
  ),
  endpoints: {
    getUserPermissions: (userEmail: string) =>
      `${ApiServerService.endpoint}/api/portal/user/email/${userEmail}/permissions_roles`,
    updateUserRole: (userTid: number, roleTid: number) =>
      `${ApiServerService.endpoint}/api/portal/user/ens_user_tid/${userTid}/roles/${roleTid}`,
  },
};

const MicroservicesApi =
  process.env.REACT_APP_MICROSERVICES_ENDPOINT ?? packagejson["config"][process.env.NODE_ENV]["MICROSERVICES_ENDPOINT"];

// also remove double foward slashes in url path, those are a nuisance
// no trailing forward slashes!
export const MicroservicesEndpoint = {
  ensUsers: cleanUrlPath(`${MicroservicesApi}/ensusers`),
  basic: cleanUrlPath(`${MicroservicesApi}/basic`),
  callAnalytics: cleanUrlPath(`${MicroservicesApi}/call-analytics`),
  cronboss: cleanUrlPath(`${MicroservicesApi}/cron-boss`),
  leads: cleanUrlPath(`${MicroservicesApi}/leads`),
  five9: cleanUrlPath(`${MicroservicesApi}/five9`),
  licenses: cleanUrlPath(`${MicroservicesApi}/licenses`),
  marketing: cleanUrlPath(`${MicroservicesApi}/marketing`),
  logging: cleanUrlPath(`${MicroservicesApi}/logging`),
  dataDictionary: cleanUrlPath(`${MicroservicesApi}/data-dictionary`),
  finance: cleanUrlPath(`${MicroservicesApi}/finance`),
  sales: cleanUrlPath(`${MicroservicesApi}/sales`),
  sunfire: cleanUrlPath(`${MicroservicesApi}/sunfire`),
};

function cleanUrlPath(url: string) {
  return url.replace(/([^:]\/)\/+/g, "$1");
}

export default ApiServerService;
