import { Button, Col, Modal, Row } from "antd";
import React, { FunctionComponent } from "react";

interface MapModalProps {
  displayLicense: boolean;
  selectedState: string | undefined;
  closeModal: (setState: undefined) => void;
}

export const MapModal: FunctionComponent<MapModalProps> = (props) => {
  const { selectedState, closeModal, children } = props;

  return (
    <Modal
      width="50%"
      centered
      title={selectedState}
      visible={selectedState !== undefined}
      onCancel={() => closeModal(undefined)}
      footer={[
        <Button type="primary" onClick={() => closeModal(undefined)}>
          Close
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>{children}</Col>
      </Row>
    </Modal>
  );
};
