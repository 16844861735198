import React from "react";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { SaleStatus } from "common/services/microservices/sales-client";

export const CashReceiptTableColumns: ColumnsType<SaleStatus> = [
  {
    title: () => <span>Sale ID</span>,
    defaultSortOrder: "descend" as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.ens_sale_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) =>
      a.ens_sale_tid < b.ens_sale_tid ? 1 : -1,
  },
  {
    title: () => <span>First Name</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_first_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_first_name ?? "").localeCompare(b.insured_first_name ?? "");
    },
  },
  {
    title: () => <span>Last Name</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.insured_last_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.insured_last_name ?? "").localeCompare(b.insured_last_name ?? "");
    },
  },
  {
    title: () => <span>Carrier</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => record.product_carrier_name,
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.product_carrier_name ?? "").localeCompare(b.product_carrier_name ?? "");
    },
  },
  {
    title: () => <span>Product</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.product_name;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.product_name ?? "").localeCompare(b.product_name ?? "");
    },
  },
  {
    title: () => <span>Total Carrier Payment Amount</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `$${record.total_carrier_payment?.toLocaleString("en-US", { minimumFractionDigits: 2 }) || '0'}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.total_carrier_payment ?? 0) < (b.total_carrier_payment ?? 0) ? 1 : -1;
    },
    width: 120,
  },
  {
    title: () => <span>Last Payment Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.last_carrier_payment_date?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.last_carrier_payment_date ?? 0) < new Date(b.last_carrier_payment_date ?? 0) ? 1 : -1;
    },
  },
  {
    title: () => <span>Last Payment Effective Date</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.last_payment_effective_date?.toLocaleDateString("en-US");
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return new Date(a.last_payment_effective_date ?? 0) < new Date(b.last_payment_effective_date ?? 0) ? 1 : -1;
    },
  }
];