import React, { useEffect, useState } from "react";
import { Select, message, Alert } from "antd";
import { authenticatedClient } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { IWorksheetInformationResponse, SpreadsheetClient } from "common/services/microservices/finance-client";
import { IPreSignedUrlResponse, WorkClient, WorkStateType } from "common/services/microservices/basic-client";
import { waitFor } from "common/utilities/waitFor";

interface SpreadsheetSheetSelectProps {
  sheetPresignedUrl: IPreSignedUrlResponse;
  onSheetSelected: (value: string) => void;
  spreadsheetInfo: IWorksheetInformationResponse | undefined;
  setSpreadsheetInfo: (value: IWorksheetInformationResponse) => void;
}

export function SpreadsheetSheetSelect(props: SpreadsheetSheetSelectProps) {
  const { sheetPresignedUrl, onSheetSelected, spreadsheetInfo, setSpreadsheetInfo } = props;

  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);

  async function loadSpreadsheetInformation() {
    setLoading(true);

    const financeClient = await authenticatedClient(MicroservicesEndpoint.finance, SpreadsheetClient);
    const basicClient = await authenticatedClient(MicroservicesEndpoint.basic, WorkClient);

    const { guid } = await financeClient.startWorksheetInformationTask(
      sheetPresignedUrl.objectGuid,
      sheetPresignedUrl.objectPrefix!
    );

    await waitFor(async () => {
      let state = await basicClient.getWorkState(guid);

      if (state.state === WorkStateType.Failed) {
        message.error(`Failed to load spreadsheet info for sheet ${sheetPresignedUrl.objectGuid}`);
        setFailed(true);
        throw "Wow";
      }

      return state.state === WorkStateType.Success;
    });

    const resultUrl = await basicClient.getWorkResultDownloadUrl(guid, null, null);
    let result = await fetch(resultUrl.presignedUrl);
    let resultData: IWorksheetInformationResponse = await result.json();

    setSpreadsheetInfo(resultData);
    setLoading(false);
  }

  useEffect(() => {
    loadSpreadsheetInformation();
    // eslint-disable-next-line
  }, []);

  const phrases = [
    "Patience is a virtue...",
    "We're working as fast as we can here.",
    "Reaching 88 MPH...",
    "まだまだ",
    "ちょっと待ってね",
    "ｷﾀ━━━━━━(ﾟ∀ﾟ)━━━━━━!",
    "Worksheet names will appear here",
    "Loading...",
  ];

  return (
    <>
      {failed ? (
        <Alert message="The column list failed to load. Please report this to engineering." type="warning" />
      ) : (
        <Select
          loading={loading}
          placeholder={
            props.spreadsheetInfo === undefined
              ? phrases[Math.floor(Math.random() * phrases.length)]
              : "Select Workbook"
          }
          onChange={(value: string) => onSheetSelected(value)}
          style={{ width: "100%" }}
        >
          {spreadsheetInfo?.worksheets.map((sheet, i) => (
            <Select.Option key={i} value={sheet.name}>
              {sheet.name} - {sheet.row_count} rows
            </Select.Option>
          ))}
        </Select>
      )}
    </>
  );
}
