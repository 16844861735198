import { Alert, Button, Col, Row } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import E from "core/images/logos/e.svg";
import "core/scss/Login.scss";
import React from "react";

interface LoginProps {
  onLoginButtonClicked: () => void;
}

export default function Login(props: LoginProps) {
  return (
    <div className="pt-4">
      <Paragraph
        className="container border bg-white p-4 shadow-sm border mt-4"
        style={{ position: "relative", zIndex: 0 }}
      >
        <div style={{ position: "relative", zIndex: 2 }}>
          <h1 className="mt-3 iap-header">
            <div className="text-center">
              INT
              <img src={E} alt="" />
              RNAL APPS PORTAL
            </div>
          </h1>
          <div className="text-center iap-slogan mb-5">The American Dream is still alive</div>
        </div>
        <svg
          className="svgHeader"
          style={{ position: "absolute", left: 0, top: 0, zIndex: 1 }}
          width="100%"
          height="120"
          viewBox="0.1 0.1 180 40"
          preserveAspectRatio="none"
        >
          <g transform="translate(-18.298844,-77.973964)">
            <path
              className="svgHeader"
              style={{ fill: "#f2f2f2" }}
              d="M 31.615583,86.351641 H 192.16499 v 26.901969 c 0,0 -32.03411,-14.237983 -59.62682,-12.72484 -22.34188,1.2252 -54.779359,9.72634 -54.779359,9.72634 0,0 -22.029534,3.62882 -34.471238,-1.88988 -12.441702,-5.51871 -11.67199,-22.013589 -11.67199,-22.013589 z"
            />
            <path
              className="svgHeader"
              style={{ fill: "#fbfbfb" }}
              d="M 18.441597,78.106256 H 198.58126 v 39.288614 c 0,0 -43.10672,-27.825245 -73.47599,-19.687823 -30.369264,8.137423 -46.832208,12.548653 -46.832208,12.548653 0,0 -32.775418,8.05972 -46.735258,0 C 17.577964,102.19598 18.441597,78.106256 18.441597,78.106256 Z"
            />
          </g>
        </svg>
        <Row>
          <Col span={8} offset={8}>
            <Alert message="Make sure you allow pop-ups for this page." type="info" />
          </Col>
        </Row>
      </Paragraph>
    </div>
  );
}
