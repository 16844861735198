import React, { useEffect, useState } from "react";
import { Transfer, Modal, Button } from "antd";
import {
  EnsProductCarrierChildResponse,
  TwoLetterState,
  UpdateEnsProductCarrierChildRequest,
} from "common/services/ApiServerService";
import { TransferItem } from "antd/lib/transfer";

const states = Object.keys(TwoLetterState);

interface ChildCarrierPreappStatesEditorProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  childCarrier: EnsProductCarrierChildResponse;
  updateChildCarrierHandler: (updateReq: UpdateEnsProductCarrierChildRequest) => void;
}

export default function ChildCarrierPreappStatesEditor(props: ChildCarrierPreappStatesEditorProps) {
  const { visible, setVisible, childCarrier, updateChildCarrierHandler } = props;
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (!visible) return;

    setTargetKeys([]);
    setSelectedKeys([]);

    if (childCarrier) setTargetKeys(childCarrier.preappointment_states.filter((s) => states.includes(s)));
  }, [childCarrier, visible]);

  const close = () => {
    setVisible(false);
  };

  const handleChange = (newTargetKeys: string[], direction: string, moveKeys: string[]) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const submitUpdateChild = () => {
    updateChildCarrierHandler({
      ens_product_carrier_child_tid: childCarrier.ens_product_carrier_child_tid,
      preappointment_states: targetKeys,
    } as UpdateEnsProductCarrierChildRequest);
    close();
  };

  return (
    <Modal title="Preappointment States Editor" visible={visible} onOk={close} onCancel={close} footer={[]}>
      <Transfer
        dataSource={states.map((s) => ({ key: s, title: s } as TransferItem))}
        titles={["Inactive", "Active"]}
        targetKeys={targetKeys.sort((a, b) => a.localeCompare(b))}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        disabled={false}
        render={(item: TransferItem) => item.title ?? ""}
      />
      <Button onClick={submitUpdateChild}>Update</Button>
    </Modal>
  );
}
