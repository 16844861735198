import { message, Select } from "antd";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { EnsUserRoleType, RolesClient, UserRole } from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useEffect, useState } from "react";

const { Option } = Select;

type RoleSelectProps = {
  selectedValue: EnsUserRoleType | undefined;
  onSelect: (roleTid: UserRole | undefined) => void;
};

export default function RoleSelect(props: RoleSelectProps) {
  const { selectedValue, onSelect } = props;
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setRoles([]);

    setLoading(true);

    authenticatedFetch(MicroservicesEndpoint.ensUsers, RolesClient, async (c) => setRoles(await c.getRolesList()))
      .catch(() => message.error("Something went wrong. Contact Engineering."))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Select
      showSearch
      disabled={loading}
      loading={loading}
      style={{ width: 200 }}
      placeholder="Select a Role"
      optionFilterProp="children"
      value={selectedValue}
      onChange={(v) => onSelect(roles.find((r) => r.ensUserRoleType == v))}
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {roles
        .sort((a, b) => a.ensUserRoleName.localeCompare(b.ensUserRoleName))
        .map((r) => (
          <Option key={r.ensUserRoleType} value={r.ensUserRoleType}>
            {r.ensUserRoleName}
          </Option>
        ))}
    </Select>
  );
}
