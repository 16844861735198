import React, { useEffect, useState } from "react";
import { Form, Col, Row, Select, InputNumber, Button } from "antd";
import {
  CronTaskStateType,
  TaskRunResponse,
  TasksClient,
} from "../../../common/services/microservices/cron-boss-client";
import { authenticatedClient } from "../../../core/components/AuthProvider";
import {
  CalculateFutureValueClient,
  GenerateFutureValueS3UrlParameters,
} from "../../../common/services/microservices/finance-client";
import { SubmitButton } from "../../../common/components/SubmitButton";
import { MicroservicesEndpoint } from "../../../common/services/ApiServerService";
import CronTaskEventByGuidDisplay from "../../commission-upload/components/CronTaskEventDisplay";

const { Option } = Select;

export default function VariableRateForecast() {
  const [discountRate, setDiscountRate] = React.useState<number>(1);
  const [forecastType, setForecastType] = React.useState<string>("TripleSeven");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [taskGuid, setTaskGuid] = React.useState<string | undefined>();
  const [taskSucceeded, setTaskSucceeded] = React.useState<boolean>(false);

  function RunGenerateFutureValueS3UrlTask() {
    return authenticatedClient(MicroservicesEndpoint.finance, CalculateFutureValueClient)
      .then((client) => {
        setIsLoading(true);
        return client.runGenerateFutureValueS3Url(
          new GenerateFutureValueS3UrlParameters({
            forecast_type: forecastType,
            discount_rate: discountRate.toString(),
          })
        );
      })
      .then((response: TaskRunResponse) => {
        console.log(response);
        setIsLoading(false);
        setTaskGuid(response.guid);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  function GetFileUrl() {
    if (!taskGuid) return;
    setIsDownloading(true);
    return authenticatedClient(MicroservicesEndpoint.finance, CalculateFutureValueClient).then((client) => {
      return client
        .getUploadPreSignedUrlAsExcel(taskGuid)
        .then((response) => {
          setIsDownloading(false);
          window.location.href = response;
        })
        .catch((err) => {
          console.log(err);
          setIsDownloading(false);
        });
    });
  }

  return (
    <>
      <Form onFinish={RunGenerateFutureValueS3UrlTask}>
        <Form.Item>
          <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Col span={3} offset={8}>
              <h4>Variable Rate:</h4>
            </Col>
            <Col span={2}>
              <InputNumber placeholder="1.0" min={0} max={100} onChange={(e) => setDiscountRate(e)} />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Col span={3} offset={8}>
              <h4>Forecast Type:</h4>
            </Col>
            <Col span={4}>
              <Select value={forecastType} onChange={(e: string) => setForecastType(e)}>
                required
                <Option value="Uhas">Uhas</Option>
                <Option value="TripleSeven">777</Option>
              </Select>
            </Col>
          </Row>
        </Form.Item>
        <Row style={{ paddingTop: 10, paddingBottom: 30 }}>
          <Col span={4} offset={9}>
            <SubmitButton loading={isLoading}>Submit</SubmitButton>
          </Col>
        </Row>
      </Form>
      {taskGuid && (
        <CronTaskEventByGuidDisplay
          guid={taskGuid}
          onTaskStateChanged={(state) => {
            if (state === CronTaskStateType.Success) {
              setTaskSucceeded(true);
            }
          }}
        />
      )}
      <Row style={{ paddingTop: 10, paddingBottom: 30 }}>
        <Col span={4} offset={9}>
          {taskSucceeded && <SubmitButton text="Download File" onClick={GetFileUrl} loading={isDownloading} />}
        </Col>
      </Row>
    </>
  );
}
