import { Menu, Dropdown, Button } from "antd";
import React from "react";

export function LedgerCategoryDropdown(props: {
  value: number | undefined;
  onValueClicked: (newValue: number) => void;
}) {
  const { value, onValueClicked } = props;

  const ledgerCategoryItems = [
    {
      key: 1725,
      text: "Top Carrier (1725)",
    },
    {
      key: 1710,
      text: "New Commissions (1710)",
    },
    {
      key: 4715,
      text: "Other Income (4715)",
    },
    {
      key: 4000,
      text: "Old Comissions (4000)",
    },
  ];

  const ledgerCategoryMenu = (
    <Menu onClick={(e) => onValueClicked(parseInt(e.key as string))}>
      {ledgerCategoryItems.map((item) => (
        <Menu.Item isSelected={value === item.key} key={item.key}>
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={ledgerCategoryMenu} className="edit-um-ledger-category" trigger={["click"]}>
      <Button>{value ? ledgerCategoryItems.find((i) => i.key === value)?.text : "Select..."}</Button>
    </Dropdown>
  );
}
