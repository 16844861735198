import React, { useEffect, useState } from "react";
import { Input, Form, Row, Col } from "antd";
import AgentSelect from "../AgentSelect";
import "../../scss/SalesStatusForm.scss";
import { EnsUserInfo } from "common/services/microservices/ensusers-client";
import { SaleStatus } from "common/services/microservices/sales-client";

export interface AgentTabObject {
  agent_ens_user_tid: number | undefined;
  referral_ens_user_tid: number | undefined;
  referral_agent_answer: string | undefined;
  commission_basis: string | undefined;
  underwriting_comments: string | undefined;
}

interface AgentTabProps {
  selectedData: (data: AgentTabObject) => void;
  selectedRecord?: SaleStatus;
  insertModal?: boolean;
}

export default function AgentTab(props: AgentTabProps) {
  const { selectedData, selectedRecord, insertModal } = props;
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [agentSelected, setAgentSelected] = useState<number | undefined>(
    !insertModal ? selectedRecord?.agent_ens_user_tid : undefined
  );
  const [referralAgentSelected, setReferralAgentSelected] = useState<number | undefined>(
    !insertModal ? selectedRecord?.referral_ens_user_tid : undefined
  );
  const [referralAgentAnswer, setReferralAgentAnswer] = useState<string | undefined>(
    !insertModal ? selectedRecord?.referral_agent_answer : undefined
  );
  const [commissionBasis, setCommissionBasis] = useState<string | undefined>(
    !insertModal ? selectedRecord?.commission_basis : undefined
  );
  const [underwritingComments, setUnderwritingComments] = useState<string | undefined>(
    !insertModal ? selectedRecord?.underwriting_comments : undefined
  );

  useEffect(() => {
    if (!selectedRecord) return;

    form.setFieldsValue({
      agent: selectedRecord.agent_ens_user_tid,
      referralAgent: selectedRecord.referral_ens_user_tid,
      referralAgentAnswer: selectedRecord.referral_agent_answer,
      commissionBasis: selectedRecord.commission_basis,
      underwritingComments: selectedRecord.underwriting_comments,
    });
    // eslint-disable-next-line
  }, [selectedRecord]);

  useEffect(() => {
    selectedData({
      agent_ens_user_tid: agentSelected,
      referral_ens_user_tid: referralAgentSelected,
      referral_agent_answer: referralAgentAnswer!,
      commission_basis: commissionBasis,
      underwriting_comments: underwritingComments,
    });
    // eslint-disable-next-line
  }, [agentSelected, referralAgentSelected, referralAgentAnswer, commissionBasis, underwritingComments]);

  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  return (
    <Form {...layout} name="basic" initialValues={{ remember: true }} form={form}>
      <Row>
        <Col span={8}>
          <Form.Item label="Agent" name="agent" rules={[{ required: true }]}>
            <AgentSelect
              onAgentSelected={(agentId: number | undefined) => {
                setAgentSelected(agentId);
              }}
              data={selectedRecord}
              style={{ width: "100%" }}
              additionalAgents={[
                {
                  ensUserTid: 8,
                  userName: "DTC@insuretysolutions.com",
                  userFirstName: "DTC",
                  userLastName: "DTC",
                  userEmail: "DTC@insuretysolutions.com",
                  active: false,
                } as EnsUserInfo,
                {
                  ensUserTid: 11,
                  userName: "Coverance@insuretysolutions.com",
                  userFirstName: "Coverance",
                  userLastName: "Coverance",
                  userEmail: "Coverance@insuretysolutions.com",
                  active: false,
                } as EnsUserInfo,
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Referral agent" name="referralAgent">
            <AgentSelect
              onAgentSelected={(agentId: number | undefined) => setReferralAgentSelected(agentId)}
              data={selectedRecord}
              referralAgentSelect={true}
              style={{ width: "100%" }}
              additionalAgents={[
                {
                  ensUserTid: 8,
                  userName: "DTC@insuretysolutions.com",
                  userFirstName: "DTC",
                  userLastName: "DTC",
                  userEmail: "DTC@insuretysolutions.com",
                  active: false,
                } as EnsUserInfo,
                {
                  ensUserTid: 11,
                  userName: "Coverance@insuretysolutions.com",
                  userFirstName: "Coverance",
                  userLastName: "Coverance",
                  userEmail: "Coverance@insuretysolutions.com",
                  active: false,
                } as EnsUserInfo,
              ]}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={8}>
          <Form.Item label="Agent answer" name="referralAgentAnswer">
            <Input
              style={{ width: "100%" }}
              placeholder="Referral agent answer"
              onChange={(e: any) => setReferralAgentAnswer(e.target.value)}
              value={selectedRecord ? selectedRecord.referral_agent_answer : undefined}
            ></Input>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Commission basis" name="commissionBasis">
            <Input
              style={{ width: "100%" }}
              placeholder="Commission basis"
              onChange={(e: any) => setCommissionBasis(e.target.value)}
              value={selectedRecord ? selectedRecord.commission_basis : undefined}
            ></Input>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={16} id="agent-form-underwriting">
          <Form.Item label="Underwriting comments" name="underwritingComments">
            <TextArea
              placeholder={underwritingComments ? "" : "Underwriting comments"}
              onChange={(e: any) => setUnderwritingComments(e.target.value)}
              rows={2}
              autoSize={{ minRows: 2, maxRows: underwritingComments?.length }}
              value={selectedRecord ? selectedRecord.underwriting_comments : undefined}
              style={{ width: "100%" }}
            ></TextArea>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
