import React, { useEffect, useState } from "react";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import { authenticatedClient } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import {
  IEnsCommissionForecastMeDzRate,
  MonthEndForecastRateClient,
} from "common/services/microservices/finance-client";
import { Input, Table, Button, Form, Row, Spin } from "antd";
import { ColumnsType } from "antd/lib/table";
import Paragraph from "antd/lib/typography/Paragraph";

export default function MonthEndRateEditorApp(props: ApplicationPageProps) {
  const [newRate, setNewRate] = useState<string | undefined>();
  const [sortedRates, setSortedRates] = useState<IEnsCommissionForecastMeDzRate[]>([]);
  const [ratesUpdating, setRatesUpdating] = useState(false);

  async function getLatestRates() {
    setRatesUpdating(true);
    console.log(MicroservicesEndpoint.finance);
    const client = await authenticatedClient(MicroservicesEndpoint.finance, MonthEndForecastRateClient);
    const rates = await client.getPreviousRates(100);
    setSortedRates(rates.sort((a, b) => b.dateAdded!.getTime() - a.dateAdded!.getTime()));
    setRatesUpdating(false);
  }

  async function addNewRate(newRate: number) {
    setRatesUpdating(true);
    const client = await authenticatedClient(MicroservicesEndpoint.finance, MonthEndForecastRateClient);
    await client.addNewRate(newRate);
    await getLatestRates();
    setRatesUpdating(false);
  }

  const rateColumns: ColumnsType<IEnsCommissionForecastMeDzRate> = [
    {
      title: "Number",
      render: (_: any, record) => record.ensCommissionForecastMeDzRateTid,
    },
    {
      title: "Rate",
      render: (_: any, record) => record.rate,
    },
    {
      title: "Date Entered",
      render: (_: any, record) => record.dateAdded?.toDateString(),
    },
    {
      title: "",
      render: (_: any, record) => (
        <>
          <Button
            loading={ratesUpdating}
            onClick={() => {
              addNewRate(record.rate!);
            }}
          >
            Set
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    getLatestRates();
  }, []);

  const numberRate = Number.parseFloat(newRate ?? "");

  return (
    <Paragraph className="container bg-white p-8 mt-4">
      <h3>Current Month-End Rate</h3>
      <h4 className="mt-1">
        {"➔"}
        {!ratesUpdating ? (
          <>
            <b className="ml-2">{sortedRates[0]?.rate}</b>
          </>
        ) : (
          <Spin className="ml-2" />
        )}
      </h4>
      <hr />
      <h3>Enter New Rate</h3>
      <p>
        Enter as a value from 0 to 1, such as <b>0.0715</b>
      </p>
      <Form>
        <Row>
          <Form.Item>
            <Input
              required
              type="number"
              className="new-rate-value"
              value={newRate}
              onChange={(e) => setNewRate(e.target.value)}
            />
          </Form.Item>
          <Button
            loading={ratesUpdating}
            type="primary"
            disabled={!newRate || numberRate > 1 || numberRate < 0 || Number.isNaN(numberRate)}
            onClick={() => addNewRate(numberRate)}
          >
            Set
          </Button>
        </Row>
      </Form>
      <hr />
      <h3>Previous Rates</h3>
      <Table
        loading={ratesUpdating}
        style={{ marginTop: "1em" }}
        size="small"
        dataSource={sortedRates}
        columns={rateColumns}
        scroll={{ x: "max-content", y: 350 }}
      />
    </Paragraph>
  );
}
