import React, { useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import AgentTab, { AgentTabObject } from "./AgentTab";
import SalesTab, { SalesTabObject } from "./SalesTab";
import DemographicsTab, { DemographicsTabObject } from "./DemographicsTab";
import MarketingTab, { MarketingTabObject } from "./MarketingTab";
import {
  InsertSaleNoteRequest,
  SaleInsertRequest,
  SaleNoteHistoryClient,
} from "common/services/microservices/sales-client";
import SaleNotesTab from "./SaleNotesTab";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";

interface AddEntryModalProps {
  showModal: boolean;
  hideModal: () => void;
  callInsertApi: (row: SaleInsertRequest) => Promise<number | void | undefined>;
  loggedInUserTid: number;
}

export default function AddEntryModal(props: AddEntryModalProps) {
  const { hideModal, callInsertApi, showModal, loggedInUserTid } = props;
  const { TabPane } = Tabs;
  const [agentObject, setAgentObject] = useState<AgentTabObject>();
  const [salesObject, setSalesObject] = useState<SalesTabObject>();
  const [demographicsObject, setDemographicsObject] = useState<DemographicsTabObject>();
  const [marketingObject, setMarketingObject] = useState<MarketingTabObject>();
  const [saleNoteContent, setSaleNoteContent] = useState<string>();
  const [calculatedAge, setCalculatedAge] = useState<number>();
  const [showMarketing, setShowMarketing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const insertSaleNote = async (newEnsSaleTid: number) => {
    const client = await authenticatedClient(MicroservicesEndpoint.sales, SaleNoteHistoryClient);
    const request = new InsertSaleNoteRequest({
      ens_sale_tid: newEnsSaleTid!,
      ens_user_tid: loggedInUserTid,
      note_content: saleNoteContent!,
    });
    await client.insertSaleNote(request);
  };

  const insertRow = async (
    agent: AgentTabObject | undefined,
    sales: SalesTabObject | undefined,
    demographics: DemographicsTabObject | undefined,
    marketing: MarketingTabObject | undefined,
    saleNoteContent: string | undefined
  ) => {
    setIsSubmitting(true);
    try {
      let row = { ...agent, ...sales, ...demographics, ...marketing } as SaleInsertRequest;
      const newEnsSaleTid = await callInsertApi(row);
      if (saleNoteContent && newEnsSaleTid) await insertSaleNote(newEnsSaleTid);
    } finally {
      setIsSubmitting(false);
    }
  };

  const validateData = (
    agent: AgentTabObject | undefined,
    sales: SalesTabObject | undefined,
    demographics: DemographicsTabObject | undefined,
    marketing: MarketingTabObject | undefined
  ) => {
    let row = { ...agent, ...sales, ...demographics, ...marketing } as SaleInsertRequest;
    if (
      !row.agent_ens_user_tid ||
      !row.ens_product_carrier_tid ||
      !row.ens_product_tid ||
      !row.current_ens_sale_status_tid ||
      !row.sale_datetime ||
      !row.effective_date ||
      !row.insured_dob
    ) {
      return true;
    } else return false;
  };

  const displayMarketing = (contactInfo: DemographicsTabObject) => {
    if (contactInfo.insured_dob) {
      setShowMarketing(true);
    }
  };

  const displayAge = (dateOfBirth: Date, effectiveDate: Date) => {
    if (!dateOfBirth || !effectiveDate) return;
    let age = 0;
    age = effectiveDate.getFullYear() - dateOfBirth.getFullYear();
    if (dateOfBirth.getMonth() > effectiveDate.getMonth()) {
      age--;
    }
    setCalculatedAge(age);
  };

  useEffect(() => {
    if (salesObject?.effective_date && demographicsObject?.insured_dob) {
      displayAge(demographicsObject.insured_dob, salesObject.effective_date);
    }
    if (demographicsObject) {
      displayMarketing(demographicsObject);
    }
  }, [salesObject, demographicsObject]);

  return (
    <>
      <Modal
        visible={showModal}
        okButtonProps={{
          disabled: validateData(agentObject, salesObject, demographicsObject, marketingObject) || isSubmitting,
          loading: isSubmitting,
        }}
        onOk={async () => {
          await insertRow(agentObject, salesObject, demographicsObject, marketingObject, saleNoteContent);
          hideModal();
        }}
        maskClosable={false}
        okText={"Insert"}
        onCancel={() => {
          hideModal();
        }}
        width={1000}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="Sales" key="1">
            <SalesTab
              selectedData={(data) => {
                setSalesObject(data);
              }}
              insertModal={true}
            />
          </TabPane>
          <TabPane tab="Sale Note" key="2">
            <SaleNotesTab setSaleNoteContent={setSaleNoteContent} saleNoteContent={saleNoteContent} />
          </TabPane>
          <TabPane tab="Demographics" key="3">
            <DemographicsTab
              selectedData={(data) => {
                setDemographicsObject(data);
              }}
              insertModal={true}
              calculatedAge={calculatedAge}
            />
          </TabPane>
          <TabPane tab="Agents" key="4">
            <AgentTab
              selectedData={(data) => {
                setAgentObject(data);
              }}
              insertModal={true}
            />
          </TabPane>
          {showMarketing && (
            <TabPane tab="Marketing" key="5">
              <MarketingTab
                onMarketingCampaignSelected={(data) => {
                  setMarketingObject(data);
                }}
              />
            </TabPane>
          )}
        </Tabs>
      </Modal>
    </>
  );
}
