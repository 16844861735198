import React, { useEffect, useState } from "react";
import { Select } from "antd";
import {
  MicroservicesEndpoint,
} from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";
import { CarrierInfoResponse, ICarrierInfoResponse, ProductCarrierClient, SaleStatus } from "common/services/microservices/sales-client";

const { Option } = Select;

interface CarrierSelectProps {
  additionalOptions?: ICarrierInfoResponse[];
  data?: SaleStatus;
  clear?: number;
  onCarrierSelected: (carrierId: number) => void;
  style?: React.CSSProperties;
}

export default function CarrierSelect(props: CarrierSelectProps) {
  const { data, clear, additionalOptions, onCarrierSelected, style } = props;
  const [carrierList, setCarrierList] = useState<CarrierInfoResponse[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<number | undefined>(data?.ens_product_carrier_tid);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    authenticatedFetch(MicroservicesEndpoint.sales, ProductCarrierClient, (c) => {
      setLoading(true);
      return c.getCarriers(true);
    }).then((res) => {
      setLoading(false);
      setCarrierList(res);
    });
  }, []);

  useEffect(() => {
    setSelectedCarrier(undefined);
  }, [clear]);

  useEffect(() => {
    if (!data) return;

    setSelectedCarrier(data.ens_product_carrier_tid);
  }, [data]);

  return (
    <Select
      allowClear
      showSearch
      style={style}
      loading={loading}
      value={selectedCarrier}
      placeholder="Select a carrier"
      optionFilterProp="children"
      onChange={(value, option) => {
        onCarrierSelected(value as number);
        setSelectedCarrier(value);
      }}
      filterOption={(input: string, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {[...carrierList, ...(additionalOptions ?? [])]
        .sort((a, b) => (a.productCarrierName > b.productCarrierName ? 1 : -1))
        .map((item) => {
          return (
            <Option key={item.ensProductCarrierTid} value={item.ensProductCarrierTid}>
              {item.productCarrierName}
            </Option>
          );
        })}
    </Select>
  );
}
