import {
  Appointment,
  EnsProductCarrierTid,
  EnsProductCategoryTid,
  AppointmentStatus,
} from "common/services/ApiServerService";
const stateAbbreviaton = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]

const colors = {
  nothing: "#CFCFC4 ",
  noAppointmentState: "#D7EBBC",
  full: "#4C8E4C",
};

export default function StateAppointmentColorGenerator(
  appointments: Appointment[],
  product_category: EnsProductCategoryTid,
  carrier: EnsProductCarrierTid
) {
  var obj: { [key: string]: any } = {};
  stateAbbreviaton.forEach((state) => (obj[state!.toLocaleUpperCase()] = { fill: colors.nothing }));

  ["AK", "AZ", "CO", "IL", "IN", "MD", "MO", "OR", "RI"].forEach((o) => obj[o.toLocaleUpperCase()] = { fill: colors.noAppointmentState });

  appointments
    .filter(
      (c) =>
        c.broker_id.product_category.ens_product_category_tid === product_category &&
        c.broker_id.carrier.ens_product_carrier_tid === carrier
    )
    .forEach(
      (o) =>
        (obj[o.state.toLocaleUpperCase()] = {
          fill: o.appointment_status === AppointmentStatus.Appointed ? colors.full : colors.nothing,
        })
    );

  return obj;
}
