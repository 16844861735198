import React, { useState } from "react";
import { YearAndMonthSelector } from "./YearAndMonthSelector";
import { MonthlyRevenueBooker } from "./MonthlyRevenueBooker";
import { ReportForecastTid, RevenueBookingInput, RevenueType } from "common/services/microservices/finance-client";
import { Collapse } from "antd";
import { ReveneBookingReportGenerator } from "./MonthlyReportGenerator";
import moment from "moment";

export function TrueUpRevenueBookingTool() {
  const [month, setMonth] = useState<number>();
  const [year, setYear] = useState<number>();
  const [postingDate, setPostingDate] = useState<moment.Moment>();
  const [accountingDate, setAccountingDate] = useState<moment.Moment>();

  return (
    <div style={{ textAlign: "center", marginBottom: "20px" }}>
      <h3>True-up Revenue Booking Tool</h3>
      <div className="col-14" style={{ paddingLeft: "5vw", paddingRight: "5vw" }}>
        <h5>Select month, year, accounting date, and posting date to book</h5>
        <YearAndMonthSelector
          onSetMonth={setMonth}
          onSetYear={setYear}
          onSetAccountingDate={setAccountingDate}
          onSetPostingDate={setPostingDate}
        />
        {month && year && accountingDate && postingDate && (
          <Collapse style={{ marginBottom: "20px", maxWidth: "100%", textAlign: "center" }}>
            <Collapse.Panel header={"Generate Report"} key="1">
              <ReveneBookingReportGenerator month={month} year={year} forecast_type_tid={ReportForecastTid.TrueUp} />
            </Collapse.Panel>
            <Collapse.Panel header={"Book Revenue"} key="2">
              <MonthlyRevenueBooker
                input={
                  new RevenueBookingInput({
                    year: year,
                    month: month,
                    accounting_date: accountingDate?.toDate(),
                    posting_date: postingDate?.toDate(),
                  })
                }
                bookingType={RevenueType.TrueUp}
              />
            </Collapse.Panel>
          </Collapse>
        )}
      </div>
    </div>
  );
}
