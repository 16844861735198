import { RcFile } from "antd/lib/upload";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { ObjectStorageClient } from "common/services/microservices/basic-client";
import { authenticatedClient } from "core/components/AuthProvider";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { IPreSignedUrlResponse } from "common/services/microservices/basic-client";

// Provides a custom request handler to the AntD Upload component
// the uploads the file to the Ensurem Microservices S3 bucket, and returns
// the location of the uploaded file once the upload is complete.

export async function MicroserviceUploadRequest(
  s3ObjectPrefix: string,
  antdOptions: UploadRequestOption
): Promise<IPreSignedUrlResponse> {
  return new Promise((resolve, reject) => {
    authenticatedClient(MicroservicesEndpoint.basic, ObjectStorageClient)
      .then((client) =>
        client.getUploadPreSignedUrl(`${s3ObjectPrefix}/${(antdOptions.file as RcFile).name ?? "uploaded-file"}`)
      )
      .then((presignedUrlResponse) => {
        fetch(presignedUrlResponse!.presignedUrl, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          method: "PUT",
          body: antdOptions.file,
        })
          // https://github.com/ant-design/ant-design/issues/28680
          // The TypeScript interface to the onSuccess/onError have issues,
          // pass undefined for not applicable parameters and hope it doesn't break.
          .then((response) => {
            resolve(presignedUrlResponse);
            antdOptions.onSuccess?.(response, undefined!);
          })
          .catch((err) => {
            antdOptions.onError?.(undefined!, err);
            reject();
          });
      })
      .catch((err) => {
        antdOptions.onError?.(undefined!, err);
        reject();
      });
  });
}
