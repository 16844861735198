import React, { useState, useEffect } from "react";
import ApiServerService, {
  Appointment,
  BrokerId,
  EnsUser,
  License,
  MicroservicesEndpoint,
  UserClient,
} from "common/services/ApiServerService";
import ApplicationPageProps from "core/models/ApplicationPageProps";
import { Row, Col, Table, Collapse, Typography, Descriptions, Space } from "antd";
import { authenticatedFetch } from "core/components/AuthProvider";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import Checkmark from "licensing/images/checkmark.svg";
import Xmark from "licensing/images/x-mark.svg";
import StateSelect from "common/components/StateSelect";
import { EnsProductCarrierTid, EnsProductCategory, ReadyToSellClient } from "common/services/microservices/licenses-client";

const { Panel } = Collapse;
const { Paragraph } = Typography;

interface AgentStateOverviewProps extends ApplicationPageProps {
  agent: EnsUser;
}

type ReadyToSellStatus = {
  carrier: string;
  product: string;
  states: {
    state: string;
    isReadyToSell: boolean;
  }[];
};

type StateReadyToSellStatus = {
  carrier: string;
  product: string;
  isReadyToSell: boolean;
};

export default function AgentStateOverview(props: AgentStateOverviewProps) {
  const { agent } = props;
  const [selectedState, setSelectedState] = useState<string>("AK");
  const [licenses, setLicenses] = useState<License[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [readyToSellStatuses, setReadyToSellStatuses] = useState<ReadyToSellStatus[]>([]);
  const [loadingLicenses, setLoadingLicenses] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [loadingBrokerIds, setLoadingBrokerIds] = useState(false);
  const [loadingRts, setLoadingRts] = useState(false);

  useEffect(() => {
    setLicenses([]);
    setAppointments([]);
    setReadyToSellStatuses([]);
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoadingLicenses(true);
      return c.getUserLicenses(agent.ens_user_tid);
    }).then((res) => {
      setLoadingLicenses(false);
      setLicenses(res);
    });

    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoadingAppointments(true);
      return c.getUserAppointments(agent.ens_user_tid);
    }).then((res) => {
      setLoadingAppointments(false);
      setAppointments(res);
    });

    const chunkBrokerIds = (b: BrokerId[], chunkSize: number) => {
      return Array.from({ length: Math.ceil(b.length / chunkSize) }).map((_, i) =>
        Array.from({ length: chunkSize }).map((_, j) => b[i * chunkSize + j])
      );
    };

    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoadingBrokerIds(true);
      return c.getUserBrokerIds(agent.ens_user_tid);
    }).then((brokerIds) => {
      setLoadingBrokerIds(false);
      setLoadingRts(true);
      let rtsList: ReadyToSellStatus[] = [];
      // hcunk requests into chunks of 5
      let chunkedBrokerIds = chunkBrokerIds(brokerIds, 5);
      (async function chunkedGetRts() {
        for (let i = 0; i < chunkedBrokerIds.length; i++) {
          let chunk = chunkedBrokerIds[i];
          let results = await Promise.all(
            chunk
              .filter((x) => x !== undefined)
              .map(async (b) => {
                return {
                  carrier: b.carrier.product_carrier_name,
                  product: b.product_category.product_category_name,
                  states: (
                    await authenticatedFetch(MicroservicesEndpoint.licenses, ReadyToSellClient, (c) => {
                      return c.getAgentRtsStatuses(
                        agent.ens_user_tid,
                        b.carrier.ens_product_carrier_tid as Number as EnsProductCarrierTid,
                        b.product_category.ens_product_category_tid as Number as EnsProductCategory,
                        new Date().getFullYear()
                      );
                    })
                  ).map((s) => {
                    return {
                      state: s.state,
                      isReadyToSell: s.readyToSell,
                    };
                  }),
                } as ReadyToSellStatus;
              })
          );

          rtsList = [...rtsList, ...results];
          setReadyToSellStatuses(rtsList);
        }

        setLoadingRts(false);
      })();
    });
  }, [agent]);

  return (
    <>
      <Row>
        <Col className="my-3">
          <Space>
            <StateSelect
              onStateSelect={(state: string) => setSelectedState(state)}
              loading={loadingLicenses || loadingAppointments || loadingRts || loadingBrokerIds}
              defaultValue={"Alaska"}
              style={{ width: 200 }}
              disableTerritories={false}
            />
            <Descriptions size="small">
              <Descriptions.Item label="License #">
                <Paragraph copyable>
                  {licenses.find((l) => l.state === selectedState)?.license_number ?? "No license"}
                </Paragraph>
              </Descriptions.Item>
            </Descriptions>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse defaultActiveKey={["2"]}>
            <Panel
              header="Appointments"
              key="1"
              collapsible={appointments.some((apt) => apt.state === selectedState) ? "header" : "disabled"}
            >
              <AppointmentsTable
                appointments={appointments.filter((a) => a.state === selectedState)}
                loading={loadingAppointments}
              />
            </Panel>
            <Panel header="Ready-to-Sell" key="2">
              <ReadyToSellTable rtsStatuses={readyToSellStatuses} state={selectedState} loading={loadingRts} />
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </>
  );
}

const ReadyToSellTable = (props: { rtsStatuses: ReadyToSellStatus[]; state: string | undefined; loading: boolean }) => {
  const { rtsStatuses, state, loading } = props;
  const [selectedStateRts, setSelectedStateRts] = useState<StateReadyToSellStatus[]>([]);

  useEffect(() => {
    if (!state) {
      setSelectedStateRts([]);
      return;
    }

    setSelectedStateRts(
      rtsStatuses.map((r) => {
        return {
          carrier: r.carrier,
          product: r.product,
          isReadyToSell: r.states.find((s) => s.state === state)?.isReadyToSell ?? false,
        };
      })
    );
  }, [state, rtsStatuses]);

  const columns: ColumnsType<StateReadyToSellStatus> = [
    {
      dataIndex: "carrier",
      title: "Carrier",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: StateReadyToSellStatus, b: StateReadyToSellStatus) => a.carrier.localeCompare(b.carrier),
      filters: [...new Set(selectedStateRts.map((r) => r.carrier))].map((rts) => {
        return {
          text: rts,
          value: rts,
        };
      }),
      onFilter: (value: any, rts: StateReadyToSellStatus) => rts.carrier === value,
    },
    {
      dataIndex: "product",
      title: "Product",
      defaultSortOrder: null as SortOrder,
      sorter: (a: StateReadyToSellStatus, b: StateReadyToSellStatus) => a.product.localeCompare(b.product),
      filters: [...new Set(selectedStateRts.map((r) => r.product))].map((rts) => {
        return {
          text: rts,
          value: rts,
        };
      }),
      onFilter: (value: any, rts: StateReadyToSellStatus) => rts.product === value,
    },
    {
      dataIndex: "isReadyToSell",
      title: "Ready-to-Sell",
      sorter: (a: StateReadyToSellStatus, b: StateReadyToSellStatus) => (a.isReadyToSell < b.isReadyToSell ? 1 : -1),
      render: (value: any, rts: StateReadyToSellStatus, index: number) => (
        <img src={rts.isReadyToSell ? Checkmark : Xmark} alt="" />
      ),
      filters: [
        {
          text: "Yes",
          value: true,
        },
        {
          text: "No",
          value: false,
        },
      ],
      onFilter: (value: any, rts: StateReadyToSellStatus) => rts.isReadyToSell === value,
    },
  ];

  const getRecordKey = (record: StateReadyToSellStatus) => {
    return record.carrier + record.product;
  };

  return (
    <Table
      size="small"
      scroll={{ y: 600 }}
      dataSource={selectedStateRts}
      columns={columns}
      rowKey={getRecordKey}
      loading={loading}
      pagination={{
        total: selectedStateRts.length,
        pageSize: selectedStateRts.length,
        hideOnSinglePage: true,
      }}
    />
  );
};

const AppointmentsTable = (props: { appointments: Appointment[]; loading: boolean }) => {
  const { appointments, loading } = props;

  const columns: ColumnsType<Appointment> = [
    {
      key: "carrier",
      title: "Carrier",
      defaultSortOrder: "ascend" as SortOrder,
      sorter: (a: Appointment, b: Appointment) =>
        a.broker_id.carrier.product_carrier_name.localeCompare(b.broker_id.carrier.product_carrier_name),
      render: (value: any, appointment: Appointment, index: number) =>
        appointment.broker_id.carrier.product_carrier_name,
    },
    {
      key: "product",
      title: "Product",
      defaultSortOrder: null as SortOrder,
      sorter: (a: Appointment, b: Appointment) =>
        a.broker_id.product_category.product_category_name.localeCompare(
          b.broker_id.product_category.product_category_name
        ),
      render: (value: any, appointment: Appointment, index: number) =>
        appointment.broker_id.product_category.product_category_name,
    },
  ];

  const getRecordKey = (record: Appointment) => {
    return record.broker_id.broker_id_tid + (record.appointment_date ?? "no appointment?").toString();
  };

  return (
    <Table
      scroll={{ y: 300 }}
      loading={loading}
      dataSource={appointments}
      columns={columns}
      rowKey={getRecordKey}
      pagination={{
        total: appointments.length,
        pageSize: appointments.length,
        hideOnSinglePage: true,
      }}
    />
  );
};
