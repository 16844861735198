import { Alert, Button, Col, Descriptions, Drawer, message, Row, Space, Tag, Transfer, Typography } from "antd";
import { AntAnchor } from "antd/lib/anchor/Anchor";
import Modal from "antd/lib/modal/Modal";
import Table, { ColumnsType } from "antd/lib/table";
import { ReloadOutlined } from '@ant-design/icons';
import { TransferItem } from "antd/lib/transfer";
import Title from "antd/lib/typography/Title";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { AgentStateLicensesRequest, AgentStateLicensesRequestClient, AgentStateLicensesRequestUpdate, EnsLicensingRequestStatusTypeTid, EnsLicensingRequestTypeTid, LicenseRequest, RequestsClient } from "common/services/microservices/licenses-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import React, { useEffect, useState } from "react"

const { Text } = Typography;

interface ReviewRequestsProps {
  // user: UserClass;
}

export default function ReviewRequests(props: ReviewRequestsProps) {
  const [requiringReview, setRequiringReview] = useState<LicenseRequest[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<LicenseRequest>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadRequiringReview();
  }, []);

  const loadRequiringReview = () => {
    setRequiringReview([]);
    setSelectedRequest(undefined);

    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, RequestsClient, async c => {
      let res = await c.getRequestsForReview();
      setRequiringReview(res);
    })
      .catch(e => message.error("Something went wrong."))
      .finally(() => setLoading(false));
  }

  const columns: ColumnsType<LicenseRequest> = [
    {
      title: (text: string, record: LicenseRequest, index: number) =>
        <Button icon={<ReloadOutlined />} onClick={() => loadRequiringReview()} />,
      render: (value: any, record: LicenseRequest, index: number) => <Button onClick={() => setSelectedRequest(record)}>Review</Button>
    },
    {
      title: "Agent",
      dataIndex: ["target", "userEmail"],
      filters: [...new Set((requiringReview || []).map((i) => i.target.userEmail))].map((userEmail) => {
        return {
          text: userEmail,
          value: userEmail,
        };
      }),
      onFilter: (value: any, record: LicenseRequest) => record.target.userEmail === value
    },
    {
      title: "Request Type",
      render: (value: any, record: LicenseRequest, index: number) => EnsLicensingRequestTypeTid[record.ensLicensingRequestType],
      filters: [...new Set((requiringReview || []).map((i) => i.ensLicensingRequestType))].map((reqType) => {
        return {
          text: EnsLicensingRequestTypeTid[reqType],
          value: reqType,
        };
      }),
      onFilter: (value: any, record: LicenseRequest) => record.ensLicensingRequestType === value
    }
  ];

  return (
    <>
      <Table
        loading={loading}
        dataSource={requiringReview}
        columns={columns}
        rowKey={(record) => record.ensLicenseRequestTid}
        pagination={{ pageSize: 5 }}
      />
      <Drawer
        title="Reviewing Request"
        placement="right"
        width="75%"
        visible={selectedRequest !== undefined}
        onClose={() => loadRequiringReview()}
      >
        <ReviewRequest request={selectedRequest} />
        <div style={{ textAlign: "right", width: "calc(100% - 24px)", bottom: 10, position: "absolute", padding: 5 }}>
          <Text type="secondary" >Request: {selectedRequest?.ensLicenseRequestTid}</Text>
        </div>
      </Drawer>
    </>
  )
}

interface ReviewRequestProps {
  request: LicenseRequest | undefined;
}

const ReviewRequest = (props: ReviewRequestProps) => {
  const { request } = props;

  switch (request?.ensLicensingRequestType) {
    case EnsLicensingRequestTypeTid.AgentStateLicenseRequest:
      return <ReviewStateLicensesRequest requestTid={request.ensLicenseRequestTid} />
    default:
      return <>Not found</>
  }
}

const ReviewStateLicensesRequest = (props: { requestTid: number | undefined }) => {
  const { requestTid } = props;
  const [selectedRequest, setSelectedRequest] = useState<AgentStateLicensesRequest>();
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadRequest(requestTid);
  }, [requestTid]);

  const loadRequest = (requestTid: number | undefined) => {
    if (!requestTid)
      return;

    setSelectedRequest(undefined);

    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, AgentStateLicensesRequestClient, async c => {
      let res = await c.getAgentStateLicensingRequest(requestTid);
      setSelectedRequest(res);
      setTargetKeys(res.states.filter(s => s.approved || s.approved == undefined).map(s => s.state));
    })
      .catch(e => message.error("Something went wrong."))
      .finally(() => setLoading(false));
  }

  const handleChange = (newTargetKeys: string[], direction: string, moveKeys: string[]) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const submitReview = (approvedStates: string[]) => {
    if (!selectedRequest)
      return;

    setLoading(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, AgentStateLicensesRequestClient, async c => {
      let res = await c.updateAgentStateLicensingRequest(selectedRequest.ensLicenseRequestTid, {
        approvedStates: approvedStates,
        deniedStates: selectedRequest.states.filter(s => !approvedStates.some(x => s.state === x)).map(s => s.state)
      } as AgentStateLicensesRequestUpdate);
      loadRequest(res.ensLicenseRequestTid);
      message.success("Request review complete.");
    })
      .catch(e => message.error("Something went wrong."))
      .finally(() => setLoading(false));
  }

  return <>
    <Row>
      <Col >
        <Descriptions
          bordered
          title="Request Info"
          layout="vertical"
          size="small"
        >
          <Descriptions.Item label="Requestor">{selectedRequest?.requestor.userEmail}</Descriptions.Item>
          <Descriptions.Item label="Agent">{selectedRequest?.target.userEmail}</Descriptions.Item>
          <Descriptions.Item label="Request Type">{EnsLicensingRequestTypeTid[selectedRequest?.ensLicensingRequestType || EnsLicensingRequestTypeTid.AgentStateLicenseRequest]}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
    <Row style={{ marginTop: 15 }}>
      <Col span={24}>
        <Transfer
          listStyle={{
            width: "100%",
            height: 300
          }}
          selectAllLabels={[
            ({ selectedCount, totalCount }) => (
              <span>
                {totalCount} states <Text type="danger">denied</Text>
              </span>
            ), ({ selectedCount, totalCount }) => (
              <span>
                {totalCount} states <Text type="success">approved</Text>
              </span>
            )
          ]}
          dataSource={!selectedRequest ? [] : selectedRequest.states.map(s => ({ key: s.state, title: s.state } as TransferItem))}
          titles={[
            <Title level={4}><Text type="danger">Deny</Text></Title>,
            <Title level={4}><Text type="success">Approve</Text></Title>
          ]}
          targetKeys={targetKeys.sort((a, b) => a.localeCompare(b))}
          selectedKeys={selectedKeys}
          onChange={handleChange}
          onSelectChange={handleSelectChange}
          disabled={loading || selectedRequest?.ensLicensingRequestStatusType != EnsLicensingRequestStatusTypeTid.AwaitingApproval}
          render={(item: TransferItem) => item.title ?? ""}
        />
      </Col>
    </Row>
    {
      selectedRequest?.ensLicensingRequestStatusType == EnsLicensingRequestStatusTypeTid.AwaitingApproval ? <>
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <Button
              disabled={loading}
              style={{ marginTop: 15 }}
              type="primary"
              block
              onClick={() => {
                submitReview(targetKeys);
              }}
            >Submit</Button>
          </Col>
        </Row>
      </>
        : selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.Approved ?
          <Alert message="Approved" type="success" style={{ marginTop: 15 }} />
          : selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.PartiallyApproved ?
            <Alert message="Partially Approved" type="warning" style={{ marginTop: 15 }} />
            : selectedRequest?.ensLicensingRequestStatusType === EnsLicensingRequestStatusTypeTid.Denied &&
            <Alert message="Denied" type="error" style={{ marginTop: 15 }} />
    }
  </>
}