import React, { useEffect, useState } from "react";
import ApiServerService, { NiprClient, NiprLastUpdateResponse } from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";

export enum NiprDateType {
  License,
  Appointment,
}

interface NiprDateStatusProps {
  type: NiprDateType;
}

export default function NiprDateStatus(props: NiprDateStatusProps) {
  const { type } = props;
  const [niprResponse, setNiprResponse] = useState<NiprLastUpdateResponse>();

  useEffect(() => {
    authenticatedFetch(ApiServerService.endpoint, NiprClient, (c) => {
      return c.getLastNiprUpdate();
    }).then((res) => {
      setNiprResponse(res);
    });
  }, []);

  const getStatus = (type: NiprDateType, res: NiprLastUpdateResponse) => {
    let status = type === NiprDateType.License ? res.licensing_status : res.appointment_status;
    let color = "red";

    // check if today is the last successful day the update task ran
    let today = new Date();
    let lastSuccess = status.last_successful_timestamp;
    if (
      lastSuccess?.getFullYear() !== today.getFullYear() ||
      lastSuccess.getMonth() !== today.getMonth() ||
      lastSuccess.getDay() !== today.getDay()
    )
      color = "red";
    else if (status.last_run_status === "success") color = "green";

    return <p style={{ color: color }}>{status.last_successful_timestamp?.toLocaleDateString("en-US")}</p>;
  };

  if (!niprResponse) return <span>Loading...</span>;

  return (
    <div>
      NIPR last updated:
      {getStatus(type, niprResponse)}
    </div>
  );
}
