import { Row, Col, Input, DatePicker, Select, Divider, Table, Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { IUnmatchedCashRecord } from "common/services/microservices/finance-client";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
import EditCommissionRecordModal from "./EditCommissionRecordModal";

interface CashMatchingErrorTableProps {
  loading?: boolean;
  errors: IUnmatchedCashRecord[];
  onRefresh: () => void;
}

export function CashMatchingErrorTable(props: CashMatchingErrorTableProps) {
  const { errors, loading, onRefresh } = props;

  const [filteredErrors, setFilteredErrors] = useState<IUnmatchedCashRecord[]>(errors);
  const [editingRow, setEditingRow] = useState<IUnmatchedCashRecord | null>(null);

  const Errors = [
    { value: "potential matches", label: "Multiple Matched Sales" },
    { value: "query not in hal", label: "No HAL Match" },
  ];

  const matchingErrorColumns: ColumnsType<IUnmatchedCashRecord> = [
    {
      title: "Carrier",
      width: "40px",
      render: (_: any, record) => record.db.carrier_name,
      sorter: (a, b) => a.db.carrier_name.localeCompare(b.db.carrier_name),
      defaultSortOrder: "ascend",
    },
    {
      title: "Payment Amount",
      width: "80px",
      render: (_: any, record) => record.db.payment_amount,
    },
    {
      title: "SID",
      width: "40px",
      render: (_: any, record) => record.match_error_ens_sale_tid,
    },
    {
      title: "Payment Date",
      width: "100px",
      render: (_: any, record) => record.db.payment_date?.toLocaleDateString(),
    },
    {
      title: "Policy Number",
      width: "100px",
      render: (_: any, record) => record.db.policy_number,
    },
    {
      title: "Payment Eff. Date",
      width: "75px",
      render: (_: any, record) => record.db.payment_effective_date?.toLocaleDateString(),
    },
    {
      title: "Policy Eff. Date",
      width: "75px",
      render: (_: any, record) => record.db.policy_effective_date.toLocaleDateString(),
    },
    {
      title: "Ledger Category",
      width: "70px",
      render: (_: any, record) => record.db.ledger_category,
    },
    {
      title: "Sheet Name",
      width: "100px",
      render: (_: any, record) => record.db.sheet_name,
    },
    {
      title: "Insured Name",
      width: "100px",
      render: (_: any, record) => record.db.insured_name,
    },
    {
      title: "Match Error",
      width: "100px",
      render: (_: any, record) => record.match_errors,
    },
    {
      title: "Edit",
      width: "100px",
      render: (_: any, record) => <Button onClick={() => setEditingRow(record)}>Edit</Button>,
    },
  ];

  function formatExportData(data: any | Date) {
    if (typeof data?.getMonth === "function" && typeof data?.getFullYear === "function") {
      return (data as Date).toLocaleDateString();
    } else if (Array.isArray(data)) {
      return data.join(", ");
    }
    return data;
  }

  useEffect(() => {
    setFilteredErrors(errors);
  }, [errors]);

  return (
    <>
      <h4>{loading ? "..." : errors.length} total unmatched items</h4>

      <h4>Filter...</h4>
      <Row className="mt-3" gutter={10}>
        <Col span={7}>
          <h6>Policy Number</h6>
          <Input
            onChange={(e) => {
              if (e.target.value) {
                setFilteredErrors(errors.filter((i) => i.db.policy_number.includes(e.target.value)));
              } else {
                setFilteredErrors(errors);
              }
            }}
            placeholder="Search by Policy Number..."
          />
        </Col>
        <Col span={7}>
          <h6>Policy Effective Date</h6>
          <DatePicker
            onChange={(e) => {
              if (e) {
                setFilteredErrors(
                  errors.filter((i) => i.db.policy_effective_date.toDateString() === e.toDate().toDateString())
                );
              } else {
                setFilteredErrors(errors);
              }
            }}
            style={{ width: "100%" }}
            placeholder="Search by Effective Date..."
          />
        </Col>
        <Col span={7}>
          <h6>Error Type</h6>
          <Select<string>
            placeholder="Search by error..."
            style={{ width: "100%" }}
            options={Errors}
            onChange={(e) => setFilteredErrors(errors.filter((i) => i.match_errors[0]?.toLowerCase().includes(e)))}
          ></Select>
        </Col>
      </Row>
      <Table
        className="mt-3"
        size="small"
        loading={loading}
        dataSource={filteredErrors}
        columns={matchingErrorColumns}
        bordered
        scroll={{ x: "max-content", y: 600 }}
        pagination={{
          total: filteredErrors.length,
          pageSize: 40,
          hideOnSinglePage: true,
        }}
      />
      <Divider />
      <h4>Export</h4>
      <CSVLink
        data={errors.map((unmatched) => {
          const dateFormattedEntires = Object.entries(unmatched.db).map(([key, value]) => {
            return [key, formatExportData(value)];
          });
          const datesFormattedDbObject = Object.fromEntries(dateFormattedEntires);
          return { ...datesFormattedDbObject, match_error: unmatched.match_errors.join(", ") };
        })}
        filename={`CashMatchOutput-${moment().format("MM-DD-YYYY")}.csv`}
      >
        <Button key="export-sales" type="primary">
          <ExportOutlined />
          Export All Match Issues
        </Button>
      </CSVLink>
      {editingRow && (
        <EditCommissionRecordModal
          record={editingRow.db}
          updateMultipleRecords={false}
          recordKeysToUpdate={[editingRow.db.ens_sale_carrier_payment_tid]}
          visible={editingRow !== null}
          closeModal={() => setEditingRow(null)}
          refreshGrid={onRefresh}
        />
      )}
    </>
  );
}
