import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import Transfer, { TransferItem } from "antd/lib/transfer";
import { EnsUserPermissionType, UserPermission } from "common/services/microservices/ensusers-client";

type PermissionsTransferWidgetProps = {
  availablePermissions: UserPermission[];
  currentActivePermissions?: UserPermission[];
  onPermissionsUpdated: (newList: EnsUserPermissionType[]) => Promise<void> | undefined;
};

export function PermissionsTransferWidget(props: PermissionsTransferWidgetProps) {
  const { availablePermissions, currentActivePermissions: initialActivePermissions, onPermissionsUpdated } = props;

  const [loading, setLoading] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);

  useEffect(() => {
    setActiveKeys(sortPermissionsList(initialActivePermissions).map((p) => p.ensUserPermissionType.toString()) ?? []);
  }, [initialActivePermissions]);

  function sortPermissionsList(items: UserPermission[] | undefined) {
    return [...(items ?? [])].sort((a, b) =>
      (a.ensUserPermissionName ?? "").localeCompare(b.ensUserPermissionName ?? "")
    );
  }

  async function onUpdateClicked() {
    try {
      setLoading(true);
      await onPermissionsUpdated(activeKeys.map((p) => parseInt(p)));
    } catch (ex) {
      message.error("Something went wrong. Contact Engineering.");
    } finally {
      setLoading(false);
    }
  }

  const transferItems: TransferItem[] = sortPermissionsList(availablePermissions).map((p) => ({
    key: p.ensUserPermissionType.toString(),
    title: p.ensUserPermissionName,
  }));

  return (
    <>
      <Transfer
        listStyle={{ width: "100%", height: 400 }}
        dataSource={transferItems}
        titles={["Inactive", "Active"]}
        selectedKeys={selectedKeys}
        targetKeys={activeKeys}
        onChange={(newTargetKeys: string[]) => setActiveKeys(newTargetKeys)}
        disabled={loading}
        onSelectChange={(sourceSelectedKeys: string[], targetSelectedKeys: string[]) =>
          setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
        }
        render={(item: TransferItem) => item.title ?? ""}
      />
      <Button loading={loading} style={{ marginTop: 5 }} block type="primary" onClick={onUpdateClicked}>
        Update
      </Button>
    </>
  );
}
