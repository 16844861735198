import React from "react";
import { Button, Modal } from "antd";
import TerminationDateChangesTable, { TerminationDateChangesTableProps } from "./TerminationDateChangesTable";

interface TerminationDateChangesModal extends TerminationDateChangesTableProps {
  visible: boolean;
  setVisible: (newVisible: boolean) => void;
}

export default function TerminationDateChangesModal(props: TerminationDateChangesModal) {
  return (
    <Modal
      width="90%"
      title={`Proposed Changes`}
      visible={props.visible}
      onCancel={() => props.setVisible(false)}
      footer={[
        <Button key="close" type="primary" onClick={() => props.setVisible(false)}>
          Close
        </Button>,
      ]}
    >
      <TerminationDateChangesTable {...props}></TerminationDateChangesTable>
    </Modal>
  );
}
