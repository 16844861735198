import { RtsStatus } from "common/services/microservices/licenses-client";

const stateAbbreviaton = ["AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FL", "GA", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"]
const colors = {
  nothing: "#CFCFC4 ",
  partial: "#FDE396",
  full: "#4C8E4C",
}

export default function StateRtsColorGenerator(rtsReports: RtsStatus[]) {

  var obj: { [key: string]: any } = {};
  stateAbbreviaton.forEach(state => obj[state!.toLocaleUpperCase()] = { fill: colors.nothing })

  rtsReports.forEach(o =>
    obj[o.state.toLocaleUpperCase()] = {
      fill: o.readyToSell ? colors.full : colors.nothing
    }
  );

  return obj
}