import React, { useEffect, useState } from "react";
import { Select } from "antd";
import ApiServerService, {
  EnsProductCarrierChildInfoTypeResponse,
  ProductCarrierClient,
} from "common/services/ApiServerService";
import { authenticatedFetch } from "core/components/AuthProvider";

const { Option } = Select;

interface ChildCompanyInfoTypelectProps {
  onInfoTypes?: (infoTypes: EnsProductCarrierChildInfoTypeResponse[]) => void;
  onChildCompanyInfoTypeSelected: (infoType: EnsProductCarrierChildInfoTypeResponse) => void;
}

export default function ChildCompanyInfoTypeSelect(props: ChildCompanyInfoTypelectProps) {
  const { onChildCompanyInfoTypeSelected, onInfoTypes } = props;
  const [infoTypes, setInfoTypes] = useState<EnsProductCarrierChildInfoTypeResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    authenticatedFetch(ApiServerService.endpoint, ProductCarrierClient, (c) => {
      return c.getCarrierChildrenInfoTypes();
    }).then((res) => {
      setInfoTypes(res);

      if (onInfoTypes) onInfoTypes(res);

      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      showSearch
      disabled={loading}
      loading={loading}
      style={{ width: 150 }}
      placeholder="Select a info type"
      optionFilterProp="children"
      onChange={(value, option) => {
        let infoType = infoTypes.find((i) => i.ens_product_carrier_child_info_type_tid === (value as number));

        if (!infoType) return;

        onChildCompanyInfoTypeSelected({
          ens_product_carrier_child_info_type_tid: infoType?.ens_product_carrier_child_info_type_tid,
          name: infoType?.name,
        } as EnsProductCarrierChildInfoTypeResponse);
      }}
      filterOption={(input: string, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {infoTypes
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((item) => {
          return (
            <Option
              key={item.ens_product_carrier_child_info_type_tid}
              value={item.ens_product_carrier_child_info_type_tid}
            >
              {item.name}
            </Option>
          );
        })}
    </Select>
  );
}
