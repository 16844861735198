import { Button, Col, Input, Row, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SortOrder } from "antd/lib/table/interface";
import ApiServerService, {
  AddBrokerIdRequest,
  BrokerId,
  EnsUser,
  EnsUserPermissionTypeTid,
  UpdateBrokerIdRequest,
  UserClient,
} from "common/services/ApiServerService";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import React, { useEffect, useState } from "react";
import BrokerIdAddModal from "./BrokerIdAddModal";

const { Paragraph } = Typography;

interface BrokerIdDisplayTabProps {
  user: UserClass;
  agent: EnsUser;
}

export default function BrokerIdTab(props: BrokerIdDisplayTabProps) {
  const { agent, user } = props;
  const [brokerIds, setBrokerIds] = useState<BrokerId[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [edittingBrokerId, setEdittingBrokerId] = useState<BrokerId>();
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getBrokerIds(agent.ens_user_tid);
  }, [agent]);

  const getBrokerIds = (ensUserTid: number) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setTableLoading(true);
      return c.getUserBrokerIds(ensUserTid);
    }).then((b) => {
      setTableLoading(false);
      setBrokerIds(b);
    });
  };

  const addBrokerId = (ensUserTid: number, brokerIdReq: AddBrokerIdRequest) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setTableLoading(true);
      return c.addBrokerId(ensUserTid, brokerIdReq);
    }).then((newBrokerId) => {
      setTableLoading(false);
      setBrokerIds([...brokerIds, newBrokerId]);
    });
  };

  const updateBrokerId = (brokerIdReq: BrokerId) => {
    setEdittingBrokerId(undefined);
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setTableLoading(true);
      return c.updateBrokerId({
        ens_user_broker_id_tid: brokerIdReq.broker_id_tid,
        broker_id: brokerIdReq.broker_id,
      } as UpdateBrokerIdRequest);
    }).then((updatedBrokerId) => {
      setTableLoading(false);
      setBrokerIds([updatedBrokerId, ...brokerIds.filter((b) => b.broker_id_tid !== brokerIdReq.broker_id_tid)]);
    });
  };

  const deleteBrokerId = (ensUserBrokerTid: number) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setTableLoading(true);
      return c.deleteBrokerId(ensUserBrokerTid);
    }).then(() => {
      setTableLoading(false);
      setBrokerIds([...brokerIds.filter((b) => b.broker_id_tid !== ensUserBrokerTid)]);
    });
  };

  const getRecordKey = (b: BrokerId) => {
    return b.broker_id_tid;
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!edittingBrokerId) return;

    edittingBrokerId.broker_id = event.target.value;
  };

  const columns: ColumnsType<BrokerId> = [
    {
      title: () => <span>Carrier</span>,
      render: (text: string, record: BrokerId, index: number) => record.carrier.product_carrier_name,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: BrokerId, b: BrokerId) => {
        return a.carrier.product_carrier_name.localeCompare(b.carrier.product_carrier_name);
      },
    },
    {
      title: () => <span>Product</span>,
      render: (text: string, record: BrokerId, index: number) => record.product_category.product_category_name,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: BrokerId, b: BrokerId) => {
        return a.product_category.product_category_name.localeCompare(b.product_category.product_category_name);
      },
    },
    {
      title: () => <span>Broker ID</span>,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: BrokerId, b: BrokerId) => {
        if (!a.broker_id || !b.broker_id) return 0;
        return a.broker_id.localeCompare(b.broker_id);
      },
      render: (text: string, record: BrokerId, index: number) => {
        if (record.broker_id_tid !== edittingBrokerId?.broker_id_tid) return record.broker_id;

        return (
          <Input
            autoFocus
            onBlur={(e) => setEdittingBrokerId(undefined)}
            onChange={onTextChange}
            placeholder={record.broker_id}
            onKeyPress={(ev) => {
              if (ev.key !== "Enter") return;

              updateBrokerId(edittingBrokerId);
            }}
          />
        );
      },
    },
  ];

  if (user.hasPermission(EnsUserPermissionType.LicensingEditAccess))
    columns.push({
      title: () => <span>Action</span>,
      render: (text: string, record: BrokerId, index: number) => (
        <>
          <Space>
            <Button
              type="primary"
              disabled={edittingBrokerId?.broker_id_tid === record.broker_id_tid}
              onClick={() => setEdittingBrokerId({ ...record } as BrokerId)}
            >
              Edit
            </Button>
            <Button onClick={() => deleteBrokerId(record.broker_id_tid)}> X</Button>
          </Space>
        </>
      ),
    });

  return (
    <Paragraph className="container">
      <Row>
        {user.hasPermission(EnsUserPermissionType.LicensingEditAccess) && (
          <Col md={{ offset: 20, span: 4 }}>
            <Button type="primary" className="my-2" onClick={() => setShowModal(true)}>
              Add New
            </Button>
          </Col>
        )}
      </Row>
      <BrokerIdAddModal {...props} addBrokerId={addBrokerId} setShowModal={setShowModal} showModal={showModal} />
      <Table
        loading={tableLoading}
        rowKey={getRecordKey}
        dataSource={brokerIds}
        columns={columns}
        scroll={{ y: 800 }}
        pagination={{
          total: brokerIds.length,
          pageSize: brokerIds.length,
          hideOnSinglePage: true,
        }}
      />
    </Paragraph>
  );
}
