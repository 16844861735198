import { Alert, AlertProps, Button, Col, Row, Table, Typography } from "antd";
import Dragger, { DraggerProps } from "antd/lib/upload/Dragger";
import ApiServerService, { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient, authenticatedFetch } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import React, { useEffect, useState } from "react";
import { InboxOutlined, ReloadOutlined } from "@ant-design/icons";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { TimesheetClient, TimesheetJobStatus } from "common/services/microservices/ensusers-client";
import {
  CronTaskState,
  CronTaskStateType,
  SortOrder,
  TasksClient,
} from "common/services/microservices/cron-boss-client";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";

const { Paragraph } = Typography;

interface TimesheetUploadProps {
  user: UserClass;
}

export default function TimesheetUpload(props: TimesheetUploadProps) {
  const { user } = props;
  const [uploadFailed, setUploadFailed] = useState(false);
  const [latestUploads, setLatestUploads] = useState<TimesheetJobStatus[]>([]);
  const [tasksLoading, setTasksLoading] = useState(false);

  useEffect(() => {
    getTasks();
  }, []);

  const uploadProps: DraggerProps = {
    accept: ".csv, text/csv",
    onChange: (info: UploadChangeParam) => {
      const { status } = info.file;

      setUploadFailed(false);

      if (status === "done") getTasks();
      else if (status === "error") setUploadFailed(true);
    },
    customRequest: (options: UploadRequestOption) => {
      authenticatedClient(MicroservicesEndpoint.ensUsers, TimesheetClient)
        .then(async (c) => {
          let url = await c.getUploadUrl();
          let uploadRes = await fetch(url.presignedUrl, {
            headers: {
              "Content-Type": "application/octet-stream",
            },
            method: "PUT",
            body: options.file,
          });

          if (!uploadRes.ok) {
            options.onError?.(undefined!, uploadRes.statusText);
            return;
          }

          await c.processTimesheet(url.objectGuid);

          options.onSuccess?.(uploadRes.statusText, undefined!);
        })
        .catch(() => {
          options.onError?.(undefined!, "Failed");
        });
    },
  };

  const getTasks = () => {
    setTasksLoading(true);
    authenticatedClient(MicroservicesEndpoint.ensUsers, TimesheetClient)
      .then(async (c) => setLatestUploads(await c.getLatest()))
      .finally(() => setTasksLoading(false));
  };

  const downloadsColumns: ColumnsType<TimesheetJobStatus> = [
    {
      title: () => <Button icon={<ReloadOutlined />} onClick={() => getTasks()} />,
      width: 250,
      dataIndex: ["guid"],
    },
    {
      title: "Date",
      align: "right",
      render: (value: any, record: TimesheetJobStatus) => record.lastEventDatetime.toLocaleString(),
    },
    {
      title: "Status",
      render: (value: any, record: TimesheetJobStatus) => CronTaskStateType[record.state],
    },
    {
      title: "Crontask",
      render: (value: any, record: TimesheetJobStatus) => (
        <a href={`https://dashboard.insuretysolutions.io/cron-boss/#taskGuid=${record.guid}`} target="_blank">
          View
        </a>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <div>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag Timesheet CSV file to this area to upload</p>
              <p className="ant-upload-hint">Processing can take up to 3 minutes.</p>
            </Dragger>
            {uploadFailed && <Alert message="Timesheet upload failed. Please contact engineering." type="error" />}
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col span={24}>
          <Title level={4}>Timesheet History</Title>
          <Table
            columns={downloadsColumns}
            rowKey={(record) => record.guid}
            dataSource={latestUploads}
            loading={tasksLoading}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        </Col>
      </Row>
    </>
  );
}
