import { Button, Col, Empty, message, Row, Select, Spin, Typography } from "antd";
import Transfer, { TransferItem } from "antd/lib/transfer";
import Title from "antd/lib/typography/Title";
import { EnsUser, TwoLetterState } from "common/services/ApiServerClient";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { UserBasicInfo, UserClient } from "common/services/microservices/ensusers-client";
import { AgentStateLicensesRequestClient, AgentStateLicensesRequestSubmit, License, LicensesClient, RequestsClient } from "common/services/microservices/licenses-client";
import { authenticatedFetch } from "core/components/AuthProvider";
import UserClass from "core/models/UserClass";
import React, { useEffect, useState } from "react"
import EnsUserSelect from "./EnsUserSelect";

const { Option } = Select;
const { Text } = Typography;

interface RequestStateLicensesProps {
  user: UserClass;
}

export default function RequestStateLicenses(props: RequestStateLicensesProps) {
  const { user } = props;
  const [selectedUser, setSelectedUser] = useState<UserBasicInfo>();
  const [submitting, setSubmitting] = useState(false);

  const submitRequest = (states: string[]) => {
    setSubmitting(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, AgentStateLicensesRequestClient, async c => {
      await c.submitAgentStateLicenseRequest({
        requestorEnsUserTid: user.EnsUserTid,
        targetEnsUserTid: selectedUser?.ensUserTid,
        states: states
      } as AgentStateLicensesRequestSubmit);
      message.success("Request for agent state licenses was successfully submitted.");
    })
      .catch(e => message.error("Something went wrong."))
      .finally(() => setSubmitting(false));
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <EnsUserSelect onSelect={setSelectedUser} agentsOnly={true} />
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <Spin spinning={submitting}>
            {!selectedUser ? <Empty /> : <StateTransfer user={selectedUser} onSelectedStates={submitRequest} />}
          </Spin>
        </Col>
      </Row>
    </>
  )
}

interface StateTransferProps {
  onSelectedStates: (selectedStates: string[]) => void,
  user: UserBasicInfo
}

const states = Object.keys(TwoLetterState).filter(s => !["AS", "GU", "MP", "PR", "VI", "UM", "MH", "FM", "PW"].some(x => x == s));

const StateTransfer = (props: StateTransferProps) => {
  const { onSelectedStates, user } = props;
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loadingUserLicenses, setLoadingUserLicenses] = useState(false);
  const [userLicenses, setUserLicenses] = useState<License[]>([]);

  useEffect(() => {
    setTargetKeys([]);
    setSelectedKeys([]);

    loadLicenses(user.ensUserTid);
  }, [user]);

  const handleChange = (newTargetKeys: string[], direction: string, moveKeys: string[]) => {
    setTargetKeys(newTargetKeys);
  };

  const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const loadLicenses = (ensUserTid: number) => {
    setLoadingUserLicenses(true);
    authenticatedFetch(MicroservicesEndpoint.licenses, LicensesClient, async c => {
      let lics = await c.getAgentLicenses(ensUserTid);
      setUserLicenses(lics);
    }).finally(() => setLoadingUserLicenses(false));
  }

  return <>
    <Transfer
      listStyle={{
        width: "100%",
        height: 300
      }}
      dataSource={loadingUserLicenses ? [] : states.filter(s => !userLicenses.some(l => s === l.state)).map(s => ({ key: s, title: s } as TransferItem))}
      titles={[
        <Title level={4}><Text>Available</Text></Title>,
        <Title level={4}><Text type="success">Request</Text></Title>
      ]}
      selectAllLabels={[
        ({ selectedCount, totalCount }) => (
          <span>
            {totalCount} available states
          </span>
        ), ({ selectedCount, totalCount }) => (
          <span>
            {totalCount} states <Text type="success">requested</Text>
          </span>
        )
      ]}
      targetKeys={targetKeys.sort((a, b) => a.localeCompare(b))}
      selectedKeys={selectedKeys}
      onChange={handleChange}
      onSelectChange={handleSelectChange}
      disabled={loadingUserLicenses}
      render={(item: TransferItem) => item.title ?? ""}
    />
    <Button
      disabled={loadingUserLicenses || !targetKeys.length}
      style={{ marginTop: 15 }}
      type="primary"
      block
      onClick={() => {
        onSelectedStates(targetKeys);
        setTargetKeys([]);
      }}
    >Submit</Button>
  </>
}