import { Button, Col, Form, Input, Row } from "antd";
import StatusAlert from "common/components/StatusAlert";
import { FindUserRequestType } from "common/services/ApiServerClient";
import React, { useState } from "react";

interface LicensingSearchProps {
  searchAgent: (idType: FindUserRequestType, id: string, inactive: boolean) => void;
  displayError: boolean;
}

export default function LicensingSearch(props: LicensingSearchProps): JSX.Element {
  const { displayError, searchAgent } = props;
  const [searchValue, setSearchValue] = useState("");
  const [inactiveSearch, setInactiveSearch] = useState<boolean>(false);

  async function onSearchClicked(ev: React.FormEvent<HTMLFormElement>) {
    let idType = FindUserRequestType.Npn;
    if (searchValue.includes("@")) {
      idType = FindUserRequestType.Email;
    }
    searchAgent(idType, searchValue, inactiveSearch);
  }

  return (
    <Row style={{ justifyContent: "center" }}>
      <Col xs={14} sm={12} md={8} lg={6}>
        <Form onFinish={onSearchClicked}>
          <Form.Item className="my-1 mx-auto justify-content-center">
            <Input
              required
              size="large"
              autoFocus
              placeholder="Enter NPN or Email"
              value={searchValue}
              onChange={(e: any) => setSearchValue(e.target.value)}
            />
          </Form.Item>
          {displayError && (
            <StatusAlert shouldDisplay={true} success={false} errorText={"User not found"}></StatusAlert>
          )}
          <Row className="justify-content-center">
            <label>
              Search Inactive?
              <input
                className="mx-1"
                type="checkbox"
                checked={inactiveSearch}
                onChange={() => setInactiveSearch(!inactiveSearch)}
              ></input>
            </label>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Button type="primary" size="large" className="my-1" htmlType="submit">
              Search
            </Button>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
