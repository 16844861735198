import React from "react";
import { SortOrder, ColumnsType } from "antd/lib/table/interface";
import { SaleStatus } from "common/services/microservices/sales-client";

export const MarketingTableColumns: ColumnsType<SaleStatus> = [
  {
    title: () => <span>Sale ID</span>,
    defaultSortOrder: "descend" as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return record.ens_sale_tid;
    },
    sorter: (a: SaleStatus, b: SaleStatus) =>
      a.ens_sale_tid < b.ens_sale_tid ? 1 : -1,
  },
  {
    title: () => <span>External ID</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `${record.external_campaign_id}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.external_campaign_id ?? "").localeCompare(b.external_campaign_id ?? "");
    },
  },
  {
    title: () => <span>External Campaign</span>,
    defaultSortOrder: null as SortOrder,
    render: (name: string, record: SaleStatus, index: number) => {
      return `${record.external_campaign_name}`;
    },
    sorter: (a: SaleStatus, b: SaleStatus) => {
      return (a.external_campaign_name ?? "").localeCompare(b.external_campaign_name ?? "");
    },
  }
];