import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface SaleNotesTabProps {
  setSaleNoteContent: (val: string) => void;
  saleNoteContent: string | undefined;
}

export default function SaleNotesTab(props: SaleNotesTabProps) {
  const { setSaleNoteContent, saleNoteContent } = props;
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };

  return (
    <Form {...layout} name="basic" initialValues={{ remember: true }} form={form}>
      <Row>
        <Col span={16} id="sale-note-content" style={{ width: '100%' }}>
          <Form.Item style={{ flexDirection: 'column', width: '800px', flex: 'unset' }} label="Contents" name="saleNoteContent">
            <TextArea
              placeholder={saleNoteContent ? "" : "Note contents"}
              onChange={(e: any) => setSaleNoteContent(e.target.value)}
              rows={2}
              cols={50}
              autoSize={{ minRows: 2, maxRows: saleNoteContent?.length }}
              style={{ width: "100%" }}
            ></TextArea>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
