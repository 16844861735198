import React, { useState, useEffect } from "react";
import { Col, Row, Spin } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import "licensing/scss/LicenseMap.scss";
import ApiServerService, {
  EnsProductCategoryTid,
  EnsProductCarrierTid,
  Appointment,
  EnsUser,
  UserClient,
} from "common/services/ApiServerService";
import CarrierDropDown from "common/components/CarrierDropDown";
import StateAppointmentColorDisplay from "licensing/models/StateAppointmentColorization";
import ProductCategoryDropdown from "licensing/components/ProductCategoryDropdown";
import { authenticatedFetch } from "core/components/AuthProvider";
import StatusAlert from "common/components/StatusAlert";
import { MapModal } from "./MapModal";
import NiprDateStatus, { NiprDateType } from "common/components/NiprDateStatus";

let ReactUsaMap = require("react-usa-map").default;

interface AppointmentMapProps {
  agent: EnsUser;
}

export default function AppointmentMap(props: AppointmentMapProps): JSX.Element {
  const { agent } = props;
  const [carrierIdValue, setCarrierIdValue] = useState<EnsProductCarrierTid>();
  const [producCategoryIdValue, setProductCategoryIdValue] = useState<EnsProductCategoryTid>();
  const [selectedState, setSelectedState] = React.useState<string>();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const mapHandler = (event: any) => {
    setSelectedState(event.target.dataset.name);
  };

  const getAppointments = (ensUserTid: number) => {
    authenticatedFetch(ApiServerService.endpoint, UserClient, (c) => {
      setLoading(true);
      return c.getUserAppointments(ensUserTid);
    })
      .then((res: Appointment[]) => {
        setAppointments(res);
      })
      .catch(() => {
        setDisplayError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const AppointmentModal = (appointment: Appointment | undefined) => {
    let notAppointedStates = ["AK", "AZ", "CO", "IL", "IN", "MD", "MO", "OR", "RI"];

    if (!appointment) {
      if (notAppointedStates.some((state) => state === selectedState))
        return "This state does not require an appointment!";
      else return "No appointment data.";
    }

    return (
      <>
        <Row>
          <Col md={10} sm={10} className="border mx-2 shadow-sm">
            <Row className="justify-content-md-center">
              <h4 className="pt-2">Carrier Name</h4>
            </Row>
            <Row key={appointment.broker_id.broker_id_tid}>
              <Col>
                <h6>{appointment.broker_id.carrier.product_carrier_name}</h6>
              </Col>
            </Row>
          </Col>
          <Col md={10} sm={10} className="border mx-2 shadow-sm">
            <Row className="justify-content-md-center">
              <h4 className="pt-2">Appointment Dates</h4>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <h6>Appointment Date: </h6>
              </Col>
              <Col md={12} sm={12}>
                {appointment.appointment_date?.toLocaleDateString("en-US") ?? "N/A"}
              </Col>
            </Row>
            <Row>
              <Col md={12} sm={12}>
                <h6>Expiration Date: </h6>
              </Col>
              <Col md={12} sm={12}>
                {appointment.expiration_date?.toLocaleDateString("en-US") ?? "N/A"}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  useEffect(() => {
    getAppointments(agent.ens_user_tid);
  }, [agent]);

  return (
    <>
      {displayError && <StatusAlert shouldDisplay={true} success={false} closable={true} />}
      <Paragraph className="container">
        <Row gutter={16} className="my-2">
          <Col md={10}>
            Product
            <ProductCategoryDropdown
              onProductSelected={(productCategoryId: number) => setProductCategoryIdValue(productCategoryId)}
              whiteListAppointments
              displayError={(error: boolean) => setDisplayError(error)}
            />
          </Col>
          <Col md={10}>
            Carrier
            <CarrierDropDown
              onCarrierSelected={(carrierId: number) => setCarrierIdValue(carrierId)}
              displayError={(error: boolean) => setDisplayError(error)}
            />
          </Col>
          <Col md={4}></Col>
        </Row>
        <Row>
          {displayError && (
            <Col>
              <StatusAlert shouldDisplay={true} success={false} />
            </Col>
          )}
          {loading ? (
            <>
              <div style={{ opacity: 0.3, width: "100%" }}>
                <ReactUsaMap
                  customize={StateAppointmentColorDisplay([], producCategoryIdValue!, carrierIdValue!)}
                  onClick={mapHandler}
                />
              </div>
              <Spin
                size="large"
                style={{
                  margin: "0",
                  marginTop: "290px",
                  position: "absolute",
                  left: "50%",
                }}
              />
            </>
          ) : (
            appointments && (
              <>
                <ReactUsaMap
                  customize={StateAppointmentColorDisplay(appointments ?? [], producCategoryIdValue!, carrierIdValue!)}
                  onClick={mapHandler}
                />
                <MapModal
                  displayLicense={true}
                  closeModal={() => setSelectedState(undefined)}
                  selectedState={selectedState}
                >
                  {AppointmentModal(
                    appointments.find(
                      (apt) =>
                        apt.state === selectedState &&
                        apt.broker_id.product_category.ens_product_category_tid === producCategoryIdValue &&
                        apt.broker_id.carrier.ens_product_carrier_tid === carrierIdValue
                    )
                  )}
                </MapModal>
              </>
            )
          )}
          <NiprDateStatus type={NiprDateType.Appointment} />
        </Row>
        <Col>
          <svg width="18" height="18">
            <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#4C8E4C", stroke: "black" }} />
          </svg>
          {" Appointed"}
        </Col>
        <Col className="align-middle">
          <svg width="18" height="18">
            <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#CFCFC4", stroke: "black" }} />
          </svg>
          {" Unappointed"}
        </Col>
        <Col className="align-middle">
          <svg width="18" height="18">
            <rect x="2" y="2" rx="1" ry="1" width="150" height="150" style={{ fill: "#D7EBBC", stroke: "black" }} />
          </svg>
          {" Appointment not required"}
        </Col>
      </Paragraph>
    </>
  );
}
