import React, { useEffect, useState } from "react";
import { Checkbox, Spin } from "antd";
import { authenticatedFetch } from "core/components/AuthProvider";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { DropdownClient, IDropbownProductCategory } from "common/services/microservices/basic-client";

interface Props {
  selectedProductCategories: number[];
  onProductCategoriesSelected: (productCategoryId: number[]) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export function ProductCheckboxes(props: Props): JSX.Element {
  const { selectedProductCategories, onProductCategoriesSelected, disabled } = props;
  const [loading, setLoading] = useState<boolean | undefined>(undefined);
  const [productCategoryList, setProductCategoryList] = useState<IDropbownProductCategory[]>([]);

  useEffect(() => {
    const getProductCategory = () => {
      authenticatedFetch(MicroservicesEndpoint.basic, DropdownClient, (c) => {
        setLoading(true);
        return c.getActiveProductCategories();
      })
        .then((res) => {
          setProductCategoryList(res.sort((a, b) => a.product_category_name.localeCompare(b.product_category_name)));
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getProductCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      {productCategoryList.map((carrier, i) => (
        <Checkbox
          key={i}
          style={{ margin: 0 }}
          disabled={disabled || loading}
          value={selectedProductCategories.includes(carrier.ens_product_category_tid)}
          onChange={(f) => {
            const checked = f.target.checked;
            if (checked) {
              onProductCategoriesSelected([...selectedProductCategories, carrier.ens_product_category_tid]);
            } else {
              onProductCategoriesSelected(
                selectedProductCategories.filter((c) => c != carrier.ens_product_category_tid)
              );
            }
          }}
        >
          {carrier.product_category_name}
        </Checkbox>
      ))}
    </>
  );
}
