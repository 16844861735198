import React, { CSSProperties, Key, useState } from "react";
import { Input, Table, message, Button, Collapse, Select, Divider } from "antd";
import {
  CommissionsUploadManagerClient,
  CommissionUploadManagerSearchResult,
  EnsProductCarrierTid,
  EnsProductCategory,
  UpdatedEnsSaleCarrierPaymentRequest,
} from "common/services/microservices/finance-client";
import { DownloadOutlined } from "@ant-design/icons";
import { MicroservicesEndpoint } from "common/services/ApiServerService";
import { authenticatedClient } from "core/components/AuthProvider";
import { ColumnsType } from "antd/lib/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons/lib/icons/";
import DeleteCommissionRecordModal, { BulkDeleteMode } from "./DeleteCommissionRecordModal";
import CarrierSelect from "common/components/CarrierSelect";
import { SortOrder } from "antd/lib/table/interface";
import UserClass from "core/models/UserClass";
import { EnsUserPermissionType } from "common/services/microservices/ensusers-client";
import { CSVLink } from "react-csv";
import moment from "moment";
import "./CommissionUploadManager.scss";
import { CashMatchingSubApp } from "./CashMatchingSubApp";
import EditCommissionRecordModal from "operations/common/EditCommissionRecordModal";
const { Option } = Select;

const validProducts = [
  { value: EnsProductCategory.MedSupp, text: "MS" },
  { value: EnsProductCategory.MedicareAdvantage, text: "MA" },
];
interface CommissionUploadManagerTabProps {
  user: UserClass;
}

export default function CommissionUploadManager(props: CommissionUploadManagerTabProps) {
  const [policyNumber, setPolicyNumber] = useState<string | undefined>();
  const [sheetName, setSheetName] = useState<string | undefined>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<CommissionUploadManagerSearchResult[] | undefined>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [recordToEdit, setRecordToEdit] = useState<CommissionUploadManagerSearchResult | undefined>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [bulkDeleteMethod, setBulkDeleteMethod] = useState<BulkDeleteMode | undefined>(undefined);
  const [recordsToDelete, setRecordsToDelete] = useState<CommissionUploadManagerSearchResult[] | undefined>();
  const [carrierId, setCarrieId] = useState<number | undefined>();
  const [paymentDate, setPaymentDate] = useState<Date | undefined>();
  const [productCategoryId, setProductCategoryId] = useState<number | undefined>();
  const [month, setMonth] = useState<number | undefined>();
  const [year, setYear] = useState<number | undefined>(new Date().getFullYear());
  const [useFullDate, setUseFullDate] = useState<boolean>(true);
  const viewOnlyAccess = false; // This was a permission once, check Git if interested
  const [selectedCarrierPaymentTids, setSelectedCarrierPaymentTids] = React.useState<number[]>([]);
  const [updateMultipleRecords, setUpdateMultipleRecords] = React.useState<boolean>(false);
  const editIcon: CSSProperties = { fontSize: "15px", color: "#1890ff" };
  const deleteIcon: CSSProperties = { fontSize: "15px", color: "#f5222d" };

  const centerAlign: CSSProperties = { textAlign: "center" };
  let uniqSelectedCarrierPaymentTids = new Set<number>();

  const onSelectCheckboxChange = (carrierPaymentTids: Key[]) => {
    carrierPaymentTids.forEach((carrierPaymentTid) => uniqSelectedCarrierPaymentTids.add(carrierPaymentTid as number));
    setSelectedCarrierPaymentTids(Array.from(uniqSelectedCarrierPaymentTids));
    setUpdateMultipleRecords(true);
  };

  function refreshGridAfterUpdate(
    updatedRecord: CommissionUploadManagerSearchResult | UpdatedEnsSaleCarrierPaymentRequest,
    updateSingleRecord?: boolean
  ) {
    setLoading(true);
    const copyOfSearchResults = searchResults?.slice();
    if (updateSingleRecord) {
      const updatedItemIdx = copyOfSearchResults?.findIndex(
        (sr) =>
          sr.ens_sale_carrier_payment_tid ==
          (updatedRecord as CommissionUploadManagerSearchResult).ens_sale_carrier_payment_tid
      );
      copyOfSearchResults![updatedItemIdx!] = updatedRecord as any; // Fix me and the surrounding lines, annoying bad code.
    } else {
      selectedCarrierPaymentTids.forEach((carrierPaymentTid) => {
        const updatedItemIdx = copyOfSearchResults?.findIndex(
          (sr) => sr.ens_sale_carrier_payment_tid == carrierPaymentTid
        );
        if (updatedRecord.ledger_category)
          copyOfSearchResults![updatedItemIdx!]["ledger_category"] = updatedRecord.ledger_category;
        if (updatedRecord.payment_date)
          copyOfSearchResults![updatedItemIdx!]["payment_date"] = updatedRecord.payment_date;
        if (updatedRecord.payment_effective_date)
          copyOfSearchResults![updatedItemIdx!]["payment_effective_date"] = updatedRecord.payment_effective_date;
        if (updatedRecord.payment_amount)
          copyOfSearchResults![updatedItemIdx!]["payment_amount"] = updatedRecord.payment_amount;
        copyOfSearchResults![updatedItemIdx!] = new CommissionUploadManagerSearchResult({
          ...copyOfSearchResults![updatedItemIdx!],
        });
      });
      uniqSelectedCarrierPaymentTids = new Set<number>();
      setSelectedCarrierPaymentTids([]);
    }
    setSearchResults(copyOfSearchResults);
    setLoading(false);
  }

  function refreshGridAfterDelete(recordsToDelete: CommissionUploadManagerSearchResult[]) {
    const ensSaleCarrierPaymentTidsToDelete = recordsToDelete.map((r) => r.ens_sale_carrier_payment_tid);
    setLoading(true);
    const updatedSearchResults = searchResults
      ?.slice()
      .filter((sr) => !ensSaleCarrierPaymentTidsToDelete.includes(sr.ens_sale_carrier_payment_tid!));
    setSearchResults(updatedSearchResults);
    setSelectedCarrierPaymentTids([]);
    setLoading(false);
  }

  const rightAlign: CSSProperties = { textAlign: "right" };

  function getCarrierPaymentsByPolicyNumber() {
    authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
      .then((client) => {
        setLoading(true);
        if (policyNumber) {
          return client.getUploadManagerSearchResultsByPolicyNumber(policyNumber);
        }
      })
      .then((response) => {
        setSearchResults(response);
        setLoading(false);
      })
      .catch(() => {
        message.error(`Failed to retrieve records`);
        setLoading(false);
      });
  }

  function getCarrierPaymentsBySheetName() {
    authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
      .then((client) => {
        setLoading(true);
        if (sheetName) {
          return client.getUploadManagerSearchResultsBySheetName(sheetName);
        }
      })
      .then((response) => {
        setSearchResults(response);
        setLoading(false);
      })
      .catch(() => {
        message.error(`Failed to retrieve records`);
        setLoading(false);
      });
  }

  function getCarrierPaymentsByUploadInfo() {
    authenticatedClient(MicroservicesEndpoint.finance, CommissionsUploadManagerClient)
      .then((client) => {
        setLoading(true);
        if (carrierId && (paymentDate || (month && year))) {
          return client.getUploadManagerSearchResultsByUploadInfo(
            carrierId!,
            paymentDate,
            year,
            month,
            productCategoryId
          );
        }
      })
      .then((response) => {
        setSearchResults(response);
        setLoading(false);
      })
      .catch(() => {
        message.error(`Failed to retrieve records`);
        setLoading(false);
      });
  }

  function renderTable(results: CommissionUploadManagerSearchResult[]) {
    const SearchResultsColumns: ColumnsType<CommissionUploadManagerSearchResult> = [
      {
        title: "Id",
        defaultSortOrder: "descend" as SortOrder,
        dataIndex: ["ens_sale_carrier_payment_tid"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => record?.ens_sale_carrier_payment_tid,
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) =>
          (a.ens_sale_carrier_payment_tid ?? 0) < (b.ens_sale_carrier_payment_tid ?? 0) ? 1 : -1,
      },
      {
        title: "Sale Tid",
        defaultSortOrder: "descend" as SortOrder,
        dataIndex: ["ensSaleTid"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => record?.ens_sale_tid,
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) =>
          (a.ens_sale_tid ?? 0) < (b.ens_sale_tid ?? 0) ? 1 : -1,
      },
      {
        title: "Carrier",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["carrierName"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => record?.carrier_name,
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) => {
          return (a.carrier_name ?? "").localeCompare(b.carrier_name ?? "");
        },
      },
      {
        title: "GL #",
        defaultSortOrder: "descend" as SortOrder,
        dataIndex: ["ledgerCategory"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => record?.ledger_category,
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) =>
          (a.ledger_category ?? 0) < (b.ledger_category ?? 0) ? 1 : -1,
      },
      {
        title: "Account #",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["accountNumber"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => record?.policy_number,
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) => {
          return (a.policy_number ?? "").localeCompare(b.policy_number ?? "");
        },
      },
      {
        title: "Payment Date",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["paymentDate"],
        render: (value: any, record: CommissionUploadManagerSearchResult) =>
          record?.payment_date ? localizeDate(record?.payment_date) : "",
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) => {
          return new Date(a.payment_date ?? 0) < new Date(b.payment_date ?? 0) ? 1 : -1;
        },
      },
      {
        title: "Paymenent Eff. Date",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["paymentEffectiveDate"],
        render: (value: any, record: CommissionUploadManagerSearchResult) =>
          record?.payment_effective_date ? localizeDate(record?.payment_effective_date) : "",
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) => {
          return new Date(a.payment_effective_date ?? 0) < new Date(b.payment_effective_date ?? 0) ? 1 : -1;
        },
      },
      {
        title: "Payment Amount",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["paymentAmount"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => {
          return `$${record.payment_amount?.toLocaleString("en-US", { minimumFractionDigits: 2 })}`;
        },
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) => {
          return (a.payment_amount ?? 0) < (b.payment_amount ?? 0) ? 1 : -1;
        },
      },
      {
        title: "Entered On Date",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["paymentAmount"],
        render: (value: any, record: CommissionUploadManagerSearchResult) =>
          record?.entered_date ? localizeDate(record?.entered_date) : "",
        sorter: (a: CommissionUploadManagerSearchResult, b: CommissionUploadManagerSearchResult) => {
          return new Date(a.entered_date ?? 0) < new Date(b.entered_date ?? 0) ? 1 : -1;
        },
      },
      {
        title: "Entered By Sheet",
        defaultSortOrder: null as SortOrder,
        dataIndex: ["paymentAmount"],
        render: (value: any, record: CommissionUploadManagerSearchResult) => record.sheet_name,
      },
      {
        title: "Edit",
        render: (value: any, record: CommissionUploadManagerSearchResult) => {
          if (!viewOnlyAccess)
            return (
              <EditOutlined
                style={editIcon}
                onClick={() => {
                  setRecordToEdit(record);
                  setShowEditModal(true);
                  setUpdateMultipleRecords(false);
                }}
              />
            );
        },
        width: 50,
      },
      {
        title: "Delete",
        render: (value: any, record: CommissionUploadManagerSearchResult) => {
          if (!viewOnlyAccess)
            return (
              <DeleteOutlined
                style={deleteIcon}
                onClick={() => {
                  setRecordsToDelete(new Array<CommissionUploadManagerSearchResult>(record));
                  setShowDeleteModal(true);
                }}
              />
            );
        },
        width: 50,
      },
    ];

    return (
      <>
        <Table
          id="commission-upload-search-table"
          columns={SearchResultsColumns}
          rowKey={"ens_sale_carrier_payment_tid"}
          rowSelection={{
            type: "checkbox",
            selectedRowKeys: selectedCarrierPaymentTids,
            onChange: onSelectCheckboxChange,
          }}
          dataSource={results}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 30,
          }}
        />
        {showEditModal && recordToEdit && (
          <EditCommissionRecordModal
            record={recordToEdit}
            updateMultipleRecords={updateMultipleRecords}
            recordKeysToUpdate={selectedCarrierPaymentTids}
            visible={showEditModal}
            closeModal={closeUpdateModal}
            refreshGrid={refreshGridAfterUpdate}
          />
        )}
        {showDeleteModal && recordsToDelete && (
          <DeleteCommissionRecordModal
            records={recordsToDelete}
            visible={showDeleteModal}
            bulkDeleteMode={bulkDeleteMethod}
            carrierId={carrierId!}
            productCategoryId={productCategoryId}
            paymentDate={paymentDate}
            paymentYear={year}
            paymentMonth={month}
            closeModal={closeDeleteModal}
            refreshGrid={refreshGridAfterDelete}
          />
        )}
      </>
    );
  }

  function closeUpdateModal() {
    setRecordToEdit(undefined);
    setShowEditModal(false);
    //getCarrierPayments();
  }

  function closeDeleteModal() {
    setRecordsToDelete(undefined);
    setShowDeleteModal(false);
    //getCarrierPayments();
  }

  function localizeDate(date: Date): string {
    let returnDate: Date = new Date();

    returnDate.setFullYear(date.getUTCFullYear());
    returnDate.setMonth(date.getUTCMonth());
    returnDate.setDate(date.getUTCDate());

    return returnDate.toLocaleDateString();
  }

  function beginSearchButton(func: () => void) {
    return (
      <Button style={{ width: "20%" }} className="mt-3" loading={isLoading} onClick={func}>
        Search
      </Button>
    );
  }

  function renderPolicySheetNameForm() {
    const exportedSearchResultsHeaders = [
      { label: "Carrier Payment Tid", key: "ens_sale_carrier_payment_tid" },
      { label: "Ens Sale Tid", key: "ensSaleTid" },
      { label: "Carrier", key: "carrierName" },
      { label: "Payment Ledger Category", key: "ledgerCategory" },
      { label: "Account #", key: "accountNumber" },
      { label: "Payment Date", key: "paymentDate" },
      { label: "Payment Effective Date", key: "paymentEffectiveDate" },
      { label: "Payment Amount", key: "paymentAmount" },
    ];

    const buildCSVUrl = () => {
      const searchFilters = [policyNumber, sheetName, carrierId, paymentDate, year, month, productCategoryId].filter(
        (f) => f
      );
      return `CommissionManagement-${searchFilters.join(",")}-${moment(new Date()).format("MM-DD-YYYY")}.csv`;
    };

    return (
      <div style={{ textAlign: "center" }}>
        <h3>Search</h3>
        <div className="col-14" style={{ paddingLeft: "360px" }}>
          <Collapse style={{ maxWidth: "50%", textAlign: "center" }}>
            <Collapse.Panel header={"By Policy Number"} key="1">
              <Input
                type="string"
                className="um-policy-number"
                placeholder="Policy Number"
                value={policyNumber}
                onChange={(e) => setPolicyNumber(e.target.value)}
                style={{ display: "inline" }}
              />
              {beginSearchButton(getCarrierPaymentsByPolicyNumber)}
            </Collapse.Panel>
            <Collapse.Panel header={"By Sheet Name"} key="2">
              <Input
                type="string"
                className="um-sheet-name"
                placeholder="Sheet Name"
                value={sheetName}
                onChange={(e) => setSheetName(e.target.value)}
              />
              {beginSearchButton(getCarrierPaymentsBySheetName)}
            </Collapse.Panel>
            <Collapse.Panel header={"By Carrier, Date and Product"} key="3">
              <CarrierSelect onCarrierSelected={(carrierId: number) => setCarrieId(carrierId)} />
              <Button
                onClick={() => {
                  if (useFullDate) {
                    setUseFullDate(false);
                    setPaymentDate(undefined);
                  } else {
                    setUseFullDate(true);
                    setMonth(undefined);
                    setYear(undefined);
                  }
                }}
              >
                {useFullDate ? "Search by Month & Year" : "Search by Full Date"}
              </Button>
              {useFullDate ? (
                <Input
                  type="date"
                  className="um-payment-date"
                  value={sheetName}
                  onChange={(e) => setPaymentDate(new Date(e.target.value))}
                  style={centerAlign}
                />
              ) : (
                <>
                  <Input
                    type="number"
                    className="um-payment-date-month"
                    value={month}
                    onChange={(e) => setMonth(parseInt(e.target.value))}
                    style={centerAlign}
                    placeholder="month"
                  />
                  <Input
                    type="number"
                    className="um-payment-date-year"
                    value={year}
                    onChange={(e) => setYear(parseInt(e.target.value))}
                    style={centerAlign}
                    placeholder="year"
                  />
                </>
              )}
              {carrierId === EnsProductCarrierTid.Aetna && (
                <Select
                  style={{ width: "100%" }}
                  allowClear
                  showSearch
                  onChange={(value: EnsProductCategory) => setProductCategoryId(value)}
                  placeholder="product"
                >
                  {validProducts.map((product, i) => (
                    <Option key={i} value={product.value}>
                      {product.text}
                    </Option>
                  ))}
                </Select>
              )}
              {beginSearchButton(getCarrierPaymentsByUploadInfo)}
            </Collapse.Panel>
          </Collapse>
        </div>
        {searchResults && (
          <>
            <Divider />
            {(sheetName || (carrierId && paymentDate) || (carrierId && year && month)) && (
              <div style={centerAlign}>
                <h3>Edit</h3>
                <Button
                  disabled={searchResults.length === 0}
                  style={{ backgroundColor: "#1890ff", color: "white", marginBottom: "10px" }}
                  onClick={() => {
                    setShowDeleteModal(true);
                    setRecordsToDelete(searchResults);
                    setBulkDeleteMethod("all");
                  }}
                >
                  Delete All
                </Button>
              </div>
            )}
            <div style={centerAlign}>
              <Button
                disabled={searchResults.length === 0}
                style={{ backgroundColor: "#1890ff", color: "white", marginBottom: "10px" }}
                onClick={() => {
                  setShowEditModal(true);
                  const searchResultsToEdit = searchResults.filter((sr) =>
                    selectedCarrierPaymentTids.includes(sr.ens_sale_carrier_payment_tid!)
                  );
                  setRecordToEdit(searchResultsToEdit[0]);
                }}
              >
                Edit Selected
              </Button>
              <Button
                disabled={searchResults.length === 0}
                style={{ backgroundColor: "#f5222d", color: "white", marginBottom: "10px" }}
                onClick={() => {
                  setShowDeleteModal(true);
                  const searchResultsToDelete = searchResults.filter((sr) =>
                    selectedCarrierPaymentTids.includes(sr.ens_sale_carrier_payment_tid!)
                  );
                  setRecordsToDelete(searchResultsToDelete);
                  setBulkDeleteMethod("by_tid");
                }}
              >
                Delete Selected
              </Button>
            </div>
            {renderTable(searchResults)}
            <div style={rightAlign}>
              <CSVLink data={searchResults} filename={buildCSVUrl()} headers={exportedSearchResultsHeaders}>
                <Button key="export-sales" type="primary">
                  <DownloadOutlined />
                  Export All
                </Button>
              </CSVLink>
            </div>
          </>
        )}
        <div style={{ paddingBottom: "20px" }}>
          <Divider />
          <CashMatchingSubApp />
        </div>
      </div>
    );
  }

  return <div> {renderPolicySheetNameForm()}</div>;
}
